import { Link } from "react-router";

const SlideBar = () => {
  const handleDropdownClick = (e) => {
    e.preventDefault();
    const parent = e.currentTarget.parentElement;
    const subMenu = parent.querySelector(".sub-menu");
    const siblings = parent.parentElement.children;

    // Toggle clicked submenu
    subMenu.style.display = subMenu.style.display === "none" ? "block" : "none";

    // Hide other submenus
    Array.from(siblings).forEach((sibling) => {
      if (sibling !== parent) {
        const siblingSubMenu = sibling.querySelector(".sub-menu");
        if (siblingSubMenu) {
          siblingSubMenu.style.display = "none";
        }
      }
    });
  };

  return (
    <>
      <aside className="slide-bar">
        <button
          type="button"
          title="close menu"
          className="close-mobile-menu border-0 bg-transparent"
          onClick={() => {
            document.querySelector(".slide-bar")?.classList.remove("show");
            document.body.classList.remove("on-side");
            document.querySelector(".body-overlay")?.classList.remove("active");
            document
              .querySelector(".open-mobile-menu > a")
              ?.classlist.remove("active");
          }}
        >
          <i className="fa fa-xmark"></i>
        </button>

        <nav className="side-mobile-menu">
          <ul>
            <li className="has-dropdown">
              <Link to="#" onClick={handleDropdownClick}>
                Solutions <i className="fa fa-angle-down"></i>
              </Link>
              <ul className="sub-menu solution-sub-menu">
                <li>
                  <Link to="/solutions-pay">
                    <div className="icon-container d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/icons/pay.svg"
                        alt="pay"
                        className="icon"
                      />
                    </div>
                    <div className="link-text text-start">
                      <h6>Itump Pay</h6>
                      <p className="mb-0">Seamless Payment Solutions</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/solutions-wallet">
                    <div className="icon-container d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/icons/wallet.svg"
                        alt="wallet"
                        className="icon"
                      />
                    </div>
                    <div className="link-text text-start">
                      <h6>Itump Wallet</h6>
                      <p className="mb-0">Business Transactions Simplified</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/solutions-health">
                    <div className="icon-container d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/icons/health.svg"
                        alt="health"
                        className="icon"
                      />
                    </div>
                    <div className="link-text text-start">
                      <h6>Itump Health</h6>
                      <p className="mb-0">Optimise your business health</p>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link to="/solutions-docuhub">
                    <div className="icon-container d-flex align-items-center justify-content-center">
                      <img
                        src="assets/img/icons/docuhub.svg"
                        alt="docuhub"
                        className="icon"
                      />
                    </div>
                    <div className="link-text text-start">
                      <h6>Itump Corpcrypt</h6>
                      <p className="mb-0">
                        Secure vital business information & documents
                      </p>
                    </div>
                  </Link>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <Link to="#" onClick={handleDropdownClick}>
                {" "}
                Services <i className="fa fa-angle-down"></i>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/service-register">
                    <img
                      src="assets/img/icons/register.svg"
                      alt="register"
                      className="icon"
                    />
                    Register New Business
                  </Link>
                </li>
                <li>
                  <Link to="/service-ein">
                    <img
                      src="assets/img/icons/globe.svg"
                      alt="globe"
                      className="icon"
                    />
                    Get a New EIN
                  </Link>
                </li>
                <li>
                  <Link to="/service">
                    <img
                      src="assets/img/icons/file.svg"
                      alt="file"
                      className="icon"
                    />
                    {`FinCen's BOI`}
                  </Link>
                </li>
                <li>
                  <Link to="/service-build">
                    <img
                      src="assets/img/icons/credit.svg"
                      alt="credit"
                      className="icon"
                    />
                    Build Business Credit
                  </Link>
                </li>
                <li>
                  <Link to="/service-change">
                    <img
                      src="assets/img/icons/users.svg"
                      alt="users"
                      className="icon"
                    />
                    Change your Agent
                  </Link>
                </li>
                <li>
                  <Link to="/service-copyright">
                    <img
                      src="assets/img/icons/copyright.svg"
                      alt="copyright"
                      className="icon"
                    />
                    Copyright & Trademark
                  </Link>
                </li>
                <li>
                  <Link to="/service-annual">
                    <img
                      src="assets/img/icons/file.svg"
                      alt="file"
                      className="icon"
                    />
                    Annual Filing
                  </Link>
                </li>
                <li>
                  <Link to="/service-close">
                    <img
                      src="assets/img/icons/arrow-right.svg"
                      alt="arrow right"
                      className="icon"
                    />
                    Close your Business
                  </Link>
                </li>
              </ul>
            </li>
            <li className="has-dropdown">
              <Link to="#" onClick={handleDropdownClick}>
                {" "}
                Resources <i className="fa fa-angle-down"></i>{" "}
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/about">
                    <img
                      src="assets/img/icons/building.svg"
                      alt="building"
                      className="icon"
                    />
                    About
                  </Link>
                </li>
                <li>
                  <Link to="/customer-stories">
                    <img
                      src="assets/img/icons/profile-tick.svg"
                      alt="profile-tick"
                      className="icon"
                    />
                    Customer
                  </Link>
                </li>
                <li>
                  <Link to="/news">
                    <img
                      src="assets/img/icons/news.svg"
                      alt="news"
                      className="icon"
                    />
                    News
                  </Link>
                </li>
                <li>
                  <Link to="/pricing">
                    <img
                      src="assets/img/icons/dollar-circle.svg"
                      alt="dollar-circle"
                      className="icon"
                    />
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link to="/team">
                    <img
                      src="assets/img/icons/people.svg"
                      alt="people"
                      className="icon"
                    />
                    Team
                  </Link>
                </li>
                <li>
                  <Link to="/support">
                    <img
                      src="assets/img/icons/contact-support.svg"
                      alt="support"
                      className="icon"
                    />
                    Help & Support
                  </Link>
                </li>
                <li>
                  <Link to="/contact">
                    <img
                      src="assets/img/icons/envelope.svg"
                      alt="envelope"
                      className="icon"
                    />
                    Contact
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#"> Enterprise </Link>
            </li>
            <li>
              <Link to="/pricing"> Pricing </Link>
            </li>
          </ul>
        </nav>
      </aside>
      <div
        className="body-overlay"
        onClick={() => {
          document.querySelector(".body-overlay").classList.remove("active");
          document.querySelector(".slide-bar").classList.remove("show");
          document.body.classList.remove("on-side");
          document
            .querySelector(".open-mobile-menu > a")
            ?.classlist.remove("active");
        }}
      />
    </>
  );
};

export default SlideBar;
