import React from "react";
import { CONTENTS } from "./data";
import TableOfContent from "../../../component/guidelines/TableOfContent";

const PartnersPolicy = () => {
    return (
        <div
            style={{
                color: "#333",
                maxWidth: "800px",
                lineHeight: "1.6",
            }}
        >
            <h1
                style={{
                    fontSize: "48px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    color: "#222",
                }}
            >
                PARTNERSHIP AGREEMENT
            </h1>
            <div
                style={{
                    marginBottom: "30px",
                }}
            >
                This Partnership Agreement (“Agreement”) is made and entered into by and between itump (“the Platform”), a company dedicated
                {" "}to providing business services via a mobile-first, finance-embedded platform, and the Partner (“the Agency” or “Partner”)
                {" "}that has been qualified and selected to provide specific business-related services through the platform. The purpose of this
                {" "}Agreement is to define the terms under which the Partner will perform services for itump, ensure compliance with standards,
                {" "}and outline the limits of liabilities for both parties.
            </div>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            {/* table of content */}
            <section style={{ marginBottom: "30px" }}>
                <h2
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                    }}
                    id="table-of-contents"
                >
                    TABLE OF CONTENTS
                </h2>
                <TableOfContent contents={CONTENTS} />
            </section>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            <dl>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="purpose"
                    >
                        1. PURPOSE OF THE AGREEMENT
                    </dt>
                    <dd>
                        itump serves as a service aggregation platform that connects small businesses, startups, and entrepreneurs with qualified partners
                        {" "}offering business-related services such as business registration, compliance assistance, document processing, and other business
                        {" "}support services. The Platform has created a partner program whereby selected, qualified agencies (the Partners) provide these
                        {" "}services via the itump platform to users (business owners and entrepreneurs). This Agreement sets forth the responsibilities,
                        {" "}obligations, terms of service, and the liabilities that both itump and the Partner agree to uphold in the execution of this partnership.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="partner-appointment"
                    >
                        2. APPOINTMENT OF PARTNER
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div>
                            itump hereby appoints the Partner to deliver specific business-related services through the itump app.
                            {" "}These services may include but are not limited to:
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                Business formation (e.g., LLC, corporation).
                            </div>
                            <div>
                                Registered agent services.
                            </div>
                            <div>
                                BOI (Beneficial Ownership Information) reporting.
                            </div>
                            <div>
                                TaxPlanning and filing assistance.
                            </div>
                                Payment processing services.
                            <div>
                            </div>
                            <div>
                                Business Planning
                            </div>
                            <div>
                                Loan Brokerage 
                            </div>
                            <div>
                                Insurance Brokerage
                            </div>
                            <div>
                                Business Credit Building
                            </div>
                                Asset Management 
                            <div>
                            </div>
                            <div>
                                Software and Technology Development
                            </div>
                            <div>
                                Web and Graphics Designs
                            </div>
                            <div>
                                UX UI Creations Etc
                            </div>
                        </div>
                        <div>
                            The Partner agrees to provide these services in accordance with the specifications and quality standards
                            {" "}as described within the platform’s framework.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="obligations"
                    >
                        3. PERFORMANCE STANDARDS & OBLIGATIONS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <span>The Partner commits to the following obligations and standards:</span>
                        <ul>
                            <li>
                                Professionalism and Service Quality: Partners must perform their services with due diligence, professionalism,
                                {" "}and in accordance with industry best practices. All tasks must meet itump&apos;s quality requirements as well
                                {" "}as any specific requirements stipulated in the work orders.
                            </li>
                            <li>
                                Compliance with Laws and Regulations: The Partner agrees to abide by all applicable local, state, and federal laws,
                                {" "}regulations, and industry standards. This includes, but is not limited to, proper handling of client data, filing
                                {" "}regulations, and other compliance-related requirements.
                            </li>
                            <li>
                                Timely Completion: Partners must ensure that services are completed within the agreed timelines. Failure to meet
                                {" "}deadlines or deliver subpar services will result in penalties or termination of the agreement as outlined
                                {" "}in this document.
                            </li>
                            <li>
                                Use of Tools: Partners agree to use the tools and systems provided by itump to track progress, update status, and
                                {" "}manage their tasks. They also agree to provide users with access to relevant documents and reports related to
                                {" "}their services in a timely manner.
                            </li>
                        </ul>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="limitations"
                    >
                        4. LIABILITIES & LIMITATIONS
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-2">
                            <span>itump&apos;s Liabilities:</span>
                            <div>
                                itump is not liable for any delays, errors, or omissions resulting from the Partner&apos;s actions, negligence, or poor
                                service quality. itump will make reasonable efforts to ensure that partners meet their obligations; however, the Platform
                                is not responsible for the Partner&appos;s failures to deliver the services accurately or on time.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span>Partner&apos;s Liabilities:</span>
                            <div>
                                The Partner shall assume full responsibility for any damages, fines, penalties, or costs resulting from:
                            </div>
                            <ul>
                                <li>
                                    Poor service delivery, errors, or omissions.
                                </li>
                                <li>
                                    Failure to meet deadlines or compliance requirements.
                                </li>
                                <li>
                                    Fraudulent behavior or violations of applicable laws.
                                </li>
                            </ul>
                        </div>
                        <div>
                            The Partner agrees to indemnify and hold itump harmless from any loss, damage, or liability resulting from the Partner&apos;s
                            actions or failure to perform as outlined in this Agreement. This includes, but is not limited to, damages arising from client
                            complaints, incorrect filings, non-compliance with tax laws, or violations of the platform&apos;s terms.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="penalties"
                    >
                        5. PENALTIES FOR POOR OR FRAUDULENT DELIVERIES
                    </dt>
                    <dd>
                        <ul>
                            <li>
                                Late Delivery: Partners must complete services within the designated timeframe. Late deliveries, without
                                {" "}valid justification, will incur a penalty fee of up to 10% of the total service fee for each day the
                                {" "}task is overdue, up to a maximum of 30% of the original service fee.
                            </li>
                            <li>
                                Poor Quality or Non-Compliance: If services do not meet the agreed-upon quality standards or fail to comply
                                {" "}with legal requirements, the Partner must correct and re-deliver the service at no additional cost to the
                                {" "}user. Failure to do so within a reasonable time will result in a penalty fee of 20% of the service fee and
                                {" "}potential removal from the itump platform.
                            </li>
                            <li>
                                Fraudulent Activity: Partners found engaging in fraudulent activities, including misrepresentation of service
                                {" "}capabilities, user data tampering, or submission of false documentation, will have their contract terminated
                                {" "}immediately, with no compensation. Additionally, partners will be liable for any losses itump incur as a result
                                {" "}of fraudulent actions, including legal fees, damages, and other costs incurred by the Platform or its users.
                            </li>
                        </ul>
                    </dd>
                </div>
                {/* start: complete */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="fraudulent-applications"
                    >
                        6. FRAUDULENT APPLICATIONS
                    </dt>
                    <dd>
                        <div className="d-flex flex-column gap-3">
                            <div className="d-flex flex-column gap-2">
                                <span>Partner Application Process:</span>
                                <div>
                                    All partners must undergo a thorough vetting process, which includes background checks,
                                    {" "}verification of credentials, and proof of expertise. Any fraudulent application, 
                                    {" "}misrepresentation of qualifications, or failure to provide accurate information
                                    {" "}will result in immediate disqualification from the partner program.
                                </div>
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <span>Indemnification for Fraudulent Activity:</span>
                                <div>
                                    In the event that a partner is found to have fraudulently applied to join the platform, or
                                    {" "}engages in fraudulent practices after acceptance, they will be liable for indemnifying
                                    {" "}itump against all resulting losses. This includes reputational damage, user complaints,
                                    {" "}and any legal actions that may arise from such behavior.
                                </div>
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="agreement-termination"
                    >
                        7. TERMINATION OF AGREEMENT
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <span >
                            This Agreement may be terminated by either party under the following conditions:
                        </span>
                        <ul>
                            <li>
                                By itump: If the Partner fails to comply with the terms outlined, provides poor or fraudulent
                                {" "}services, or engages in unethical or illegal activities.
                            </li>
                            <li>
                                By the Partner: The Partner may terminate the agreement with a 30-day written notice to itump.
                                {" "}However, if services remain incomplete or payment issues are unresolved, the Partner must
                                {" "}settle any outstanding payments or penalties before termination is processed.
                            </li>
                        </ul>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="confidentiality"
                    >
                        8. CONFIDENTIALITY
                    </dt>
                    <dd>
                        Both parties agree to keep all business information, customer data, financial data, and any proprietary
                        {" "}information confidential and not disclose it to third parties without prior written consent, except
                        {" "}where required by law. The confidentiality obligation will survive the termination of this Agreement.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="governed-law"
                    >
                        9. GOVERNED LAW
                    </dt>
                    <dd>
                        This Agreement is governed by and construed in accordance with the laws of the jurisdiction in which itump
                        {" "}is registered, and any disputes arising from this Agreement shall be resolved in the appropriate courts
                        {" "}or through arbitration in that jurisdiction.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="dispute-resolution"
                    >
                        10. DISPUTE RESOLUTION
                    </dt>
                    <dd>
                        In the event of a dispute, both parties agree to first attempt mediation before proceeding to litigation.
                        {" "}Any unresolved disputes will be handled through arbitration, with the costs split equally between the parties involved.
                    </dd>
                </div>
                {/* start: complete */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="amendments"
                    >
                        11. AMENDMENTS
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        itump reserves the right to modify or amend this Agreement at any time. Any modifications or amendments will be
                        {" "}provided to the Partner in writing and will become effective upon mutual agreement or after a designated period.
                        {" "}The Partner&apos;s continued participation in the itump program constitutes acceptance of any changes.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="acceptance"
                    >
                        12. ACKNOWLEDGE AND ACCEPTANCE
                    </dt>
                    <dd>
                        <div>
                            By clicking the “I Agree” button, the Partner acknowledges that they have read, understood, and agreed to the terms
                            {" "}and conditions outlined in this Agreement. The Partner affirms that they are authorized to enter into this
                            {" "}Agreement on behalf of their agency or business entity.
                        </div>
                        <div>
                            This Partnership Agreement defines the terms of service, obligations, liabilities, and penalties associated with itump&apos;s
                            {" "}partnership program. It also includes an acknowledgment clause where acceptance of the terms is done by clicking the
                            {" "}“I Agree” button, ensuring that the Partner agrees to be bound by these terms upon initiating the partnership.
                        </div>
                        <div>
                            This revised version removes the appointment listing and includes a clear acknowledgment for users to agree to the terms
                            {" "}by clicking “I Agree”. The agreement is structured to ensure that both itump and its partners are protected while
                            {" "}maintaining a fair, legally sound working relationship.
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default PartnersPolicy
