import { Routes, Route } from "react-router";
import AgreementsLayout from "./AgreementsLayout";
// pages
import Eula from "./eula/Eula";
import Disclaimer from "./disclaimer/Disclaimer";
import PartnersPolicy from "./partners-policy/PartnersPolicy";
import Privacy from "./privacy/PrivacyPolicy";
import ReturnPolicy from "./return-policy/ReturnPolicy";
import TermsOfUse from "./terms-of-service/Terms";

export default function AgreementsRoutes() {
    return (
        <Routes>
            <Route path="/" element={<AgreementsLayout />}>
                <Route path="eula" element={<Eula />} />
                <Route path="disclaimer" element={<Disclaimer />} />
                <Route path="partners-policy" element={<PartnersPolicy />} />
                <Route path="return-policy" element={<ReturnPolicy />} />
                <Route path="privacy" element={<Privacy />} />
                <Route path="terms-of-service" element={<TermsOfUse />} />
            </Route>
        </Routes>
    )
}
