import React from "react";
import { Link } from "react-router";

const FooterSection = () => {
  return (
    <>
      <section className="footer-section p-t-150 p-b-65 p-t-lg-120 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-12">
              <div className="footer-newsletter-wrap">
                <div className="footer-top">
                  <div className="footer-logo">
                    <Link to="/" className="d-inline-block w-100">
                      <img
                        src="assets/img/logo/logo.svg"
                        alt="logo"
                        className="w-100"
                      />
                    </Link>
                  </div>
                  <p className="footer-desc mb-0">
                    Your trusted partner in business growth, allowing you focus
                    on what truly matters: growth and innovation.
                  </p>
                </div>
                <div className="footer-newsletter-content">
                  <p className="newsletter-title">
                    Subscribe to our newsletter
                  </p>
                  <p className="newsletter-body">
                    Get tips, technical guides, and best practices. Twice a
                    month. Right in your inbox.
                  </p>
                </div>
                <div className="newsletter-form">
                  <input type="text" placeholder="Email Address" />
                  <button
                    type="submit"
                    title="send"
                    className="d-flex align-items-center justify-content-center"
                  >
                    <i className="fa fa-arrow-right-long"></i>
                  </button>
                </div>

                <div className="address-container mt-6">
                  <h6 className="newsletter-title">
                    Address
                  </h6>
                  <p className="newsletter-body ">
                    30 Wall Str, 8th FL,
                    New York, NY 10005
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-none d-md-block">
              <div className="footer-menu">
                <p>Company</p>
                <ul>
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Customer Stories</Link>
                  </li> */}
                  {/* <li>
                    <Link to="#">News</Link>
                  </li> */}
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/team">Team</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Help & Support</Link>
                  </li> */}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 col-6 d-none d-md-block">
              <div className="footer-menu">
                <p>Solutions</p>
                <ul>
                  <li>
                    <Link to="/solutions-pay">Pay</Link>
                  </li>
                  <li>
                    <Link to="/solutions-wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/solutions-health">Health</Link>
                  </li>
                  <li>
                    <Link to="/solutions-docuhub">Corpcrypt</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Asset</Link>
                  </li>
                  <li>
                    <Link to="#">University</Link>
                  </li>
                  <li>
                    <Link to="#">Studio</Link>
                  </li>
                  <li>
                    <Link to="#">Marketing</Link>
                  </li>
                  <li>
                    <Link to="#">Feedback</Link>
                  </li>
                  <li>
                    <Link to="#">Social</Link>
                  </li>
                  <li>
                    <Link to="#">CRM</Link>
                  </li>
                  <li>
                    <Link to="#">Connect</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-4 d-none d-md-block">
              <div className="footer-menu">
                <p>Services</p>
                <ul>
                  <li>
                    <Link to="/service-register">Register New Business</Link>
                  </li>
                  <li>
                    <Link to="/service-ein">Get a New EIN</Link>
                  </li>
                  <li>
                    <Link to="/service">FinCen's BOI</Link>
                  </li>
                  <li>
                    <Link to="/service-build">Build Business Credit</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Change your Agent</Link>
                  </li> */}
                  <li>
                    <Link to="/service-copyright">Copyright & Trademark</Link>
                  </li>
                  <li>
                    <Link to="/service-annual">Annual Filing</Link>
                  </li>
                  <li>
                    <Link to="/service-close">Close your Business</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row p-t-65 p-t-xs-30">
            <div className="col-xl-12">
              <div className="footer-thumb" style={{ flexWrap: "wrap" }}>
                <Link to="https://apps.apple.com/us/app/itump/id6443640069">
                  {" "}
                  <img src="assets/img/thumb/app.svg" alt="app" />
                </Link>
                <img src="assets/img/thumb/play.svg" alt="app" />
                <div className="footer-t-shape-1">
                  <svg
                    width="703"
                    height="95"
                    viewBox="0 0 703 95"
                    fill="none"
                    xmlns="http:/www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 95V11C1 5.47715 5.47715 1 11 1H703"
                      stroke="url(#paint0_linear_2068_82520)"
                      stroke-width="0.5"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2068_82520"
                        x1="703"
                        y1="0.999999"
                        x2="694.333"
                        y2="53.7359"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#7256FF" stop-opacity="0.6" />
                        <stop offset="1" stop-color="#322E52" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="footer-t-shape-2">
                  <svg
                    width="10"
                    height="2"
                    viewBox="0 0 10 2"
                    fill="none"
                    xmlns="http:/www.w3.org/2000/svg"
                  >
                    <path d="M0 1H10" stroke="#7256FF" stroke-opacity="0.6" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-menu-sm-wrapper d-flex d-md-none flex-column p-t-40">
            <div className="footer-menu footer-menu-sm pt-0">
              <button
                type="button"
                className="footer-menu-title bg-transparent border-0 text-white fw-bold d-block w-100 text-start mb-0 position-relative"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-menu-1"
                aria-expanded="false"
                aria-controls="collapse-menu-1"
              >
                <span className="d-block me-4">Company</span>
              </button>
              <div
                className="footer-menu-collapse collapse"
                id="collapse-menu-1"
              >
                <ul className="mt-3 pt-1">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Customer Stories</Link>
                  </li> */}
                  {/* <li>
                    <Link to="#">News</Link>
                  </li> */}
                  <li>
                    <Link to="/pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/team">Team</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Help & Support</Link>
                  </li> */}
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="footer-menu footer-menu-sm">
              <button
                type="button"
                className="footer-menu-title bg-transparent border-0 text-white fw-bold d-block w-100 text-start mb-0 position-relative"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-menu-2"
                aria-expanded="false"
                aria-controls="collapse-menu-2"
              >
                <span className="d-block me-4">Solutions</span>
              </button>
              <div
                className="footer-menu-collapse collapse"
                id="collapse-menu-2"
              >
                <ul className="mt-3 pt-1">
                  <li>
                    <Link to="/solutions-pay">Pay</Link>
                  </li>
                  <li>
                    <Link to="/solutions-wallet">Wallet</Link>
                  </li>
                  <li>
                    <Link to="/solutions-health">Health</Link>
                  </li>
                  <li>
                    <Link to="/solutions-docuhub">Corpcrypt</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Asset</Link>
                  </li>
                  <li>
                    <Link to="#">University</Link>
                  </li>
                  <li>
                    <Link to="#">Studio</Link>
                  </li>
                  <li>
                    <Link to="#">Marketing</Link>
                  </li>
                  <li>
                    <Link to="#">Feedback</Link>
                  </li>
                  <li>
                    <Link to="#">Social</Link>
                  </li>
                  <li>
                    <Link to="#">CRM</Link>
                  </li>
                  <li>
                    <Link to="#">Connect</Link>
                  </li> */}
                </ul>
              </div>
            </div>
            <div className="footer-menu footer-menu-sm">
              <button
                type="button"
                className="footer-menu-title bg-transparent border-0 text-white fw-bold d-block w-100 text-start mb-0 position-relative"
                data-bs-toggle="collapse"
                data-bs-target="#collapse-menu-3"
                aria-expanded="false"
                aria-controls="collapse-menu-3"
              >
                <span className="d-block me-4">Services</span>
              </button>
              <div
                className="footer-menu-collapse collapse"
                id="collapse-menu-3"
              >
                <ul className="mt-3 pt-1">
                  <li>
                    <Link to="/service-register">Register New Business</Link>
                  </li>
                  <li>
                    <Link to="/service-ein">Get a New EIN</Link>
                  </li>
                  <li>
                    <Link to="/service">FinCen's BOI</Link>
                  </li>
                  <li>
                    <Link to="/service-build">Build Business Credit</Link>
                  </li>
                  {/* <li>
                    <Link to="#">Change your Agent</Link>
                  </li> */}
                  <li>
                    <Link to="/service-copyright">Copyright & Trademark</Link>
                  </li>
                  <li>
                    <Link to="/service-annual">Annual Filing</Link>
                  </li>
                  <li>
                    <Link to="/service-close">Close your Business</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterSection;
