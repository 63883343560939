import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import ScrollToTop from "./component/ScrollToTop";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Blog from "./pages/blog/Blog";
import BlogDetails from "./pages/blog-details/BlogDetails";
import Contact from "./pages/contact/Contact";
// import CustomerStories from "./pages/customer-stories/CustomerStories";
import InTheNews from "./pages/in-the-news/InTheNews";
import JobDetails from "./pages/job-details/JobDetails";
import JobOpeningsEmpty from "./pages/job-openings-empty/JobOpeningsEmpty";
import JobOpening from "./pages/job-openings/JobOpening";
// import News from "./pages/news/News";
import PressRelease from "./pages/press-release/PressRelease";
import Pricing from "./pages/pricing/Pricing";
import Service from "./pages/service/Service";
import ServiceAnnual from "./pages/service-annual/ServiceAnnual";
import ServiceBuild from "./pages/service-build/ServiceBuild";
import ServiceClose from "./pages/service-close/ServiceClose";
import ServiceCopyright from "./pages/service-copyright/ServiceCopyright";
import ServiceEin from "./pages/service-ein/ServiceEin";
import ServiceRegister from "./pages/service-register/ServiceRegister";
import SolutionsDocuhub from "./pages/solutions-docuhub/SolutionsDocuhub";
import SolutionsHealth from "./pages/solutions-health/SolutionsHealth";
import SolutionsPay from "./pages/solutions-pay/SolutionsPay";
import SolutionsWallet from "./pages/solutions-wallet/SolutionsWallet";
import SupportCategory from "./pages/support-category/SupportCategory";
import SupportIndividualPage from "./pages/support-individual-page/SupportIndividualPage";
import Team from "./pages/team/Team";
import AgreementsRoutes from "./pages/guidelines/AgreementsRoutes";
import { useEffect } from "react";

const App = () => {

  return (
    <>

      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/blog" element={<Blog />} />
          <Route exact path="/blog-details" element={<BlogDetails />} />
          <Route exact path="/contact" element={<Contact />} />
          {/* <Route exact path="/customer-stories" element={<CustomerStories />} /> */}
          <Route exact path="/in-the-news" element={<InTheNews />} />
          <Route exact path="/job-details" element={<JobDetails />} />
          <Route
            exact
            path="/job-openings-empty"
            element={<JobOpeningsEmpty />}
          />
          <Route exact path="/job-openings" element={<JobOpening />} />
          {/* <Route exact path="/news" element={<News />} /> */}
          <Route exact path="/press-release" element={<PressRelease />} />

          <Route exact path="/pricing" element={<Pricing />} />
          <Route exact path="/service" element={<Service />} />
          <Route exact path="/service-annual" element={<ServiceAnnual />} />
          <Route exact path="/service-build" element={<ServiceBuild />} />
          <Route exact path="/service-close" element={<ServiceClose />} />
          <Route
            exact
            path="/service-copyright"
            element={<ServiceCopyright />}
          />
          <Route exact path="/service-ein" element={<ServiceEin />} />
          <Route exact path="/service-register" element={<ServiceRegister />} />

          <Route
            exact
            path="/solutions-docuhub"
            element={<SolutionsDocuhub />}
          />
          <Route exact path="/solutions-health" element={<SolutionsHealth />} />
          <Route exact path="/solutions-pay" element={<SolutionsPay />} />
          <Route exact path="/solutions-wallet" element={<SolutionsWallet />} />
          <Route exact path="/support-category" element={<SupportCategory />} />

          <Route
            exact
            path="/support-individual-page"
            element={<SupportIndividualPage />}
          />
          {/* <Route exact path="/support" element={<Support />} /> */}
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/*" element={<AgreementsRoutes />} />
          {/* <Route exact path="/eula" element={<Eula />} />
          <Route exact path="/disclaimer" element={<Disclaimer />} />
          <Route exact path="/partners-policy" element={<PartnersPolicy />} />
          <Route exact path="/return-policy" element={<ReturnPolicy />} />
          <Route exact path="/privacy" element={<PrivacyPolicy />} />
           <Route exact path="/terms-of-service" element={<Terms />} /> */}
        </Routes>
      </Router>
    </>
  );
}

export default App
