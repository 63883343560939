import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";
import ServiceCard from "../../component/ServiceCard";

const ServiceBuild = () => {
  const faqs = [
    {
      id: "one",
      question: "How long does it take to register a new business?",
      answer:
        "The time it takes to register a business varies depending on the state and complexity of your business structure. However, with Itump, the process is streamlined and typically takes just a few weeks.",
    },
    {
      id: "two",
      question: "Do I need a registered agent?",
      answer:
        "Yes, in most states, you'll need a registered agent. This is a person or entity designated to receive legal and official mail on behalf of your business. Itump can help you find a reliable registered agent service if needed.",
    },
    {
      id: "three",
      question: "Can I register a business if I'm not based in the U.S.?",
      answer:
        "Absolutely! Itump makes it possible to register a U.S. business from anywhere in the world. We'll guide you through the process and help you meet all the requirements.",
    },
    {
      id: "four",
      question: "What support is available after my business is registered?",
      answer:
        "Itump provides ongoing support even after your business is registered. We offer resources and guidance on compliance, taxes, and other aspects of running your business.",
    },
    {
      id: "five",
      question: "What business structures can I choose from?",
      answer:
        "You can choose from various business structures, including sole proprietorship, partnership, LLC, and corporation. Itump can help you determine the best structure for your needs.",
    },
    {
      id: "six",
      question: "Do I need an EIN to register my business?",
      answer:
        "An EIN (Employer Identification Number) is required for most businesses, especially if you plan to hire employees or operate as a corporation or partnership. Itump can help you obtain an EIN.",
    },
    {
      id: "seven",
      question: "Can I register my business in any state?",
      answer:
        "Yes, you can choose to register your business in any U.S. state. Itump can help you understand the specific requirements and regulations for each state.",
    },
    {
      id: "eight",
      question: "What documents do I need to provide?",
      answer:
        "The required documents vary depending on your business structure and the state you choose. Itump will guide you through the process and help you gather the necessary documents.",
    },
    {
      id: "nine",
      question: "Can I change my business structure later?",
      answer:
        "Yes, you can change your business structure later if needed. However, this involves legal and administrative procedures. Itump can help you with this process.",
    },
    {
      id: "ten",
      question: "What happens after my business is registered?",
      answer:
        "Once your business is registered, you'll receive a confirmation and any necessary documentation. You can then start operating your business legally. Itump will continue to support you with compliance, taxes, and other business needs.",
    },
  ];

  const requirementsItems = [
    {
      id: 1,
      title: "Business Entity",
      description: "A unique name for your new business.",
    },
    {
      id: 2,
      title: "Employer Identification Number (EIN)",
      description: "A unique name for your new business.",
    },
    {
      id: 3,
      title: "Business Bank Account",
      description: "A unique name for your new business.",
    },
    {
      id: 4,
      title: "Business Phone Number",
      description: "A unique name for your new business.",
    },
  ];

  const whatYouGetItems = [
    {
      id: 5,
      title: "Established Business Credit Profile",
      description: "A unique name for your new business.",
    },
    {
      id: 6,
      title: "Access to Lines of Credit and Loans",
      description: "A unique name for your new business.",
    },
    {
      id: 7,
      title: "Credit Monitoring",
      description: "A unique name for your new business.",
    },
    {
      id: 8,
      title: "Vendor and Trade Line Setup",
      description: "A unique name for your new business.",
    },
  ];
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-2 service-hero service-build-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-285 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  BUILD BUSINESS CREDIT
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Strengthen Your Business Financial Base
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Establish and grow your business credit to unlock new
                  financial opportunities
                </p>
              </div>
              <div>
                <Link
                  to="https://boi.itump.com/login"
                  className="theme-btn"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="250"
              >
                <img src="assets/img/thumb/thumb-42.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-15.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-16.png" alt="shape" />
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Why Business Credit Matters
                </h3>
                <p
                  className="common-title-body"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Unlock Growth and Financial Stability with Strong Business
                  Credit
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap file-with-us-wrap register-card-wrapper m-b-65 m-b-xs-40">
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/coin.svg" alt="coin" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Access Financing
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Secure loans and lines of credit with favorable terms
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img
                      src="assets/img/icons/profile-2user.svg"
                      alt="profile icon"
                    />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Attract Investors
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Demonstrate financial responsibility to potential
                      investors
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/trend-up.svg" alt="trend-up" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Manage Cash Flow
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Improve cash flow management by leveraging credit
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/maximize.svg" alt="maximize" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Expand Operations
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Use credit to invest in business growth and expansion
                      opportunities
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img
                      src="assets/img/icons/buildings-2.svg"
                      alt="building"
                    />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Build Business Reputation
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Establish a strong financial reputation with vendors and
                      suppliers
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-card-section p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <ServiceCard
              title="Requirements"
              imageSrc="assets/img/thumb/thumb-32.png"
              shapeSrc="assets/img/shape/shape-10.png"
              items={requirementsItems}
            />

            <ServiceCard
              title="What You Get"
              imageSrc="assets/img/thumb/thumb-33.png"
              shapeSrc="assets/img/shape/shape-11.png"
              items={whatYouGetItems}
            />
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <div className="steps-to-register-section how-it-work-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="common-title-wrap m-b-125 m-b-lg-80 m-b-md-60 m-b-xs-40">
                <h2
                  className="common-title mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  How It Works
                </h2>
              </div>
            </div>
          </div>
          <div
            className="step-container work-step-container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="step-wrapper register-steps-wrap work-step-wrapper d-flex flex-column position-relative z-1">
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number with-border fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    1
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">Assessment</h3>
                    <p className="step-desc mb-0">
                      Start by evaluating your current business credit status.
                      Our tools will analyze your business's credit profile and
                      provide a detailed report.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/work-step-img-1.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    2
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Strategy Development
                    </h3>
                    <p className="step-desc mb-0">
                      Based on the assessment, we’ll create a tailored strategy
                      to build and improve your business credit.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/work-step-img-2.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    3
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">Implementation</h3>
                    <p className="step-desc mb-0">
                      Follow our step-by-step process to establish credit lines,
                      maintain good credit practices, and track your progress.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/work-step-img-3.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    4
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Monitoring and Adjustment
                    </h3>
                    <p className="step-desc mb-0">
                      Continuously monitor your credit status and adjust your
                      strategy as needed to ensure continued growth.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/work-step-img-4.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
            </div>
            <div className="step-shape h-100">
              <img
                src="assets/img/shape/step-border-shape-2.png"
                alt="border"
                className="h-100"
              />
            </div>
          </div>
          <div className="text-center p-t-175 p-t-xl-140 p-t-lg-100 p-t-md-80 p-t-xs-60">
            <Link to="https://boi.itump.com/login" className="theme-btn">
              Get Started <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="s-step-shape-1" data-aos="fade-up" data-aos-delay="50">
          <img src="assets/img/shape/shape-17.png" alt="shape" />
        </div>
      </div>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end  */}

      <FAQSection faqs={faqs} />

      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceBuild;
