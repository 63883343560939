import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SupportCategory = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* job section start  */}
      <section className="support-section bg-light overflow-hidden p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="support-section-title common-title-wrap common-title-light mx-auto text-md-center">
            <ul
              className="pagination d-flex align-items-center justify-content-md-center p-0 list-unstyled gap-1"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <li>
                <Link to="/support">Support</Link>
              </li>
              <li className="arrow-right d-flex align-items-center justify-content-center flex-shrink-0">
                <i className="fa fa-angle-right"></i>
              </li>
              <li>Getting Started</li>
            </ul>
            <h1
              className="common-title"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Getting Started
            </h1>
          </div>
          <div
            className="search-bar position-relative mx-auto"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <label
              for="country"
              className="search-icon position-absolute top-50 translate-middle-y"
            >
              <img src="assets/img/icons/search-normal.svg" alt="search" />
            </label>
            <input
              type="search"
              placeholder="Select Country"
              className="search-input w-100 border-0"
            />
          </div>
          <div className="question-blk-wrapper m-t-60">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="50">
                <div className="question-blk">
                  <h5 className="question-blk-title fw-bold mb-0">
                    Account & Billing
                  </h5>
                  <ul className="question-list p-0 list-unstyled">
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Can I upgrade or downgrade my plan?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a free trial available?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Are there any cancellation fees?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a setup fee?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    className="see-all-btn d-inline-flex align-items-center fw-bold"
                  >
                    See all <i className="fa fa-arrow-right-long"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div className="question-blk">
                  <h5 className="question-blk-title fw-bold mb-0">
                    Account & Billing
                  </h5>
                  <ul className="question-list p-0 list-unstyled">
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Can I upgrade or downgrade my plan?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a free trial available?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Are there any cancellation fees?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a setup fee?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    className="see-all-btn d-inline-flex align-items-center fw-bold"
                  >
                    See all <i className="fa fa-arrow-right-long"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="150">
                <div className="question-blk">
                  <h5 className="question-blk-title fw-bold mb-0">
                    Account Setup
                  </h5>
                  <ul className="question-list p-0 list-unstyled">
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>How do I create an account on Itump?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>
                          What are the system requirements for using Itump?
                        </span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>
                          How do I verify my email address after registration?
                        </span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>
                          How can I change my password after creating an
                          account?
                        </span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    className="see-all-btn d-inline-flex align-items-center fw-bold"
                  >
                    See all <i className="fa fa-arrow-right-long"></i>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6" data-aos="fade-up" data-aos-delay="250">
                <div className="question-blk">
                  <h5 className="question-blk-title fw-bold mb-0">
                    Understanding Itump Services
                  </h5>
                  <ul className="question-list p-0 list-unstyled">
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>What core services does Itump provide?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>How can I integrate other tools with Itump?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Are there any cancellation fees?</span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>
                          What are the benefits of using Itump’s services?
                        </span>
                        <i className="fa fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                  <Link
                    to="#"
                    className="see-all-btn d-inline-flex align-items-center fw-bold"
                  >
                    See all <i className="fa fa-arrow-right-long"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* job section end  */}

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SupportCategory;
