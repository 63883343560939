import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const JobOpening = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* job section start */}
      <section className="job-section team-hero bg-light overflow-hidden p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="job-section-wrapper mx-auto">
            <div className="hero-content common-title-wrap common-title-light mx-auto text-center m-b-60">
              <h1
                className="common-title"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                We’re making itump better. <br /> Join our team
              </h1>
              <p
                className="common-title-body mb-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Build your career with Itump. Explore exciting opportunities and
                innovate with us. <br /> Discover a supportive work environment
                where you can thrive.
              </p>
              <div
                className="job-info m-t-35 m-b-35 py-4 d-md-none d-flex align-items-center justify-content-between flex-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/briefcase-2.svg"
                    alt="briefcase"
                  />{" "}
                  Full-Time
                </p>
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/buildings-3.svg"
                    alt="building"
                  />{" "}
                  Engineering
                </p>
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/location.svg"
                    alt="briefcase"
                  />{" "}
                  New York, USA
                </p>
              </div>
              <Link
                to="#"
                className="apply-btn theme-btn d-md-none d-inline-block fw-medium"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Apply now
              </Link>
            </div>
            <div className="growth-form-wrap m-b-40">
              <form action="#">
                <div
                  className="growth-form"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="select-wrap">
                    <select
                      title="employment-type"
                      name="employment-type"
                      id="employment-type"
                    >
                      <option value="0">Employment Type</option>
                      <option value="1">Employment Type</option>
                      <option value="2">Employment Type</option>
                    </select>
                    <div className="icon">
                      <img
                        src="assets/img/icons/briefcase-2.svg"
                        alt="briefcase"
                      />
                    </div>
                    <div className="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                  <div className="select-wrap">
                    <select
                      title="department"
                      name="department"
                      id="department"
                    >
                      <option value="0">Department</option>
                      <option value="1">Department</option>
                      <option value="2">Department</option>
                    </select>
                    <div className="icon">
                      <img
                        src="assets/img/icons/buildings-3.svg"
                        alt="building"
                      />
                    </div>
                    <div className="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                  <div className="select-wrap">
                    <select title="location" name="location" id="location">
                      <option value="0">Location</option>
                      <option value="1">Location</option>
                      <option value="2">Location</option>
                    </select>
                    <div className="icon">
                      <img src="assets/img/icons/location.svg" alt="location" />
                    </div>
                    <div className="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div className="job-list d-flex flex-column gap-4">
              <div className="job-card" data-aos="fade-up" data-aos-delay="50">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">Marketing Manager</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-1"
                    aria-expanded="false"
                    aria-controls="job-coll-1"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-1">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="job-card" data-aos="fade-up" data-aos-delay="100">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">Senior Software Engineer</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-2"
                    aria-expanded="false"
                    aria-controls="job-coll-2"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-2">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="job-card" data-aos="fade-up" data-aos-delay="150">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">Financial Analyst</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-3"
                    aria-expanded="false"
                    aria-controls="job-coll-3"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-3">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="job-card" data-aos="fade-up" data-aos-delay="200">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">UX Designer</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-4"
                    aria-expanded="false"
                    aria-controls="job-coll-4"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-4">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="job-card" data-aos="fade-up" data-aos-delay="250">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">Project Management</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-5"
                    aria-expanded="false"
                    aria-controls="job-coll-5"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-5">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="job-card" data-aos="fade-up" data-aos-delay="300">
                <div className="job-card-header d-flex align-items-center gap-3">
                  <h6 className="job-title fw-medium w-100 mb-0">
                    <span className="d-block">Frontend Developer</span>
                    <p className="d-flex d-md-none align-items-center gap-3 mt-2 mb-0">
                      <span>Remote</span>
                      <span>United States</span>
                    </p>
                  </h6>
                  <div className="job-status w-100 d-none d-md-flex align-items-center gap-2">
                    <img
                      src="assets/img/icons/briefcase-2.svg"
                      alt="briefcase"
                    />
                    <span className="fw-medium">Remote</span>
                  </div>
                  <div className="job-location w-100 d-none d-md-flex align-items-center gap-2">
                    <img src="assets/img/icons/us-flag.png" alt="us flag" />
                    <span className="fw-medium">United States</span>
                  </div>
                  <button
                    type="button"
                    title="click to view"
                    className="job-collapse-toggle bg-transparent flex-shrink-0 d-flex align-items-center justify-content-center rounded-circle"
                    data-bs-toggle="collapse"
                    data-bs-target="#job-coll-6"
                    aria-expanded="false"
                    aria-controls="job-coll-6"
                  ></button>
                </div>
                <div className="job-card-body collapse" id="job-coll-6">
                  <div>
                    <p className="job-desc">
                      We are looking for an experienced Senior Full Stack
                      Engineer to work on unique product challenges: complex
                      algorithms for demand prediction, optimal real-time
                      pricing, routing, fraud detection, distributed systems and
                      much more. Volumes are growing at a steady pace.
                    </p>
                    <Link
                      to="#"
                      className="read-more-btn d-inline-flex align-items-center gap-2 fw-medium"
                    >
                      {" "}
                      Read More{" "}
                      <span className="icon">
                        <i className="fa-light fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* job section end  */}

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default JobOpening;
