import React from "react";
import { Link } from "react-router-dom";

const FAQSection = ({ faqs }) => {
  return (
    <section className="faq-section">
      <div className="container">
        <div className="row">
          <div className="col-xl-12">
            <div className="common-title-wrap m-b-40">
              <h2
                className="common-title mb-0"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Frequently Asked Questions
              </h2>
            </div>
          </div>
          <div className="col-xl-12">
            <div className="faq-container">
              <div className="accordion" id="faq-accordion">
                {faqs.map((faq, index, id) => (
                  <div
                    className="accordion-item"
                    data-aos="fade-up"
                    data-aos-delay={`${50 * (index + 1)}`}
                    key={index}
                  >
                    <button
                      type="button"
                      className="accordion-header"
                      data-bs-toggle="collapse"
                      data-bs-target={`#faq-coll-${faq.id}`}
                      aria-expanded="false"
                      aria-controls={`faq-coll-${faq.id}`}
                    >
                      {faq.question}
                      <img
                        src="assets/img/icons/arrow-down.svg"
                        alt="arrow down"
                        className="angle-icon"
                      />
                    </button>
                    <div
                      className="accordion-content collapse"
                      id={`faq-coll-${faq.id}`}
                      data-bs-parent="#faq-accordion"
                    >
                      <p>
                        {" "}
                        <pre style={{ whiteSpace: "pre-wrap" }}>
                          {faq.answer}
                        </pre>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
              <p className="contact" data-aos="fade-up" data-aos-delay="50">
                If you have more questions, do not hesitate to{" "}
                <Link to="/contact">Contact Us</Link>
              </p>
            </div>
          </div>
        </div>
        <div className="row p-t-180 p-t-lg-160 p-t-md-120 p-t-xs-80">
          <div className="col-xl-12">
            <div
              className="get-started-wrap position-relative"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <Link to="https://boi.itump.com/login">
                Get Started{" "}
                <img
                  src="assets/img/icons/angles-right.svg"
                  alt="angles right"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQSection;
