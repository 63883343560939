import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const JobDetails = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* job section start */}
      <section className="job-detail-section job-section team-hero bg-light overflow-hidden p-t-30 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <Link
            to="#"
            className="back-btn d-inline-flex align-items-center gap-2 fw-medium"
          >
            <i className="fa fa-arrow-left-long"></i> Careers
          </Link>
          <div className="job-section-wrapper mx-auto">
            <div className="hero-content common-title-wrap common-title-light mx-auto text-center m-b-60 m-t-60">
              <h1
                className="common-title"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Frontend Developer
              </h1>
              <p
                className="common-title-body mb-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Build your career with Itump. Explore exciting opportunities and
                innovate with us. <br /> Discover a supportive work environment
                where you can thrive.
              </p>
              <div
                className="job-info m-t-30 m-b-30 py-4 d-flex align-items-center justify-content-center flex-wrap gap-md-5"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/briefcase-2.svg"
                    alt="briefcase"
                  />{" "}
                  Full-Time
                </p>
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/buildings-3.svg"
                    alt="building"
                  />{" "}
                  Engineering
                </p>
                <p className="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    className="flex-shrink-0"
                    src="assets/img/icons/location.svg"
                    alt="briefcase"
                  />{" "}
                  New York, USA
                </p>
              </div>
              <Link
                to="#"
                className="apply-btn theme-btn d-inline-block fw-medium"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Apply now
              </Link>
            </div>
            <div className="job-detail-wrapper d-flex flex-column">
              <div className="job-detail">
                <h5
                  className="job-detail-title fw-bold mb-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Job Description:
                </h5>
                <p
                  className="job-detail-desc mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Itump is looking for a skilled Front-End Developer to join our
                  team and play a crucial role in building and maintaining
                  user-facing features for our digital platforms. As a Front-End
                  Developer at Itump, you will collaborate with designers,
                  product managers, and backend developers to bring innovative
                  web applications to life. Your work will directly impact the
                  user experience, ensuring that our platforms are not only
                  visually appealing but also highly functional and responsive.
                </p>
              </div>
              <div className="job-detail">
                <h5
                  className="job-detail-title fw-bold mb-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  About the Role:
                </h5>
                <p
                  className="job-detail-desc mb-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  As a Senior Software Engineer at Iptum, you will play a
                  pivotal role in shaping the future of our technology. You will
                  be responsible for designing, developing, and maintaining
                  high-quality software solutions that drive our business
                  forward. You will collaborate with a talented team of
                  engineers to solve complex problems and deliver innovative
                  products.
                </p>
              </div>
              <div className="job-detail">
                <h5
                  className="job-detail-title fw-bold mb-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Key Responsibilities:
                </h5>
                <ul
                  className="m-0 ps-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <li className="job-detail-desc">
                    Design and develop robust and scalable software applications
                  </li>
                  <li className="job-detail-desc">
                    Collaborate with cross-functional teams to define and
                    implement technical solutions
                  </li>
                  <li className="job-detail-desc">
                    Write clean, well-tested, and maintainable code
                  </li>
                  <li className="job-detail-desc">
                    Troubleshoot and resolve technical issues in a timely manner
                  </li>
                  <li className="job-detail-desc">
                    Mentor and guide junior developers
                  </li>
                  <li className="job-detail-desc">
                    Stay up-to-date with the latest technologies and industry
                    trends
                  </li>
                </ul>
              </div>
              <div className="job-detail">
                <h5
                  className="job-detail-title fw-bold mb-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Required Skills and Experience:
                </h5>
                <ul
                  className="m-0 ps-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <li className="job-detail-desc">
                    Strong proficiency in programming languages such as Python,
                    Java, or C++
                  </li>
                  <li className="job-detail-desc">
                    Experience with software development methodologies (Agile,
                    Scrum)
                  </li>
                  <li className="job-detail-desc">
                    Solid understanding of data structures, algorithms, and
                    design patterns
                  </li>
                  <li className="job-detail-desc">
                    Experience with databases (SQL, NoSQL) and cloud platforms
                    (AWS, GCP, Azure)
                  </li>
                  <li className="job-detail-desc">
                    Excellent problem-solving and debugging skills
                  </li>
                  <li className="job-detail-desc">
                    Stay up-to-date with the latest technologies and industry
                    trends
                  </li>
                </ul>
              </div>
              <div className="job-detail">
                <h5
                  className="job-detail-title fw-bold mb-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Benefits:
                </h5>
                <ul
                  className="m-0 ps-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <li className="job-detail-desc">Competitive salary</li>
                  <li className="job-detail-desc">Flexible work hours</li>
                  <li className="job-detail-desc">
                    Opportunities for professional development and career growth
                  </li>
                  <li className="job-detail-desc">
                    Engaging and collaborative work environment
                  </li>
                </ul>
              </div>
              <div className="job-detail">
                <p
                  className="job-detail-desc mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Join Itump and be a part of a forward-thinking team that is
                  committed to pushing the boundaries of web development. Apply
                  now to contribute to projects that make a significant impact
                  in the digital space!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* job section end  */}

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default JobDetails;
