import { memo } from "react";
import { Link, useLocation } from "react-router";
import { AnimatePresence, motion } from "motion/react";
import { RiCloseLargeFill } from "react-icons/ri";

// TODO: replace Link with NavLink comp
// Gosh! how is bootstrap and tailwind in one project lol...

const AgreementsSidebar = ({ openMenu, setOpenMenu }) => {
    const location = useLocation();

    return (
        <div className={`agreements-sidebar-wrapper ${openMenu && "active"}`}>
            {openMenu && (
                <AnimatePresence>
                    <motion.div 
                        className="agreements-sidebar-backdrop"
                        key="sidebar-backdrop"
                        exit={{ opacity: 0 }}
                    />
                </AnimatePresence>
            )}
            {/* <div className={`agreements-sidebar-backdrop ${openMenu && "active"}`} /> */}
            <aside className={`agreements-sidebar ${openMenu && "active"} d-none d-sm-block`}>
                <div className="d-flex justify-content-between align-items-center mb-10">
                    <h3
                        style={{
                            fontSize: "18px",
                            fontWeight: "bold",
                            marginBottom: "0px !important"
                        }}
                    >
                        Important Links
                    </h3>
                    <button
                        className="agreements-sidebar-close-btn"
                        onClick={() => setOpenMenu(false)}
                    >
                        <RiCloseLargeFill size={22} />
                    </button>
                </div>
                <div className="agreements-sidebar-content">
                    <div
                        style={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "15px 0"
                        }}
                    >
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/eula" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/eula"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            EULA
                        </Link>
                    </div>
                    <div
                        style={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "15px 0"
                        }}
                    >
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/disclaimer" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/disclaimer"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            Disclaimer
                        </Link>
                    </div>
                    <div
                        style={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "15px 0"
                        }}
                    >
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/partners-policy" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/partners-policy"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            Partners Policy
                        </Link>
                    </div>
                    <div
                        style={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "15px 0"
                        }}
                    >
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/return-policy" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/return-policy"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            Return Policy
                        </Link>
                    </div>
                    <div
                        style={{
                            borderBottom: "1px solid #e0e0e0",
                            padding: "15px 0"
                        }}
                    >
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/privacy" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/privacy"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            Privacy Policy
                        </Link>
                    </div>
                    <div style={{ padding: "15px 0" }}>
                        <Link
                            style={{
                                display: "block",
                                color: location?.pathname === "/terms-of-service" ? "#7C4DFF" : "#333",
                                textDecoration: "none",
                                fontWeight: "500",
                            }}
                            to="/terms-of-service"
                            onClick={() => openMenu && setOpenMenu(false)}
                        >
                            Terms of Service
                        </Link>
                    </div>
                </div>
                <button
                    className="agreement-sidebar-lng-button"
                    style={{
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <span>🌐</span> English (EN)
                    <span style={{ fontSize: "12px" }}>▼</span>
                </button>
            </aside>
        </div>
    )
}
 
export default memo(AgreementsSidebar)