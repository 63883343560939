import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";

const ServiceClose = () => {
  const faqs = [
    {
      id: "one",
      question: "What are the legal requirements for closing a business?",
      answer:
        "The legal requirements vary depending on your business structure, location, and industry. Itump Health can provide guidance on the specific requirements for your business.",
    },
    {
      id: "two",
      question: "How do I notify employees about the closure?",
      answer:
        "Comply with labor laws regarding notice periods and severance pay. Communicate the closure clearly and provide support to employees during the transition.",
    },
    {
      id: "three",
      question: "What should I do with remaining business assets?",
      answer:
        "You can sell, liquidate, or transfer assets. Itump Assets can help you track and manage these assets.",
    },
    {
      id: "four",
      question: "How do I handle outstanding customer orders?",
      answer:
        "Communicate clearly with customers about the closure and provide options for refunds, exchanges, or alternative solutions.",
    },
    {
      id: "five",
      question: "What are my tax obligations when closing a business?",
      answer:
        "You'll need to file final tax returns and settle any outstanding tax liabilities. Itump Health can provide guidance on tax requirements.",
    },
    {
      id: "six",
      question: "How do I cancel my business licenses and permits?",
      answer:
        "Contact the relevant licensing agencies and follow their procedures for canceling your licenses and permits.",
    },
    {
      id: "seven",
      question: "What records should I keep after closing my business?",
      answer:
        "Maintain important records, such as financial statements, tax returns, and legal documents, for a reasonable period after closure.",
    },
  ];
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-2 service-hero service-close-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-245 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  CLOSE YOUR BUSINESS
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Streamline the Business Closure Process
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Close your business efficiently while meeting all legal and
                  regulatory requirements.
                </p>
              </div>
              <div>
                <Link
                  to="https://boi.itump.com/login"
                  className="theme-btn"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="250"
              >
                <img
                  src="assets/img/thumb/service-close-hero-img.png"
                  alt="thumb"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-27.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-19.png" alt="shape" />
        </div>
      </section>

      <section className="service-card-section p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>Requirements</h3>
                </div>
                <div
                  className="service-card-thumb m-b-80"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-32.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-1"
                        aria-expanded="false"
                        aria-controls="service-item-coll-1"
                      >
                        <span>Business Name and Identification Number</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-1"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-2"
                        aria-expanded="false"
                        aria-controls="service-item-coll-2"
                      >
                        <span>Articles of Dissolution</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-2"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-3"
                        aria-expanded="false"
                        aria-controls="service-item-coll-3"
                      >
                        <span>Final Tax Returns and Payroll Documents</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-3"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-4"
                        aria-expanded="false"
                        aria-controls="service-item-coll-4"
                      >
                        <span>
                          Settlement of Outstanding Debts and Liabilities
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-4"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-1">
                  <img src="assets/img/shape/shape-10.png" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>What You Get</h3>
                </div>
                <div
                  className="service-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-33.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content m-t-35"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-5"
                        aria-expanded="false"
                        aria-controls="service-item-coll-5"
                      >
                        <span>Official Closure of your Business Entity</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-5"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-6"
                        aria-expanded="false"
                        aria-controls="service-item-coll-6"
                      >
                        <span>Finalized Tax and Legal Obligations</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-6"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-7"
                        aria-expanded="false"
                        aria-controls="service-item-coll-7"
                      >
                        <span>
                          Documentation to Confirm Business Dissolution
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-7"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-2">
                  <img src="assets/img/shape/shape-11.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <section className="steps-to-register-section annual-filling-step-section service-close-step">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div
                className="common-title-wrap m-b-125 m-b-lg-80 m-b-md-60 m-b-xs-40"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <h2 className="common-title mb-0">How It Works</h2>
              </div>
            </div>
          </div>
          <div
            className="step-container service-close-step-container annual-filling-step-container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="step-wrapper register-steps-wrap d-flex flex-column position-relative z-1">
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number with-border fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    1
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Submit Your Information
                    </h3>
                    <p className="step-desc mb-0">
                      Complete our simple online form with your business
                      details.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/service-close-step-1.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    2
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Review & Prepare
                    </h3>
                    <p className="step-desc mb-0">
                      Our experts review your submission and prepare the
                      necessary legal documents.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/service-close-step-2.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    3
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Filing with the State
                    </h3>
                    <p className="step-desc mb-0">
                      We’ll file the dissolution documents with the appropriate
                      state authorities on your behalf.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/service-close-step-3.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    4
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">Confirmation</h3>
                    <p className="step-desc mb-0">
                      Receive official confirmation that your business has been
                      closed and is in good standing.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/service-close-step-4.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
            </div>
            <div className="step-shape h-100">
              <img
                src="assets/img/shape/step-border-shape.png"
                alt="border"
                className="h-100"
              />
            </div>
          </div>
          <div
            className="text-center p-t-175 p-t-xl-140 p-t-lg-100 p-t-md-80 p-t-xs-60"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <Link to="https://boi.itump.com/login" className="theme-btn">
              Get Started <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="s-step-shape-1">
          <img src="assets/img/shape/shape-20.png" alt="shape" />
        </div>
      </section>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end  */}

      <FAQSection faqs={faqs} />
      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="/tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="/start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceClose;
