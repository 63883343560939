import React from "react";
import { CONTENTS } from "./data";
import TableOfContent from "../../../component/guidelines/TableOfContent";

const PrivacyPolicy = () => {
    return (
        <div
            style={{
                color: "#333",
                maxWidth: "800px",
                lineHeight: "1.6",
            }}
        >
            <h1
                style={{
                    fontSize: "48px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    color: "#222",
                }}
            >
                PRIVACY POLICY
            </h1>
            <div
                style={{
                    marginBottom: "30px",
                }}
            >
                <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Last updated November 12, 2024
                </p>
                <p style={{ marginBottom: "20px" }}>
                    This Privacy Notice for 24One, Inc. (doing business as ITUMP) ("we," "us," or "our"), describes how and
                    {" "}why we might access, collect, store, use, and/or share ("process") your personal information when
                    {" "}you use our services ("Services"), including when you:
                </p>
                <ul
                    style={{
                        paddingLeft: "25px",
                        marginBottom: "15px",
                    }}
                >
                    <li>
                        Visit our website at
                        {" "}<a href="https://itump.com" target="_blank" rel="noreferrer">https://itump.com</a>,
                        {" "}or any website of ours that links to this Privacy Notice</li>
                    <li>
                        Download and use our mobile application (itump), or any other application of ours that links to this Privacy Notice
                    </li>
                    <li>
                        Use Mobile-First Finance Embedded Business Support Platform. itump is a global finance embedded
                        {" "}mobile-first platform designed to support entrepreneurs and small businesses by integrating
                        {" "}business formation, compliance management, payment processing, and business credit building tools.
                        {" "}itump's services streamline complex processes, helping users establish, operate, and scale their businesses efficiently.
                        {" "}From forming an LLC to managing payments through itump Pay, itump's AI-driven and Fintech-enabled
                        {" "}platform simplifies every step of the entrepreneurial journey globally.
                    </li>
                    <li>Engage with us in other related ways, including any sales, marketing, or events</li>
                </ul>
                <p style={{ marginBottom: "20px" }}>
                    Questions or concerns? Reading this Privacy Notice will help you understand your privacy rights and choices.
                    {" "}We are responsible for making decisions about how your personal information is processed.
                    {" "}If you do not agree with our policies and practices, please do not use our Services.
                    {" "}If you still have any questions or concerns, please contact us at g.legals@itump.com.
                </p>
            </div>
            <div>
                <h2
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                    }}
                >
                    SUMMARY OF KEY POINTS
                </h2>
                <div>
                    <div className="mb-3">
                        This summary provides key points from our Privacy Notice, but you can find out more details about any
                        {" "}of these topics by clicking the link following each key point or by using our <a href="#table-of-contents">table of contents</a>
                        {" "}below to find the section you are looking for.
                    </div>
                    <div className="mb-3">
                        What personal information do we process? When you visit, use, or navigate our Services, we may process personal
                        {" "}information depending on how you interact with us and the Services, the choices you make, and the products
                        {" "}and features you use. Learn more about <a href="#wiwc">personal information you disclose to us.</a>
                    </div>
                    <div className="mb-3">
                        Do we process any sensitive personal information? Some of the information may be considered "special" or "sensitive"
                        {" "}in certain jurisdictions, for example your racial or ethnic origins, sexual orientation, and religious beliefs.
                        {" "}We may process sensitive personal information when necessary with your consent or as otherwise permitted by applicable law.
                        {" "}<a href="#ds">Learn more about sensitive information we process.</a>
                    </div>
                    <div className="mb-3">
                        Do we collect any information from third parties? We do not collect any information from third parties.
                    </div>
                    <div className="mb-3">
                        How do we process your information? We process your information to provide, improve, and administer our Services,
                        {" "}communicate with you, for security and fraud prevention, and to comply with law.
                        {" "}We may also process your information for other purposes with your consent.
                        {" "}We process your information only when we have a valid legal reason to do so.
                        {" "}<a href="#hdwpyi">Learn more about how we process your information.</a>
                    </div>
                    <div className="mb-3">
                        In what situations and with which parties do we share personal information? We may share information in
                        {" "}specific situations and with specific third parties.
                        {" "}Learn more about when and <a href="#wwwsypi">with whom we share your personal information.</a>
                    </div>
                    <div className="mb-3">
                        How do we keep your information safe? We have adequate organizational and technical processes and
                        {" "}procedures in place to protect your personal information. However, no electronic transmission
                        {" "}over the internet or information storage technology can be guaranteed to be 100% secure,
                        {" "}so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                        {" "}parties will not be able to defeat our security and improperly collect, access, steal, or modify your information.
                        {" "}Learn more about <a href="#hwkyis">how we keep your information safe.</a>
                    </div>
                    <div className="mb-3">
                        What are your rights? Depending on where you are located geographically, the applicable privacy
                        {" "}law may mean you have certain rights regarding your personal information.
                        {" "}Learn more about <a href="privacy-rights">your privacy rights</a>.
                    </div>
                    <div className="mb-2">
                        How do you exercise your rights? The easiest way to exercise your rights is by submitting a
                        {" "}<a href="#dsar">data subject access request</a>, or by contacting us. We will consider and act upon any request
                        {" "}in accordance with applicable data protection laws.
                    </div>
                    <div>
                        Want to learn more about what we do with any information we collect?
                        {" "}<a href="#wiwc">Review the Privacy Notice in full.</a>
                    </div>
                </div>
            </div>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            {/* table of content */}
            <section style={{ marginBottom: "30px" }}>
                <h2
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                    }}
                    id="table-of-contents"
                >
                    TABLE OF CONTENTS
                </h2>
                <TableOfContent contents={CONTENTS} />
            </section>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            <dl>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="wiwc"
                    >
                        1. WHAT INFORMATION DO WE COLLECT?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We collect personal information that you provide to us.
                        </span>
                        <br/><br/>
                        <div className="d-flex flex-column gap-3">
                            <div>
                                We collect personal information that you voluntarily provide to us when you
                                {" "}register on the Services, express an interest in obtaining information about
                                {" "}us or our products and Services, when you participate in activities on the Services,
                                {" "}or otherwise when you contact us.
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <div>
                                    Personal Information Provided by You. The personal information that we collect depends
                                    {" "}on the context of your interactions with us and the Services, the choices you
                                    {" "}make, and the products and features you use. The personal information we collect
                                    {" "}may include the following:
                                </div>
                                <ul className="ml-10">
                                    <li>names</li>
                                    <li>phone numbers</li>
                                    <li>email addresses</li>
                                    <li>mailing addresses</li>
                                    <li>job titles</li>
                                    <li>usernames</li>
                                    <li>passwords</li>
                                    <li>contact preferences</li>
                                    <li>contact or authentication data</li>
                                    <li>billing addresses</li>
                                    <li>credit/debit card numbers</li>
                                    <li>ein number</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <div>
                                    Sensitive Information. When necessary, with your consent or as otherwise permitted by
                                    {" "}applicable law, we process the following categories of sensitive information:
                                </div>
                                <ul className="ml-10">
                                    <li>biometric data</li>
                                    <li>social security numbers or other government identifiers</li>
                                </ul>
                            </div>
                            <div>
                                Payment Data. We may collect data necessary to process your payment if you choose to
                                {" "}make purchases, such as your payment instrument number, and the security code
                                {" "}associated with your payment instrument. All payment data is handled and stored
                                {" "}by Stripe. You may find their privacy notice link(s) here:
                                {" "}<a href="https://stripe.com/legal/ssa" target="_blank" rel="noreferrer">https://stripe.com/legal/ssa.</a>
                            </div>
                            <div>
                                Social Media Login Data. We may provide you with the option to register with us using
                                {" "}your existing social media account details, like your Facebook, X, or other social
                                {" "}media account. If you choose to register in this way, we will collect certain
                                {" "}profile information about you from the social media provider, as described in
                                {" "}the section called <a href="#socials-authentication">"HOW DO WE HANDLE YOUR SOCIAL LOGINS?"</a> below.
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <div>
                                    Application Data. If you use our application(s), we also may collect the following information
                                    {" "}if you choose to provide us with access or permission:
                                </div>
                                <ul className="ml-10">
                                    <li>
                                        <b><i>Geolocation Information.</i></b> We may request access or permission to track location-based
                                        {" "}information from your mobile device, either continuously or while you are using
                                        {" "}our mobile application(s), to provide certain location-based services.
                                        {" "}If you wish to change our access or permissions, you may do so in your device's settings.
                                    </li>
                                    <li>
                                        <b><i>Mobile Device Access.</i></b> We may request access or permission to certain
                                        {" "}features from your mobile device, including your mobile device's bluetooth,
                                        {" "}camera, contacts, reminders, sensors, sms messages, social media accounts, and
                                        {" "}other features. If you wish to change our access or permissions, you may do so
                                        {" "}in your device's settings.
                                    </li>
                                    <li>
                                        <b><i>Push Notifications.</i></b> We may request to send you push notifications regarding
                                        {" "}your account or certain features of the application(s). If you wish to opt out from
                                        {" "}receiving these types of communications, you may turn them off in your device's settings.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                This information is primarily needed to maintain the security and operation of our application(s),
                                {" "}for troubleshooting, and for our internal analytics and reporting purposes.
                            </div>
                            <div>
                                All personal information that you provide to us must be true, complete, and accurate, and you must
                                {" "}notify us of any changes to such personal information.
                            </div>
                            <div>
                                <div
                                    style={{
                                        fontSize: "24px",
                                        fontWeight: "bold",
                                        marginBottom: "15px",
                                    }}
                                >
                                    Google API
                                </div>
                                <div>
                                    Our use of information received from Google APIs will adhere to
                                    {" "}<a href="https://developers.google.com/terms/api-services-user-data-policy">Google API Services User Data Policy</a>, including the
                                    {" "}<a href="https://developers.google.com/terms/api-services-user-data-policy#limited-use">Limited Use requirements</a>.
                                </div>
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="hdwpyi"
                    >
                        2. HOW DO WE PROCESS YOUR INFORMATION?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We process your information to provide, improve, and administer our
                            {" "}Services, communicate with you, for security and fraud prevention, and to
                            {" "}comply with law. We may also process your information for other purposes with your consent.
                        </span>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                We process your personal information for a variety of reasons, depending
                                {" "}on how you interact with our Services, including:
                            </div>
                            <ul className="ml-10 d-flex flex-column gap-1">
                                <li>
                                    To facilitate account creation and authentication and otherwise manage user accounts.
                                    {" "}We may process your information so you can create and log in to your account,
                                    {" "}as well as keep your account in working order.
                                </li>
                                <li>
                                    To deliver and facilitate delivery of services to the user. We may process your information
                                    {" "}to provide you with the requested service.
                                </li>
                                <li>
                                    To respond to user inquiries/offer support to users. We may process your information to
                                    {" "}respond to your inquiries and solve any potential issues you might have with the requested service.
                                </li>
                                <li>
                                    To send administrative information to you. We may process your information to send you
                                    {" "}details about our products and services, changes to our terms and policies, and other similar information.
                                </li>
                                <li>
                                    To fulfill and manage your orders. We may process your information to fulfill and manage your 
                                    {" "}orders, payments, returns, and exchanges made through the Services.
                                </li>
                                <li>
                                    To enable user-to-user communications. We may process your information if you choose to use
                                    {" "}any of our offerings that allow for communication with another user.
                                </li>
                                <li>
                                    To save or protect an individual's vital interest. We may process your information when necessary to
                                    {" "}save or protect an individual’s vital interest, such as to prevent harm.
                                </li>
                            </ul>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="lbwro"
                    >
                        3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We only process your personal information when we believe it is necessary
                            {" "}and we have a valid legal reason (i.e., legal basis) to do so under applicable law,
                            {" "}like with your consent, to comply with laws, to provide you with services to enter into
                            {" "}or fulfill our contractual obligations, to protect your rights, or to fulfill our
                            {" "}legitimate business interests.
                        </span>
                        <span className="italic" style={{ textDecoration: "underline" }}><b><i>If you are located in the EU or UK, this section applies to you.</i></b></span>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid
                                {" "}legal bases we rely on in order to process your personal information. As such, we
                                {" "}may rely on the following legal bases to process your personal information:
                            </div>
                            <ul className="ml-10 d-flex flex-column gap-1">
                                <li>
                                    Consent. We may process your information if you have given us permission (i.e., consent)
                                    {" "}to use your personal information for a specific purpose. You can withdraw your consent
                                    {" "}at any time. Learn more about <a href="#pdm">withdrawing your consent.</a>
                                </li>
                                <li>
                                    Performance of a Contract. We may process your personal information when we believe
                                    {" "}it is necessary to fulfill our contractual obligations to you, including providing
                                    {" "}our Services or at your request prior to entering into a contract with you
                                </li>
                                <li>
                                    Legal Obligations. We may process your information where we believe it is necessary for
                                    {" "}compliance with our legal obligations, such as to cooperate with a law enforcement
                                    {" "}body or regulatory agency, exercise or defend our legal rights, or disclose your
                                    {" "}information as evidence in litigation in which we are involved.
                                </li>
                                <li>
                                    Vital Interests. We may process your information where we believe it is necessary to
                                    {" "}protect your vital interests or the vital interests of a third party, such as situations
                                    {" "}involving potential threats to the safety of any person.
                                </li>
                            </ul>
                        </div>
                        <span className="italic" style={{ textDecoration: "underline" }}>
                            <b><i>If you are located in Canada, this section applies to you.</i></b>
                        </span>
                        <div>
                            We may process your information if you have given us specific permission (i.e., express consent) to
                            {" "}use your personal information for a specific purpose, or in situations where your permission
                            {" "}can be inferred (i.e., implied consent). You can withdraw your consent at any time.
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                In some exceptional cases, we may be legally permitted under applicable law to process
                                {" "}your information without your consent, including, for example:
                            </div>
                            <ul className="ml-10 d-flex flex-column gap-1">
                                <li>
                                    If collection is clearly in the interests of an individual and consent cannot
                                    {" "}be obtained in a timely way
                                </li>
                                <li>
                                    For investigations and fraud detection and prevention
                                </li>
                                <li>
                                    For business transactions provided certain conditions are met
                                </li>
                                <li>
                                    If it is contained in a witness statement and the collection is necessary to assess,
                                    {" "}process, or settle an insurance claim
                                </li>
                                <li>
                                    For identifying injured, ill, or deceased persons and communicating with next of kin
                                </li>
                                <li>
                                    If we have reasonable grounds to believe an individual has been, is, or may be victim of financial abuse
                                </li>

                                <li>
                                    If it is reasonable to expect collection and use with consent would compromise the
                                    {" "}availability or the accuracy of the information and the collection is reasonable
                                    {" "}for purposes related to investigating a breach of an agreement or a contravention of the laws of Canada or a province
                                </li>
                                <li>
                                    If disclosure is required to comply with a subpoena, warrant, court order, or rules of the
                                    {" "}court relating to the production of records
                                </li>
                                <li>
                                    If it was produced by an individual in the course of their employment, business, or profession
                                    {" "}and the collection is consistent with the purposes for which the information was produced
                                </li>
                                <li>
                                    If the collection is solely for journalistic, artistic, or literary purposes
                                </li>
                                <li>
                                    If the information is publicly available and is specified by the regulations
                                </li>
                            </ul>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="wwwsypi"
                    >
                        4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We may share information in specific situations described in this section and/or with the following third parties.
                        </span>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                We may need to share your personal information in the following situations:
                            </div>
                            <ul className="ml-10 d-flex flex-column gap-1">
                                <li>
                                    Business Transfers. We may share or transfer your information in connection with,
                                    {" "}or during negotiations of, any merger, sale of company assets, financing, or
                                    {" "}acquisition of all or a portion of our business to another company.
                                </li>
                                <li>
                                    Affiliates. We may share your information with our affiliates, in which case we will require
                                    {" "}those affiliates to honor this Privacy Notice. Affiliates include our parent company and
                                    {" "}any subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
                                </li>
                                <li>
                                    Business Partners. We may share your information with our business partners
                                    {" "}to offer you certain products, services, or promotions.
                                </li>
                            </ul>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="cookies-policy"
                    >
                        5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We may use cookies and other tracking technologies to collect and store your information.
                        </span>
                        <br/><br/>
                        <div className="d-flex flex-column gap-3">
                            <div>
                                We may use cookies and similar tracking technologies (like web beacons and pixels) to gather
                                {" "}information when you interact with our Services. Some online tracking technologies help
                                {" "}us maintain the security of our Services and your account, prevent crashes, fix bugs,
                                {" "}save your preferences, and assist with basic site functions.
                            </div>
                            <div>
                                We also permit third parties and service providers to use online tracking technologies on
                                {" "}our Services for analytics and advertising, including to help manage and display
                                {" "}advertisements, to tailor advertisements to your interests, or to send abandoned
                                {" "}shopping cart reminders (depending on your communication preferences). The third
                                {" "}parties and service providers use their technology to provide advertising about
                                {" "}products and services tailored to your interests which may appear either on our
                                {" "}Services or on other websites.
                            </div>
                            <div>
                                To the extent these online tracking technologies are deemed to be a "sale"/"sharing"
                                {" "}(which includes targeted advertising, as defined under the applicable laws) under
                                {" "}applicable US state laws, you can opt out of these online tracking technologies by
                                {" "}submitting a request as described below under section
                                {" "}<a href="#usrpr">"DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?"</a>
                            </div>
                            {/* <div>
                                Specific information about how we use such technologies and how you can refuse certain
                                {" "}cookies is set out in our Cookie Notice:
                                {" "}<a href="#cookies">http://www.itump.com/cookies.</a>
                            </div> */}
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="socials-authentication"
                    >
                        6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: If you choose to register or log in to our Services using a social
                            {" "}media account, we may have access to certain information about you.
                        </span>
                        <br/><br/>
                        <div className="d-flex flex-column gap-3">
                            <div>
                                Our Services offer you the ability to register and log in using your third-party
                                {" "}social media account details (like your Facebook or X logins). Where you
                                {" "}choose to do this, we will receive certain profile information about you
                                {" "}from your social media provider. The profile information we receive may vary
                                {" "}depending on the social media provider concerned, but will often include your
                                {" "}name, email address, friends list, and profile picture, as well as other
                                {" "}information you choose to make public on such a social media platform.
                            </div>
                            <div>
                                We will use the information we receive only for the purposes that are described
                                {" "}in this Privacy Notice or that are otherwise made clear to you on the relevant Services.
                                {" "}Please note that we do not control, and are not responsible for, other uses of your
                                {" "}personal information by your third-party social media provider. We recommend that
                                {" "}you review their privacy notice to understand how they collect, use, and share your
                                {" "}personal information, and how you can set your privacy preferences on their sites and apps.
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="hlwkyi"
                    >
                        7. HOW LONG DO WE KEEP YOUR INFORMATION?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We keep your information for as long as necessary to fulfill the purposes
                            {" "}outlined in this Privacy Notice unless otherwise required by law.
                        </span>
                        <br/><br/>
                        <div className="d-flex flex-column gap-3">
                            <div>
                                We will only keep your personal information for as long as it is necessary for the
                                {" "}purposes set out in this Privacy Notice, unless a longer retention period is required
                                {" "}or permitted by law (such as tax, accounting, or other legal requirements).
                                {" "}No purpose in this notice will require us keeping your personal information for
                                {" "}longer than twenty four (24) months past the termination of the user's account.
                            </div>
                            <div>
                                When we have no ongoing legitimate business need to process your personal information,
                                {" "}we will either delete or anonymize such information, or, if this is not possible
                                {" "}(for example, because your personal information has been stored in backup archives),
                                {" "}then we will securely store your personal information and isolate it from any
                                {" "}further processing until deletion is possible.
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="hwkyis"
                    >
                        8. HOW DO WE KEEP YOUR INFORMATION SAFE?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We aim to protect your personal information through a system of organizational and technical security measures.
                        </span>
                        <br/><br/>
                        <div>
                            We have implemented appropriate and reasonable technical and organizational security measures
                            {" "}designed to protect the security of any personal information we process. However,
                            {" "}despite our safeguards and efforts to secure your information, no electronic transmission
                            {" "}over the Internet or information storage technology can be guaranteed to be 100% secure,
                            {" "}so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third
                            {" "}parties will not be able to defeat our security and improperly collect, access, steal, or
                            {" "}modify your information. Although we will do our best to protect your personal information,
                            {" "}transmission of personal information to and from our Services is at your own risk.
                            {" "}You should only access the Services within a secure environment
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="dwcifm"
                    >
                        9. DO WE COLLECT INFORMATION FROM MINORS?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>
                            In Short: We do not knowingly collect data from or market to children under 18 years of age.
                        </span>
                        <br/><br/>
                        <div>
                            We absolutely do not knowingly collect, solicit data from, or market to children under 18 years of age,
                            {" "}nor do we intentionally sell such personal information. By using the Services, you represent
                            {" "}that you are at least 18 or that you are the parent or guardian of such a minor and consent
                            {" "}to such minor dependent’s use of the Services. If we learn that personal information from users 
                            {" "}less than 18 years of age has been collected, we will deactivate the account and take reasonable
                            {" "}measures to promptly delete such data from our records. If you become aware of any data we may
                            {" "}have collected from children under age 18, please contact us at g.legals@itump.com.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="privacy-rights"
                    >
                        10. WHAT ARE YOUR PRIVACY RIGHTS?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>
                            In Short: Depending on your state of residence in the US or in some regions, such as the
                            {" "}European Economic Area (EEA), United Kingdom (UK), Switzerland, and Canada, you have rights
                            {" "}that allow you greater access to and control over your personal information.
                            {" "}You may review, change, or terminate your account at any time, depending on your
                            {" "}country, province, or state of residence.
                        </span>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                In some regions (like the EEA, UK, Switzerland, and Canada), you have certain rights under applicable
                                {" "}data protection laws. These may include the right (i) to request access and obtain a copy of your personal
                                {" "}information, (ii) to request rectification or erasure; (iii) to restrict the processing of your personal
                                {" "}information; (iv) if applicable, to data portability; and (v) not to be subject to automated decision-making.
                                {" "}In certain circumstances, you may also have the right to object to the processing of your personal information.
                                {" "}You can make such a request by contacting us by using the contact details provided in the section 
                                {" "}"<a href="#contact-support">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below.
                            </div>
                            <div>
                                We will consider and act upon any request in accordance with applicable data protection laws.
                            </div>
                            <div>
                                If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                                {" "}information, you also have the right to complain to your 
                                {" "}<a href="https://ec.europa.eu/newsroom/article29/items/612080" target="_blank" rel="noreferrer">Member State data protection authority</a>
                                {" "}or
                                {" "}<a href="https://ico.org.uk/make-a-complaint/data-protection-complaints/" target="_blank" rel="noreferrer">UK data protection authority.</a>
                            </div>
                            <div>
                                If you are located in Switzerland, you may contact the
                                {" "}<a href="https://www.edoeb.admin.ch/en" target="_blank" rel="noreferrer">Federal Data Protection and Information Commissioner.</a>
                            </div>
                            <div>
                                Withdrawing your consent: If we are relying on your consent to process your personal information,
                                {" "}which may be express and/or implied consent depending on the applicable law, you have
                                {" "}the right to withdraw your consent at any time. You can withdraw your consent at
                                {" "}anytime by contacting us by using the contact details provided in the section
                                {" "}"<a href="#contact-support">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>" below or updating your preferences.
                            </div>
                            <div>
                                However, please note that this will not affect the lawfulness of the processing before its withdrawal
                                {" "}nor, when applicable law allows, will it affect the processing of your personal information
                                {" "}conducted in reliance on lawful processing grounds other than consent.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div
                                style={{
                                    fontSize: "24px",
                                    fontWeight: "bold",
                                    marginBottom: "15px",
                                }}
                            >
                                Account Information
                            </div>
                            <div>
                                If you would at any time like to review or change the information in your account or terminate your account, you can:
                            </div>
                            <ul>
                                <li>
                                    Log in to your account settings and update your user account.
                                </li>
                            </ul>
                            <div>
                                Upon your request to terminate your account, we will deactivate or delete your account and information from
                                {" "}our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot
                                {" "}problems, assist with any investigations, enforce our legal terms and/or comply with applicable legal requirements.
                            </div>
                            <div>
                                Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you prefer, you can usually
                                {" "}choose to set your browser to remove cookies and to reject cookies. If you choose to remove cookies or reject cookies,
                                {" "}this could affect certain features or services of our Services.
                            </div>
                            <div>
                                If you have questions or comments about your privacy rights, you may email us at
                                {" "}<a href="mailto:g.legals@itump.com">g.legals@itump.com.</a>
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="do-not-track"
                    >
                        11. CONTROLS FOR DO-NOT-TRACK FEATURES
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div>
                            Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track
                            {" "}("DNT") feature or setting you can activate to signal your privacy preference not to have
                            {" "}data about your online browsing activities monitored and collected. At this stage, no uniform
                            {" "}technology standard for recognizing and implementing DNT signals has been finalized.
                            {" "}As such, we do not currently respond to DNT browser signals or any other mechanism that
                            {" "}automatically communicates your choice not to be tracked online. If a standard for online
                            {" "}tracking is adopted that we must follow in the future, we will inform you about that
                            {" "}practice in a revised version of this Privacy Notice.
                        </div>
                        <div>
                            California law requires us to let you know how we respond to web browser DNT signals.
                            {" "}Because there currently is not an industry or legal standard for recognizing
                            {" "}or honoring DNT signals, we do not respond to them at this time.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="usrpr"
                    >
                        12. DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>
                            In Short: If you are a resident of California, Colorado, Connecticut, Delaware,
                            {" "}Florida, Indiana, Iowa, Kentucky, Minnesota, Montana, Nebraska, New Hampshire,
                            {" "}New Jersey, Oregon, Tennessee, Texas, Utah, or Virginia, you may have the right
                            {" "}to request access to and receive details about the personal information we maintain
                            {" "}about you and how we have processed it, correct inaccuracies, get a copy of, or delete
                            {" "}your personal information. You may also have the right to withdraw your consent to our
                            {" "}processing of your personal information. These rights may be limited in some circumstances
                            {" "}by applicable law. More information is provided below.
                        </span>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                Categories of Personal Information We Collect
                            </span>
                            <span>
                                We have collected the following categories of personal information in the past twelve (12) months:
                            </span>
                            <table>
                                <thead>
                                    <th>Category</th>
                                    <th>Examples</th>
                                    <th>Collected</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>A. Identifiers</td>
                                        <td>
                                            Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique
                                            {" "}personal identifier, online identifier, Internet Protocol address, email address, and account name
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>B. Personal information as defined in the California Customer Records statute</td>
                                        <td>
                                            Name, contact information, education, employment, employment history, and financial information
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>C. Protected classification characteristics under state or federal law</td>
                                        <td>
                                            Gender, age, date of birth, race and ethnicity, national origin, marital status, and other demographic data
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>D. Commercial information</td>
                                        <td>
                                            Transaction information, purchase history, financial details, and payment information
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>E. Biometric information</td>
                                        <td>
                                            Fingerprints and voiceprints
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>F. Internet or other similar network activity</td>
                                        <td>
                                            Browsing history, search history, online behavior, interest data, and interactions with our and
                                            {" "}other websites, applications, systems, and advertisements
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>G. Geolocation data</td>
                                        <td>
                                            Device location
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>H. Audio, electronic, sensory, or similar information</td>
                                        <td>
                                            Images and audio, video or call recordings created in connection with our business activities
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>I. Professional or employment-related information</td>
                                        <td>
                                            Business contact details in order to provide you our Services at a business level or job title, work history,
                                            {" "}and professional qualifications if you apply for a job with us
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>J. Education Information</td>
                                        <td>
                                            Student records and directory information
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>K. Inferences drawn from collected personal information</td>
                                        <td>
                                            Inferences drawn from any of the collected personal information listed above to create a profile or
                                            {" "}summary about, for example, an individual&apos;s preferences and characteristics
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                    <tr>
                                        <td>L. Sensitive personal Information</td>
                                        <td>
                                            Account login information, biometric data, contents of email or text messages, debit or credit card
                                            {" "}numbers, financial information including account access details and social security numbers
                                        </td>
                                        <td>NO</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div>
                            We only collect sensitive personal information, as defined by applicable privacy laws or the purposes allowed
                            {" "}by law or with your consent. Sensitive personal information may be used, or disclosed to a service provider
                            {" "}or contractor, for additional, specified purposes. You may have the right to limit the use or disclosure of your
                            {" "}sensitive personal information. We do not collect or process sensitive personal information for the purpose of
                            {" "}inferring characteristics about you.
                        </div>
                        <div>
                            <div>
                                We may also collect other personal information outside of these categories through instances where you interact with
                                {" "}us in person, online, or by phone or mail in the context of:
                            </div>
                            <ul>
                                <li>
                                    Receiving help through our customer support channels;
                                </li>
                                <li>
                                    Participation in customer surveys or contests; and
                                </li>
                                <li>
                                    Facilitation in the delivery of our Services and to respond to your inquiries.
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                We will use and retain the collected personal information as needed to provide the Services or for:
                            </div>
                            <ul>
                                <li>
                                    Category H - As long as the user has an account with us
                                </li>
                                <li>
                                    Category L - As long as the user has an account with us
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                Sources of Personal Information
                            </span>
                            <div>
                                Learn more about the sources of personal information we collect in
                                {" "}"<a href="#wiwc">WHAT INFORMATION DO WE COLLECT?</a>"
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                How We Use and Share Personal Information
                            </span>
                            <div>
                                Learn more about how we use your personal information in the section,
                                {" "}"<a href="#hdwpyi">HOW DO WE PROCESS YOUR INFORMATION?</a>"
                            </div>
                            <div>Will your information be shared with anyone else?</div>
                            <div>
                                We may disclose your personal information with our service providers pursuant to a written contract
                                {" "}between us and each service provider. Learn more about how we disclose personal information to
                                {" "}in the section,
                                "<a href="#wwwsypi">WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>"
                            </div>
                            <div>
                                We may use your personal information for our own business purposes, such as for undertaking internal
                                {" "}research for technological development and demonstration. This is not considered to be "selling" of your personal information.
                            </div>
                            <div>
                                We have not disclosed, sold, or shared any personal information to third parties for a business or
                                {" "}commercial purpose in the preceding twelve (12) months. We will not sell or share personal information
                                {" "}in the future belonging to website visitors, users, and other consumers.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                Your Rights
                            </span>
                            <div>
                                You have rights under certain US state data protection laws. However, these rights are not absolute, and in
                                {" "}certain cases, we may decline your request as permitted by law. These rights include:
                            </div>
                            <ul>
                                <li>
                                    Right to know whether or not we are processing your personal data
                                </li>
                                <li>
                                    Right to access your personal data
                                </li>
                                <li>
                                    Right to correct inaccuracies in your personal data
                                </li>
                                <li>
                                    Right to request the deletion of your personal data
                                </li>
                                <li>
                                    Right to obtain a copy of the personal data you previously shared with us
                                </li>
                                <li>
                                    Right to non-discrimination for exercising your rights
                                </li>
                                <li>
                                    Right to opt out of the processing of your personal data if it is used for targeted advertising
                                    {" "}(or sharing as defined under California&apos;s privacy law), the sale of personal data, or
                                    {" "}profiling in furtherance of decisions that produce legal or similarly significant effects ("profiling")
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div>
                                Depending upon the state where you live, you may also have the following rights:
                            </div>
                            <ul>
                                <li>
                                    Right to access the categories of personal data being processed (as permitted by applicable
                                    {" "}law, including Minnesota’s privacy law)
                                </li>
                                <li>
                                    Right to obtain a list of the categories of third parties to which we have disclosed personal data
                                    {" "}(as permitted by applicable law, including California's and Delaware's privacy law)
                                </li>
                                <li>
                                    Right to obtain a list of specific third parties to which we have disclosed personal data
                                    {" "}(as permitted by applicable law, including Minnesota's and Oregon's privacy law)
                                </li>
                                <li>
                                    Right to review, understand, question, and correct how personal data has been profiled
                                    {" "}(as permitted by applicable law, including Minnesota&apos;s privacy law)
                                </li>
                                <li>
                                    Right to limit use and disclosure of sensitive personal data (as permitted by applicable
                                    {" "}law, including California&apos;s privacy law)
                                </li>
                                <li>
                                    Right to opt out of the collection of sensitive data and personal data collected through the operation
                                    {" "}of a voice or facial recognition feature (as permitted by applicable law, including Florida’s privacy law)
                                </li>
                            </ul>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                How to Exercise Your Rights
                            </span>
                            <div>
                                To exercise these rights, you can contact us by submitting a data subject access request, by emailing us at
                                {" "}<a href="mailto:g.legals@itump.com">g.legals@itump.com</a>, by calling toll-free at 
                                {" "}<a href="call:88887ITUMP">88887ITUMP</a>, by visiting
                                {" "}<a href="/contact">http://www.itump.com/contact</a>, or by referring to the contact details at the bottom of this document.
                            </div>
                            <div>
                                Under certain US state data protection laws, you can designate an authorized agent to make a request on your
                                {" "}behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly
                                {" "}authorized to act on your behalf in accordance with applicable laws.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                Request Verification
                            </span>
                            <div>
                                Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we
                                {" "}have the information in our system. We will only use personal information provided in your request to verify your
                                {" "}identity or authority to make the request. However, if we cannot verify your identity from the information already
                                {" "}maintained by us, we may request that you provide additional information for the purposes of verifying your identity
                                {" "}and for security or fraud-prevention purposes.
                            </div>
                            <div>
                                If you submit the request through an authorized agent, we may need to collect additional information to verify your
                                {" "}identity before processing your request and the agent will need to provide a written and signed permission
                                {" "}from you to submit such request on your behalf.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                Appeals
                            </span>
                            <div>
                                Under certain US state data protection laws, if we decline to take action regarding your request, you may appeal our
                                {" "}decision by emailing us at g.legals@itump.com. We will inform you in writing of any action taken or not taken in
                                {" "}response to the appeal, including a written explanation of the reasons for the decisions. If your appeal is denied,
                                {" "}you may submit a complaint to your state attorney general.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold" }}>
                                California "Shine The Light" Law
                            </span>
                            <div>
                                California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California
                                {" "}residents to request and obtain from us, once a year and free of charge, information about categories of personal
                                {" "}information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all
                                {" "}third parties with which we shared personal information in the immediately preceding calendar year. If you are a California
                                {" "}resident and would like to make such a request, please submit your request in writing to us by using the contact details
                                {" "}provided in the section
                                {" "}"<a href="#contact-support">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>"
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="orpr"
                    >
                        13. DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <span style={{ fontStyle: "italic" }}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</span>
                        <div className="d-flex flex-column gap-2">
                            <div><b>Australia and New Zealand</b></div>
                            <div>
                                We collect and process your personal information under the obligations and conditions set by
                                {" "}Australia's Privacy Act 1988 and New Zealand's Privacy Act 2020 (Privacy Act).
                            </div>
                            <div>
                                This Privacy Notice satisfies the notice requirements defined in both Privacy Acts, in particular:
                                {" "}what personal information we collect from you, from which sources, for which purposes,
                                {" "}and other recipients of your personal information
                            </div>
                            <div>
                                <div>
                                    If you do not wish to provide the personal information necessary to fulfill their
                                    {" "}applicable purpose, it may affect our ability to provide our services, in particular:
                                </div>
                                <ul>
                                    <li>offer you the products or services that you want</li>
                                    <li>respond to or help with your requests</li>
                                    <li>manage your account with us</li>
                                    <li>confirm your identity and protect your account</li>
                                </ul>
                            </div>
                            <div>
                                At any time, you have the right to request access to or correction of your personal information.
                                {" "}You can make such a request by contacting us by using the contact details provided in the section
                                {" "}"<a href="#pdm">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>"
                            </div>
                            <div>
                                If you believe we are unlawfully processing your personal information, you have the right to submit
                                {" "}a complaint about a breach of the Australian Privacy Principles to the
                                {" "}<a href="https://www.oaic.gov.au/privacy/privacy-complaints/lodge-a-privacy-complaint-with-us" target="_blank" rel="noreferrer">Office of the Australian Information Commissioner</a>
                                {" "}and a breach of New Zealand's Privacy Principles to the
                                {" "}<a href="https://www.privacy.org.nz/your-rights/making-a-complaint/" target="_blank" rel="noreferrer">Office of New Zealand Privacy Commissioner</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div><b>Australia and New Zealand</b></div>
                            <div>
                                At any time, you have the right to request access to or correction of your personal information.
                                {" "}You can make such a request by contacting us by using the contact details provided in the section
                                {" "}"<a href="#pdm">HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>"
                            </div>
                            <div>
                                If you are unsatisfied with the manner in which we address any complaint with regard to our
                                {" "}processing of personal information, you can contact the office of the regulator, the details of which are:
                            </div>
                            <a href="https://inforegulator.org.za/" className="d-block" target="_blank" rel="noreferrer">The Information Regulator (South Africa)</a>
                            <div>
                                General enquiries:
                                {" "}<a href="mailto:enquiries@inforegulator.org.za">enquiries@inforegulator.org.za</a>
                            </div>
                            <div>
                                Complaints (complete POPIA/PAIA form 5):
                                {" "}<a href="mailto:PAIAComplaints@inforegulator.org.za">PAIAComplaints@inforegulator.org.za</a>
                                {" "}&
                                {" "}<a href="mailto:POPIAComplaints@inforegulator.org.za">POPIAComplaints@inforegulator.org.za</a>
                            </div>
                        </div>
                    </dd>
                </div>
                {/* downwards completed */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="notice-updates"
                    >
                        14. DO WE MAKE UPDATES TO THIS NOTICE?
                    </dt>
                    <dd>
                        <span style={{ fontStyle: "italic" }}>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</span>
                        <br/><br/>
                        <div>
                            We may update this Privacy Notice from time to time. The updated version will be indicated by an
                            {" "}updated "Revised" date at the top of this Privacy Notice. If we make material changes to
                            {" "}this Privacy Notice, we may notify you either by prominently posting a notice of such changes
                            {" "}or by directly sending you a notification. We encourage you to review this Privacy
                            {" "}Notice frequently to be informed of how we are protecting your information.
                            24One, Inc. represents and warrants that 24One, Inc. will comply with applicable third-party terms of agreement when using Licensed Application.
                            {" "}In Accordance with Section 9 of the "Instructions for Minimum Terms of Developer's End-User License Agreement,"
                            {" "}both Apple and Google and their subsidiaries shall be third-party beneficiaries of this End User License Agreement
                            {" "}and — upon Your acceptance of the terms and conditions of this License Agreement, both Apple and Google will have the right
                            {" "}(and will be deemed to have accepted the right) to enforce this End User License Agreement against You as a third-party beneficiary thereof.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="contact-support"
                    >
                        15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                    </dt>
                    <dd>
                        If you have questions or comments about this notice, you may contact our Data Protection
                        {" "}Officer (DPO) by email at g.legals@itump.com, by phone at 88887ITUMP, or contact us by post at:
                        <br/><br/>
                        24One, Inc.
                        <br/>
                        Data Protection Officer
                        <br/>
                        30 Wall Street
                        <br/>
                        New York, NY 10005
                        <br/>
                        United States   
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="pdm"
                    >
                        16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
                    </dt>
                    <dd>
                        Based on the applicable laws of your country or state of residence in the US, you may have the
                        {" "}right to request access to the personal information we collect from you, details about how
                        {" "}we have processed it, correct inaccuracies, or delete your personal information. You may also
                        {" "}have the right to withdraw your consent to our processing of your personal information.
                        {" "}These rights may be limited in some circumstances by applicable law. To request to review,
                        {" "}update, or delete your personal information, please fill out and submit a
                        {" "}<a href="/">data subject access request.</a>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default PrivacyPolicy
