import React from "react";
import { Link } from "react-router";
import { ReactComponent as EinIcon } from "../assets/icons/globe.svg";
import { ReactComponent as FileIcon } from "../assets/icons/file.svg";
import { ReactComponent as CreditIcon } from "../assets/icons/credit.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/users.svg";
import { ReactComponent as CopyrightIcon } from "../assets/icons/copyright.svg";
import { ReactComponent as ArrowRightIcon } from "../assets/icons/arrow-right.svg";
import { ReactComponent as BuildingIcon } from "../assets/icons/building.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/profile-tick.svg";
import { ReactComponent as NewsIcon } from "../assets/icons/news.svg";
import { ReactComponent as DollarIcon } from "../assets/icons/dollar-circle.svg";
import { ReactComponent as PeopleIcon } from "../assets/icons/people.svg";
import { ReactComponent as ContactIcon } from "../assets/icons/contact-support.svg";
import { ReactComponent as EnvelopeIcon } from "../assets/icons/envelope.svg";
import { ReactComponent as RegisterIcon } from "../assets/icons/register.svg";

const HeaderSection = () => {
  return (
    <>
      <section className="header-section d-lg-block">
        <div className="container">
          <div className="header-wrapper d-flex justify-content-between align-items-center">
            <div className="header-left">
              <div className="header-logo">
                <Link to="/">
                  <img src="../../assets/img/logo/logo.svg" alt="logo" />
                </Link>
              </div>
              <div className="header-menu d-none d-xl-flex">
                <ul>
                  <li className="has-dropdown">
                    {/* <li> */}
                    <Link to="#">
                      Solutions <i className="fa fa-angle-down"></i>
                    </Link>
                    <div className="d-flex flex-row sub-menu solution-sub-menu align-items-center">
                      <div className="solution-display-box">
                        <img
                          src="../assets/img/logo/solution_logo.svg"
                          alt="solution"
                        />
                        <div className=" d-flex  flex-column">
                          <h1 className="dropdown-title">Our Solutions</h1>
                          <p className="mb-1">
                            Solutions to manage and enhance your business
                            operations
                          </p>
                        </div>
                      </div>
                      <ul className="flexColumn">
                        <li className="itump-link">
                          <Link to="/solutions-pay" className="mb-7">
                            <div className="icon-container d-flex align-items-center justify-content-center">
                              <img
                                src="./assets/img/icons/pay.svg"
                                alt="pay"
                                className="icon"
                              />
                            </div>
                            <div className="link-text text-start">
                              <h6 className="d-flex font-medium text-white">
                                <span
                                  className="mr-3 title"
                                  style={{ marginRight: "10px" }}
                                >
                                  Itump Pay{" "}
                                </span>
                                <span className="hidden-arrow">
                                  <i className="fa fa-arrow-right"></i>
                                </span>
                              </h6>
                              <p className="mb-0">Seamless Payment Solutions</p>
                            </div>
                          </Link>
                        </li>
                        <li className="itump-link">
                          <Link className="mb-7" to="/solutions-wallet">
                            <div className="icon-container d-flex align-items-center justify-content-center">
                              <img
                                src="../assets/img/icons/wallet.svg"
                                alt="wallet"
                                className="icon"
                              />
                            </div>
                            <div className="link-text text-start">
                              <h6 className="d-flex font-medium text-white">
                                <span
                                  className="mr-3 title"
                                  style={{ marginRight: "10px" }}
                                >
                                  Itump Wallet
                                </span>
                                <span className="hidden-arrow">
                                  <i className="fa fa-arrow-right"></i>
                                </span>
                              </h6>

                              <p className="mb-0">
                                Business Transactions Simplified
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="itump-link">
                          <Link className="mb-7" to="/solutions-health">
                            <div className="icon-container d-flex align-items-center justify-content-center">
                              <img
                                src="../assets/img/icons/health.svg"
                                alt="health"
                                className="icon"
                              />
                            </div>
                            <div className="link-text text-start">
                              <h6 className="d-flex font-medium text-white">
                                <span
                                  className="title"
                                  style={{ marginRight: "10px" }}
                                >
                                  Itump Health
                                </span>
                                <span className="hidden-arrow">
                                  <i className="fa fa-arrow-right"></i>
                                </span>
                              </h6>
                              <p className="mb-0">
                                Optimise your business health
                              </p>
                            </div>
                          </Link>
                        </li>
                        <li className="itump-link">
                          <Link to="/solutions-docuhub">
                            <div className="icon-container d-flex align-items-center justify-content-center">
                              <img
                                src="../assets/img/icons/docuhub.svg"
                                alt="docuhub"
                                className="icon"
                              />
                            </div>
                            <div className="link-text text-start">
                              <h6 className="d-flex font-medium text-white">
                                <span
                                  className="mr-3 title"
                                  style={{ marginRight: "10px" }}
                                >
                                  Itump Corpcrypt
                                </span>
                                <span className="hidden-arrow">
                                  <i className="fa fa-arrow-right"></i>
                                </span>
                              </h6>
                              <p className="mb-0">
                                Secure vital business information & documents
                              </p>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="has-dropdown">
                    {/* <li> */}
                    <Link to="/service">
                      Services <i className="fa fa-angle-down"></i>
                    </Link>
                    <div
                      id="service-sub-menu"
                      className="service-sub-menu sub-menu"
                      style={{
                        flexDirection: "row",

                        width: "1097px",
                      }}
                    >
                      <div
                        className="service-display-box"
                        style={{
                          height: "auto",
                        }}
                      >
                        <img
                          src="../assets/img/logo/service_logo.svg"
                          alt="solution"
                          className=""
                          style={{
                            mixBlendMode: "color-dodge",
                            width: "199px",
                            height: "197px",
                            margin: "10px 72px 18px 72px",
                          }}
                        />
                        <div
                          className="d-flex  flex-column"
                          style={{
                            margin: "0 0 24px 24px",
                            width: "267px",
                          }}
                        >
                          <h1
                            className="dropdown-title"
                            style={{
                              marginBottom: "6px",
                            }}
                          >
                            Our Services
                          </h1>
                          <p
                            style={{
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "white",
                            }}
                          >
                            Services to start, manage, and grow your business
                            effectively
                          </p>
                        </div>
                      </div>
                      <ul
                        style={{
                          gap: "24px",
                          margin: "22.5px 0 22.5px 0",
                        }}
                      >
                        <div>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service-register"
                              // className="flex items-center hover:text-blue-600"
                            >
                              <RegisterIcon
                                id="register-icon"
                                className="icon w-8 h-8"
                              />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Register New Business
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Start Your Journey with Ease{" "}
                                </p>
                              </div>
                            </Link>
                          </li>

                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service-ein"
                              // className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/globe.svg"
                                alt="globe"
                                className="icon w-8 h-8"
                              /> */}
                              <EinIcon id="ein-icon" className="icon w-8 h-8" />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Get a New EIN
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Secure Your Employer Identification Number
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/file.svg"
                                alt="file"
                                className="icon w-8 h-8"
                              /> */}
                              <FileIcon
                                id="file-icon"
                                className="icon w-8 h-8"
                              />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    FinCen's BOI
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Beneficial Ownership Information Reporting{" "}
                                </p>
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/service-build"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/credit.svg"
                                alt="credit"
                                className="icon w-8 h-8"
                              /> */}
                              <CreditIcon
                                id="credit-icon"
                                className="icon w-8 h-8"
                              />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Build Business Credit{" "}
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Strengthen Your Business Financial Base
                                </p>
                              </div>
                            </Link>
                          </li>
                        </div>

                        <div>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service-change"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/users.svg"
                                alt="users"
                                className="icon w-8 h-8"
                              /> */}
                              <UsersIcon
                                id="user-icon"
                                className="icon w-8 h-8"
                              />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Change your Agent
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Switch Your Registered Agent with Ease
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service-copyright"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/copyright.svg"
                                alt="copyright"
                                className="icon w-8 h-8"
                              /> */}

                              <CopyrightIcon
                                id="copyright-icon"
                                className="icon w-8 h-8"
                              />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Copyright & Trademark
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Protect Your Intellectual Property
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link
                              to="/service-annual"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/file.svg"
                                alt="file"
                                className="icon w-8 h-8"
                              /> */}
                              <FileIcon
                                id="copyright-icon"
                                className="file-icon w-8 h-8"
                              />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Annual Filing
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Stay Compliant with Annual Filings
                                </p>
                              </div>
                            </Link>
                          </li>

                          <li>
                            <Link
                              to="/service-close"
                              className="flex items-center hover:text-blue-600"
                            >
                              {/* <img
                                src="assets/img/icons/arrow-right.svg"
                                alt="arrow right"
                                className="icon w-8 h-8"
                              /> */}

                              <ArrowRightIcon
                                id="arrowright-icon"
                                className="file-icon w-8 h-8"
                              />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Close your Business
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Streamline the Business Closure Process{" "}
                                </p>
                              </div>
                            </Link>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </li>
                  <li className="has-dropdown">
                    <Link to="#">
                      Resources <i className="fa fa-angle-down"></i>
                    </Link>
                    <div
                      id="resources-sub-menu"
                      className="service-sub-menu sub-menu"
                      style={{
                        flexDirection: "row",
                        background: "#141414",
                        padding: "10px",
                        width: "1097px",
                      }}
                    >
                      <div
                        className="service-display-box"
                        style={{
                          height: "auto",
                        }}
                      >
                        <img
                          src="assets/img/logo/resource_logo.svg"
                          alt="solution"
                          style={{
                            width: "343px",
                            height: "164px",
                            margin: "43px 0px 40px 0px",
                          }}
                        />
                        <div
                          className="d-flex  flex-column"
                          style={{
                            margin: "0 0 24px 24px",
                            width: "267px",
                          }}
                        >
                          <h1
                            className="dropdown-title"
                            style={{
                              marginBottom: "6px",
                            }}
                          >
                            Resources
                          </h1>
                          <p
                            style={{
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "white",
                            }}
                          >
                            Learn all your need to know about Itump
                          </p>
                        </div>
                      </div>
                      <ul>
                        <div>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link to="/about">
                              {/* <img
                                src="assets/img/icons/building.svg"
                                alt="building"
                                className="icon"
                              /> */}
                              <BuildingIcon
                                id="building-icon"
                                className="icon w-8 h-8"
                              />

                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    About
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Know about us as a company
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            {/* <Link to="/customer-stories"> */}
                            <Link to="#">
                              {/* <img
                                src="assets/img/icons/profile-tick.svg"
                                alt="profile-tick"
                                className="icon"
                              /> */}
                              <ProfileIcon
                                id="profile-icon"
                                className="icon w-8 h-8"
                              />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Customer Stories
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Meet the companies building with Itump
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            {/* <Link to="/news"> */}
                            <Link to="#">
                              {/* <img
                                src="assets/img/icons/news.svg"
                                alt="news"
                                className="icon"
                              /> */}

                              <NewsIcon
                                id="news-icon"
                                className="icon w-8 h-8"
                              />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    News
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  See trending news about Itump{" "}
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li>
                            <Link to="/pricing">
                              {/* <img
                                src="assets/img/icons/dollar-circle.svg"
                                alt="dollar-circle"
                                className="icon"
                              /> */}

                              <DollarIcon />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Pricing
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Find the Perfect Plan for Your Business Needs{" "}
                                </p>
                              </div>
                            </Link>
                          </li>
                        </div>
                        <div>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link to="/team">
                              {/* <img
                                src="assets/img/icons/people.svg"
                                alt="people"
                                className="icon"
                              /> */}

                              <PeopleIcon />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Team
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Meet the team behind Itump{" "}
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link to="#">
                              {/* <img
                                src="assets/img/icons/contact-support.svg"
                                alt="support"
                                className="icon"
                              /> */}
                              <ContactIcon />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Help & Support
                                  </span>
                                  <span className="hidden-arrow">
                                    <i className="fa fa-arrow-right"></i>
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Get quick help about Itump
                                </p>
                              </div>
                            </Link>
                          </li>
                          <li
                            style={{
                              marginBottom: "28px",
                            }}
                          >
                            <Link to="/contact">
                              {/* <img
                                src="assets/img/icons/envelope.svg"
                                alt="envelope"
                                className="icon"
                              /> */}

                              <EnvelopeIcon />
                              <div className="link-text text-start">
                                <h6 className="d-flex font-medium">
                                  <span
                                    className="mr-3 title"
                                    style={{
                                      lineHeight: "24px",
                                    }}
                                  >
                                    Contact
                                  </span>
                                </h6>
                                <p className="mb-0 text-[15px]">
                                  Contact our sales team
                                </p>
                              </div>
                            </Link>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </li>

                  {/* <li>
                    <Link to="#"> Enterprise </Link>
                  </li> */}
                  <li>
                    <Link to="/pricing"> Pricing </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="header-right">
              <div className="login-btn d-none d-xl-inline-block">
                <Link to="https://boi.itump.com/login"> Log In </Link>
              </div>
              <div className="get-started-btn">
                <Link to="https://boi.itump.com/login"> Get Started </Link>
              </div>
              <button
                type="button"
                title="Open Menu"
                className="open-mobile-menu d-xl-none bg-transparent border-0"
                onClick={() => {
                  document
                    .querySelector(".slide-bar")
                    ?.classList.toggle("show");
                  document.body.classList.add("on-side");
                  document
                    .querySelector(".body-overlay")
                    ?.classList.add("active");
                  document
                    .querySelector(".open-mobile-menu")
                    ?.classList.add("active");
                }}
              >
                <img src="assets/img/icons/menubar.svg" alt="menubar" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderSection;
