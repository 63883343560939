import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ItumpMobile from "../../component/ItumpMobileSection";

const Pricing = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* pricing section start  */}
      <section className="pricing-section team-hero bg-light overflow-hidden p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="pricing-section-title common-title-wrap common-title-light mx-auto text-center m-b-80 m-b-lg-70 m-b-md-60 m-b-xs-60">
            <h1 className="common-title mx-auto">
              Find the Perfect Plan for Your Business Needs
            </h1>
            <p className="common-title-body">
              Whether you’re just starting out or scaling up, we’ve designed our
              pricing plans to grow with you.
            </p>
          </div>
          <div className="pricing-tab-wrapper d-grid">
            <div className="text-center text-lg-start">
              <ul
                className="price-tab nav nav-pills mx-auto mx-lg-0"
                id="price-tab"
                role="tablist"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="annual-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#annual"
                    type="button"
                    role="tab"
                    aria-controls="annual"
                    aria-selected="true"
                  >
                    Annually
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="monthy-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#monthy"
                    type="button"
                    role="tab"
                    aria-controls="monthy"
                    aria-selected="false"
                  >
                    Monthly
                  </button>
                </li>
              </ul>
              <p className="save-text mt-3 mb-0">
                Save up to 15% with <br /> annual subscriptions
              </p>
            </div>
            <div
              className="price-tab-content tab-content"
              id="price-tabContent"
            >
              <div
                className="tab-pane fade show active"
                id="annual"
                role="tabpanel"
                aria-labelledby="annual-tab"
                tabindex="0"
              >
                <div className="price-card-wrapper d-grid">
                  <div className="price-card">
                    <h5 className="price-card-title fw-bold">Free Account</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">0</h4>
                      </div>
                      <p className="mb-0">
                        Per Month <br /> Billed Annually
                      </p>
                    </div>
                    <p className="price-card-desc">
                      Free Forever. <br /> Activation Required.
                    </p>
                    <Link
                      to="#"
                      className="price-card-btn with-border theme-btn fw-bold d-block w-100 text-center"
                    >
                      Start for Free
                    </Link>
                  </div>
                  <div className="price-card popular position-relative overflow-hidden">
                    <h5 className="price-card-title fw-bold">Startup</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">2,449</h4>
                      </div>
                      <p className="mb-0">
                        One Time <br /> Payment
                      </p>
                    </div>
                    <p className="price-card-desc">
                      A $58.99 fee required after the first 12 months
                    </p>
                    <Link
                      to="https://boi.itump.com/login"
                      className="price-card-btn theme-btn fw-bold d-block w-100 text-center"
                    >
                      Get Started
                    </Link>
                    <span className="price-card-tag position-absolute top-0 end-0 fw-bold text-white">
                      MOST POPULAR
                    </span>
                  </div>
                  <div className="price-card">
                    <h5 className="price-card-title fw-bold">Enterprise</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">5,497</h4>
                      </div>
                      <p className="mb-0">
                        One Time <br /> Payment
                      </p>
                    </div>
                    <p className="price-card-desc">
                      A $58.99 fee required after the first 12 months
                    </p>
                    <Link
                      to="https://boi.itump.com/login"
                      className="price-card-btn with-border theme-btn fw-bold d-block w-100 text-center"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="monthy"
                role="tabpanel"
                aria-labelledby="monthy-tab"
                tabindex="0"
              >
                <div className="price-card-wrapper d-grid">
                  <div className="price-card">
                    <h5 className="price-card-title fw-bold">Free Account</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">0</h4>
                      </div>
                      <p className="mb-0">
                        Per Month <br /> Billed Annually
                      </p>
                    </div>
                    <p className="price-card-desc">
                      Free Forever. <br /> Activation Required.
                    </p>
                    <Link
                      to="#"
                      className="price-card-btn with-border theme-btn fw-bold d-block w-100 text-center"
                    >
                      Start for Free
                    </Link>
                  </div>
                  <div className="price-card popular position-relative overflow-hidden">
                    <h5 className="price-card-title fw-bold">Startup</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">2,449</h4>
                      </div>
                      <p className="mb-0">
                        One Time <br /> Payment
                      </p>
                    </div>
                    <p className="price-card-desc">
                      A $58.99 fee required after the first 12 months
                    </p>
                    <Link
                      to="https://boi.itump.com/login"
                      className="price-card-btn theme-btn fw-bold d-block w-100 text-center"
                    >
                      Get Started
                    </Link>
                    <span className="price-card-tag position-absolute top-0 end-0 fw-bold text-white">
                      MOST POPULAR
                    </span>
                  </div>
                  <div className="price-card">
                    <h5 className="price-card-title fw-bold">Enterprise</h5>
                    <div className="price-card-price d-flex align-items-center">
                      <div className="price-amount d-flex align-items-start">
                        <span className="fw-medium">$</span>
                        <h4 className="fw-bold mb-0">5,497</h4>
                      </div>
                      <p className="mb-0">
                        One Time <br /> Payment
                      </p>
                    </div>
                    <p className="price-card-desc">
                      A $58.99 fee required after the first 12 months
                    </p>
                    <Link
                      to="https://boi.itump.com/login"
                      className="price-card-btn with-border theme-btn fw-bold d-block w-100 text-center"
                    >
                      Get Started
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="pricing-feature-container d-flex flex-column">
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                Cloud Storage{" "}
                <button
                  type="button"
                  className="info-btn border-0 bg-transparent"
                >
                  <img src="assets/img/icons/info.svg" alt="info" />
                </button>
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">CorpCrypt</h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <span>Free Account</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Free Account</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Free Account</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    University Basic
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    DIY AI website Creation
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Optional</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Crypt Access
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business Fillings
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Access To Downloads
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business Filling
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Receive Invoices
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Orders & Services
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business Health Usage
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Basic University
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                LLC/Corp Formation
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Preparing & Filing the Articles of Organization
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>8 Members</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Unlimited Members</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Customized Operating Agreement
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Unlimited</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Physical Address (NOT PO BOX)
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>2 Meetings To Finalize</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Tax Consultation
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Employer Identification Number
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    EIN Confirmation Letter
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    DUNS Number
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Bylaws for Corps
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Banking Resolution
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Free Registered Agent service
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>1 Year</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    IRS Form 2553
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                Advanced Corporate Filling
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Dedicated CPA Connection to File your taxes
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Yearly</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Tax Consultation
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    SST Resale Certificate
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    MTC Tax Exemption
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Banking Resolution
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Banking Consultation
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    ITIN Application & Approval "For NON-US Residents"
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Bank Account "For NON-US Residents"
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Bylaws for Corps
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                Basic Business Branding & Website
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Domain Name
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Website Landing Page
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business SEO
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Premium Business Logo
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business Card, T-Shirt, & Accessories Design
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Business Postcards Design
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Corporate Email
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                    <div className="p-feature-item text-center">
                      <span>2</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Unlimited Pages Business Website
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                Business Credit Building{" "}
                <button
                  type="button"
                  className="info-btn border-0 bg-transparent"
                >
                  <img src="assets/img/icons/info.svg" alt="info" />
                </button>
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Get Approved for HIGH-Limit Revolving Credit Cards in Your
                    Business's Name with No Personal Guarantee
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Access the Largest Supply of Business Credit Sources to Get
                    Vendor Credit, Retail Credit, Service Credit, Fleet Credit,
                    and Business Credit Cards Linked to Your EIN
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Receive Concierge Coaching and Servicing with Your Own
                    Business Credit Advisors Who Help You Easily Navigate the
                    Corporate Credit Building Process
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Enjoy Our Core and Partner Programs with Affordable and
                    Customizable Payment Plans
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Get Your Business Credit Profile Quickly Set Up and
                    Activated with D&B, Experian, and Equifax Commercial Saving
                    You Time and Money
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Corporate Compliance Review to Ensure Your Business Exceeds
                    Credit Issuer and Lender Fundability™ Standards to Get
                    Automated Approvals
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Get Business Credit and Financing through Your Own Finance
                    Suite
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h6 className="feature-list-title sm fw-bold mb-0">
                    Bureau Insights™ See the same comprehensive report lenders
                    and underwriters look at when making lending decisions
                  </h6>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricing-feature">
              <h4 className="pricing-feature-title mb-0 d-flex align-items-center">
                Support
              </h4>
              <div className="feature-list-wrapper">
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">Support</h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <span>Basic</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>1 Year</span>
                    </div>
                    <div className="p-feature-item text-center">
                      <span>Unlimited</span>
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Priority support
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
                <div className="feature-list d-grid">
                  <h5 className="feature-list-title fw-bold mb-0">
                    Personal Analytics Expert
                  </h5>
                  <div className="p-feature-item-wrapper d-grid">
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/xmark.svg" alt="xmark" />
                    </div>
                    <div className="p-feature-item text-center">
                      <img src="assets/img/icons/check.svg" alt="check" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* pricing section end  */}

      {/* cta section start  */}
      <section className="price-cta bg-light">
        <div className="container">
          <div className="price-cta-content position-relative z-1 overflow-hidden">
            <h3 className="cta-title fw-bold mb-2">Want more from Itump?</h3>
            <p className="cta-desc mb-4">
              Get even more support tailored to your business's growth and
              unique challenges
            </p>
            <Link
              to="#"
              className="cta-btn d-inline-flex align-items-center fw-medium"
            >
              Reach Out to Us <i className="fa-solid fa-circle-arrow-right"></i>
            </Link>
            <img
              src="assets/img/shape/price-cta-shape.png"
              alt="shape"
              className="shape position-absolute"
            />
          </div>
        </div>
      </section>
      {/* cta section end  */}

      {/* faq section start  */}
      {/* <section className="faq-section light bg-light p-t-100 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="common-title-wrap common-title-light m-b-40">
            <h2 className="common-title mb-0">Frequently Asked Question</h2>
          </div>
          <div className="faq-container">
            <div className="accordion" id="faq-accordion">
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-one"
                  aria-expanded="false"
                  aria-controls="faq-coll-one"
                >
                  Can I upgrade or downgrade my plan?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-one"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-two"
                  aria-expanded="false"
                  aria-controls="faq-coll-two"
                >
                  Is there a free trial available?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-two"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-three"
                  aria-expanded="false"
                  aria-controls="faq-coll-three"
                >
                  Are there any cancellation fees?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-three"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-four"
                  aria-expanded="false"
                  aria-controls="faq-coll-four"
                >
                  Is there a setup fee?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-four"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-five"
                  aria-expanded="false"
                  aria-controls="faq-coll-five"
                >
                  Can I pause my subscription instead of canceling it?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-five"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
              <div className="accordion-item">
                <button
                  type="button"
                  className="accordion-header"
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-coll-six"
                  aria-expanded="false"
                  aria-controls="faq-coll-six"
                >
                  Do I need technical expertise to use Itump?
                  <img
                    src="assets/img/icons/arrow-down3.svg"
                    alt="arrow down"
                    className="angle-icon"
                  />
                </button>
                <div
                  className="accordion-content collapse"
                  id="faq-coll-six"
                  data-bs-parent="#faq-accordion"
                >
                  <p>Answer content goes here.</p>
                </div>
              </div>
            </div>
            <p className="contact">
              If you have more questions, do not hesitate to{" "}
              <Link to="/contact">Contact Us</Link>
            </p>
          </div>
        </div>
      </section> */}

      <section className="itump-mobile-section bg-light p-t-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-md-80 p-b-xs-40">
        <div className="container">
          <ItumpMobile className="style-light" />
        </div>
      </section>
      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default Pricing;
