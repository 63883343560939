import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SolutionsPay = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="itump-pay-hero overflow-hidden">
        <div className="container">
          <div className="hero-wrapper position-relative">
            <div className="hero-content-wrapper mx-auto text-center position-relative z-1">
              <div className="hero-content mx-auto">
                <h1
                  className="hero-title px-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Your All-in-One Payment Gateway
                </h1>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="100">
                  {" "}
                  Itump Pay is designed to handle payments efficiently,
                  securely, and flexibly, making it an ideal solution for
                  businesses ranging from startups to enterprises
                </p>
              </div>
              <div
                className="hero-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="get-started-btn-2">
                  <Link to="https://boi.itump.com/login">Get Started</Link>
                </div>
                <div className="learn-more-btn">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="hero-shape-1 position-absolute top-0 start-0 h-100 d-none d-md-block">
              <img
                src="assets/img/shape/shape-28.png"
                alt="shape"
                className="w-100 h-100"
              />
            </div>
            <div className="hero-shape-2 position-absolute top-0 end-0 h-100 d-none d-md-block">
              <img
                src="assets/img/shape/shape-28.png"
                alt="shape"
                className="w-100 h-100"
              />
            </div>
            <div className="hero-shape-3 position-absolute d-none d-md-block">
              <img
                src="assets/img/shape/shape-29.svg"
                alt="shape"
                className="w-100"
                data-aos="fade-up"
                data-aos-delay="50"
              />
            </div>
            <div className="hero-shape-4 position-absolute d-none d-md-block">
              <img
                src="assets/img/shape/shape-30.svg"
                alt="shape"
                className="w-100"
                data-aos="fade-down"
                data-aos-delay="50"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="payment-section p-b-155 p-b-xl-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-7">
              <div className="payment-heading-wrap position-relative p-t-150 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 m-b-xs-40">
                <div className="p-r-65 p-r-lg-30 p-r-md-20 p-r-xs-0">
                  <h3 className="title" data-aos="fade-up" data-aos-delay="50">
                    Simplifying Payments for Businesses of All Sizes
                  </h3>
                  <p className="text" data-aos="fade-up" data-aos-delay="100">
                    In today's fast-paced business environment, seamless payment
                    processing is critical for success. Itump Pay is designed to
                    handle payments efficiently, securely, and flexibly, making
                    it an ideal solution for businesses ranging from startups to
                    enterprises. Whether you’re managing online transactions,
                    recurring billing, or in-store purchases, Itump Pay
                    integrates effortlessly into your workflow.
                  </p>
                </div>
                <div className="border-shape m-t-175 m-t-xl-120 m-t-lg-100 m-t-md-80 d-none d-md-block"></div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="payment-content-wrapper d-flex flex-column position-relative z-1 p-t-150 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-0">
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-1.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">Multi-Channel Payment Integration</Link>
                    </h3>
                    <p>
                      Itump Pay allows you to accept payments from multiple
                      channels—online, in-app, or in-store. This flexibility
                      enables businesses to offer customers a unified and
                      seamless experience. Integrate payments directly into your
                      e-commerce site, mobile app, or point-of-sale (POS)
                      systems without missing a beat.
                    </p>
                  </div>
                </div>
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-2.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">
                        Global Reach with Localized Payment Options
                      </Link>
                    </h3>
                    <p>
                      With Itump Pay, you can conduct business globally while
                      offering customers localized payment methods. Accept
                      payments in various currencies, and through preferred
                      methods, including credit cards, digital wallets, and even
                      region-specific options such as PayPal or Apple Pay. This
                      makes scaling your business across borders simpler than
                      ever.
                    </p>
                  </div>
                </div>
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-3.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">
                        Recurring Billing & Subscription Management
                      </Link>
                    </h3>
                    <p>
                      Easily set up and manage recurring payments for
                      subscription-based businesses. Automated billing cycles,
                      customizable plans, and real-time reporting ensure that
                      your subscription business runs smoothly. No more chasing
                      late payments; Itump Pay handles it for you.
                    </p>
                  </div>
                </div>
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-4.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">Advanced Fraud Detection & Security</Link>
                    </h3>
                    <p>
                      Security is paramount, and Itump Pay provides advanced
                      fraud detection tools to minimize risks. With features
                      like tokenization, encryption, and real-time monitoring,
                      you can ensure that both your business and your customers
                      are protected from fraudulent activity.
                    </p>
                  </div>
                </div>
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-5.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">Comprehensive Reporting & Analytics</Link>
                    </h3>
                    <p>
                      Track your business’s financial health with our
                      comprehensive dashboard, providing real-time insights into
                      transactions, payment trends, and customer behavior. Make
                      data-driven decisions using detailed analytics and reports
                      designed to give you a clear view of your financial
                      landscape.
                    </p>
                  </div>
                </div>
                <div className="payment-content-wrap">
                  <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                    <img src="assets/img/thumb/pay-thumb-6.png" alt="thumb" />
                  </div>
                  <div className="info" data-aos="fade-up" data-aos-delay="100">
                    <h3>
                      <Link to="#">Instant Payouts</Link>
                    </h3>
                    <p>
                      No more waiting for days to access your funds. Itump Pay
                      offers instant payouts to your linked bank account, so you
                      can manage your cash flow effectively and focus on growing
                      your business.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-shape-1">
          <img src="assets/img/shape/shape-31.png" alt="shape" />
        </div>
      </section>

      <section className="transaction-section p-t-40">
        <div className="container">
          <div className="transaction-tab">
            <ul
              className="nav nav-tabs m-b-100 m-b-lg-80 m-b-md-60 m-b-xs-40"
              id="myTab"
              role="tablist"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="tap-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#tap-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="tap-tab-pane"
                  aria-selected="true"
                >
                  <img
                    src="assets/img/icons/wifi.png"
                    alt="wifi"
                    className="icon"
                  />
                  Tap to Pay
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="scan-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#scan-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="scan-tab-pane"
                  aria-selected="false"
                >
                  <img
                    src="assets/img/icons/scanner.png"
                    alt="scanner"
                    className="icon"
                  />
                  Scan to Pay
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="send-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#send-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="send-tab-pane"
                  aria-selected="false"
                >
                  <img
                    src="assets/img/icons/receipt.png"
                    alt="receipt"
                    className="icon"
                  />
                  Send an Invoice
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="tap-tab-pane"
                role="tabpanel"
                aria-labelledby="tap-tab"
                tabindex="0"
              >
                <div className="row">
                  <div
                    className="col-md-5"
                    data-aos="zoom-in"
                    data-aos-delay="50"
                  >
                    <div className="transaction-thumb">
                      <img
                        src="assets/img/thumb/pay-thumb.png"
                        alt="thumb"
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="transaction-content p-t-50 p-t-xs-40 p-b-xs-60">
                      <h3 data-aos="fade-up" data-aos-delay="50">
                        Seamless Contactless Transactions
                      </h3>
                      <p data-aos="fade-up" data-aos-delay="100">
                        With our Tap to Pay feature, businesses can accept
                        payments effortlessly through contactless technology.
                        Simply tap your card or mobile device, and the
                        transaction is instantly processed—no need for
                        additional hardware. This fast and secure solution
                        enables you to provide a seamless payment experience for
                        your customers, wherever you are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="scan-tab-pane"
                role="tabpanel"
                aria-labelledby="scan-tab"
                tabindex="0"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="transaction-thumb">
                      <img
                        src="assets/img/thumb/pay-thumb.png"
                        alt="thumb"
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="transaction-content p-t-50 p-t-xs-40 p-b-xs-60">
                      <h3>Seamless Contactless Transactions</h3>
                      <p>
                        With our Tap to Pay feature, businesses can accept
                        payments effortlessly through contactless technology.
                        Simply tap your card or mobile device, and the
                        transaction is instantly processed—no need for
                        additional hardware. This fast and secure solution
                        enables you to provide a seamless payment experience for
                        your customers, wherever you are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="send-tab-pane"
                role="tabpanel"
                aria-labelledby="send-tab"
                tabindex="0"
              >
                <div className="row">
                  <div className="col-md-5">
                    <div className="transaction-thumb">
                      <img
                        src="assets/img/thumb/pay-thumb.png"
                        alt="thumb"
                        className="w-100"
                      />
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="transaction-content p-t-50 p-t-xs-40 p-b-xs-60">
                      <h3>Seamless Contactless Transactions</h3>
                      <p>
                        With our Tap to Pay feature, businesses can accept
                        payments effortlessly through contactless technology.
                        Simply tap your card or mobile device, and the
                        transaction is instantly processed—no need for
                        additional hardware. This fast and secure solution
                        enables you to provide a seamless payment experience for
                        your customers, wherever you are.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-transaction-shape-1">
          <img src="assets/img/shape/shape-32.png" alt="shape" />
        </div>
      </section>

      <section className="p-t-140 p-t-lg-120 p-t-md-80 p-t-xs-60 p-b-200 p-b-xl-160 p-b-lg-120 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="service-steps-wrapper">
            <div className="service-steps-wrap">
              <div className="icon" data-aos="fade-up" data-aos-delay="50">
                <img src="assets/img/icons/effort-icon.svg" alt="effort-icon" />
              </div>
              <h3 data-aos="fade-up" data-aos-delay="100">
                Effortless Integration
              </h3>
              <p data-aos="fade-up" data-aos-delay="150">
                Developers can easily use our robust API and SDKs to get Itump
                Pay up and running in no time.
              </p>
            </div>
            <div className="service-steps-wrap">
              <div className="icon" data-aos="fade-up" data-aos-delay="50">
                <img
                  src="assets/img/icons/scalable-icon.svg"
                  alt="scalable-icon"
                />
              </div>
              <h3 data-aos="fade-up" data-aos-delay="100">
                Scalable and Customizable
              </h3>
              <p data-aos="fade-up" data-aos-delay="150">
                Whether you're a small business or a global enterprise, Itump
                Pay is built to scale with your needs.
              </p>
            </div>
            <div className="service-steps-wrap">
              <div className="icon" data-aos="fade-up" data-aos-delay="50">
                <img src="assets/img/icons/support.svg" alt="support" />
              </div>
              <h3 data-aos="fade-up" data-aos-delay="100">
                24/7 Support
              </h3>
              <p data-aos="fade-up" data-aos-delay="150">
                Our team of payment experts is available around the clock to
                help with any issues you may face.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="use-section position-relative z-1 p-t-60">
        <div className="use-shape">
          <img
            src="assets/img/shape/user-shape.png"
            alt="shape"
            className="w-100"
            data-aos="fade-right"
            data-aos-delay="50"
          />
        </div>
        <div className="container">
          <div className="use-section-wrapper p-b-210 p-b-xl-160 p-b-lg-120 p-b-md-80 p-b-xs-60">
            <div className="row justify-content-center text-center">
              <div className="col-xl-9 col-lg-10">
                <div className="use-content common-title-wrap p-t-110">
                  <h2
                    className="common-title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Start using Itump Pay Today
                  </h2>
                  <p
                    className="common-title-body"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Whether you're looking for faster processing, enhanced
                    security, or deeper insights into your revenue, Itump Pay is
                    the partner you need to grow
                  </p>
                  <Link
                    to="https://boi.itump.com/login"
                    className="theme-btn"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    Get Started <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SolutionsPay;
