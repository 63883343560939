import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import CopyrightSection from "../../component/CopyrightSection";
import FooterSection from "../../component/FooterSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";

const ServiceCopyright = () => {
  const faqs = [
    {
      id: "one",
      question: "What is the difference between copyright and trademark?",
      answer:
        "Copyright protects your creative works, like books, music, and art. Trademark protects your brand name, logo, and slogans. Think of it this way: copyright is for your creations, and trademark is for your brand identity.",
    },
    {
      id: "two",
      question: "How long does the copyright or trademark process take?",
      answer:
        "It varies. Copyright registration can take a few months, while trademark registration can take a year or more. Itump can help you streamline the process and stay organized.",
    },
    {
      id: "three",
      question: "Do I need both copyright and trademark protection?",
      answer:
        "It depends on your business. If you create original content and have a unique brand, you might need both. Itump can help you assess your needs and determine the right protection for your business.",
    },
    {
      id: "four",
      question: "Can I file for a trademark myself?",
      answer:
        "Yes, you can file for a trademark yourself, but it can be a complex process. Itump can provide guidance and support to make it easier.",
    },
    {
      id: "five",
      question: "What if my application is denied?",
      answer:
        "If your application is denied, you can appeal the decision or refile with revisions. Itump can help you understand the reasons for denial and assist with the appeal process.",
    },
    {
      id: "six",
      question: "How do I maintain my trademark?",
      answer:
        "You need to use your trademark regularly and renew it periodically to maintain its protection. Itump can help you track deadlines and ensure your trademark remains valid.",
    },
  ];
  return (
    <>
      <HeaderSection />
      <SlideBar />

      <section className="hero-section-2 service-hero service-copyright-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-265 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  COPYRIGHT & TRADEMARK
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {" "}
                  Protect Your Intellectual Property
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Safeguard your brand and creative assets with professional
                  copyright and trademark services
                </p>
              </div>
              <div>
                <Link
                  to="https://boi.itump.com/login"
                  className="theme-btn"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src="assets/img/thumb/thumb-48.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-21.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-22.png" alt="shape" />
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-8">
              <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  {" "}
                  Why Copyright & Trademark <br /> Are Essential
                </h3>
                <p
                  className="common-title-body"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {" "}
                  Secure your brand's identity and creative assets to maintain
                  your competitive edge and prevent unauthorized use
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap file-with-us-wrap copyright-card-wrapper m-b-65 m-b-xs-40">
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/security.svg" alt="security" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Safeguard Valuable Assets
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Ensure timely notifications and compliance with a more
                      reliable registered agent
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/briefcase.svg" alt="briefcase" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Legal Protection
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Meet state regulations when moving your business by
                      changing your registered agent
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/lock.svg" alt="lock" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Maintain Brand Integrity
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="200"
                    >
                      Essential for both startups and established businesses to
                      protect intellectual property
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-card-section p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>Requirements</h3>
                </div>
                <div
                  className="service-card-thumb m-b-80"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-32.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-1"
                        aria-expanded="false"
                        aria-controls="service-item-coll-1"
                      >
                        <span>Business Details</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-1"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-2"
                        aria-expanded="false"
                        aria-controls="service-item-coll-2"
                      >
                        <span>Details of the Intellectual Property</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-2"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-3"
                        aria-expanded="false"
                        aria-controls="service-item-coll-3"
                      >
                        <span>Proof of Ownership or Creation</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-3"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-1">
                  <img src="assets/img/shape/shape-10.png" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>What You Get</h3>
                </div>
                <div
                  className="service-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-33.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content m-t-35"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-4"
                        aria-expanded="false"
                        aria-controls="service-item-coll-4"
                      >
                        <span>Registered Copyright or Trademark</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-4"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-5"
                        aria-expanded="false"
                        aria-controls="service-item-coll-5"
                      >
                        <span>
                          Legal Protection for your Intellectual Property
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-5"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-6"
                        aria-expanded="false"
                        aria-controls="service-item-coll-6"
                      >
                        <span>Exclusive Rights for All Brand Assets</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-6"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-2">
                  <img src="assets/img/shape/shape-11.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <section className="boi-complete-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="common-title-wrap m-b-90">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Our Simple 3-Step Process
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="boi-complete-steps p-b-85 p-b-lg-60 p-b-md-50 p-b-xs-40">
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.6666 4.6665H18.9999C10.8333 4.6665 7.33325 9.33317 7.33325 16.3332V39.6665C7.33325 46.6665 10.8333 51.3332 18.9999 51.3332H37.6666C45.8333 51.3332 49.3333 46.6665 49.3333 39.6665V16.3332C49.3333 9.33317 45.8333 4.6665 37.6666 4.6665ZM18.9999 28.5832H28.3333C29.2899 28.5832 30.0833 29.3765 30.0833 30.3332C30.0833 31.2898 29.2899 32.0832 28.3333 32.0832H18.9999C18.0433 32.0832 17.2499 31.2898 17.2499 30.3332C17.2499 29.3765 18.0433 28.5832 18.9999 28.5832ZM37.6666 41.4165H18.9999C18.0433 41.4165 17.2499 40.6232 17.2499 39.6665C17.2499 38.7098 18.0433 37.9165 18.9999 37.9165H37.6666C38.6233 37.9165 39.4166 38.7098 39.4166 39.6665C39.4166 40.6232 38.6233 41.4165 37.6666 41.4165ZM43.4999 21.5832H38.8333C35.2866 21.5832 32.4166 18.7132 32.4166 15.1665V10.4998C32.4166 9.54317 33.2099 8.74984 34.1666 8.74984C35.1233 8.74984 35.9166 9.54317 35.9166 10.4998V15.1665C35.9166 16.7765 37.2233 18.0832 38.8333 18.0832H43.4999C44.4566 18.0832 45.2499 18.8765 45.2499 19.8332C45.2499 20.7898 44.4566 21.5832 43.4999 21.5832Z"
                        fill="#7A7A7A"
                      ></path>
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Submit Your Information</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        Provide the necessary details about your intellectual
                        property through our streamlined online form.
                      </p>
                    </div>
                  </div>
                  <div className="step-shape">
                    <svg
                      width="321"
                      height="8"
                      viewBox="0 0 321 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M320.354 4.35355C320.549 4.15829 320.549 3.84171 320.354 3.64645L317.172 0.464466C316.976 0.269204 316.66 0.269204 316.464 0.464466C316.269 0.659728 316.269 0.976311 316.464 1.17157L319.293 4L316.464 6.82843C316.269 7.02369 316.269 7.34027 316.464 7.53553C316.66 7.7308 316.976 7.7308 317.172 7.53553L320.354 4.35355ZM0 4.5H2.96296V3.5H0V4.5ZM8.88889 4.5H14.8148V3.5H8.88889V4.5ZM20.7407 4.5H26.6667V3.5H20.7407V4.5ZM32.5926 4.5H38.5185V3.5H32.5926V4.5ZM44.4444 4.5H50.3704V3.5H44.4444V4.5ZM56.2963 4.5H62.2222V3.5H56.2963V4.5ZM68.1481 4.5H74.0741V3.5H68.1481V4.5ZM80 4.5H85.9259V3.5H80V4.5ZM91.8519 4.5H97.7778V3.5H91.8519V4.5ZM103.704 4.5H109.63V3.5H103.704V4.5ZM115.556 4.5H121.481V3.5H115.556V4.5ZM127.407 4.5H133.333V3.5H127.407V4.5ZM139.259 4.5H145.185V3.5H139.259V4.5ZM151.111 4.5H157.037V3.5H151.111V4.5ZM162.963 4.5H168.889V3.5H162.963V4.5ZM174.815 4.5H180.741V3.5H174.815V4.5ZM186.667 4.5H192.593V3.5H186.667V4.5ZM198.518 4.5H204.444V3.5H198.518V4.5ZM210.37 4.5H216.296V3.5H210.37V4.5ZM222.222 4.5H228.148V3.5H222.222V4.5ZM234.074 4.5H240V3.5H234.074V4.5ZM245.926 4.5H251.852V3.5H245.926V4.5ZM257.778 4.5H263.704V3.5H257.778V4.5ZM269.629 4.5H275.555V3.5H269.629V4.5ZM281.481 4.5H287.407V3.5H281.481V4.5ZM293.333 4.5H299.259V3.5H293.333V4.5ZM305.185 4.5H311.111V3.5H305.185V4.5ZM317.037 4.5H320V3.5H317.037V4.5Z"
                        fill="url(#paint0_linear_2283_10853)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2283_10853"
                          x1="0"
                          y1="4.5"
                          x2="320"
                          y2="4.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#323232" />
                          <stop offset="1" stop-color="#224370" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32.4103 6.71984L21.467 4.99317C12.3436 3.56984 8.12031 6.64984 6.67364 15.7732L4.94697 26.7165C4.01364 32.6898 4.99364 36.5632 8.37697 38.9198C10.1503 40.1798 12.577 41.0198 15.727 41.5098L26.6703 43.2365C35.7936 44.6598 40.017 41.5798 41.4636 32.4565L43.167 21.5132C43.447 19.7165 43.5636 18.1065 43.4703 16.6832C43.167 10.8498 39.737 7.86317 32.4103 6.71984ZM19.227 21.8165C16.497 21.8165 14.2803 19.5998 14.2803 16.8932C14.2803 14.1632 16.497 11.9465 19.227 11.9465C21.9336 11.9465 24.1503 14.1632 24.1503 16.8932C24.1503 19.5998 21.9336 21.8165 19.227 21.8165Z"
                        fill="#4A7ABA"
                      />
                      <path
                        d="M47.8332 31.4298L44.3332 41.9531C41.4165 50.7265 36.7498 53.0598 27.9765 50.1431L17.4532 46.6431C14.1632 45.5465 11.7832 44.1931 10.2432 42.4898C11.7132 43.0731 13.4165 43.5165 15.3532 43.8198L26.3198 45.5465C27.8132 45.7798 29.2132 45.8965 30.5198 45.8965C38.2198 45.8965 42.3498 41.7431 43.7732 32.8065L45.4765 21.8631C45.7098 20.5098 45.8032 19.3198 45.8032 18.2231C49.3498 21.1398 49.8632 25.2931 47.8332 31.4298Z"
                        fill="#4A7ABA"
                      />
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Review and Preparation</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        Our experts will review your submission and prepare the
                        appropriate copyright or trademark application.
                      </p>
                    </div>
                  </div>
                  <div className="step-shape">
                    <svg
                      width="321"
                      height="8"
                      viewBox="0 0 321 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M320.354 4.35355C320.549 4.15829 320.549 3.84171 320.354 3.64645L317.172 0.464466C316.976 0.269204 316.66 0.269204 316.464 0.464466C316.269 0.659728 316.269 0.976311 316.464 1.17157L319.293 4L316.464 6.82843C316.269 7.02369 316.269 7.34027 316.464 7.53553C316.66 7.7308 316.976 7.7308 317.172 7.53553L320.354 4.35355ZM0 4.5H2.96296V3.5H0V4.5ZM8.88889 4.5H14.8148V3.5H8.88889V4.5ZM20.7407 4.5H26.6667V3.5H20.7407V4.5ZM32.5926 4.5H38.5185V3.5H32.5926V4.5ZM44.4444 4.5H50.3704V3.5H44.4444V4.5ZM56.2963 4.5H62.2222V3.5H56.2963V4.5ZM68.1481 4.5H74.0741V3.5H68.1481V4.5ZM80 4.5H85.9259V3.5H80V4.5ZM91.8519 4.5H97.7778V3.5H91.8519V4.5ZM103.704 4.5H109.63V3.5H103.704V4.5ZM115.556 4.5H121.481V3.5H115.556V4.5ZM127.407 4.5H133.333V3.5H127.407V4.5ZM139.259 4.5H145.185V3.5H139.259V4.5ZM151.111 4.5H157.037V3.5H151.111V4.5ZM162.963 4.5H168.889V3.5H162.963V4.5ZM174.815 4.5H180.741V3.5H174.815V4.5ZM186.667 4.5H192.593V3.5H186.667V4.5ZM198.518 4.5H204.444V3.5H198.518V4.5ZM210.37 4.5H216.296V3.5H210.37V4.5ZM222.222 4.5H228.148V3.5H222.222V4.5ZM234.074 4.5H240V3.5H234.074V4.5ZM245.926 4.5H251.852V3.5H245.926V4.5ZM257.778 4.5H263.704V3.5H257.778V4.5ZM269.629 4.5H275.555V3.5H269.629V4.5ZM281.481 4.5H287.407V3.5H281.481V4.5ZM293.333 4.5H299.259V3.5H293.333V4.5ZM305.185 4.5H311.111V3.5H305.185V4.5ZM317.037 4.5H320V3.5H317.037V4.5Z"
                        fill="url(#paint0_linear_2283_10854)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2283_10854"
                          x1="0"
                          y1="4.5"
                          x2="320"
                          y2="4.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2F80ED" stop-opacity="0.4" />
                          <stop offset="1" stop-color="#2F80ED" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M34.1501 4.6665H23.1834C20.7568 4.6665 18.7734 6.6265 18.7734 9.05317V11.2465C18.7734 13.6732 20.7334 15.6332 23.1601 15.6332H34.1501C36.5768 15.6332 38.5368 13.6732 38.5368 11.2465V9.05317C38.5601 6.6265 36.5768 4.6665 34.1501 4.6665Z"
                        fill="#2F80ED"
                      />
                      <path
                        d="M40.8938 11.2466C40.8938 14.9566 37.8604 17.99 34.1504 17.99H23.1838C19.4738 17.99 16.4404 14.9566 16.4404 11.2466C16.4404 9.93995 15.0404 9.12329 13.8738 9.72995C10.5838 11.48 8.34375 14.9566 8.34375 18.9466V40.9033C8.34375 46.6433 13.0338 51.3333 18.7738 51.3333H38.5604C44.3004 51.3333 48.9904 46.6433 48.9904 40.9033V18.9466C48.9904 14.9566 46.7504 11.48 43.4604 9.72995C42.2938 9.12329 40.8938 9.93995 40.8938 11.2466ZM36.4604 29.7033L27.1271 39.0366C26.7771 39.3866 26.3338 39.55 25.8904 39.55C25.4471 39.55 25.0037 39.3866 24.6538 39.0366L21.1538 35.5366C20.4771 34.86 20.4771 33.74 21.1538 33.0633C21.8304 32.3866 22.9504 32.3866 23.6271 33.0633L25.8904 35.3266L33.9871 27.23C34.6638 26.5533 35.7838 26.5533 36.4604 27.23C37.1371 27.9066 37.1371 29.0266 36.4604 29.7033Z"
                        fill="#2F80ED"
                      />
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Filing and Confirmation</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        We file your application with the relevant authorities
                        and provide you with confirmation once it's registered.
                      </p>
                    </div>
                  </div>
                  <div className="s-bio-shape-1">
                    <img src="assets/img/shape/shape-23.png" alt="shape" />
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end  */}

      <FAQSection faqs={faqs} />

      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>
      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceCopyright;
