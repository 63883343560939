import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SolutionsDocuhub = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-6 position-relative z-1">
        <div className="container">
          <div className="hero-content mx-auto">
            <h2 className="hero-title" data-aos="fade-up" data-aos-delay="50">
              Centralized Document Management Made Easy
            </h2>
            <p className="hero-sub" data-aos="fade-up" data-aos-delay="100">
              Itump DocuHub is your central hub for storing, managing, and
              protecting all your important business documents.
            </p>
          </div>
          <div
            className="hero-btn-wrap"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="get-started-btn-2">
              <Link to="https://boi.itump.com/login">Get Started</Link>
            </div>
            <div className="learn-more-btn">
              <Link to="https://boi.itump.com/login">Learn More</Link>
            </div>
          </div>
        </div>
        <div className="s-shape-1">
          <img src="assets/img/shape/shape-41.png" alt="shape" />
        </div>
        <div className="s-shape-2">
          <img src="assets/img/shape/shape-42.png" alt="shape" />
        </div>
        <div className="s-shape-3">
          <img src="assets/img/shape/shape-43.png" alt="shape" />
        </div>
        <div className="s-shape-4">
          <img src="assets/img/shape/shape-44.png" alt="shape" />
        </div>
      </section>

      <section className="steps-section-2 p-b-100 p-b-md-0 p-b-xs-0">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="service-steps-wrapper style-2">
                <div
                  className="service-steps-wrap border-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="icon">
                    <img
                      src="assets/img/icons/organization.svg"
                      alt="organization"
                    />
                  </div>
                  <h3>Easy organization</h3>
                  <p>
                    Categorize and tag your documents for quick and easy
                    retrieval. No more wasted time searching for the files you
                    need.
                  </p>
                </div>
                <div
                  className="service-steps-wrap border-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <div className="icon">
                    <img
                      src="assets/img/icons/import-document.svg"
                      alt="import-document"
                    />
                  </div>
                  <h3>Automatically import documents</h3>
                  <p>
                    Any sensitive information you enter into Itump service forms
                    is automatically added to your DocuHub.
                  </p>
                </div>
                <div
                  className="service-steps-wrap border-0"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="icon">
                    <img
                      src="assets/img/icons/version-control.svg"
                      alt="version-control"
                    />
                  </div>
                  <h3>Version control</h3>
                  <p>
                    Track changes to your documents and easily revert to
                    previous versions if needed.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center m-t-240 m-t-xl-200 m-t-lg-160 m-t-md-120 m-t-xs-120 m-b-250 m-b-xl-200 m-b-lg-160 m-b-md-120 m-b-xs-120">
            <div className="col-md-6">
              <div className="document-storage-content finance-content-wrap m-b-xs-40">
                <h3 className="title" data-aos="fade-up" data-aos-delay="50">
                  Secure Document Storage You Can Trust
                </h3>
                <p className="text" data-aos="fade-up" data-aos-delay="100">
                  DocuHub uses advanced encryption technology to protect your
                  data from unauthorized access. Access to your DocuHub is
                  protected by your passcode or Face ID, adding an extra layer
                  of security to your confidential information. You can rest
                  assured that your confidential information is safe.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="document-storage-thumb finance-thumb mx-auto"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/thumb/document-storage.png"
                  alt="thumb"
                  className="w-100"
                />
              </div>
            </div>
          </div>
          <div className="row m-b-180 m-b-xl-160 m-b-lg-140 m-b-md-120 m-b-xs-120">
            <div className="col-md-6 m-b-xs-40">
              <div className="document-card document-sync growth-item-4 h-100">
                <div className="document-card-content m-b-65 m-b-lg-40 m-b-md-30 m-b-xs-25">
                  <h6
                    className="document-card-content-title fw-bold mb-2"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Instant Document Sync
                  </h6>
                  <p
                    className="document-card-content-desc"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Whatever sensitive information you filled in a service form
                    is automatically added to your DocuHub, and can always be
                    updated and re-saved.
                  </p>
                </div>
                <div
                  className="document-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <img
                    src="assets/img/thumb/document-sync.png"
                    alt="document-sync"
                    className="w-100"
                  />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="document-card document-centralized growth-item-4 h-100">
                <div
                  className="document-card-thumb m-b-90 m-b-lg-60 m-b-md-40 m-b-xs-25 mx-auto"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <img
                    src="assets/img/thumb/centralized-storage.png"
                    alt="growth-thumb"
                    className="w-100"
                  />
                </div>
                <div className="document-card-content">
                  <p
                    className="document-card-content-title fw-bold mb-2"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Centralized Storage
                  </p>
                  <p
                    className="document-card-content-desc"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    Keep all your important documents in one place, easily
                    accessible from any device.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="business-cta text-center">
            <div className="use-content common-title-wrap mx-auto">
              <h2
                className="common-title"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                Simplify Your Business Document Management
              </h2>
              <p
                className="common-title-body"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Itump DocuHub is your all-in-one platform for secure and
                efficient business document storage and management. Sign up and
                see the difference.
              </p>
              <Link
                to="https://boi.itump.com/login"
                className="theme-btn"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                Get Started <i className="fa fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SolutionsDocuhub;
