import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const JobOpeningsEmpty = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* job section start  */}
      <section class="job-section team-hero bg-light overflow-hidden p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div class="container">
          <div class="job-section-wrapper mx-auto">
            <div class="hero-content common-title-wrap common-title-light mx-auto text-center m-b-60">
              <h1 class="common-title" data-aos="fade-up" data-aos-delay="50">
                We’re making itump better. <br /> Join our team
              </h1>
              <p
                class="common-title-body mb-0"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Build your career with Itump. Explore exciting opportunities and
                innovate with us. <br /> Discover a supportive work environment
                where you can thrive.
              </p>
              <div
                class="job-info m-t-35 m-b-35 py-4 d-md-none d-flex align-items-center justify-content-between flex-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <p class="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    class="flex-shrink-0"
                    src="assets/img/icons/briefcase-2.svg"
                    alt="briefcase"
                  />{" "}
                  Full-Time
                </p>
                <p class="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    class="flex-shrink-0"
                    src="assets/img/icons/buildings-3.svg"
                    alt="building"
                  />{" "}
                  Engineering
                </p>
                <p class="d-flex align-items-center gap-2 mb-0 fw-medium">
                  <img
                    class="flex-shrink-0"
                    src="assets/img/icons/location.svg"
                    alt="briefcase"
                  />{" "}
                  New York, USA
                </p>
              </div>
              <Link
                to="#"
                class="apply-btn theme-btn d-md-none d-inline-block fw-medium"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                Apply now
              </Link>
            </div>
            <div class="growth-form-wrap m-b-60">
              <form action="#">
                <div class="growth-form" data-aos="fade-up" data-aos-delay="50">
                  <div class="select-wrap">
                    <select
                      title="employment-type"
                      name="employment-type"
                      id="employment-type"
                    >
                      <option value="0">Employment Type</option>
                      <option value="1">Employment Type</option>
                      <option value="2">Employment Type</option>
                    </select>
                    <div class="icon">
                      <img
                        src="assets/img/icons/briefcase-2.svg"
                        alt="briefcase"
                      />
                    </div>
                    <div class="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                  <div class="select-wrap">
                    <select
                      title="department"
                      name="department"
                      id="department"
                    >
                      <option value="0">Department</option>
                      <option value="1">Department</option>
                      <option value="2">Department</option>
                    </select>
                    <div class="icon">
                      <img
                        src="assets/img/icons/buildings-3.svg"
                        alt="building"
                      />
                    </div>
                    <div class="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                  <div class="select-wrap">
                    <select title="location" name="location" id="location">
                      <option value="0">Location</option>
                      <option value="1">Location</option>
                      <option value="2">Location</option>
                    </select>
                    <div class="icon">
                      <img src="assets/img/icons/location.svg" alt="location" />
                    </div>
                    <div class="down-arrow">
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="job-empty text-center p-t-60 p-b-60 text-center">
              <img
                src="assets/img/icons/note-2.svg"
                alt="note"
                class="job-empty-icon mx-auto"
                data-aos="zoom-in"
                data-aos-delay="50"
              />
              <h5
                class="job-empty-title fw-bold mt-4"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                We're not hiring right now
              </h5>
              <p
                class="job-empty-desc mb-0 mx-auto"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                We're currently not accepting applications for any positions.
                Please check back again for future opportunities.
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* job section end  */}

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default JobOpeningsEmpty;
