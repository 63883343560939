import React, { useState } from "react";
import { Link } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/bundle";
import { AnimatePresence, motion } from "motion/react";
import HeaderSection from "../../component/HeaderSection";
import FeatureSection from "../../component/FeatureSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
// import CustomerSection from "../../component/CustomerSection";
import ComplianceCard from "../../component/ComplianceCard";
import ItumpMobile from "../../component/ItumpMobileSection";

const Home = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [isHovered4, setIsHovered4] = useState(false);
  const [isHovered5, setIsHovered5] = useState(false);
  const [isHovered6, setIsHovered6] = useState(false);
  const [isHovered7, setIsHovered7] = useState(false);

  const [isHovered9, setIsHovered9] = useState(false);
  const [isHovered10, setIsHovered10] = useState(false);
  const [isHovered11, setIsHovered11] = useState(false);
  const [isHovered12, setIsHovered12] = useState(false);
  const [isHovered13, setIsHovered13] = useState(false);
  const [isHovered14, setIsHovered14] = useState(false);
  const [isHovered15, setIsHovered15] = useState(false);
  const [isHovered16, setIsHovered16] = useState(false);
  const [isHovered17, setIsHovered17] = useState(false);
  const [isHovered18, setIsHovered18] = useState(false);
  const [isHovered19, setIsHovered19] = useState(false);
  const [isHovered36, setIsHovered36] = useState(false);

  const handleMouseOver = () => {
    setIsHovered(true);
  };

  const handleMouseOut = () => {
    setIsHovered(false);
  };

  const handleMouseOver2 = () => {
    setIsHovered2(true);
  };

  const handleMouseOut2 = () => {
    setIsHovered2(false);
  };

  const handleMouseOver3 = () => {
    setIsHovered3(true);
  };

  const handleMouseOut3 = () => {
    setIsHovered3(false);
  };

  const handleMouseOver4 = () => {
    setIsHovered4(true);
  };

  const handleMouseOut4 = () => {
    setIsHovered4(false);
  };

  const handleMouseOver5 = () => {
    setIsHovered5(true);
  };

  const handleMouseOut5 = () => {
    setIsHovered5(false);
  };

  const handleMouseOver6 = () => {
    setIsHovered6(true);
  };

  const handleMouseOut6 = () => {
    setIsHovered6(false);
  };

  const handleMouseOver7 = () => {
    setIsHovered7(true);
  };

  const handleMouseOut7 = () => {
    setIsHovered7(false);
  };

  const handleMouseOver9 = () => {
    setIsHovered9(true);
  };

  const handleMouseOut9 = () => {
    setIsHovered9(false);
  };

  const handleMouseOver10 = () => {
    setIsHovered10(true);
  };

  const handleMouseOut10 = () => {
    setIsHovered10(false);
  };

  const handleMouseOver11 = () => {
    setIsHovered11(true);
  };

  const handleMouseOut11 = () => {
    setIsHovered11(false);
  };
  const handleMouseOver12 = () => {
    setIsHovered12(true);
  };

  const handleMouseOut12 = () => {
    setIsHovered12(false);
  };

  const handleMouseOver13 = () => {
    setIsHovered13(true);
  };

  const handleMouseOut13 = () => {
    setIsHovered13(false);
  };
  const handleMouseOver14 = () => {
    setIsHovered14(true);
  };

  const handleMouseOut14 = () => {
    setIsHovered14(false);
  };

  const handleMouseOver15 = () => {
    setIsHovered15(true);
  };

  const handleMouseOut15 = () => {
    setIsHovered15(false);
  };

  const handleMouseOver16 = () => {
    setIsHovered16(true);
  };

  const handleMouseOut16 = () => {
    setIsHovered16(false);
  };

  const handleMouseOver17 = () => {
    setIsHovered17(true);
  };

  const handleMouseOut17 = () => {
    setIsHovered17(false);
  };

  const handleMouseOver18 = () => {
    setIsHovered18(true);
  };

  const handleMouseOut18 = () => {
    setIsHovered18(false);
  };

  const handleMouseOver19 = () => {
    setIsHovered19(true);
  };

  const handleMouseOut19 = () => {
    setIsHovered19(false);
  };

  const handleMouseOver36 = () => {
    setIsHovered36(true);
  };

  const handleMouseOut36 = () => {
    setIsHovered36(false);
  };

  return (
    <>
      <HeaderSection />
      <SlideBar />

      <section className="hero-section p-t-175 p-t-xl-140 p-t-lg-120 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="hero-content mx-auto">
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                  data-aos-delay="50"
                >
                  Global Mobile-First Finance-Embedded Business Companion!
                </h2>
                <p
                  className="hero-sub"
                  data-aos="fade-up"
                  data-aos-duration="1100"
                  data-aos-delay="100"
                >
                  Itump helps you stay compliant and grow by providing you with
                  necessary business support and finance tools to scale globally
                </p>
              </div>
              <div
                className="hero-btn-wrap"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="150"
              >
                <div className="get-started-btn-2">
                  <Link to="https://boi.itump.com/login"> Get Started </Link>
                </div>
                <div className="learn-more-btn">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>

              <div
                className="map-thumb p-t-80 p-t-md-60 p-b-0 p-b-md-60 mx-auto  d-md-block"
                // data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/thumb/map.svg"
                  alt="map img"
                  className="w-100"
                  style={{ maxWidth: "1400px" }}
                />
              </div>
              <div className="hero-brand-slider-wrap m-t-60">
                <p
                  className="brand-slide-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Trusted by businesses
                </p>
                <div
                  className="brand-slider"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <Swiper
                    spaceBetween={34}
                    slidesPerView="auto"
                    // modules={[Navigation, Pagination]}
                    modules={[Autoplay]}
                    // navigation
                    loop
                    speed={5000}
                    autoplay
                  // autoplay={{ delay: 1 }}
                  // allowTouchMove={false}
                  // disableOnInteraction
                  >
                    {/* <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-1.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide> */}
                    <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-2.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide>
                    <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-3.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide>
                    <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-4.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide>
                    <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-5.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide>

                    <SwiperSlide
                      style={{ width: "auto" }}
                      className="slider-item"
                    >
                      <img
                        src="assets/img/brand-logo/brand-logo-2.png"
                        alt="brand-logo"
                      />
                    </SwiperSlide>
                  </Swiper>
                  <div className="slider-overlay"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="common-shape-left"></div>
        <div className="common-shape-right"></div>
        <div className="hero-shape">
          <img src="assets/img/bg/bg-1.png" alt="bg" />
        </div>
      </section>

      <FeatureSection />
      <div className="growth-desk-overview">
        <section
          className="growth-section p-t-235 p-t-xl-170 p-b-220 p-b-xl-170 p-t-lg-140 p-t-md-100 p-t-xs-80 p-b-lg-140 p-b-md-100 p-b-xs-80 overflow-hidden"
          id="Growth"
        >
          <div className="container">
            <div className="growth-section-wrapper position-relative">
              <div className="row">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Automated Growth Suggestions
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Reveal hidden growth opportunities with AI-driven insights
                      and recommendations.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row g-lg-4 g-3">
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="0.005"
                  onMouseOver={handleMouseOver}
                  onMouseOut={handleMouseOut}
                  style={{
                    position: "relative",
                    padding: 0,
                  }}
                >
                  <div className="growth-item-1">
                    <div className="growth-item-content">
                      <p className="growth-item-title">
                        Optimize Business
                        <br />
                        Standard by 30%
                      </p>
                      <p className="growth-item-body">
                        Tailored, data-driven recommendations to optimize
                        efficiency, enhance customer engagement, and boost
                        revenue
                      </p>
                    </div>
                    <div
                      className="growth-item-thumb ms-auto"
                      style={{ position: "relative", width: "100%" }}
                    >
                      {/* Initial Image */}
                      <motion.img
                        src="assets/img/thumb/thumb-4.jpg"
                        alt="initial-growth-thumb"
                        className="w-100 object-fit-cover"
                        // style={{ zIndex: "1" }}
                        initial={{ opacity: 1 }}
                        animate={{ opacity: isHovered ? 0 : 1 }}
                        transition={{ duration: 0.5 }}
                      />

                      {/* Hover Image */}
                      <motion.img
                        src="assets/img/thumb/thumb-4.png"
                        alt="hover-growth-thumb"
                        className="w-100 object-fit-cover"
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          zIndex: "1",
                        }}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isHovered ? 1 : 0 }}
                        transition={{ duration: 0.5 }}
                      />
                    </div>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="assets/img/bg/optimize-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <motion.div
                    className="growth-item-2"
                    initial="initial"
                    animate="initial"
                    whileHover="hovered"
                    onMouseOver={handleMouseOver2}
                    onMouseOut={handleMouseOut2}
                    style={{ magine: 0 }}
                  >
                    <div className="growth-item-content">
                      <p className="growth-item-title">Manage in Real-time</p>
                      <p className="growth-item-body">
                        Track and prioritize growth-related tasks, projects, and
                        initiatives with real-time status updates and urgency
                        levels
                      </p>
                    </div>
                    <div
                      className="growth-item-shape"
                      style={{ position: "relative" }}
                    >
                      <img
                        src="assets/img/bg/bg-3.png"
                        alt="shape-shape"
                        className="shape-shape"
                        style={{ position: "absolute", top: "-160px" }}
                      />
                      <div className="growth-item-shape-desk">
                        <motion.img
                          src="assets/img/thumb/red.svg"
                          variants={{
                            initial: { top: "-58px" },
                            hovered: { top: "-114px" },
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                            // top: "-58px",
                            position: "absolute",
                            left: "55.5px",
                            gap: "0px",
                            opacity: "0px",
                          }}
                          className="growth-red"
                        />
                        <motion.img
                          src="assets/img/thumb/yellow.svg"
                          variants={{
                            initial: { top: "-115px" },
                            hovered: { top: "-55px" },
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                            top: "-115px",
                            position: "absolute",
                            left: "199.5px",
                            gap: "0px",
                            opacity: "0px",
                          }}
                          className="growth-yellow"
                        />
                        <motion.img
                          src="assets/img/thumb/green.svg"
                          variants={{
                            initial: { top: "-80px" },
                            hovered: { top: "-78px" },
                          }}
                          style={{
                            width: "24px",
                            height: "24px",
                            top: "-80px",
                            position: "absolute",
                            left: "343.5px",
                            gap: "0px",
                            opacity: "0px",
                          }}
                          className="growth-green"
                        />

                        <motion.img
                          src="assets/img/thumb/assigne.svg"
                          initial={{ opacity: 1 }}
                          animate={{ opacity: isHovered2 ? 0 : 1 }}
                          transition={{ duration: 0.5 }}
                          style={{
                            width: "122px",
                            height: "40px",
                            bottom: "114px",
                            position: "absolute",
                            right: "14.5px",
                            gap: "0px",
                            opacity: "0px",
                          }}
                        />

                        <motion.img
                          src="assets/img/thumb/assigne-1.svg"
                          initial={{ opacity: 0 }}
                          animate={{ opacity: isHovered2 ? 1 : 0 }}
                          transition={{ duration: 0.5 }}
                          style={{
                            width: "122px",
                            height: "40px",
                            bottom: "114px",
                            position: "absolute",
                            right: "14.5px",
                            gap: "0px",
                            opacity: "0px",
                          }}
                        />
                      </div>
                    </div>
                    <motion.div
                      className="net"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered2 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src="assets/img/bg/manage-bg.svg"
                        alt="bg"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </motion.div>
                  </motion.div>
                  <div
                    className="growth-item-3"
                    onMouseOver={handleMouseOver3}
                    onMouseOut={handleMouseOut3}
                  >
                    <div className="growth-item-process">
                      <div className="growth-item-desk">
                        <div className="growth-item-thumb d-flex">
                          <img
                            alt="icon"
                            src="assets/img/icons/love-icon.svg"
                          />
                          <img alt="icon" src="assets/img/icons/web-icon.svg" />
                          <img
                            alt="icon"
                            src="assets/img/icons/shield-icon.svg"
                          />
                        </div>
                        <img
                          alt="icons"
                          src="assets/img/icons/cude-icon.svg"
                          style={{ zIndex: "1" }}
                        />
                      </div>
                    </div>
                    <img
                      src="assets/img/thumb/thumb-5.png"
                      alt="growth-thumb"
                      className="shape-shape"
                    />
                    <div className="growth-item-content">
                      <p className="growth-item-title">Best on Every Process</p>
                      <p className="growth-item-body">
                        Discover complementary services that work together to
                        drive growth, increase efficiency, and enhance customer
                        experience Manage in Real-time
                      </p>
                    </div>

                    <motion.img
                      src="assets/img/icons/line-icon.svg"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered3 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        top: "135px",

                        position: "absolute",
                        right: "80px",
                      }}
                    />

                    <motion.div
                      className="net"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered3 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src="assets/img/bg/manage-bg.svg"
                        alt="bg"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </motion.div>
                  </div>
                </div>
                <div
                  className="feature-item-container d-md-none"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  style={{ display: "none" }}
                >
                  <div className="feature-item pt-md-0">
                    <div className="feature-thumb">
                      <img
                        src="assets/img/thumb/thumb-1.png"
                        alt="feature-thumb"
                      />
                    </div>
                    <div className="feature-content">
                      <p className="feature-title">
                        Automated Growth Suggestions
                      </p>
                      <p className="feature-body">
                        Tailored, data-driven recommendations to optimize
                        efficiency, enhance customer engagement, and boost
                        revenue
                      </p>
                      <Link to="#" className="read-more-btn">
                        Read More{" "}
                        <i className="fa-solid fa-circle-arrow-down"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="growth-mobile-overview">
        <section class="growth-section p-t-235 p-t-xl-170 p-b-220 p-b-xl-170 p-t-lg-140 p-t-md-100 p-t-xs-80 p-b-lg-140 p-b-md-100 p-b-xs-80 overflow-hidden">
          <div class="container">
            <div class="growth-section-wrapper position-relative">
              <div class="row">
                <div class="col-xl-6">
                  <div class="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                    <h3
                      class="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Automated Growth Suggestions
                    </h3>
                    <p
                      class="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Reveal hidden growth opportunities with AI-driven insights
                      and recommendations.
                    </p>
                  </div>
                </div>
              </div>
              <div class="row g-lg-4 g-3">
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="50">
                  <div class="growth-item-1">
                    <div class="growth-item-content">
                      <p class="growth-item-title">
                        Optimize Business
                        <br />
                        Standard by 30%
                      </p>
                      <p class="growth-item-body">
                        Tailored, data-driven recommendations to optimize
                        efficiency, enhance customer engagement, and boost
                        revenue
                      </p>
                    </div>
                    <div class="growth-item-thumb ms-auto">
                      <img
                        src="assets/img/thumb/thumb-4.jpg"
                        alt="growth-thumb"
                        class="w-100 object-fit-cover"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6" data-aos="fade-up" data-aos-delay="150">
                  <div class="growth-item-2">
                    <div class="growth-item-content">
                      <p class="growth-item-title">Manage in Real-time</p>
                      <p class="growth-item-body">
                        Track and prioritize growth-related tasks, projects, and
                        initiatives with real-time status updates and urgency
                        levels
                      </p>
                    </div>
                    <div class="growth-item-shape">
                      <img src="assets/img/bg/bg-3.png" alt="shape-shape" />
                    </div>
                  </div>
                  <div class="growth-item-3">
                    <div class="growth-item-thumb position-relative">
                      <img
                        src="assets/img/thumb/thumb-5.png"
                        alt="growth-thumb"
                      />
                    </div>
                    <div class="growth-item-content">
                      <p class="growth-item-title">Best on Every Process</p>
                      <p class="growth-item-body">
                        Discover complementary services that work together to
                        drive growth, increase efficiency, and enhance customer
                        experience Manage in Real-time
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="feature-item-container d-md-none"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <div class="feature-item pt-md-0">
                    <div class="feature-thumb">
                      <img
                        src="assets/img/thumb/thumb-1.png"
                        alt="feature-thumb"
                      />
                    </div>
                    <div class="feature-content">
                      <p class="feature-title">Automated Growth Suggestions</p>
                      <p class="feature-body">
                        Tailored, data-driven recommendations to optimize
                        efficiency, enhance customer engagement, and boost
                        revenue
                      </p>
                      <Link to="#" class="read-more-btn">
                        Read More <i class="fa-solid fa-circle-arrow-down"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="transaction-desk-overview">
        <section
          className="financial-management-section growth-section position-relative overflow-hidden"
          id="financial"
        >
          <div className="container">
            <div className="financial-management-wrapper position-relative">
              <div className="row">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40 position-relative">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Financial Management
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Transform operations with automated efficiency and
                      insights.
                    </p>
                  </div>
                </div>
              </div>
              <div className=" row g-3 g-lg-4" style={{}}>
                <motion.div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  onMouseOver={handleMouseOver4}
                  onMouseOut={handleMouseOut4}
                  initial="initial"
                  animate="initial"
                  whileHover="hovered"
                  style={{ margin: "0", paddingRight: "10px" }}
                >
                  <div className="growth-item-4 growth-5 growth-finance h-100">
                    <div className="growth-item-content m-b-35 ">
                      <p className="growth-item-title growth-finance-title">
                        Finances at a Glance
                      </p>
                      <p className="growth-item-body">
                        rack and monitor all financial operations and pending
                        transactions within a period for real-time visibility
                        and control
                      </p>
                    </div>
                    <img
                      src="assets/img/thumb/thumb-6.png"
                      alt="growth-thumb"
                      className="shape-shape"
                    />
                    <div className="finance-glance ">
                      <div
                        className="growth-item-thumb "
                        style={{ position: "relative" }}
                      >
                        <img
                          src="assets/img/thumb/thumb-6.svg"
                          alt="growth-thumb"
                        />
                      </div>
                      <motion.img
                        src="assets/img/thumb/thumb-6-wallet.svg"
                        variants={{
                          initial: { right: "220px" },
                          hovered: { right: "125px" },
                        }}
                        style={{
                          position: "absolute",
                          top: "212px",
                          height: "264px",
                          zIndex: "1",
                        }}
                        alt="growth-thumb"
                      />

                      <motion.img
                        src="assets/img/thumb/thumb-6-pending.png"
                        variants={{
                          initial: { right: "50px" },
                          hovered: { right: "130px" },
                        }}
                        style={{
                          position: "absolute",
                          bottom: "40px",
                          right: "50px",
                          zIndex: "1",
                          height: "146px",
                        }}
                        alt="growth-thumb"
                      />

                      <motion.div
                        className="finance-net"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: isHovered4 ? 1 : 0 }}
                        transition={{ duration: 0.5 }}
                        style={{
                          opacity: 0,
                          padding: "0 11.5px",
                          borderRadius: "22px",
                        }}
                      >
                        <img
                          src="assets/img/bg/optimize-bg.svg"
                          alt="bg"
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "cover",
                          }}
                        />
                      </motion.div>
                    </div>
                  </div>
                </motion.div>

                <motion.div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                  onMouseOver={handleMouseOver5}
                  onMouseOut={handleMouseOut5}
                  initial="initial"
                  animate="initial"
                  whileHover="hovered"
                  style={{ margin: "0", padding: "0", overflow: "hidden" }}
                >
                  <div className="growth-item-4 h-100 flex-md-column gap-4 gap-md-0">
                    <div
                      className="growth-item-thumb-2 m-b-60 m-b-md-40 m-b-xs-0 px-3 mx-1 px-md-0 mx-md-0"
                    // style={{ position: "relative" }}
                    >
                      <img
                        src="assets/img/thumb/thumb-7.png"
                        alt="growth-thumb"
                        id="shape-shape"
                      />

                      <div
                        className="transcation-desk"
                        id="transcation-desk"
                        style={{ zIndex: "100" }}
                      >
                        <motion.div
                          className="transaction-card"
                          style={{
                            position: "absolute",
                            top: "34px",
                            zIndex: "1",
                          }}
                          variants={{
                            initial: { left: "-30px", width: "400px" },
                            hovered: { left: "125px", width: "329px" },
                          }}
                          transition={{ duration: 0.5 }}
                        >
                          <div className="transaction-info">
                            <div className="avatar">
                              <img
                                src="assets/img/icons/profile.svg"
                                alt="profile"
                              />
                            </div>
                            <div className="details">
                              <h4 className="name">John Doe</h4>
                              <p className="description">Customer Purchase</p>
                              <p className="date">22 Nov 2023, 11:00 PM</p>
                            </div>
                          </div>
                          <div className="amount">+$20.00</div>
                        </motion.div>

                        <motion.div
                          className="transaction-card"
                          style={{
                            position: "absolute",
                            top: "170px",
                            zIndex: "1",
                            left: "110px",
                          }}
                          variants={{
                            initial: { width: "400px" },
                            hovered: { width: "375px" },
                          }}
                        >
                          <div className="transaction-info">
                            <div className="avatar">
                              <img
                                src="assets/img/icons/trello.svg"
                                alt="profile"
                              />
                            </div>
                            <div className="details">
                              <h4 className="name">Trello Subscription</h4>
                              <p className="description">
                                Integrations & Subscriptions
                              </p>
                              <p className="date">22 Nov 2023, 11:00 PM</p>
                            </div>
                          </div>
                          <div className="amount">-$20.00</div>
                        </motion.div>

                        <motion.div
                          className={
                            isHovered5
                              ? "transaction-card-hover"
                              : "transaction-card"
                          }
                          style={{
                            position: "absolute",
                            top: "300px",
                            zIndex: "1",
                          }}
                          variants={{
                            initial: { right: "-100px", width: "400px" },
                            hovered: { right: "80px", width: "416px" },
                          }}
                          transition={{ duration: 0.5 }}
                        >
                          <div className="transaction-info">
                            <div className="avatar">
                              <img
                                src="assets/img/icons/profile.svg"
                                alt="profile"
                              />
                            </div>
                            <div className="details">
                              <h4 className="name">Johnny Doe</h4>
                              <p className="description">Customer Purchase</p>
                              <p className="date">22 Nov 2023, 11:00 PM</p>
                            </div>
                          </div>
                          <div className="amount">+$20.00</div>
                        </motion.div>
                      </div>
                    </div>
                    <div
                      className="growth-item-content transcation-mobile"
                      style={{
                        position: "absolute",
                        top: "534px",
                        left: "0px",
                      }}
                    >
                      <p className="growth-item-title">Collect Money Easily</p>
                      <p className="growth-item-body">
                        Get detailed briefs on financial transactions, including
                        income, expenses, and transfers, to understand where
                        your money is going
                      </p>
                    </div>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered5 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="assets/img/bg/colloect-money.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        // padding: "0 11.5px",
                        // borderRadius: "5px",
                      }}
                    />
                  </motion.div>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="transaction-mobile-overview">
        <section
          className="financial-management-section growth-section position-relative overflow-hidden"
          id="financial"
        >
          <div className="container">
            <div className="financial-management-wrapper position-relative">
              <div className="row">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40 position-relative">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Financial Management
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Transform operations with automated efficiency and
                      insights.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row g-3 g-lg-4">
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <div className="growth-item-4 growth-5 h-100">
                    <div className="growth-item-content m-b-35">
                      <p className="growth-item-title">Finances at a Glance</p>
                      <p className="growth-item-body">
                        rack and monitor all financial operations and pending
                        transactions within a period for real-time visibility
                        and control
                      </p>
                    </div>
                    <div className="growth-item-thumb p-l-70 p-l-lg-30 p-l-md-20 d-flex">
                      <img
                        src="assets/img/thumb/thumb-6.png"
                        alt="growth-thumb"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <div className="growth-item-4 h-100 d-flex flex-column-reverse flex-md-column gap-4 gap-md-0">
                    <div className="growth-item-thumb-2 m-b-60 m-b-md-40 m-b-xs-0 px-3 mx-1 px-md-0 mx-md-0">
                      <img
                        src="assets/img/thumb/thumb-7.png"
                        alt="growth-thumb"
                      />
                    </div>
                    <div className="growth-item-content">
                      <p className="growth-item-title">Collect Money Easily</p>
                      <p className="growth-item-body">
                        Get detailed briefs on financial transactions, including
                        income, expenses, and transfers, to understand where
                        your money is going
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="compliance-desk-overview">
        <section
          className="compliance-section growth-section p-b-220 p-b-xl-170 p-b-lg-140 p-b-md-100 p-b-xs-80 overflow-hidden"
          id="compliance"
        >
          <div className="container">
            <div className="compliance-wrapper position-relative p-t-220 p-t-xl-170 p-t-lg-140 p-t-md-100 p-t-xs-80">
              <img
                src="assets/img/shape/border-shape.png"
                alt="border-shape"
                className="border-shape position-absolute top-0"
              />
              <div className="row position-relative z-1">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Compliance &
                      <br />
                      Documentation
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Future-proof compliance and risk management with
                      intelligent document workflows.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row position-relative z-1 g-lg-4 g-3">
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <motion.div
                    className="compliance-card one growth-item-4 growth-5 m-b-25 m-b-md-15"
                    onMouseOver={handleMouseOver6}
                    onMouseOut={handleMouseOut6}
                    style={{ position: "relative" }}
                  >
                    <div className="growth-item-content m-b-35">
                      <p className="growth-item-title">DocuHub</p>
                      <p className="growth-item-body">
                        Centralise and manage documentations all from one place
                      </p>
                    </div>
                    <div className="growth-item-thumb style-2 d-flex justify-content-end p-r-25">
                      <div className="doeCo">
                        <div className="doeCo-icon">
                          <img
                            src="assets/img/icons/doe-icon.svg"
                            alt=""
                            style={{ width: "48px", height: "48px" }}
                          />
                          <span>The Doe Co</span>
                        </div>
                        <motion.div
                          className="card-list"
                          style={{ zIndex: "1" }}
                        >
                          <motion.div
                            className="hubcard"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <AnimatePresence mode="wait">
                              <motion.span
                                key={
                                  isHovered6
                                    ? "Contacts"
                                    : "Employment Information"
                                }
                                className="card-text"
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{
                                  duration: 0.4,
                                  ease: "easeInOut",
                                }}
                                style={{
                                  position: "absolute",
                                  left: "20px",

                                  textAlign: "center",
                                }}
                              >
                                {isHovered6
                                  ? "Contacts"
                                  : "Employment Information"}
                              </motion.span>
                            </AnimatePresence>
                            <img
                              src="assets/img/icons/button-icon.svg"
                              alt="plus"
                              style={{
                                width: "20px",
                                marginBottom: "10px",
                                position: "absolute",
                                right: "20px",
                              }}
                            />
                          </motion.div>
                          <motion.div
                            className="hubcard"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <AnimatePresence mode="wait">
                              <motion.span
                                key={isHovered6 ? "Structure" : "Shares"}
                                className="card-text"
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{
                                  duration: 0.4,
                                  ease: "easeInOut",
                                }}
                                style={{
                                  position: "absolute",
                                  left: "20px",

                                  textAlign: "center",
                                }}
                              >
                                {isHovered6 ? "Structure" : "Shares"}
                              </motion.span>
                            </AnimatePresence>
                            <img
                              src="assets/img/icons/button-icon.svg"
                              alt="plus"
                              style={{
                                width: "20px",
                                marginBottom: "10px",
                                position: "absolute",
                                right: "20px",
                              }}
                            />
                          </motion.div>
                          <motion.div
                            className="highlight"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <AnimatePresence mode="wait">
                              <motion.span
                                key={isHovered6 ? "Formation" : "Documents"}
                                className="card-text"
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{
                                  duration: 0.4,
                                  ease: "easeInOut",
                                }}
                                style={{
                                  position: "absolute",
                                  left: "20px",

                                  textAlign: "center",
                                }}
                              >
                                {isHovered6 ? "Formation" : "Documents"}
                              </motion.span>
                            </AnimatePresence>
                            <img
                              src="assets/img/icons/button-icon.svg"
                              alt="plus"
                              style={{
                                width: "20px",
                                marginBottom: "10px",
                                position: "absolute",
                                right: "20px",
                              }}
                            />
                          </motion.div>
                          <motion.div
                            className="hubcard"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <AnimatePresence mode="wait">
                              <motion.span
                                key={
                                  isHovered6
                                    ? "Employment Information"
                                    : "Contacts"
                                }
                                className="card-text"
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{
                                  duration: 0.4,
                                  ease: "easeInOut",
                                }}
                                style={{
                                  position: "absolute",
                                  left: "20px",

                                  textAlign: "center",
                                }}
                              >
                                {isHovered6
                                  ? "Employment Information"
                                  : "Contacts"}
                              </motion.span>
                            </AnimatePresence>
                            <img
                              src="assets/img/icons/button-icon.svg"
                              alt="plus"
                              style={{
                                width: "20px",
                                marginBottom: "10px",
                                position: "absolute",
                                right: "20px",
                              }}
                            />
                          </motion.div>
                          <motion.div
                            className="hubcard"
                            style={{
                              position: "relative",
                              display: "inline-block",
                            }}
                          >
                            <AnimatePresence mode="wait">
                              <motion.span
                                key={isHovered6 ? "Shares" : "Structure"}
                                className="card-text"
                                initial={{ opacity: 0, y: 5 }}
                                animate={{ opacity: 1, y: 0 }}
                                exit={{ opacity: 0, y: -5 }}
                                transition={{
                                  duration: 0.4,
                                  ease: "easeInOut",
                                }}
                                style={{
                                  position: "absolute",
                                  left: "20px",

                                  textAlign: "center",
                                }}
                              >
                                {isHovered6 ? "Shares" : "Structure"}
                              </motion.span>
                            </AnimatePresence>
                            <img
                              src="assets/img/icons/button-icon.svg"
                              alt="plus"
                              style={{
                                width: "20px",
                                marginBottom: "10px",
                                position: "absolute",
                                right: "20px",
                              }}
                            />
                          </motion.div>
                        </motion.div>
                        <img
                          src="assets/img/shape/doe-arrow.svg"
                          className="line"
                          alt="doe-arrow"
                          style={{ width: "225px" }}
                        />
                      </div>
                    </div>
                    <motion.div
                      className="net"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered6 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src="assets/img/bg/duo-bg.svg"
                        alt="bg"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </motion.div>
                  </motion.div>
                  <motion.div
                    className="compliance-card three growth-item-4 growth-5"
                    onMouseOver={handleMouseOver7}
                    onMouseOut={handleMouseOut7}
                    initial="initial"
                    animate="initial"
                    whileHover="hovered"
                    style={{ margin: "0", padding: "", position: "relative" }}
                  >
                    <div className="growth-item-content p-b-25">
                      <p className="growth-item-title">Go Into Details</p>
                      <p className="growth-item-body">
                        Know the details with real-time updates from team
                        documentations
                      </p>
                    </div>
                    <div className="growth-item-thumb d-flex justify-content-end position-relative z-1">
                      <img
                        src="assets/img/thumb/thumb-10.png"
                        alt="growth-thumb"
                        className={isHovered7 ? "details-image" : ""}
                      />
                    </div>
                    <motion.div
                      className="net"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered7 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src="assets/img/bg/duo-bg.svg"
                        alt="bg"
                        style={{
                          height: "100%",
                          width: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </motion.div>
                  </motion.div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <ComplianceCard />
                  <motion.div
                    className="compliance-card four growth-item-4"
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "-webkit-fill-available",
                      overflow: "hidden",
                    }}
                    initial="initial"
                    animate="initial"
                    whileHover="hovered"
                    onMouseOver={handleMouseOver9}
                    onMouseOut={handleMouseOut9}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="growth-item-thumb-2">
                      <div className="group-report">
                        <motion.div
                          className={
                            isHovered9
                              ? `card-border report-card`
                              : `report-card`
                          }
                          style={{
                            top: "54px",
                            width: "370px",
                            right: "130px",
                          }}
                          variants={{
                            initial: { top: "14px" },
                            hovered: { top: "104px" },
                          }}
                          transition={{ duration: 1.0 }}
                        >
                          <img
                            className="icon"
                            src="assets/img/icons/report-file.svg"
                            alt="file"
                          />
                          <div
                            className={isHovered9 ? "details-hover" : "details"}
                          >
                            <div className="content">
                              <div className="header">
                                <div className="title">
                                  Business Annual Report
                                </div>
                                <div className="critical">Critical</div>
                              </div>
                              <div className="date">Since Jun 6, 2024</div>
                            </div>
                            <div className="body">
                              <div className="description">
                                Yearly summary for compliance and transparency
                              </div>
                              <Link href="#" className="details-link">
                                See Details →
                              </Link>
                            </div>
                          </div>
                        </motion.div>

                        <motion.div
                          className="report-card"
                          style={{
                            width: "293px",
                            top: "-184px",
                            left: "304px",
                          }}
                          variants={{
                            initial: { left: "304px" },
                            hovered: { left: "20px" },
                          }}
                          transition={{ duration: 1.5 }}
                        >
                          <img
                            className="icon"
                            src="assets/img/icons/Domain.svg"
                            alt="file"
                          />
                          <div className="details">
                            <div className="title">Website Domain</div>

                            <div className="expire">Expires 02 Dec 2023</div>

                            <Link href="#" className="details-link">
                              Renew with $10 →
                            </Link>
                          </div>
                        </motion.div>

                        <motion.div
                          className="report-card"
                          style={{
                            width: "293px",
                            top: "-74px",
                            left: "256px",
                          }}
                          variants={{
                            initial: { top: "-94px" },
                            hovered: { top: "-150px" },
                          }}
                          transition={{ duration: 0.5 }}
                        >
                          <img
                            className="icon"
                            src="assets/img/icons/profile-icon.svg"
                            alt="profile"
                          />
                          <div className="details">
                            <div className="title">Agent Fee</div>

                            <div className="expire">Expires 02 Dec 2023</div>

                            <Link href="#" className="details-link">
                              Renew with $40 →
                            </Link>
                          </div>
                        </motion.div>
                      </div>
                    </div>
                    <div
                      className="growth-item-content p-t-60 p-t-40 p-t-xs-25"
                      style={{ position: "relative", bottom: "130px" }}
                    >
                      <p className="growth-item-title">
                        Increase Efficiency by 23%
                        <br className="d-none d-lg-block" />
                        with Risk Reports
                      </p>
                      <p className="growth-item-body">
                        Identify and mitigate potential compliance risks way
                        ahead of time with visibility and actionable insights
                      </p>
                    </div>
                    <motion.div
                      className="net"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered9 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    >
                      <img
                        src="assets/img/bg/efficiency-bg.svg"
                        alt="bg"
                        style={{
                          height: "100%",
                          objectFit: "cover",
                          width: "100%",
                          top: "15px",
                        }}
                      />
                    </motion.div>
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="compliance-mobile-overview">
        <section
          className="compliance-section growth-section p-b-220 p-b-xl-170 p-b-lg-140 p-b-md-100 p-b-xs-80 overflow-hidden"
          id="compliance"
        >
          <div className="container">
            <div className="compliance-wrapper position-relative p-t-220 p-t-xl-170 p-t-lg-140 p-t-md-100 p-t-xs-80">
              <img
                src="assets/img/shape/border-shape.png"
                alt="border-shape"
                className="border-shape position-absolute top-0"
              />
              <div className="row position-relative z-1">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Compliance &
                      <br />
                      Documentation
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Future-proof compliance and risk management with
                      intelligent document workflows.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row position-relative z-1 g-lg-4 g-3">
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <div className="compliance-card one growth-item-4 growth-5 m-b-25 m-b-md-15">
                    <div className="growth-item-content m-b-35">
                      <p className="growth-item-title">DocuHub</p>
                      <p className="growth-item-body">
                        Centralise and manage documentations all from one place
                      </p>
                    </div>
                    <div className="growth-item-thumb style-2 d-flex justify-content-end p-r-25">
                      <img
                        src="assets/img/thumb/thumb-8.png"
                        alt="shape-shape"
                      />
                    </div>
                  </div>
                  <div className="compliance-card three growth-item-4 growth-5">
                    <div className="growth-item-content p-b-25">
                      <p className="growth-item-title">Go Into Details</p>
                      <p className="growth-item-body">
                        Know the details with real-time updates from team
                        documentations
                      </p>
                    </div>
                    <div className="growth-item-thumb d-flex justify-content-end position-relative z-1">
                      <img
                        src="assets/img/thumb/thumb-10.png"
                        alt="growth-thumb"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-md-6"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <div className="compliance-card two growth-item-4 m-b-25 m-b-md-15">
                    <div className="growth-item-content m-b-60 m-b-md-40 m-b-xs-25">
                      <p className="growth-item-title">Progress Dashboards</p>
                      <p className="growth-item-body">
                        View real-time team progress towards goals and track
                        milestones
                      </p>
                    </div>
                    <div className="growth-item-thumb style-2 d-flex justify-content-center">
                      <img
                        src="assets/img/thumb/thumb-9.png"
                        alt="shape-shape"
                      />
                    </div>
                  </div>
                  <div className="compliance-card four growth-item-4">
                    <div className="growth-item-thumb-2">
                      <img
                        src="assets/img/thumb/thumb-11.png"
                        alt="growth-thumb"
                      />
                    </div>
                    <div className="growth-item-content p-t-60 p-t-40 p-t-xs-25">
                      <p className="growth-item-title">
                        Increase Efficiency by 23%
                        <br className="d-none d-lg-block" />
                        with Risk Reports
                      </p>
                      <p className="growth-item-body">
                        Identify and mitigate potential compliance risks way
                        ahead of time with visibility and actionable insights
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="tam-desk-overview">
        <section className="tam-section growth-section p-b-220 p-b-xl-170 p-b-lg-140 p-b-md-100 p-b-xs-80 position-relative overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                  <h3
                    className="common-title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Technology Assets
                    <br />
                    Management (TAM)
                  </h3>
                  <p
                    className="common-title-body"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    Maximize asset value and minimize waste with streamlined
                    management of the entire technology asset lifecycle.
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-lg-4 g-3">
              <motion.div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-delay="50"
                onMouseOver={handleMouseOver10}
                onMouseOut={handleMouseOut10}
                style={{
                  position: "relative",
                  // padding: "0",
                }}
              >
                <div className="tam-card-1 growth-item-4 growth-5 h-100 position-relative">
                  <div className="growth-item-content style-2 p-b-55 p-b-md-35">
                    <p className="growth-item-title">Comprehensive Overview</p>
                    <p className="growth-item-body">
                      Quickly access a 360-degree view of all your technology
                      assets, enabling informed and efficient decision-making.
                    </p>
                  </div>
                  <div
                    className="growth-item-thumb d-flex justify-content-center"
                    style={{ position: "absolute", bottom: "0", left: "94px" }}
                  >
                    {/* Initial Image */}
                    <motion.img
                      src="assets/img/thumb/thumb-12.png"
                      alt="growth-thumb"
                      className="w-100"
                      initial={{ opacity: 1 }}
                      animate={{ opacity: isHovered10 ? 0 : 1 }}
                      transition={{ duration: 0.5 }}
                    />

                    {/* Hover Image */}
                    <motion.img
                      src="assets/img/thumb/itumpA.png"
                      alt="growth-thumb"
                      className="w-100"
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered10 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                      style={{
                        position: "absolute",

                        zIndex: "1",
                      }}
                    />
                  </div>
                </div>
                <motion.div
                  className="net"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isHovered10 ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src="assets/img/bg/overview-bg.svg"
                    alt="bg"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      padding: "0 11.5px",
                      borderRadius: "22px",
                    }}
                  />
                </motion.div>
              </motion.div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-delay="150"
                onMouseOver={handleMouseOver36}
                onMouseOut={handleMouseOut36}
                style={{
                  position: "relative",
                  // padding: "0",
                }}
              >
                <div className="tam-card-2 growth-item-4 growth-5 h-100 position-relative">
                  <div className="growth-item-content style-2 p-b-80 p-b-md-60 p-b-xs-25">
                    <p className="growth-item-title">Financial Management</p>
                    <p className="growth-item-body">
                      Effortlessly handle financial aspects of your technology
                      assets, including purchases, depreciation, and maintenance
                      costs.
                    </p>
                  </div>
                  <div className="growth-item-thumb-2 p-b-35 p-b-xs-25">
                    <div className="filter-container">
                      <button className="filter-button">
                        All Transactions ▾
                      </button>
                      <button className="filter-button">All Types ▾</button>
                      <button className="filter-button">Last 6 Months ▾</button>
                    </div>
                    {/* Initial Image */}
                    <motion.img
                      src="assets/img/thumb/financial-management-thumb.svg"
                      alt="financial-management"
                      style={{ width: "343px" }}
                      initial={{ opacity: 1 }}
                      animate={{ opacity: isHovered36 ? 0 : 1 }}
                      transition={{ duration: 1.5 }}
                    />
                    {/* Hover Image */}
                    <motion.img
                      src="assets/img/thumb/financials-hover.svg"
                      alt="financial-management"
                      style={{
                        position: "absolute",
                        bottom: "36px",
                        left: "",
                        zIndex: "1",
                        width: "343px",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered36 ? 1 : 0 }}
                      transition={{ duration: 1.5 }}
                    />

                    <img
                      src="assets/img/shape/financials-line.svg"
                      alt="line"
                      style={{ position: "absolute", top: "235px", right: "0" }}
                    />
                  </div>
                </div>
                <motion.div
                  className="net"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isHovered36 ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src="assets/img/bg/financials-hover.svg"
                    alt="bg"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      padding: "0 11.5px",
                      borderRadius: "22px",
                    }}
                  />
                </motion.div>
              </div>
              <motion.div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-delay="250"
                onMouseOver={handleMouseOver11}
                onMouseOut={handleMouseOut11}
                initial="initial"
                animate="initial"
                whileHover="hovered"
                style={{
                  position: "relative",
                  // padding: "0 ",
                }}
              >
                <div className="tam-card-3 growth-item-4 growth-6 growth-5 h-100">
                  <div className="growth-item-thumb-2 d-flex justify-content-end">
                    <img
                      src="assets/img/thumb/thumb-14.png"
                      alt="shape-shape"
                      className="mx-auto position-relative z-1 w-100"
                    />
                    <motion.img
                      src="assets/img/thumb/designer-cusor.svg"
                      alt="designer-cursor"
                      style={{
                        position: "absolute",
                        top: "149px",
                        left: "250px",
                        zIndex: "1",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered11 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    />
                    <motion.img
                      src="assets/img/thumb/dev-cursor.svg"
                      alt="dev-cursor"
                      style={{
                        position: "absolute",
                        top: "195px",
                        left: "359px",
                        zIndex: "1",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered11 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    />
                    <motion.img
                      src="assets/img/thumb/dev-cursor2.svg"
                      alt="dev-cursor2"
                      style={{
                        position: "absolute",
                        top: "128px",
                        left: "430px",
                        zIndex: "1",
                      }}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: isHovered11 ? 1 : 0 }}
                      transition={{ duration: 0.5 }}
                    />
                  </div>
                  <div className="growth-item-content p-t-65 p-t-md-35 p-t-xs-25 p-b-35 p-b-md-20">
                    <p className="growth-item-title">
                      Team Progress Monitoring
                    </p>
                    <p className="growth-item-body">
                      Monitor real-time progress on all projects and
                      initiatives, keeping everything on track and within scope.
                    </p>
                  </div>
                </div>
                <motion.div
                  className="net"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isHovered11 ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src="assets/img/bg/progress-monitoring-bg.svg"
                    alt="bg"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      padding: "0 11.5px",
                      borderRadius: "22px",
                    }}
                  />
                </motion.div>
              </motion.div>
              <div
                className="col-md-6"
                data-aos="fade-up"
                data-aos-delay="350"
                onMouseOver={handleMouseOver12}
                onMouseOut={handleMouseOut12}
                style={{
                  position: "relative",
                  // padding: 0,
                }}
              >
                <div className="tam-card-4 growth-item-4 growth-6 h-100">
                  <div className="growth-item-thumb-2">
                    <img
                      src="assets/img/thumb/thumb-15.png"
                      alt="growth-thumb"
                      className="position-relative z-1 w-100"
                    />
                  </div>
                  <div className="growth-item-content p-t-35 p-t-xs-15">
                    <p className="growth-item-title">Customer Tracking</p>
                    <p className="growth-item-body">
                      Track and manage customer locations and interactions
                      linked to your technology assets, enhancing client
                      satisfaction and retention.
                    </p>
                  </div>
                </div>
                <motion.div
                  className="net"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: isHovered12 ? 1 : 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <img
                    src="assets/img/bg/customer-tracking-bg.svg"
                    alt="bg"
                    style={{
                      height: "100%",
                      objectFit: "cover",
                      width: "100%",
                      padding: "0 11.50px",
                      borderRadius: "22px",
                    }}
                  />
                </motion.div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="tam-mobile-overview">
        <section className="tam-section growth-section p-b-220 p-b-xl-170 p-b-lg-140 p-b-md-100 p-b-xs-80 position-relative overflow-hidden">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                  <h3
                    className="common-title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Technology Assets
                    <br />
                    Management (TAM)
                  </h3>
                  <p
                    className="common-title-body"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    Maximize asset value and minimize waste with streamlined
                    management of the entire technology asset lifecycle.
                  </p>
                </div>
              </div>
            </div>
            <div className="row g-lg-4 g-3">
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="50">
                <div className="tam-card-1 growth-item-4 growth-5 h-100 position-relative">
                  <div className="growth-item-content style-2 p-b-55 p-b-md-35">
                    <p className="growth-item-title">Comprehensive Overview</p>
                    <p className="growth-item-body">
                      Quickly access a 360-degree view of all your technology
                      assets, enabling informed and efficient decision-making.
                    </p>
                  </div>
                  <div className="growth-item-thumb d-flex justify-content-center">
                    <img
                      src="assets/img/thumb/thumb-12.png"
                      alt="growth-thumb"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="150">
                <div className="tam-card-2 growth-item-4 growth-5 h-100 position-relative">
                  <div className="growth-item-content style-2 p-b-80 p-b-md-60 p-b-xs-25">
                    <p className="growth-item-title">Financial Management</p>
                    <p className="growth-item-body">
                      Effortlessly handle financial aspects of your technology
                      assets, including purchases, depreciation, and maintenance
                      costs.
                    </p>
                  </div>
                  <div className="growth-item-thumb-2 d-flex justify-content-center p-b-35 p-b-xs-25">
                    <img
                      src="assets/img/thumb/thumb-13.png"
                      alt="growth-thumb"
                      className="w-100"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="250">
                <div className="tam-card-3 growth-item-4 growth-6 growth-5 h-100">
                  <div className="growth-item-thumb-2 d-flex justify-content-end">
                    <img
                      src="assets/img/thumb/thumb-14.png"
                      alt="shape-shape"
                      className="mx-auto position-relative z-1 w-100"
                    />
                  </div>
                  <div className="growth-item-content p-t-65 p-t-md-35 p-t-xs-25 p-b-35 p-b-md-20">
                    <p className="growth-item-title">
                      Team Progress Monitoring
                    </p>
                    <p className="growth-item-body">
                      Monitor real-time progress on all projects and
                      initiatives, keeping everything on track and within scope.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6" data-aos="fade-up" data-aos-delay="350">
                <div className="tam-card-4 growth-item-4 growth-6 h-100">
                  <div className="growth-item-thumb-2">
                    <img
                      src="assets/img/thumb/thumb-15.png"
                      alt="growth-thumb"
                      className="position-relative z-1 w-100"
                    />
                  </div>
                  <div className="growth-item-content p-t-35 p-t-xs-15">
                    <p className="growth-item-title">Customer Tracking</p>
                    <p className="growth-item-body">
                      Track and manage customer locations and interactions
                      linked to your technology assets, enhancing client
                      satisfaction and retention.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      <div className="unified-desk-overview">
        <section className="unified-card-section p-b-300 p-b-xl-240 p-b-lg-200 p-b-md-160 p-b-xs-100 position-relative overflow-hidden">
          <div className="container">
            <div className="unified-area-wrapper position-relative">
              <div className="row">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-65 m-b-xs-40">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Unified Workspaces
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Streamline your digital landscape with seamless desktop
                      and mobile experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xl-12"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                  onMouseOver={handleMouseOver13}
                  onMouseOut={handleMouseOut13}
                  style={{
                    position: "relative",
                    // padding: 0,
                  }}
                >
                  <div className="unified-card-1">
                    <div
                      className="unified-content-wrap-1"
                      style={{ zIndex: "1", position: "relative" }}
                    >
                      <h2 className="unified-title text-white">
                        General Overview
                      </h2>
                      <p className="unified-body">Welcome back, Obieze! 🚀</p>
                    </div>
                    <div
                      className="card-separator-1"
                      style={{ position: "relative", zIndex: "1" }}
                    ></div>
                    <div className="unified-card-thumb">
                      <img
                        src="assets/img/thumb/thumb-16.png"
                        alt="growth-thumb"
                        className="position-relative z-1"
                      />
                    </div>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered13 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="assets/img/bg/general-overview-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                        padding: "0 11.5px",
                        borderRadius: "20px 20px 0 0",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="col-xl-6 col-lg-6 col-md-6 p-t-25"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  onMouseOver={handleMouseOver14}
                  onMouseOut={handleMouseOut14}
                  style={{
                    position: "relative",
                    // padding: 0,
                  }}
                >
                  <div className="unified-card-2 growth-item-4 growth-6 h-100">
                    <div className="growth-item-thumb-2 d-flex justify-content-end">
                      <img
                        src="assets/img/thumb/thumb-17.png"
                        alt="shape-shape"
                      />
                    </div>
                    <div className="growth-item-content p-t-5">
                      <p className="growth-item-title">Business Harmony</p>
                      <p className="growth-item-body">
                        Streamline collaboration and communication across with a
                        central powerhouse
                      </p>
                    </div>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered14 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="assets/img/bg/business-harmony.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                        padding: "25.5px 11.5px 0 11.5px",
                        borderRadius: "20px",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="col-xl-6 col-lg-6 col-md-6 p-t-25"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  onMouseOver={handleMouseOver15}
                  onMouseOut={handleMouseOut15}
                  style={{
                    position: "relative",
                    // padding: 0,
                  }}
                >
                  <div className="unified-card-3 growth-item-4 growth-6 h-100">
                    <div className="growth-item-thumb-2 p-t-40 d-flex justify-content-end">
                      <img
                        src="assets/img/thumb/thumb-18.png"
                        alt="shape-shape"
                      />
                    </div>
                    <div className="growth-item-content p-t-35">
                      <p className="growth-item-title">Access Anywhere</p>
                      <p className="growth-item-body">
                        Seamlessly access your workspace on both web and mobile,
                        with enhanced features on the web
                      </p>
                    </div>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered15 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <img
                      src="assets/img/bg/access-anywhere.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                        padding: "25.5px 11.5px 0 11.5px",
                        borderRadius: "30px 20px ",
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/img/bg/unified-bg-pattern.png"
            alt="bg pattern"
            className="bg-pattern position-absolute start-0 end-0 mx-auto w-100"
          />
        </section>
      </div>

      <div className="unified-mobile-overview">
        <section className="unified-card-section p-b-300 p-b-xl-240 p-b-lg-200 p-b-md-160 p-b-xs-100 position-relative overflow-hidden">
          <div className="container">
            <div className="unified-area-wrapper position-relative">
              <div className="row">
                <div className="col-xl-6">
                  <div className="common-title-wrap m-b-65 m-b-xs-40">
                    <h3
                      className="common-title"
                      data-aos="fade-up"
                      data-aos-delay="50"
                    >
                      Unified Workspaces
                    </h3>
                    <p
                      className="common-title-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Streamline your digital landscape with seamless desktop
                      and mobile experiences.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div
                  className="col-xl-12"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <div className="unified-card-1">
                    <div className="unified-content-wrap-1">
                      <h2 className="unified-title text-white">
                        General Overview
                      </h2>
                      <p className="unified-body">Welcome back, Obieze! 🚀</p>
                    </div>
                    <div className="card-separator-1"></div>
                    <div className="unified-card-thumb">
                      <img
                        src="assets/img/thumb/thumb-16.png"
                        alt="growth-thumb"
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-6 col-md-6 p-t-25"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="unified-card-2 growth-item-4 growth-6 h-100">
                    <div className="growth-item-thumb-2 d-flex justify-content-end">
                      <img
                        src="assets/img/thumb/thumb-17.png"
                        alt="shape-shape"
                      />
                    </div>
                    <div className="growth-item-content p-t-5">
                      <p className="growth-item-title">Business Harmony</p>
                      <p className="growth-item-body">
                        Streamline collaboration and communication across with a
                        central powerhouse
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="col-xl-6 col-lg-6 col-md-6 p-t-25"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <div className="unified-card-3 growth-item-4 growth-6 h-100">
                    <div className="growth-item-thumb-2 p-t-40 d-flex justify-content-end">
                      <img
                        src="assets/img/thumb/thumb-18.png"
                        alt="shape-shape"
                      />
                    </div>
                    <div className="growth-item-content p-t-35">
                      <p className="growth-item-title">Access Anywhere</p>
                      <p className="growth-item-body">
                        Seamlessly access your workspace on both web and mobile,
                        with enhanced features on the web
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            src="assets/img/bg/unified-bg-pattern.png"
            alt="bg pattern"
            className="bg-pattern position-absolute start-0 end-0 mx-auto w-100"
          />
        </section>
      </div>

      <section className="industry-solution">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Industry Solutions
                </h3>
                <p
                  className="common-title-body d-md-none mb-0"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  Streamline your digital landscape with seamless desktop and
                  mobile experiences.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap">
                <div
                  className="industry-solution-item"
                  onMouseOver={handleMouseOver16}
                  onMouseOut={handleMouseOut16}
                  style={{
                    position: "relative",
                    zIndex: "1",
                  }}
                >
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/pay.svg" alt="pay" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p className="solution-title">Itump Pay</p>
                    <p className="solution-body">
                      Seamless payment solutions to handle transactions securely
                      with automated invoicing and real-time tracking
                    </p>
                    <Link to="/solutions-pay" className="learnmore-btn">
                      Learn More <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>

                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered16 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ zIndex: "-1" }}
                  >
                    <img
                      src="assets/img/bg/industry-card-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="industry-solution-item border-r-none"
                  onMouseOver={handleMouseOver17}
                  onMouseOut={handleMouseOut17}
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/wallet.svg" alt="wallet" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p className="solution-title">Itump Wallet</p>
                    <p className="solution-body">
                      Manage funds effortlessly with our all-in-one digital
                      wallet, allowing you to store, transfer, and access
                      transaction histories with ease.
                    </p>
                    <Link to="/solutions-wallet" className="learnmore-btn">
                      Learn More <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered17 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ zIndex: "-1" }}
                  >
                    <img
                      src="assets/img/bg/industry-card-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="industry-solution-item border-b-none"
                  onMouseOver={handleMouseOver18}
                  onMouseOut={handleMouseOut18}
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/docuhub.svg" alt="docuhub" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p className="solution-title">Itump DocuHub</p>
                    <p className="solution-body">
                      Protect and manage your business assets efficiently,
                      handling investments and securing intellectual property.
                    </p>
                    <Link to="/solutions-docuhub" className="learnmore-btn">
                      Learn More <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered18 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ zIndex: "-1" }}
                  >
                    <img
                      src="assets/img/bg/industry-card-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </div>
                <div
                  className="industry-solution-item border-b-none border-r-none"
                  onMouseOver={handleMouseOver19}
                  onMouseOut={handleMouseOut19}
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/health.svg" alt="health" />
                  </div>
                  <div
                    className="solution-content"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p className="solution-title">Itump Health</p>
                    <p className="solution-body">
                      Monitor your business health with detailed analytics,
                      personalized growth suggestions, and real-time insights.
                    </p>
                    <Link to="/solutions-health" className="learnmore-btn">
                      Learn More <i className="fa fa-arrow-right"></i>
                    </Link>
                  </div>
                  <motion.div
                    className="net"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: isHovered19 ? 1 : 0 }}
                    transition={{ duration: 0.5 }}
                    style={{ zIndex: "-1" }}
                  >
                    <img
                      src="assets/img/bg/industry-card-bg.svg"
                      alt="bg"
                      style={{
                        height: "100%",
                        objectFit: "cover",
                        width: "100%",
                      }}
                    />
                  </motion.div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <CustomerSection /> */}

      <section className="people-around-section p-b-180 p-b-lg-140 p-b-md-100 p-b-xs-60 position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="people-around-title">
                <h2 data-aos="fade-up" data-aos-delay="50">
                  People from around the world use Itump for their Business
                </h2>
              </div>
              <div
                className="people-around-thumb d-none d-md-block"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img src="assets/img/thumb/thumb-28.png" alt="thumb" />
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="people-body-thumb d-none d-md-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img src="assets/img/thumb/thumb-29.png" alt="thumb" />
              </div>
              <div
                className="people-body"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <p>
                  Itump helps you simplify complexity, amplify productivity, and
                  achieve compliance with its innovative and customizable
                  solutions.
                </p>
              </div>
            </div>
          </div>
          <div className="row p-t-210 p-t-xl-170 p-t-lg-140 p-t-md-100 p-t-xs-80">
            <div className="col-xl-12">
              <div
                className="get-started-wrap position-relative"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login">
                  Get Started
                  <img
                    src="assets/img/icons/angles-right.svg"
                    alt="angles right"
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="itump-mobile-section">
        <div className="container">
          <div className="common-title-wrap m-b-40 d-md-none">
            <h3 className="common-title" data-aos="fade-up" data-aos-delay="50">
              Unified Workspaces
            </h3>
            <p
              className="common-title-body"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Streamline your digital landscape with seamless desktop and mobile
              experiences.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6 p-b-20">
              <div className="itump-card-layout h-100 d-flex flex-column-reverse flex-md-column">
                <div
                  className="itump-card-thumb p-t-80 p-t-md-40 p-t-xs-0 p-b-95 p-b-md-60 p-b-xs-0"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <img src="assets/img/thumb/thumb-25.png" alt="thumb" />
                </div>
                <div
                  className="itump-card-content p-r-25 p-r-xs-20 p-l-25 p-l-xs-20 p-b-35 p-t-xs-20"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <div className="itump-card-title">
                    <h2>Support & Resources</h2>
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.8995 24.1008C13.8027 28.004 20.1384 28.004 24.0416 24.1008C27.9449 20.1976 27.9449 13.8619 24.0416 9.95867C20.1384 6.05544 13.8027 6.05544 9.8995 9.95867C5.99627 13.8619 5.99627 20.1976 9.8995 24.1008ZM15.2028 13.8053L19.4454 13.8053C19.6576 13.8053 19.8414 13.8902 19.9758 14.0245C20.1101 14.1589 20.195 14.3427 20.195 14.5549L20.195 18.7975C20.195 19.2076 19.8556 19.547 19.4454 19.547C19.0353 19.547 18.6959 19.2076 18.6959 18.7975L18.6959 16.3651L15.026 20.0349C14.7361 20.3249 14.2553 20.3249 13.9654 20.0349C13.6754 19.745 13.6754 19.2642 13.9654 18.9743L17.6352 15.3044L15.2028 15.3044C14.7927 15.3044 14.4533 14.965 14.4533 14.5549C14.4533 14.1447 14.7927 13.8053 15.2028 13.8053Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="itump-body">
                    <p>
                      Get comprehensive support through detailed documentation
                      and community forums for knowledge sharing.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 p-b-20">
              <div className="itump-card-layout h-100">
                <div
                  className="itump-card-thumb-2"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <img src="assets/img/thumb/thumb-26.png" alt="thumb" />
                </div>
                <div
                  className="itump-card-content p-r-25 p-r-xs-20 p-l-25 p-l-xs-20 p-b-30 p-b-xs-20"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="itump-card-title">
                    <h2>In the News</h2>
                    <svg
                      width="34"
                      height="34"
                      viewBox="0 0 34 34"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.8995 24.1008C13.8027 28.004 20.1384 28.004 24.0416 24.1008C27.9449 20.1976 27.9449 13.8619 24.0416 9.95867C20.1384 6.05544 13.8027 6.05544 9.8995 9.95867C5.99627 13.8619 5.99627 20.1976 9.8995 24.1008ZM15.2028 13.8053L19.4454 13.8053C19.6576 13.8053 19.8414 13.8902 19.9758 14.0245C20.1101 14.1589 20.195 14.3427 20.195 14.5549L20.195 18.7975C20.195 19.2076 19.8556 19.547 19.4454 19.547C19.0353 19.547 18.6959 19.2076 18.6959 18.7975L18.6959 16.3651L15.026 20.0349C14.7361 20.3249 14.2553 20.3249 13.9654 20.0349C13.6754 19.745 13.6754 19.2642 13.9654 18.9743L17.6352 15.3044L15.2028 15.3044C14.7927 15.3044 14.4533 14.965 14.4533 14.5549C14.4533 14.1447 14.7927 13.8053 15.2028 13.8053Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="itump-body">
                    <p>
                      Stay informed with the latest industry trends, best
                      practices, and product updates through our regularly
                      updated blog.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <ItumpMobile />
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default Home;
