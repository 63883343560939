import React from "react";
import { CONTENTS } from "./data";
import TableOfContent from "../../../component/guidelines/TableOfContent";

const ReturnPolicy = () => {
    return (
            <div
            style={{
                color: "#333",
                maxWidth: "800px",
                lineHeight: "1.6",
            }}
        >
            <h1
                style={{
                    fontSize: "48px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    color: "#222",
                }}
            >
                TERMS OF USE
            </h1>
            <div
                style={{
                    marginBottom: "30px",
                }}
            >
                <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                    Last updated November 13, 2024
                </p>
                <h2
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                    }}
                >
                    AGREEMENT TO OUR LEGAL TERMS
                </h2>
                <p style={{ marginBottom: "20px" }}>
                    We are 24One, Inc., doing business as ITUMP and Astra Credo ("Company," "we," "us," "our"), a company
                    {" "}registered in New York, United States at 30 Wall Street, New York, NY 10005.
                </p>
                <p style={{ marginBottom: "20px" }}>
                    We operate the website
                    {" "}<a href="https://itump.com" target="_blank" rel="noreferrer">https://itump.com</a>
                    {" "}(the "Site"), the mobile application itump (the "App"),
                    {" "}as well as any other related products and services that refer or link to these legal terms (the "Legal Terms") (collectively, the "Services").
                </p>
                <p style={{ marginBottom: "20px" }}>
                    You can contact us by phone at 18888748867, email at g.legals@itump.com, or by mail to 30 Wall Street, New York, NY 10005, United States.
                </p>
                <p style={{ marginBottom: "20px" }}>
                    These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of
                    {" "}an entity ("you"), and 24One, Inc., concerning your access to and use of the Services.
                    {" "}You agree that by accessing the Services, you have read, understood, and agreed to be bound
                    {" "}by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS,
                    {" "}THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                </p>
                <p style={{ marginBottom: "20px" }}>
                    We will provide you with prior notice of any scheduled changes to the Services you are using.
                    {" "}The modified Legal Terms will become effective upon posting or notifying you by no-reply@itump.com,
                    {" "}as stated in the email message. By continuing to use the Services after the effective date
                    {" "}of any changes, you agree to be bound by the modified terms.
                </p>
                <p style={{ marginBottom: "20px" }}>
                    The Services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services.
                </p>
                <p style={{ marginBottom: "20px" }}>
                    We recommend that you print a copy of these Legal Terms for your records.
                </p>
            </div>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            {/* table of content */}
            <section style={{ marginBottom: "30px" }}>
                <h2
                    style={{
                        fontSize: "24px",
                        fontWeight: "bold",
                        marginBottom: "15px",
                    }}
                    id="table-of-contents"
                >
                    TABLE OF CONTENTS
                </h2>
                <TableOfContent contents={CONTENTS}/>
            </section>
            <hr
                style={{
                    border: "none",
                    borderBottom: "1px solid #e0e0e0",
                    margin: "30px 0",
                }}
            />
            <dl>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="our-services"
                    >
                        1. OUR SERVICES
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            The information provided when using the Services is not intended for distribution to or use by any person
                            {" "}or entity in any jurisdiction or country where such distribution or use would be contrary to law or
                            {" "}regulation or which would subject us to any registration requirement within such jurisdiction or country.
                            {" "}Accordingly, those persons who choose to access the Services from other locations do so on their own initiative
                            {" "}and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.
                        </div>
                        <div>
                            The Services are not tailored to comply with industry-specific regulations (Health Insurance Portability and
                            {" "}Accountability Act (HIPAA), Federal Information Security Management Act (FISMA), etc.), so if your interactions
                            {" "}would be subjected to such laws, you may not use the Services. You may not use the Services in a way that would violate the Gramm-Leach-Bliley Act (GLBA).
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="property-rights"
                    >
                        2. INTELLECTUAL PROPERTY RIGHTS
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Our Intellectual Property</span>
                            <div>
                                We are the owner or the licensee of all intellectual property rights in our Services, including all
                                {" "}source code, databases, functionality, software, website designs, audio, video, text, photographs,
                                {" "}and graphics in the Services (collectively, the "Content"), as well as the trademarks, service marks,
                                {" "}and logos contained therein (the "Marks").
                            </div>
                            <div>
                                Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property
                                {" "}rights and unfair competition laws) and treaties in the United States and around the world.
                            </div>
                            <div>
                                The Content and Marks are provided in or through the Services "AS IS" for your personal, non-commercial use
                                {" "}or internal business purpose only.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <div className="d-flex flex-column gap-3">
                                <span style={{ fontWeight: "bold", fontSize: "18px" }}>Your use of our Services</span>
                                <div>
                                    Subject to your compliance with these Legal Terms, including the
                                    {" "}"<a href="#prohibited-acts">PROHIBITED ACTIVITIES</a>" section below, we grant you a non-exclusive,
                                    {" "}non-transferable, revocable license to:
                                </div>
                                <ul>
                                    <li>access the Services; and</li>
                                    <li>
                                        download or print a copy of any portion of the Content to which you have properly gained access.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                solely for your personal, non-commercial use or internal business purpose.
                            </div>
                            <div>
                                Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no
                                {" "}Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly
                                {" "}displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited
                                {" "}for any commercial purpose whatsoever, without our express prior written permission.
                            </div>
                            <div>
                                If you wish to make any use of the Services, Content, or Marks other than as set out in this section
                                {" "}or elsewhere in our Legal Terms, please address your request to: g.legals@itump.com. If we ever
                                {" "}grant you the permission to post, reproduce, or publicly display any part of our Services or Content,
                                {" "}you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any
                                {" "}copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.
                            </div>
                            <div>
                                We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.
                            </div>
                            <div>
                                Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and
                                {" "}your right to use our Services will terminate immediately.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Your submissions</span>
                            <div>
                                Please review this section and the
                                {" "}"<a href="#prohibited-acts">PROHIBITED ACTIVITIES</a>" section carefully prior to using
                                {" "}our Services to understand the (a) rights you give us and (b) obligations you have when you
                                {" "}post or upload any content through the Services.
                            </div>
                            <div>
                                Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other
                                {" "}information about the Services ("Submissions"), you agree to assign to us all intellectual property
                                {" "}rights in such Submission. You agree that we shall own this Submission and be entitled to its
                                {" "}unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without
                                {" "}acknowledgment or compensation to you.
                            </div>
                            <div className="d-flex flex-column gap-2">
                                <span>You are responsible for what you post or upload: By sending us Submissions through any part of the Services you:</span>
                                <ul>
                                    <li>
                                        confirm that you have read and agree with our
                                        {" "}"<a href="#prohibited-acts">PROHIBITED ACTIVITIES</a>" and will not post, send,
                                        {" "}publish, upload, or transmit through the Services any Submission that is illegal, harassing,
                                        {" "}hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any
                                        {" "}person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
                                    </li>
                                    <li>
                                        to the extent permissible by applicable law, waive any and all moral rights to any such Submission;
                                    </li>
                                    <li>
                                        warrant that any such Submission are original to you or that you have the necessary rights and licenses
                                        {" "}to submit such Submissions and that you have full authority to grant us the above-mentioned
                                        {" "}rights in relation to your Submissions; and
                                    </li>
                                    <li>
                                        warrant and represent that your Submissions do not constitute confidential information.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                You are solely responsible for your Submissions and you expressly agree to reimburse us for any and all
                                {" "}losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual
                                {" "}property rights, or (c) applicable law.
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="user-representations"
                    >
                        3. USER REPRESENTATIONS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            By using the Services, you represent and warrant that: (1) all registration information you submit will be true,
                            {" "}accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update
                            {" "}such registration information as necessary; (3) you have the legal capacity and you agree to comply with
                            {" "}these Legal Terms; (4) you are not a minor in the jurisdiction in which you reside; (5) you will not access
                            {" "}the Services through automated or non-human means, whether through a bot, script or otherwise; (6) you will
                            {" "}not use the Services for any illegal or unauthorized purpose; and (7) your use of the Services will not violate
                            {" "}any applicable law or regulation.
                        </div>
                        <div>
                            If you provide any information that is untrue, inaccurate, not current, or incomplete, we have the right to
                            {" "}suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof).
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="user-representations"
                    >
                        4. USER REGISTRATION
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        You may be required to register to use the Services. You agree to keep your password confidential and
                        {" "}will be responsible for all use of your account and password. We reserve the right to remove, reclaim,
                        {" "}or change a username you select if we determine, in our sole discretion, that such username
                        {" "}is inappropriate, obscene, or otherwise objectionable.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="products"
                    >
                        5. PRODUCTS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        We make every effort to display as accurately as possible the colors, features, specifications,
                        {" "}and details of the products available on the Services. However, we do not guarantee that
                        {" "}the colors, features, specifications, and details of the products will be accurate, complete,
                        {" "}reliable, current, or free of other errors, and your electronic display may not accurately
                        {" "}reflect the actual colors and details of the products. All products are subject to availability,
                        {" "}and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products
                        {" "}at any time for any reason. Prices for all products are subject to change.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="purchases-payment"
                    >
                        6. PURCHASES AND PAYMENT
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-2">
                            <span>We accept the following forms of payment:</span>
                            <ul>
                                <li>Visa</li>
                                <li>Mastercard</li>
                                <li>American Express</li>
                                <li>Discover</li>
                                <li>Apple Pay</li>
                                <li>Itump Pay</li>
                                <li>Stripe Pay</li>
                                <li>Link</li>
                                <li>Klana</li>
                            </ul>
                        </div>
                        <div>
                            You agree to provide current, complete, and accurate purchase and account information for all purchases
                            {" "}made via the Services. You further agree to promptly update account and payment information,
                            {" "}including email address, payment method, and payment card expiration date, so that we can complete
                            {" "}your transactions and contact you as needed. Sales tax will be added to the price of purchases as deemed
                            {" "}required by us. We may change prices at any time. All payments shall be in US dollars.
                        </div>
                        <div>
                            You agree to pay all charges at the prices then in effect for your purchases and any applicable shipping
                            {" "}fees, and you authorize us to charge your chosen payment provider for any such amounts upon placing your
                            {" "}order. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested
                            {" "}or received payment.
                        </div>
                        <div>
                            We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel
                            {" "}quantities purchased per person, per household, or per order. These restrictions may include orders placed by or
                            {" "}under the same customer account, the same payment method, and/or orders that use the same billing or shipping address.
                            {" "}We reserve the right to limit or prohibit orders that, in our sole judgment, appear to be placed by dealers, resellers, or distributors.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="subscriptions"
                    >
                        7. SUBSCRIPTIONS
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Billing and Renewal</span>
                            <div>
                                Your subscription will continue and automatically renew unless canceled. You consent to our charging your
                                {" "}payment method on a recurring basis without requiring your prior approval for each recurring charge,
                                {" "}until such time as you cancel the applicable order. The length of your billing cycle will depend on the
                                {" "}type of subscription plan you choose when you subscribed to the Services.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Cancellation</span>
                            <div>
                                You can cancel your subscription at any time by contacting us using the contact information provided below.
                                {" "}Your cancellation will take effect at the end of the current paid term. If you have any questions or are
                                {" "}unsatisfied with our Services, please email us at
                                {" "}<a href="mailto:g.legals@itump.com">g.legals@itump.com.</a>
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Fee Changes</span>
                            <div>
                                We may, from time to time, make changes to the subscription fee and will communicate any price changes to
                                {" "}you in accordance with applicable law.
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="return-policy"
                    >
                        8. RETURN/REFUNDS POLICY
                    </dt>
                    <dd>
                        All sales are final and no refund will be issued.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="software"
                    >
                        9. SOFTWARE
                    </dt>
                    <dd>
                        We make every effort to display as accurately as possible the colors, features, specifications,
                        {" "}and details of the products available on the Services. However, we do not guarantee that
                        {" "}the colors, features, specifications, and details of the products will be accurate, complete,
                        {" "}reliable, current, or free of other errors, and your electronic display may not accurately
                        {" "}reflect the actual colors and details of the products. All products are subject to availability,
                        {" "}and we cannot guarantee that items will be in stock. We reserve the right to discontinue any products
                        {" "}at any time for any reason. Prices for all products are subject to change.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="prohibited-acts"
                    >
                        10. PROHIBITED ACTIVITIES
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div>
                            You may not access or use the Services for any purpose other than that for which we make the Services available.
                            {" "}The Services may not be used in connection with any commercial endeavors except those that
                            {" "}are specifically endorsed or approved by us.
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span>As a user of the Services, you agree not to:</span>
                            <ul>
                                <li>
                                    Systematically retrieve data or other content from the Services to create or compile, directly or
                                    {" "}indirectly, a collection, compilation, database, or directory without written permission from us.
                                </li>
                                <li>
                                    Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account
                                    {" "}information such as user passwords.
                                </li>
                                <li>
                                    Circumvent, disable, or otherwise interfere with security-related features of the Services, including
                                    {" "}features that prevent or restrict the use or copying of any Content or enforce limitations on the
                                    {" "}use of the Services and/or the Content contained therein.
                                </li>
                                <li>
                                    Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
                                </li>
                                <li>
                                    Use any information obtained from the Services in order to harass, abuse, or harm another person.
                                </li>
                                <li>
                                    Make improper use of our support services or submit false reports of abuse or misconduct.
                                </li>
                                <li>
                                    Use the Services in a manner inconsistent with any applicable laws or regulations.
                                </li>
                                <li>
                                    Engage in unauthorized framing of or linking to the Services.
                                </li>
                                <li>
                                    Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including 
                                    {" "}excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes
                                    {" "}with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters,
                                    {" "}or interferes with the use, features, functions, operation, or maintenance of the Services.
                                </li>
                                <li>
                                    Engage in any automated use of the system, such as using scripts to send comments or messages, or using
                                    {" "}any data mining, robots, or similar data gathering and extraction tools.
                                </li>
                                <li>
                                    Delete the copyright or other proprietary rights notice from any Content.
                                </li>
                                <li>
                                    Attempt to impersonate another user or person or use the username of another user.
                                </li>
                                <li>
                                    Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active
                                    {" "}information collection or transmission mechanism, including without limitation, clear graphics interchange
                                    {" "}formats ("gifs"), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as
                                    {" "}"spyware" or "passive collection mechanisms" or "pcms").
                                </li>
                                <li>
                                    Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
                                </li>
                                <li>
                                    Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
                                </li>
                                <li>
                                    Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
                                </li>
                                <li>
                                    Copy or adapt the Services' software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
                                </li>
                                <li>
                                    Except as permitted by applicable law, decipher, compile, disassemble, or reverse engineer any of
                                    {" "}the software comprising or in any way making up a part of the Services.
                                </li>
                                <li>
                                    Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or
                                    {" "}distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper,
                                    {" "}or offline reader that accesses the Services, or use or launch any unauthorized script or other software.
                                </li>
                                <li>
                                    Use a buying agent or purchasing agent to make purchases on the Services.
                                </li>
                                <li>
                                    Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by
                                    {" "}electronic or other means for the purpose of sending unsolicited email, or creating user accounts by
                                    {" "}automated means or under false pretenses.
                                </li>
                                <li>
                                    Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content
                                    {" "}for any revenue-generating endeavor or commercial enterprise.
                                </li>
                                <li>
                                    Use the Services to advertise or offer to sell goods and services.
                                </li>
                                <li>
                                    Sell or otherwise transfer your profile.
                                </li>
                            </ul>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="user-contributions"
                    >
                        11. USER GENERATED CONTRIBUTIONS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <span>
                            The Services does not offer users to submit or post content. We may provide you with the opportunity to create,
                            {" "}submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on
                            {" "}the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions,
                            {" "}or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users
                            {" "}of the Services and through third-party websites. As such, any Contributions you transmit may be treated in accordance with
                            {" "}the Services' Privacy Policy. When you create or make available any Contributions, you thereby represent and warrant that:
                        </span>
                        <ul>
                            <li>
                                The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or
                                {" "}copying of your Contributions do not and will not infringe the proprietary rights, including but not limited
                                {" "}to the copyright, patent, trademark, trade secret, or moral rights of any third party.
                            </li>
                            <li>
                                You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions
                                {" "}to use and to authorize us, the Services, and other users of the Services to use your Contributions
                                {" "}in any manner contemplated by the Services and these Legal Terms.
                            </li>
                            <li>
                                You have the written consent, release, and/or permission of each and every identifiable individual person
                                {" "}in your Contributions to use the name or likeness of each and every such identifiable individual person
                                {" "}to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
                            </li>
                            <li>
                                Your Contributions are not false, inaccurate, or misleading. 
                            </li>
                            <li>
                                Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters,
                                {" "}spam, mass mailings, or other forms of solicitation.
                            </li>
                            <li>
                                Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous,
                                {" "}or otherwise objectionable (as determined by us). 
                            </li>
                            <li>
                                Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
                            </li>
                            <li>
                                Your Contributions are not used to harass or threaten (in the legal sense of those terms) any other person
                                {" "}and to promote violence against a specific person or class of people.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable law, regulation, or rule.
                            </li>
                            <li>
                                Your Contributions do not violate the privacy or publicity rights of any third party.
                            </li>
                            <li>
                                Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended
                                {" "}to protect the health or well-being of minors.
                            </li>
                            <li>
                                Your Contributions do not include any offensive comments that are connected to race, national origin,
                                {" "}gender, sexual preference, or physical handicap.
                            </li>
                            <li>
                                Your Contributions do not otherwise violate, or link to material that violates, any provision of these
                                {" "}Legal Terms, or any applicable law or regulation.
                            </li>
                        </ul>
                        <span>
                            Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things,
                            {" "}termination or suspension of your rights to use the Services.
                        </span>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="contribution-license"
                    >
                        12. CONTRIBUTION LICENSE
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            You and Services agree that we may access, store, process, and use any information and personal data that
                            {" "}you provide following the terms of the Privacy Policy and your choices (including settings).
                        </div>
                        <div>
                            By submitting suggestions or other feedback regarding the Services, you agree that we can use and share such
                            {" "}feedback for any purpose without compensation to you.
                        </div>
                        <div>
                            We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions
                            {" "}and any intellectual property rights or other proprietary rights associated with your Contributions.
                            {" "}We are not liable for any statements or representations in your Contributions provided by you in any area
                            {" "}on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to
                            {" "}exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="review-guidelines"
                    >
                        13. GUIDELINES FOR REVIEWS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply
                            {" "}with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed;
                            {" "}(2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language;
                            {" "}(3) your reviews should not contain discriminatory references based on religion, race, gender, national origin,
                            {" "}age, marital status, sexual orientation, or disability; (4) your reviews should not contain references to illegal
                            {" "}activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make
                            {" "}any conclusions as to the legality of conduct; (7) you may not post any false or misleading statements; and
                            {" "}(8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.
                        </div>
                        <div>
                            We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or
                            {" "}to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us,
                            {" "}and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume
                            {" "}liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review,
                            {" "}you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable
                            {" "}right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or
                            {" "}distribute all content relating to review.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="mobile-license"
                    >
                        14. MOBILE APPLICATION LICENSE
                    </dt>
                    <dd className="d-flex flex-column gap-3">
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Use License</span>
                            <div>
                                If you access the Services via the App, then we grant you a revocable, non-exclusive, non-transferable, limited right to
                                {" "}install and use the App on wireless electronic devices owned or controlled by you, and to access and use the App on
                                {" "}such devices strictly in accordance with the terms and conditions of this mobile application license contained in these
                                {" "}Legal Terms. You shall not: (1) except as permitted by applicable law, decompile, reverse engineer, disassemble, attempt
                                {" "}to derive the source code of, or decrypt the App; (2) make any modification, adaptation, improvement, enhancement,
                                {" "}translation, or derivative work from the App; (3) violate any applicable laws, rules, or regulations
                                {" "}in connection with your access or use of the App; (4) remove, alter, or obscure any proprietary notice
                                {" "}(including any notice of copyright or trademark) posted by us or the licensors of the App; (5) use the
                                {" "}App for any revenue-generating endeavor, commercial enterprise, or other purpose for which it is not designed
                                {" "}or intended; (6) make the App available over a network or other environment permitting access or use by multiple
                                {" "}devices or users at the same time; (7) use the App for creating a product, service, or software that is, directly
                                {" "}or indirectly, competitive with or in any way a substitute for the App; (8) use the App to send automated queries to
                                {" "}any website or to send any unsolicited commercial email; or (9) use any proprietary information or any of our interfaces
                                {" "}or our other intellectual property in the design, development, manufacture, licensing, or distribution of any applications,
                                {" "}accessories, or devices for use with the App.
                            </div>
                        </div>
                        <div className="d-flex flex-column gap-2">
                            <span style={{ fontWeight: "bold", fontSize: "18px" }}>Apple and Android Devices</span>
                            <div>
                                The following terms apply when you use the App obtained from either the Apple Store or Google Play
                                {" "}(each an "App Distributor") to access the Services: (1) the license granted to you for our App is limited
                                {" "}to a non-transferable license to use the application on a device that utilizes the Apple iOS or Android operating systems,
                                {" "}as applicable, and in accordance with the usage rules set forth in the applicable App Distributor’s terms of service;
                                {" "}(2) we are responsible for providing any maintenance and support services with respect to the App as specified in the
                                {" "}terms and conditions of this mobile application license contained in these Legal Terms or as otherwise required under
                                {" "}applicable law, and you acknowledge that each App Distributor has no obligation whatsoever to furnish any maintenance
                                {" "}and support services with respect to the App; (3) in the event of any failure of the App to conform to any applicable
                                {" "}warranty, you may notify the applicable App Distributor, and the App Distributor, in accordance with its terms and
                                {" "}policies, may refund the purchase price, if any, paid for the App, and to the maximum extent permitted by applicable
                                {" "}law, the App Distributor will have no other warranty obligation whatsoever with respect to the App; (4) you represent
                                {" "}and warrant that (i) you are not located in a country that is subject to a US government embargo, or that has been
                                {" "}designated by the US government as a "terrorist supporting" country and (ii) you are not listed on any US government
                                {" "}list of prohibited or restricted parties; (5) you must comply with applicable third-party terms of agreement when using
                                {" "}the App, e.g., if you have a VoIP application, then you must not be in violation of their wireless data service agreement
                                {" "}when using the App; and  (6) you acknowledge and agree that the App Distributors are third-party beneficiaries of the
                                {" "}terms and conditions in this mobile application license contained in these Legal Terms, and that each App Distributor
                                {" "}will have the right (and will be deemed to have accepted the right) to enforce the terms and conditions in this mobile
                                {" "}application license contained in these Legal Terms against you as a third-party beneficiary thereof.
                            </div>
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="third-parties"
                    >
                        15. THIRD-PARTY WEBSITES AND CONTENT
                    </dt>
                    <dd>
                        The Services may contain (or you may be sent via the Site or App) links to other websites ("Third-Party Websites")
                        {" "}as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications,
                        {" "}software, and other content or items belonging to or originating from third parties ("Third-Party Content").
                        {" "}Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy,
                        {" "}appropriateness, or completeness by us, and we are not responsible for any Third-Party Websites accessed through
                        {" "}the Services or any Third-Party Content posted on, available through, or installed from the Services, including
                        {" "}the content, accuracy, offensiveness, opinions, reliability, privacy practices, or other policies of or contained
                        {" "}in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or installation
                        {" "}of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us.
                        {" "}If you decide to leave the Services and access the Third-Party Websites or to use or install any Third-Party Content,
                        {" "}you do so at your own risk, and you should be aware these Legal Terms no longer govern. You should review the applicable
                        {" "}terms and policies, including privacy and data gathering practices, of any website to which you navigate from the
                        {" "}Services or relating to any applications you use or install from the Services. Any purchases you make through
                        {" "}Third-Party Websites will be through other websites and from other companies, and we take no responsibility
                        {" "}whatsoever in relation to such purchases which are exclusively between you and the applicable third party.
                        {" "}You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and
                        {" "}you shall hold us blameless from any harm caused by your purchase of such products or services. Additionally,
                        {" "}you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in
                        {" "}any way from any Third-Party Content or any contact with Third-Party Websites.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="advertisers"
                    >
                        16. ADVERTISERS
                    </dt>
                    <dd>
                        We allow advertisers to display their advertisements and other information in certain areas of the Services,
                        {" "}such as sidebar advertisements or banner advertisements. We simply provide the space to place
                        {" "}such advertisements, and we have no other relationship with advertisers.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="services-management"
                    >
                        17. SERVICES MANAGEMENT
                    </dt>
                    <dd>
                        We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms;
                        {" "}(2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal
                        {" "}Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion
                        {" "}and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible)
                        {" "}any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability,
                        {" "}to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome
                        {" "}to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate
                        {" "}the proper functioning of the Services.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="privacy-policy"
                    >
                        18. PRIVACY POLICY
                    </dt>
                    <dd>
                        We care about data privacy and security. Please review our Privacy Policy:
                        {" "}<a href="/privacy">http://www.itump.com/privacy</a>. By using the Services,
                        {" "}you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services
                        {" "}are hosted in the United States. If you access the Services from any other region of the world with laws or other requirements
                        {" "}governing personal data collection, use, or disclosure that differ from applicable laws in the United States, then through your
                        {" "}continued use of the Services, you are transferring your data to the United States, and you expressly consent to have your data
                        {" "}transferred to and processed in the United States.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="term-termination"
                    >
                        19. TERM AND TERMINATION
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            These Legal Terms shall remain in full force and effect while you use the Services. WITHOUT LIMITING ANY OTHER PROVISION
                            {" "}OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO
                            {" "}AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON,
                            {" "}INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS
                            {" "}OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT
                            {" "}AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
                        </div>
                        <div>
                            If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account
                            {" "}under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of
                            {" "}the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate
                            {" "}legal action, including without limitation pursuing civil, criminal, and injunctive redress.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="modifications"
                    >
                        20. MODIFICATIONS AND INTERRUPTIONS
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <div>
                            We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole
                            {" "}discretion without notice. However, we have no obligation to update any information on our Services. We will not be
                            {" "}liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services.
                        </div>
                        <div>
                            We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other
                            {" "}problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors.
                            {" "}We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time
                            {" "}or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or
                            {" "}inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services.
                            {" "}Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any
                            {" "}corrections, updates, or releases in connection therewith.
                        </div>
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="services-management"
                    >
                        21. GOVERNING LAW
                    </dt>
                    <dd>
                        These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of the State
                        {" "}of Delaware applicable to agreements made and to be entirely performed within the State of Delaware, without regard
                        {" "}to its conflict of law principles.
                    </dd>
                </div>

                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="dispute-resolution"
                    >
                        22. DISPUTE RESOLUTION
                    </dt>
                    <dd>
                        There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions,
                        {" "}pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions
                        {" "}and to change or update the information on the Services at any time, without prior notice.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="corrections"
                    >
                        23. CORRECTIONS
                    </dt>
                    <dd>
                        There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions,
                        {" "}pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions
                        {" "}and to change or update the information on the Services at any time, without prior notice.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="disclaimer"
                    >
                        24. DISCLAIMER
                    </dt>
                    <dd>
                        THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES
                        {" "}WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED,
                        {" "}IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                        {" "}MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                        {" "}ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES' CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS
                        {" "}LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT
                        {" "}AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF
                        {" "}THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
                        {" "}INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES,
                        {" "}TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
                        {" "}OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                        {" "}TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR
                        {" "}ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES,
                        {" "}ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND
                        {" "}WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY
                        {" "}THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR
                        {" "}IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="limitations"
                    >
                        25. LIMITATIONS OF LIABILITY
                    </dt>
                    <dd>
                        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                        {" "}INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT,
                        {" "}LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN
                        {" "}ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR
                        {" "}LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED
                        {" "}TO THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE three (3) MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING.
                        {" "}CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                        {" "}LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
                        {" "}MAY NOT APPLY TO YOU, AND YOU MAY HAVE ADDITIONAL RIGHTS.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="indemnification"
                    >
                        26. INDEMNIFICATION
                    </dt>
                    <dd>
                        You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers,
                        {" "}agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable
                        {" "}attorneys&apos; fees and expenses, made by any third party due to or arising out of: (1) use of the Services;
                        {" "}(2) breach of these Legal Terms; (3) any breach of your representations and warranties set forth in these Legal Terms;
                        {" "}(4) your violation of the rights of a third party, including but not limited to intellectual property rights; or
                        {" "}(5) any overt harmful act toward any other user of the Services with whom you connected via the Services.
                        {" "}Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control
                        {" "}of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our
                        {" "}defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which
                        {" "}is subject to this indemnification upon becoming aware of it.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="user-data"
                    >
                        27. USER DATA
                    </dt>
                    <dd>
                        We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services,
                        {" "}as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely
                        {" "}responsible for all data that you transmit or that relates to any activity you have undertaken using the Services.
                        {" "}You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any
                        {" "}right of action against us arising from any such loss or corruption of such data.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="electronic-functions"
                    >
                        28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
                    </dt>
                    <dd>
                        Visiting the Services, sending us emails, and completing online forms constitute electronic communications.
                        {" "}You consent to receive electronic communications, and you agree that all agreements, notices, disclosures,
                        {" "}and other communications we provide to you electronically, via email and on the Services, satisfy any legal
                        {" "}requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES,
                        {" "}CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                        {" "}INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes,
                        {" "}regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery
                        {" "}or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="text-messaging"
                    >
                        29. SMS TEXT MESSAGING
                    </dt>
                    <dd>
                        Opting Out
                        If at any time you wish to stop receiving SMS messages from us, simply reply to the text with "STOP.” You may receive an SMS message confirming your opt out.
                        Message and Data Rates
                        Please be aware that message and data rates may apply to any SMS messages sent or received. The rates are determined by your carrier and the specifics of your mobile plan.
                        Support
                        If you have any questions or need assistance regarding our SMS communications, please email us at g.legals@itump.com or call at 18888748867.
                    </dd>
                </div>
                {/* end: complete */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="california-residents"
                    >
                        30. CALIFORNIA USERS AND RESIDENTS
                    </dt>
                    <dd>
                        If any complaint with us is not satisfactorily resolved, you can contact the Complaint Assistance Unit of
                        {" "}the Division of Consumer Services of the California Department of Consumer Affairs in writing at
                        {" "}1625 North Market Blvd., Suite N 112, Sacramento, California 95834 or by telephone at (800) 952-5210 or (916) 445-1254
                    </dd>
                </div>
                {/* start: complete */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="miscellaneous"
                    >
                        31. MISCELLANEOUS
                    </dt>
                    <dd>
                        These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute
                        {" "}the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of
                        {" "}these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent
                        {" "}permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible
                        {" "}or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or
                        {" "}part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the
                        {" "}provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions.
                        {" "}There is no joint venture, partnership, employment or agency relationship created between you and us as a result of these Legal
                        {" "}Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them.
                        {" "}You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by
                        {" "}the parties hereto to execute these Legal Terms.
                    </dd>
                </div>
                {/* end: complete */}
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="itump-lo-fi"
                    >
                        32. ITUMP LO-FI FOR BUSINESSES
                    </dt>
                    <dd>
                        Itump Lo-Fi Financing Terms and Conditions By utilizing the Itump Lo-Fi Financing option, you acknowledge and agree to the following terms
                        {" "}and conditions, which outline the scope, limitations, and obligations associated with this service. The Lo-Fi Financing option is
                        {" "}designed to empower users to offer their customers flexible payment plans spanning 3 to 12 months or longer, at low financing fees
                        {" "}that are retained entirely by the user. However, it is crucial to understand that this service is provided strictly on an “as-is” basis,
                        {" "}and the associated risks are borne entirely by you as the user.
                        {" "}Scope and Nature of Service Itump and its partners act exclusively as technology providers, offering a platform that facilitates
                        {" "}financing arrangements between you and your customers. We neither oversee, enforce, nor guarantee the fulfillment of any
                        {" "}payment agreements established between you and your customers. The platform is intended as a tool to streamline your
                        {" "}financing processes, and Itump holds no responsibility for the outcome of these arrangements. User Responsibility and
                        {" "}Assumption of Risk The use of the Lo-Fi Financing option is entirely at your own risk. You, as the user, bear full
                        {" "}responsibility for all financial agreements, including setting terms, managing payment schedules, and addressing customer
                        {" "}defaults. Itump and its partners explicitly disclaim any liability for incomplete payments, delayed transactions, or the
                        {" "}total cessation of payments by your customers. You are solely responsible for mitigating risks associated with your financing practices.
                        {" "}Fees and Payment Obligations Users are required to pay a platform fee for the use of the Lo-Fi Financing service.
                        {" "}While the financing fees charged to your customers are retained by you, any additional platform-related fees, as
                        {" "}specified by Itump, must be paid as a condition of service use. Itump reserves the right to adjust the fee structure or
                        {" "}introduce new fees with prior notice. Continued use of the service after any such changes indicates your acceptance of
                        {" "}the updated terms. Refunds and Disputed Transactions In the event of disputes or payment issues, Itump retains sole discretion
                        {" "}to investigate and determine appropriate resolutions. This includes the right to process refunds where deemed necessary and
                        {" "}to impose administrative fees to cover the costs of dispute resolution. These fees are non-refundable and will be communicated
                        {" "}to you prior to enforcement. By using the platform, you consent to this dispute resolution mechanism and agree to abide by
                        {" "}its outcomes. Platform Usage and Compliance As a user of the Itump Lo-Fi Financing option, you agree to use the platform in
                        {" "}good faith and in compliance with these terms.
                        {" "}Misuse of the platform, fraudulent activity, or violations of these terms may result in suspension or termination of your
                        {" "}access to the service. Itump reserves the right to take necessary actions, including legal recourse, to protect its
                        {" "}interests and the integrity of the platform. Amendments and Updates to Terms Itump may update or revise these Terms
                        {" "}and Conditions at its sole discretion. Any significant changes will be communicated to you in advance, and your
                        {" "}continued use of the service following such updates constitutes your acceptance of the revised terms. It is your
                        {" "}responsibility to review the terms periodically to ensure continued compliance. Limitation of Liability To the fullest
                        {" "}extent permitted by law, Itump and its partners disclaim liability for any direct, indirect, incidental, or consequential
                        {" "}damages arising from your use of the Lo-Fi Financing option. This includes, but is not limited to, losses due to customer
                        {" "}non-payment, disputes, or platform malfunctions.
                        {" "}You agree to indemnify and hold harmless Itump and its partners against any claims, liabilities, or damages resulting from
                        {" "}your financing activities. Governing Law and Dispute Resolution These terms shall be governed by and construed in accordance
                        {" "}with the laws applicable in Itump’s jurisdiction of operation. Any disputes arising from or related to the use of the Lo-Fi
                        {" "}Financing option will be resolved through binding arbitration or in a court of competent jurisdiction, as determined by Itump.
                        {" "}Acknowledgment and Acceptance By using the Itump Lo-Fi Financing option, you confirm that you have read, understood, and agreed
                        {" "}to these Terms and Conditions in their entirety. You acknowledge that all risks associated with financing activities rest solely
                        {" "}with you, and Itump bears no financial responsibility for any outcomes related to customer payments. Your continued use of the
                        {" "}platform constitutes your acceptance of these terms and any future updates.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="order-placement"
                    >
                        33. ORDER PLACEMENT AND REFUND POLICY
                    </dt>
                    <dd>
                        Itump Order Placement and Refund Policy By placing an order or making a payment through the Itump platform, you acknowledge
                        {" "}and agree to the following terms and conditions. These terms are designed to protect the integrity of Itump&apos;s operations
                        {" "}and its agreements with third-party service providers. Due to the involvement of external partners in fulfilling tasks and
                        {" "}services, all orders and payments made on the platform are final and non-refundable. Order Placement Policy
                        {" "}1. Binding Commitment: Placing an order or making a payment through the Itump platform constitutes a binding commitment.
                        {" "}Once an order is submitted and confirmed, it cannot be canceled or modified. 2. No Refunds: Payments made for orders are
                        {" "}final and non-refundable. This includes cases where services are delayed, interrupted, or affected by external factors beyond Itump&apos;s control.
                        {" "}3. Third-Party Engagement: Many tasks and services are fulfilled through partnerships with third-party providers.
                        {" "}Payments made are used to engage these providers, and refunds cannot be processed once the order has been initiated.
                        {" "}User Responsibilities 1. Accuracy of Information: Users are responsible for ensuring that all information provided
                        {" "}during order placement is accurate and complete. Errors or omissions may result in delays or incomplete services,
                        {" "}for which Itump is not liable. 2. Order Review: Users are encouraged to review their orders carefully before submitting
                        {" "}them, as changes cannot be made once the order is confirmed. Limitations of Liability
                        {" "}1. Third-Party Dependencies: Itump is not responsible for the performance or delays caused by third-party providers
                        {" "}engaged to fulfill tasks or services. 2. Service Quality: While Itump works with reliable third-party partners,
                        {" "}it does not guarantee the quality or timelines of services delivered by these providers.
                        {" "}3. Force Majeure: Itump is not liable for delays or failures in service due to events beyond its control,
                        {" "}including but not limited to natural disasters, technical failures, or third-party disruptions. Dispute Resolution
                        {" "}1. Service Issues: If an issue arises regarding the quality or delivery of services, Itump will work to mediate and
                        {" "}resolve the matter with the relevant third-party provider. However, this does not entitle the user to a refund.
                        {" "}2. Final Determination: Itump’s determination regarding disputes related to orders or payments is final and binding. Policy Amendments
                        {" "}1. Changes to Terms: Itump reserves the right to amend this policy at any time. Users will be notified of significant updates,
                        {" "}and continued use of the platform constitutes acceptance of the revised terms. Acknowledgment and Acceptance By placing
                        {" "}an order or making a payment on the Itump platform, you confirm the following: • You have read, understood, and agree
                        {" "}to this Order Placement and Refund Policy. • You acknowledge that all payments are final and non-refundable.
                        {" "}• You accept full responsibility for ensuring the accuracy of your order details and understand that errors cannot
                        {" "}be corrected after confirmation. • You agree to hold Itump harmless from claims or disputes related to third-party service delivery.
                        {" "}For any questions or concerns regarding your order, contact Itump support prior to submission. By proceeding, you accept these terms in full
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="tap-to-pay"
                    >
                        34. TAP TO PAY TERMS AND CONDITIONS
                    </dt>
                    <dd>
                        Itump Tap-to-Pay Terms and Conditions By using the Tap-to-Pay feature provided by Itump, you agree to the following terms and conditions.
                        {" "}This feature is designed to facilitate seamless payment transactions between users and their customers. While Itump provides the
                        {" "}technology and platform for these transactions, users are required to comply with the guidelines and restrictions outlined herein 
                        {" "}to ensure proper use and avoid any liability issues. Scope and Usage of Tap-to-Pay 1. Platform Role: Itump acts as a facilitator
                        {" "}for payment transactions through the Tap-to-Pay feature. Itump does not act as a financial guarantor or mediator between the user
                        {" "}and the customer. 2. User Responsibility: Users are solely responsible for ensuring that all transactions made through the Tap-to-Pay
                        {" "}feature are legitimate, authorized, and compliant with applicable laws and regulations.
                        {" "}3. Fraud Prevention: It is the user’s responsibility to verify customer identities and ensure payment authorization.
                        {" "}Itump is not liable for unauthorized or fraudulent transactions initiated via Tap-to-Pay. Prohibited Activities 
                        {" "}1. Unauthorized Use: Users may not use Tap-to-Pay to process transactions that are fraudulent, unauthorized, or violate applicable laws.
                        {" "}2. Third-Party Sharing: Users must not allow third parties to use their Tap-to-Pay accounts or devices for processing payments.
                        {" "}3. Improper Use of Funds: Funds processed through Tap-to-Pay must only be used for legitimate business purposes directly related to the user’s account.
                        {" "}Liability and Risk 1. User Assumes Risk: All risks associated with Tap-to-Pay transactions, including chargebacks, disputes,
                        {" "}and unauthorized transactions, are borne solely by the user. 2. Indemnification: Users agree to indemnify and hold
                        {" "}harmless Itump and its partners from any claims, damages, or losses resulting from misuse of the Tap-to-Pay feature.
                        {" "}Chargebacks and Disputed Transactions 1. Resolution Process: In the event of chargebacks or disputed transactions,
                        {" "}Itump will investigate and make a determination at its sole discretion. 2. Fees for Disputes: Users may be subject
                        {" "}to fees for disputed transactions, including administrative fees for processing chargebacks.
                        {" "}3. Liability for Disputes: Users are responsible for resolving disputes directly with their customers unless otherwise
                        {" "}determined by Itump. Account Restrictions and Termination 1. Misuse of Tap-to-Pay: Any misuse of the Tap-to-Pay feature,
                        {" "}including unauthorized activities or fraudulent transactions, may result in account suspension or termination.
                        {" "}2. Recovery of Funds: Itump reserves the right to recover funds or impose penalties for any unauthorized or disputed
                        {" "}transactions. 3. Access Restrictions: Continued access to the Tap-to-Pay feature is contingent upon compliance with
                        {" "}these terms and prompt resolution of any disputes or outstanding balances. Amendments and Legal Terms
                        {" "}1. Policy Updates: Itump may update or modify these terms at any time. Continued use of Tap-to-Pay signifies
                        {" "}acceptance of the updated terms. 2. Legal Framework: These terms are governed by the laws of the jurisdiction
                        {" "}in which Itump operates and are enforceable under applicable regulations. Acknowledgment and Acceptance By using
                        {" "}the Tap-to-Pay feature, you agree to the following: • You understand and accept full responsibility for all
                        {" "}transactions processed through this feature. • You acknowledge the risks associated with payment disputes and
                        {" "}unauthorized transactions. • You agree to comply with all applicable laws and these terms to maintain access to the Tap-to-Pay feature.
                        {" "} • You indemnify and hold harmless Itump from any claims or liabilities arising from your use of the Tap-to-Pay feature.
                        {" "}If you have questions about these terms or require clarification, please contact Itump support before proceeding.
                        {" "}Continued use of the Tap-to-Pay feature constitutes your agreement to these terms.
                    </dd>
                </div>
                
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="terms-for-customers"
                    >
                        35. ITUMP LO-FI FINANCING TERMS FOR CUSTOMERS
                    </dt>
                    <dd>
                        Itump Lo-Fi Financing Terms for Customers By selecting the Itump Lo-Fi Financing option to pay for services
                        {" "}or obligations owed to Itump, you agree to the following terms and conditions, which outline the responsibilities,
                        {" "}risks, and obligations associated with this financing method. The Lo-Fi Financing option allows you to spread
                        {" "}payments over an agreed term while incurring a small financing fee. This fee is applied to your total payment
                        {" "}amount and may result in an increase in the total amount payable. Scope and Usage of Lo-Fi Financing
                        {" "}1. The Itump Lo-Fi Financing option provides a convenient way to manage your payments in installments over
                        {" "}an agreed term, typically ranging from 3 to 12 months or more. 2. A financing fee will be applied to your total
                        {" "}payment, increasing the overall amount owed. This fee is non-negotiable and is clearly outlined before you confirm your financing plan.
                        3. By choosing this option, you agree to complete all payments in full and on time according to the agreed schedule.
                        {" "}Payment Obligations 1. Timely Payment: You are required to adhere to the payment schedule provided at the time
                        {" "}of opting for the Lo-Fi Financing plan. Failure to make payments on time may result in additional penalties or fees.
                        {" "}2. Non-Cancellation of Payments: Payments cannot be canceled or paused unless your entire outstanding balance is first
                        {" "}paid in full. 3. Account Closure: You cannot close your Itump account while there is an outstanding balance associated
                        {" "}with your financing plan. Consequences of Default or Late Payment
                        {" "}1. If payments are not completed within the agreed terms, Itump reserves the right to report your account status
                        {" "}and payment history to credit bureaus and consumer affairs agencies. 2. Failure to resolve outstanding balances
                        {" "}may lead to account termination and escalation of the debt to credit recovery partners. This process may include
                        {" "}additional fees or penalties as determined by the recovery agency.
                        {" "}3. Itump retains the right to take any legal or financial recovery measures necessary to secure the repayment
                        {" "}of the balance due. Transparency of Costs 1. The financing fee and the total amount payable under the Lo-Fi Financing
                        {" "}option are disclosed prior to your acceptance of the payment plan. 2. By proceeding, you acknowledge and agree to pay the
                        {" "}total amount as outlined, including any applicable financing fees. Governing Terms 1. These terms form part of the binding
                        {""}agreement between you and Itump and are subject to all applicable laws in the jurisdiction of operation. 2. Itump reserves
                        {" "}the right to amend these terms at any time. Continued use of the financing option constitutes your acceptance of any updates.
                        {" "}Acknowledgment and Acceptance By opting to use the Itump Lo-Fi Financing option, you confirm that: • You understand and agree
                        {" "}to all the terms and conditions outlined above.
                        {" "}• You acknowledge the financing fee and agree to the total payment amount, including any applicable fees. • You
                        {" "}accept full responsibility for completing all payments within the agreed term. • You understand that failure to
                        {" "}comply with these terms may result in actions such as reporting to credit bureaus, account termination, and
                        {" "}escalation to credit recovery partners. Your use of the Itump Lo-Fi Financing option signifies your agreement to these terms.
                        {" "}If you have any questions or concerns about the payment plan or your obligations, contact Itump customer support before proceeding.
                    </dd>
                </div>
                <div style={{ marginBottom: "30px" }}>
                    <dt
                        style={{
                            fontSize: "24px",
                            fontWeight: "bold",
                            marginBottom: "15px",
                        }}
                        id="contact-us"
                    >
                        36. CONTACT US
                    </dt>
                    <dd className="d-flex flex-column gap-2">
                        <span>In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at</span>
                        <div className="d-flex flex-column gap-1">
                            <span>24One, Inc.</span>
                            <span>Data Protection Officer</span>
                            <span>30 Wall Street</span>
                            <span>New York, NY 10005</span>
                            <span>United States</span>
                            <span>Phone: <a href="call:18888748867">18888748867</a></span>
                            <span>Email: <a href="mailto:g.legals@itump.com">g.legals@itump.com</a></span>
                        </div>
                    </dd>
                </div>
            </dl>
        </div>
    )
}

export default ReturnPolicy
