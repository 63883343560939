const ServiceCard = ({ title, imageSrc, shapeSrc, items }) => {
  return (
    <div className="col-md-6">
      <div className="service-card h-100">
        <div
          className="service-card-title"
          data-aos="fade-up"
          data-aos-delay="50"
        >
          <h3>{title}</h3>
        </div>
        <div
          className="service-card-thumb"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <img src={imageSrc} alt="thumb" />
        </div>
        <div className="service-card-content m-t-35">
          <div
            className="service-accordion"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            {items.map((item) => (
              <div className="service-item" key={item.id}>
                <button
                  type="button"
                  className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                  data-bs-toggle="collapse"
                  data-bs-target={`#service-item-coll-${item.id}`}
                  aria-expanded="false"
                  aria-controls={`service-item-coll-${item.id}`}
                >
                  <span>{item.title}</span>
                </button>
                <div
                  className="service-content collapse"
                  id={`service-item-coll-${item.id}`}
                >
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="s-service-shape-2">
          <img src={shapeSrc} alt="shape" />
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
