import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const BlogDetails = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="blog-details-section bg-light p-t-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="blog-details-content m-b-65 m-b-xs-40">
            <Link
              to="#"
              className="back-btn d-flex align-items-center gap-3 fw-bold mb-4"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <span className="icon d-flex align-items-center justify-content-center rounded-circle">
                <i className="fa fa-arrow-left"></i>
              </span>
              Back to blog list
            </Link>
            <div className="read-wrap" data-aos="fade-up" data-aos-delay="100">
              <div className="people">Startup Support</div>
              <p>8 min read</p>
            </div>
            <h2 data-aos="fade-up" data-aos-delay="150">
              5 Steps to Building Business Credit from Day One
            </h2>
            <p data-aos="fade-up" data-aos-delay="200">
              Building your business’s financial foundation with the essentials
              for establishing strong credit
            </p>
          </div>
          <div
            className="blog-thumb overflow-hidden"
            data-aos="zoom-in"
            data-aos-delay="250"
          >
            <img
              src="assets/img/thumb/blog-detail-thumb.jpg"
              alt="thumb"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
        </div>
      </section>

      <section className="d-blog-section bg-light p-t-80 p-t-xs-60 p-b-80 p-b-xs-60 position-relative">
        <div className="container">
          <div className="row g-0 align-items-baseline">
            <div className="col-lg-4 order-2 order-lg-1">
              <div className="blog-author-info">
                <div
                  className="author-wrap"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="author-icon">
                    <img src="assets/img/thumb/thumb-100.png" alt="thumb" />
                  </div>
                  <div className="author-info">
                    <h4>Olivia Rhye</h4>
                    <p>Ore Temilayo, CEO</p>
                  </div>
                </div>
                <div
                  className="published"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <p className="text">Published on</p>
                  <p className="date">17 Jan 2024</p>
                </div>
                <div
                  className="d-flex align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="copy-btn">
                    <Link to="#">
                      <span className="icon">
                        <img src="assets/img/icons/copy.svg" alt="copy icon" />
                      </span>
                      Copy link
                    </Link>
                  </div>
                  <div className="social-icon-wrap d-flex align-items-center">
                    <Link
                      to
                      href="#"
                      title="twitter"
                      className="social-icon d-flex align-items-center justify-content-center"
                    >
                      <i className="fa-brands fa-x-twitter"></i>
                    </Link>
                    <Link
                      to
                      href="#"
                      title="instagram"
                      className="social-icon d-flex align-items-center justify-content-center"
                    >
                      <i className="fa-brands fa-facebook"></i>
                    </Link>
                    <Link
                      to
                      href="#"
                      title="linkedin"
                      className="social-icon d-flex align-items-center justify-content-center"
                    >
                      <i className="fa-brands fa-linkedin"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 order-1 order-lg-2">
              <div className="position-sticky top-0 start-0">
                <div className="blog-content-info ps-lg-5 m-b-md-60 m-b-xs-60">
                  <div className="title m-b-25">
                    <h3 data-aos="fade-up" data-aos-delay="50">
                      Introduction
                    </h3>
                  </div>
                  <div
                    className="text m-b-45"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <p>
                      Mi tincidunt elit, id quisque ligula ac diam, amet. Vel
                      etiam suspendisse morbi eleifend faucibus eget vestibulum
                      felis. Dictum quis montes, sit sit. Tellus aliquam enim
                      urna, etiam. Mauris posuere vulputate arcu amet, vitae
                      nisi, tellus tincidunt. At feugiat sapien varius id. Eget
                      quis mi enim, leo lacinia pharetra, semper. Eget in
                      volutpat mollis at volutpat lectus velit, sed auctor.
                      Porttitor fames arcu quis fusce augue enim. Quis at
                      habitant diam at. Suscipit tristique risus, at donec. In
                      turpis vel et quam imperdiet. Ipsum molestie aliquet
                      sodales id est ac volutpat.
                    </p>
                  </div>
                  <div
                    className="blog-thumb m-b-15"
                    data-aos="zoom-in"
                    data-aos-delay="150"
                  >
                    <img src="assets/img/thumb/thumb-101.png" alt="thumb" />
                  </div>
                  <div className="text m-b-50">
                    <p data-aos="fade-up" data-aos-delay="50">
                      Image courtesy of Laura Davidson via{" "}
                      <Link to="#">Unsplash</Link>
                    </p>
                  </div>
                  <div
                    className="quote-wrap m-b-50"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <p className="quote">
                      “In a world older and more complete than ours they move
                      finished and complete, gifted with extensions of the
                      senses we have lost or never attained, living by voices we
                      shall never hear.”
                    </p>
                    <p className="author">— Olivia Rhye, Product Designer</p>
                  </div>
                  <div
                    className="text m-b-45"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <p>
                      Dolor enim eu tortor urna sed duis nulla. Aliquam
                      vestibulum, nulla odio nisl vitae. In aliquet pellentesque
                      aenean hac vestibulum turpis mi bibendum diam. Tempor
                      integer aliquam in vitae malesuada fringilla. Elit nisi in
                      eleifend sed nisi. Pulvinar at orci, proin imperdiet
                      commodo consectetur convallis risus. Sed condimentum enim
                      dignissim adipiscing faucibus consequat, urna. Viverra
                      purus et erat auctor aliquam. Risus, volutpat vulputate
                      posuere purus sit congue convallis aliquet. Arcu id augue
                      ut feugiat donec porttitor neque. Mauris, neque ultricies
                      eu vestibulum, bibendum quam lorem id. Dolor lacus, eget
                      nunc lectus in tellus, pharetra, porttitor. Ipsum sit
                      mattis nulla quam nulla. Gravida id gravida ac enim mauris
                      id. Non pellentesque congue eget consectetur turpis.
                      Sapien, dictum molestie sem tempor. Diam elit, orci,
                      tincidunt aenean tempus. Quis velit eget ut tortor tellus.
                      Sed vel, congue felis elit erat nam nibh orci.
                    </p>
                  </div>
                  <div
                    className="title m-b-15"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <h3>Software and tools</h3>
                  </div>
                  <div
                    className="text m-b-45"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <p>
                      Pharetra morbi libero id aliquam elit massa integer
                      tellus. Quis felis aliquam ullamcorper porttitor. Pulvinar
                      ullamcorper sit dictumst ut eget a, elementum eu. Maecenas
                      est morbi mattis id in ac pellentesque ac.
                    </p>
                  </div>
                  <div
                    className="title m-b-15"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <h3>Other resources</h3>
                  </div>
                  <div className="text" data-aos="fade-up" data-aos-delay="50">
                    <p>
                      Sagittis et eu at elementum, quis in. Proin praesent
                      volutpat egestas sociis sit lorem nunc nunc sit. Eget diam
                      curabitur mi ac. Auctor rutrum lacus malesuada massa
                      ornare et. Vulputate consectetur ac ultrices at diam dui
                      eget fringilla tincidunt. Arcu sit dignissim massa erat
                      cursus vulputate gravida id. Sed quis auctor vulputate hac
                      elementum gravida cursus dis.
                    </p>
                  </div>
                  <div
                    className="list-wrap m-b-45"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <ol>
                      <li>
                        Lectus id duis vitae porttitor enim gravida morbi.
                      </li>
                      <li>
                        Eu turpis posuere semper feugiat volutpat elit, ultrices
                        suspendisse. Auctor vel in vitae placerat.
                      </li>
                      <li>
                        Suspendisse maecenas ac donec scelerisque diam sed est
                        duis purus.
                      </li>
                    </ol>
                  </div>
                  <div
                    className="blog-thumb m-b-15"
                    data-aos="zoom-in"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/thumb/thumb-102.png" alt="thumb" />
                  </div>
                  <div
                    className="text m-b-50"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <p>
                      Image courtesy of Leon via <Link to="#">Unsplash</Link>
                    </p>
                  </div>
                  <div className="text" data-aos="fade-up" data-aos-delay="50">
                    <p>
                      Lectus leo massa amet posuere. Malesuada mattis non
                      convallis quisque. Libero sit et imperdiet bibendum
                      quisque dictum vestibulum in non. Pretium ultricies tempor
                      non est diam. Enim ut enim amet amet integer cursus. Sit
                      ac commodo pretium sed etiam turpis suspendisse at.
                      Tristique odio senectus nam posuere ornare leo metus,
                      ultricies. Blandit duis ultricies vulputate morbi feugiat
                      cras placerat elit. Aliquam tellus lorem sed ac. Montes,
                      sed mattis pellentesque suscipit accumsan. Cursus viverra
                      aenean magna risus elementum faucibus molestie
                      pellentesque. Arcu ultricies sed mauris vestibulum.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default BlogDetails;
