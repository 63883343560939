import React from "react";
import { Link } from "react-router";
import FooterSection from "../../../component/FooterSection";
import CopyrightSection from "../../../component/CopyrightSection";
import GuidelinesSidebar from "../../../component/guidelines/AgreementsSidebar";

const Disclaimer = () => {
    return (
        <div
            style={{
                color: "#333",
                maxWidth: "800px",
                lineHeight: "1.6",
            }}
        >
            <h1
                style={{
                    fontSize: "48px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    color: "#222",
                }}
            >
                DISCLAIMER
            </h1>
            <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                Last updated November 13, 2024
            </p>
            <div className="d-flex flex-column gap-3">
                <div>
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        WEBSITE DISCLAIMER
                    </p>
                    <div>
                        The information provided by 24One, Inc. ("we," "us," or "our") on
                        {" "}<a href="/">https://itump.com</a> (the "Site") and our mobile application is for general informational purposes only.
                        {" "}All information on the Site and our mobile application is provided in good faith, however we make no representation or warranty
                        {" "}of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any
                        {" "}information on the Site or our mobile application. UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR
                        {" "}DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR OUR MOBILE APPLICATION OR RELIANCE ON ANY INFORMATION PROVIDED
                        {" "}ON THE SITE AND OUR MOBILE APPLICATION. YOUR USE OF THE SITE AND OUR MOBILE APPLICATION AND YOUR RELIANCE ON ANY INFORMATION ON
                        {" "}THE SITE AND OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
                    </div>
                </div>
                <div>
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        EXTERNAL LINKS DISCLAIMER
                    </p>
                    <div>
                        The Site and our mobile application may contain (or you may be sent through the Site or our mobile application) links to other
                        {" "}websites or content belonging to or originating from third parties or links to websites and features in banners or other
                        {" "}advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability,
                        {" "}availability, or completeness by us. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR
                        {" "}RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE OR FEATURE LINKED IN
                        {" "}ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                        {" "}YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                    </div>
                </div>
                <div>
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        PROFESSIONAL DISCLAIMER
                    </p>
                    <div>
                        The Site cannot and does not contain legal advice. The legal information is provided for general informational and educational purposes
                        {" "}only and is not a substitute for professional advice. Accordingly, before taking any actions based upon such information, we encourage
                        {" "}you to consult with the appropriate professionals. We do not provide any kind of legal advice. THE USE OR RELIANCE OF ANY INFORMATION
                        {" "}CONTAINED ON THE SITE OR OUR MOBILE APPLICATION IS SOLELY AT YOUR OWN RISK.
                    </div>
                </div>
                <div>
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        AFFILIATES DISCLAIMER
                    </p>
                    <div>
                        The Site and our mobile application may contain links to affiliate websites, and we receive an affiliate commission for any purchases
                        {" "}made by you on the affiliate website using such links. Our affiliates include the following:
                    </div>
                </div>
                <div>
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        TESTIMONIALS DISCLAIMER
                    </p>
                    <div>
                        The Site may contain testimonials by users of our products and/or services. These testimonials reflect the real-life experiences and
                        {" "}opinions of such users. However, the experiences are personal to those particular users, and may not necessarily be representative
                        {" "}of all users of our products and/or services. We do not claim, and you should not assume, that all users will have the same
                        {" "}experiences. YOUR INDIVIDUAL RESULTS MAY VARY.
                    </div>
                    <div>
                        The testimonials on the Site are submitted in various forms such as text, audio and/or video, and are reviewed by us before being posted.
                        {" "}They appear on the Site verbatim as given by the users, except for the correction of grammar or typing errors. Some testimonials may have
                        {" "}been shortened for the sake of brevity where the full testimonial contained extraneous information not relevant to the general public.
                    </div>
                    <div>
                        The views and opinions contained in the testimonials belong solely to the individual user and do not reflect our views and opinions.
                        {" "}We are not affiliated with users who provide testimonials, and users are not paid or otherwise compensated for their testimonials.
                    </div>
                    <div>
                        The testimonials on the Site are not intended, nor should they be construed, as claims that our products and/or services can be used to
                        {" "}diagnose, treat, mitigate, cure, prevent, or otherwise be used for any disease or medical condition.
                        {" "}No testimonials have been clinically proven or evaluated.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Disclaimer
