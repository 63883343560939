import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const MissionSection = () => {
  const steps = [
    { text: "Empowerment Through Simplicity", className: "" },
    { text: "Commitment to Compliance", className: "paste" },
    { text: "Innovation with Integrity", className: "green" },
    { text: "Customer-Centric Growth", className: "orange" },
  ];

  return (
    <section className="mission-section p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
      <div className="container">
        <div className="mission-section-title common-title-wrap text-center m-b-60 m-b-md-50 m-b-xs-40 p-b-60 p-b-md-50 p-b-xs-40">
          <h3 className="common-title" data-aos="fade-up" data-aos-delay="50">
            Our Mission and Values
          </h3>
          <p
            className="common-title-body"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            Our mission is to empower entrepreneurs and established businesses
            alike with tools that streamline operations, enhance compliance, and
            support financial health.
          </p>
        </div>

        {/* Desktop and Tablet View */}
        <div className="mission-step-slider d-none d-md-flex gap-3">
          <div className="mission-steps-wrap d-flex">
            {steps.map((step, index) => (
              <div
                key={index}
                className={`mission-steps ${step.className}`}
                data-aos="fade-up"
                data-aos-delay={50 * (index + 1)}
              >
                <h3>{step.text}</h3>
              </div>
            ))}
          </div>
        </div>

        {/* Mobile View */}
        <div
          className="mission-step-slider d-block d-md-none"
          style={{ height: "130px" }}
        >
          <Swiper
            spaceBetween={5}
            slidesPerView="auto"
            modules={[Autoplay]}
            // loop
            // autoplay
            speed={5000}
          >
            {steps.map((step, index) => (
              <SwiperSlide className="mission-steps-wrap" key={index}>
                <div className={`mission-steps ${step.className}`}>
                  <h3>{step.text}</h3>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default MissionSection;
