import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ItumpMobile from "../../component/ItumpMobileSection";

const Team = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* hero section start */}
      <section className="hero-section-7 team-hero bg-light overflow-hidden">
        <div className="container">
          <div className="hero-content common-title-wrap common-title-light mx-auto text-center p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
            <h1 className="common-title" data-aos="fade-up" data-aos-delay="50">
              Behind Every Solution, <br /> A Team That Delivers
            </h1>
            <p
              className="common-title-body"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              From visionary leaders to innovative developers, our diverse team
              shares a passion for building solutions that empower businesses
              worldwide. Together, we blend expertise, creativity, and
              dedication to create tools that simplify business operations and
              drive growth.
            </p>
            <Link
              to="#"
              className="theme-btn d-inline-block"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              Join Our Team
            </Link>
          </div>
        </div>
        <div className="hero-thumb-wrapper mx-auto">
          <div className="row">
            <div className="col-3">
              <div
                className="hero-thumb overflow-hidden h-100 position-relative"
                data-aos="zoom-in"
                data-aos-delay="50"
              >
                <img
                  src="assets/img/thumb/t-hero-thumb-1.jpg"
                  alt="team thumb"
                  className="w-100 h-100 object-fit-cover"
                />
              </div>
            </div>
            <div className="col-6">
              <div
                className="hero-thumb overflow-hidden h-100 position-relative"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img
                  src="assets/img/thumb/t-hero-thumb-2.jpg"
                  alt="team thumb"
                  className="w-100 h-100 object-fit-cover"
                />
              </div>
            </div>
            <div className="col-3">
              <div
                className="hero-thumb overflow-hidden h-100 position-relative"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/thumb/t-hero-thumb-3.jpg"
                  alt="team thumb"
                  className="w-100 h-100 object-fit-cover"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* hero section end  */}

      {/* team section start  */}
      <section className="team-section bg-light p-t-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-lg-80 p-b-xs-60 overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="team-section-title common-title-wrap common-title-light">
                <h2
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Our Leadership Team
                </h2>
                <p
                  className="common-title-body mb-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  A collective of diverse minds and expertise, united to drive
                  innovation and deliver exceptional solutions for businesses
                  worldwide.
                </p>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-member-list">
                <div className="row">
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-1.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Jesse Daniels
                      </h5>
                      <span className="team-member-designation">
                        Chief Technology Architect{" "}
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-2.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Aura Ojeda Gomez
                      </h5>
                      <span className="team-member-designation">
                        VP of Marketing
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-3.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Rishabh Trivedi
                      </h5>
                      <span className="team-member-designation">
                        VP of Engineering
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-4.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Alohan Oghosa Kelvin
                      </h5>
                      <span className="team-member-designation">
                        VP of Design
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="250"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-5.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Ochulor Chiedozie
                      </h5>
                      <span className="team-member-designation">
                        VP of Research & Development
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-6.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Sardar Usama Khalid
                      </h5>
                      <span className="team-member-designation">
                        Head of Legal & Compliance
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="350"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-7.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Kale Nakoro Emmanuel
                      </h5>
                      <span className="team-member-designation">
                        Chief Media Strategist
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="400"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-8.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Arvind Verma
                      </h5>
                      <span className="team-member-designation">
                        VP of Solutions
                      </span>
                    </div>
                  </div>
                  <div
                    className="col-md-4 col-sm-6"
                    data-aos="fade-up"
                    data-aos-delay="450"
                  >
                    <div className="team-member">
                      <div className="team-member-img overflow-hidden">
                        <img
                          src="assets/img/thumb/t-member-img-9.jpg"
                          alt="team member img"
                          className="w-100 h-100 object-fit-cover"
                        />
                      </div>
                      <h5 className="team-member-name fw-bold mb-1">
                        Rachael Papreja
                      </h5>
                      <span className="team-member-designation">
                        Head of Customer Success
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* team section end */}

      {/* testimonial section start  */}
      <section className="review-section position-relative overflow-hidden z-1 p-t-90 p-t-md-80 p-t-xs-60 p-b-110 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="common-title-wrap common-title-light text-center m-b-90 m-b-lg-70 m-b-md-50 m-b-xs-40">
            <h2
              className="common-title mb-2"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              With Love from Our People
            </h2>
            <p
              className="common-title-body mb-0"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Hear what we have to say about our work and our product
            </p>
          </div>
          <div
            className="review-slider-container d-flex flex-wrap align-items-center justify-content-center"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <button
              type="button"
              title="slide prev"
              className="slider-btn r-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0 order-2 order-md-1"
            >
              <i className="fa-solid fa-arrow-left-long"></i>
            </button>
            <div className="review-slider swiper mx-0 order-1 order-md-2">
              <div className="review-slider-wrapper swiper-wrapper">
                <div className="review-slide swiper-slide position-relative">
                  <div className="review-slide-card d-flex flex-column justify-content-between position-relative z-1">
                    <p className="review">
                      Working at itump has been an incredible journey. The
                      supportive environment and collaborative spirit empower me
                      to grow professionally every day. I love being part of a
                      team that genuinely cares about each other's success!
                    </p>
                    <div className="reviewer-info">
                      <h5>Olivia Rhye</h5>
                      <p className="mb-0">
                        <span className="designation position-relative d-inline-block">
                          Chief Product Officer
                        </span>
                        <span>Growth Team</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="review-slide swiper-slide position-relative">
                  <div className="review-slide-card d-flex flex-column justify-content-between position-relative z-1">
                    <p className="review">
                      Working at itump has been an incredible journey. The
                      supportive environment and collaborative spirit empower me
                      to grow professionally every day. I love being part of a
                      team that genuinely cares about each other's success!
                    </p>
                    <div className="reviewer-info">
                      <h5>Olivia Rhye</h5>
                      <p className="mb-0">
                        <span className="designation position-relative d-inline-block">
                          Chief Product Officer
                        </span>
                        <span>Growth Team</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              title="slide next"
              className="slider-btn r-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0 order-3"
            >
              <i className="fa-solid fa-arrow-right-long"></i>
            </button>
          </div>
        </div>
        <img
          src="assets/img/bg/testimonial-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern position-absolute top-0 start-0 end-0 mx-auto w-100"
        />
      </section>
      {/* testimonial section end  */}

      <section className="itump-mobile-section bg-light p-t-100 p-t-md-80 p-t-xs-60 p-b-145 p-b-xl-120 p-b-lg-100 p-b-md-80 p-b-xs-40">
        <div className="container">
          <div
            className="get-started-wrap position-relative m-b-145 m-b-xl-120 m-b-lg-100 m-b-md-80 m-b-xs-65"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <Link to="#">
              See Openings{" "}
              <img src="assets/img/icons/angles-right.svg" alt="angles right" />
            </Link>
          </div>
          <ItumpMobile className="style-light" />
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default Team;
