export const CONTENTS = [
    { title: "WHAT INFORMATION DO WE COLLECT?", href: "#wiwc" },
    { title: "HOW DO WE PROCESS YOUR INFORMATION?", href: "#hdwpyi" },
    { title: "WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?", href: "#lbwro" },
    { title: "WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?", href: "#wwwsypi" },
    { title: "DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?", href: "#cookies-policy" },
    { title: "HOW DO WE HANDLE YOUR SOCIAL LOGINS?", href: "#socials-authentication" },
    { title: "HOW LONG DO WE KEEP YOUR INFORMATION?", href: "#hlwkyi" },
    { title: "HOW DO WE KEEP YOUR INFORMATION SAFE?", href: "#hwkyis" },
    { title: "DO WE COLLECT INFORMATION FROM MINORS?", href: "#dwcifm" },
    { title: "WHAT ARE YOUR PRIVACY RIGHTS?", href: "#privacy-rights" },
    { title: "CONTROLS FOR DO-NOT-TRACK FEATURES", href: "#do-not-track" },
    { title: "DO UNITED STATES RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?", href: "#usrpr" },
    { title: "DO OTHER REGIONS HAVE SPECIFIC PRIVACY RIGHTS?", href: "#orpr" },
    { title: "DO WE MAKE UPDATES TO THIS NOTICE?", href: "#notice-updates" },
    { title: "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?", href: "#contact-support" },
    { title: "HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?", href: "#pdm" }
]