export const CONTENTS = [
    {
        title: "PURPOSE OF THE AGREEMENT",
        href: "#purpose"
    },
    {
        title: "APPOINTMENT OF PARTNER",
        href: "#partner-appointment"
    },
    {
        title: "PERFORMANCE STANDARDS & OBLIGATIONS",
        href: "#obligations"
    },
    {
        title: "LIABILITIES & LIMITATIONS",
        href: "#limitations"
    },
    {
        title: "PENALTIES FOR POOR OR FRAUDULENT DELIVERIES",
        href: "#penalties"
    },
    {
        title: "FRAUDULENT APPLICATIONS",
        href: "#fraudulent-applications"
    },
    {
        title: "TERMINATION OF AGREEMENT",
        href: "#agreement-termination"
    },
    {
        title: "CONFIDENTIALITY",
        href: "#confidentiality"
    },
    {
        title: "GOVERNED LAW",
        href: "#governed-law"
    },
    {
        title: "DISPUTE RESOLUTION",
        href: "#dispute-resolution"
    },
    {
        title: "AMENDMENTS",
        href: "#amendments"
    },
    {
        title: "ACKNOWLEDGE AND ACCEPTANCE",
        href: "#acceptance"
    }
]