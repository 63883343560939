import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import MissionSection from "../../component/MissionSection";
import ItumpMobile from "../../component/ItumpMobileSection";

const About = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-7 bg-light p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-xl-10">
              <div className="hero-heading-wrap mx-auto">
                <h2 data-aos="fade-up" data-aos-delay="50">
                  Empowering Business, Simplifying Operations
                </h2>
                <p data-aos="fade-up" data-aos-delay="100">
                  Itump was built as the fintech solution for businesses, we are
                  more than just a platform, we are a comprehensive support
                  network for businesses.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          className="map-thumb p-t-80 p-t-md-60 p-b-80 p-b-md-60 mx-auto d-none d-md-block"
          data-aos="zoom-in"
          data-aos-delay="150"
        >
          <img src="assets/img/thumb/map.png" alt="map img" className="w-100" />
        </div>
        <div className="container">
          <div className="counter-slider swiper p-b-120 p-b-lg-100 p-b-md-80 p-t-xs-40">
            <div className="counter-wrapper swiper-wrapper p-b-xs-20">
              <div
                className="counter-wrap swiper-slide text-center text-md-start"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <h3>1000+</h3>
                <p>Active Businesses</p>
              </div>
              <div
                className="counter-wrap swiper-slide text-center text-md-start"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3>6</h3>
                <p>Countries</p>
              </div>
              <div
                className="counter-wrap swiper-slide text-center text-md-start"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <h3>22</h3>
                <p>Employees Worldwide</p>
              </div>
              <div
                className="counter-wrap swiper-slide text-center text-md-start"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <h3>$500k</h3>
                <p>Saved in Transactions</p>
              </div>
            </div>
            <div className="swiper-pagination light d-flex d-md-none align-items-center justify-content-center position-static"></div>
          </div>
        </div>
        <div
          className="hero-banner-thumb px-4 px-md-0"
          data-aos="zoom-in"
          data-aos-delay="50"
        >
          <img
            src="assets/img/thumb/thumb-55.jpg"
            alt="banner img"
            className="w-100 object-fit-cover"
          />
        </div>
      </section>

      <section className="about-section p-t-140 p-t-lg-120 p-t-md-120 p-t-xs-120 p-b-140 p-b-lg-120 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="row align-items-center g-0">
            <div className="col-md-6">
              <div className="about-content common-title-wrap common-title-light p-r-100 p-r-xl-80 p-r-lg-60 p-r-md-30 p-r-xs-0 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Who We Are
                </h3>
                <p
                  className="common-title-body mb-4"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {" "}
                  Founded with a belief in the power of technology to transform
                  business processes, Itump set out to create a user-friendly
                  platform that addresses every stage of the business journey.
                  We combine deep industry expertise with cutting-edge
                  technology to ensure businesses can thrive without getting
                  lost in paperwork or complex regulations.
                </p>
                <Link
                  to="https://apps.apple.com/us/app/itump/id6443640069"
                  className="next-btn d-inline-flex align-items-center gap-2 fw-medium"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  Take the next steps for your Business
                  <i className="fa-solid fa-circle-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="about-thumb"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/thumb/about-thumb.png"
                  alt="about-thumb"
                  className="w-100 h-100"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* 
      <section className="mission-section p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="mission-section-title common-title-wrap text-center m-b-60 m-b-md-50 m-b-xs-40 p-b-60 p-b-md-50 p-b-xs-40">
            <h3 className="common-title" data-aos="fade-up" data-aos-delay="50">
              Our Mission and Values
            </h3>
            <p
              className="common-title-body"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Our mission is to empower entrepreneurs and established businesses
              alike with tools that streamline operations, enhance compliance,
              and support financial health.
            </p>
          </div>
          <div className="mission-step-slider ">
            <div className="mission-steps-wrap d-flex ">
              <div
                className="mission-steps "
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <h3>Empowerment Through Simplicity</h3>
              </div>
              <div
                className="mission-steps  paste"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <h3>Commitment to Compliance</h3>
              </div>
              <div
                className="mission-steps  green"
                data-aos="fade-up"
                data-aos-delay="250"
              >
                <h3>Innovation with Integrity</h3>
              </div>
              <div
                className="mission-steps  orange"
                data-aos="fade-up"
                data-aos-delay="350"
              >
                <h3>Customer-Centric Growth</h3>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <MissionSection />

      <section className="story-section position-relative overflow-hidden z-1 p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-140 p-b-xl-120 p-b-lg-100 p-b-md-80 p-b-xs-60 bg-light">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6">
              <div className="story-content common-title-wrap common-title-light h-100 p-r-60 p-r-lg-40 p-r-md-30 p-r-xs-0 p-b-xs-40">
                <h3
                  className="story-title common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Our Story
                </h3>
                <p
                  className="story-desc common-title-body mb-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Founded with a belief in the power of technology to transform
                  business processes, Itump set out to create a user-friendly
                  platform that addresses every stage of the business journey.
                </p>
                <p
                  className="story-desc common-title-body mb-0"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  We combine deep industry expertise with cutting-edge
                  technology to ensure businesses can thrive without getting
                  lost in paperwork or complex regulations.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="story-content common-title-wrap common-title-light h-100 p-l-60 p-l-lg-40 p-l-md-30 p-l-xs-0 p-t-xs-40 border-0">
                <h3
                  className="story-title common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Our Vision
                </h3>
                <p
                  className="story-desc common-title-body mb-0"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  We envision a world where managing a business is
                  straightforward, reliable, and efficient. Itump is committed
                  to creating a suite of services that not only meet the
                  administrative needs of modern businesses but also adapt to
                  future demands, supporting sustainable growth and stability.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="itump-mobile-section bg-light p-b-145 p-b-xl-120 p-b-lg-100 p-b-md-80 p-b-xs-40">
        <div className="container">
          <div
            className="get-started-wrap position-relative m-b-145 m-b-xl-120 m-b-lg-100 m-b-md-80 m-b-xs-65"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <Link to="/team">
              Meet the Team{" "}
              <img src="assets/img/icons/angles-right.svg" alt="angles right" />
            </Link>
          </div>

          <ItumpMobile className="style-light" />
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default About;
