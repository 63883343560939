import { useState, useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

const ComplianceCard = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [progress, setProgress] = useState(31); // Starts at 31 months
  const controls = useAnimation();

  useEffect(() => {
    if (isHovered) {
      controls.start({ width: "100%" }); // Progress bar animates to 100%

      let start = 50; // Progress bar starts at 50%
      const interval = setInterval(() => {
        if (start <= 100) {
          // Scale progress from 50% → 100% to 31 → 60 months
          const mappedValue = Math.round(31 + ((start - 50) / 50) * (60 - 31));
          setProgress(mappedValue);
          start += 1;
        } else {
          clearInterval(interval);
        }
      }, 20); // Speed of text update

      return () => clearInterval(interval);
    } else {
      setProgress(31);
      controls.start({ width: "50%" }); // Reset progress bar to 50%
    }
  }, [isHovered, controls]);

  return (
    <div
      className="compliance-card two growth-item-4 m-b-25 m-b-md-15"
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
      style={{ position: "relative" }}
    >
      {/* Card Content */}
      <div className="growth-item-content m-b-60 m-b-md-40 m-b-xs-25">
        <p className="growth-item-title">Progress Dashboards</p>
        <p className="growth-item-body">
          View real-time team progress towards goals and track milestones
        </p>
      </div>

      {/* Progress Bar + Other Elements */}
      <div
        className="growth-item-thumb style-2 d-flex justify-content-center"
        style={{ gap: "28px", position: "relative", zIndex: "1" }}
      >
        {/* Progress Bar */}
        <div className="bar-div">
          <div className="progress-container">
            <motion.div
              className="progress-bar"
              initial={{ width: "50%" }}
              animate={controls}
              transition={{ duration: 0.5 }}
            />
          </div>
          <p>
            {progress} <span>of</span> 60 Month Plan
          </p>
        </div>

        {/* Other Elements */}
        <div className="flag">
          <img src="assets/img/shape/flag.svg" alt="flag" />
        </div>
        <img
          src="assets/img/shape/progress-dashboard-line.svg"
          alt="line"
          style={{
            position: "absolute",
            top: "51px",
            right: "69px",
            width: "250px",
          }}
        />
      </div>

      {/* Hover Effect Background */}
      <motion.div
        className="net"
        initial={{ opacity: 0 }}
        animate={{ opacity: isHovered ? 1 : 0 }}
        transition={{ duration: 0.5 }}
      >
        <img
          src="assets/img/bg/progress-dashboard.svg"
          alt="bg"
          style={{
            height: "100%",
            objectFit: "cover",
            width: "100%",
          }}
        />
      </motion.div>
    </div>
  );
};

export default ComplianceCard;
