import { useEffect, useState, useCallback } from "react";
import { Link, Outlet } from "react-router";
import {useMediaQuery} from '@react-hook/media-query'
import { AnimatePresence, motion } from "motion/react";
import { TfiAngleUp } from "react-icons/tfi";
import { RxHamburgerMenu } from "react-icons/rx";
import AgreementsSidebar from "../../component/guidelines/AgreementsSidebar";
import "./agreements.css";

const AgreementsLayout = () => {
    const matches = useMediaQuery("only screen and (max-width: 1024px)");

    // for scroll to top btn
    const [isVisible, setIsVisible] = useState(false);
    // for menu open on mobile
    const [openMenu, setOpenMenu] = useState(false);

    useEffect(() => {
        if (matches)
            setOpenMenu(false);
    }, [matches]);

    useEffect(() => {
        const toggleVisibility = () => {
            if (window.scrollY > 200) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
    
        window.addEventListener("scroll", toggleVisibility);

        return () => {
            window.removeEventListener("scroll", toggleVisibility);
        };
    }, []);

    const scrollToTop = useCallback(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, []);

    return (
        <div
            className="p-4 p-md-0"
            style={{
                backgroundColor: "white",
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh"
            }}
        >
            {/* Header */}
            <header
                className="container"
                style={{
                    backgroundColor: "white",
                    padding: "50px 0",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div className="header-logo">
                        <Link to="/">
                            <img src="../../assets/img/logo/logo-white.svg" alt="logo" />
                        </Link>
                    </div>
                </div>
                <button
                    className="open-menu-btn"
                    onClick={() => setOpenMenu(true)}
                >
                    <RxHamburgerMenu size={30} />
                </button>
                <button
                    className="lng-button"
                    style={{
                        marginLeft: "auto",
                        backgroundColor: "#f5f5f5",
                        border: "none",
                        borderRadius: "8px",
                        padding: "8px 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                    }}
                >
                    <span>🌐</span> English (EN)
                    <span style={{ fontSize: "12px" }}>▼</span>
                </button>
            </header>
            {/* Main Content Section */}
            <main
                className="position-relative container d-block d-sm-flex"
                style={{
                    backgroundColor: "white",
                    padding: "25px 0",
                    flex: "1",
                    justifyContent: "space-between",
                    gap: "20px"
                }}
            >
                <div className="main-outlet">
                    <Outlet />
                </div>
                {/* Sidebar */}
                <AgreementsSidebar
                    openMenu={openMenu}
                    setOpenMenu={setOpenMenu}
                />
                <AnimatePresence>
                    {isVisible ? (
                        <motion.button
                            key="scroll-to-top-btn"
                            className="scrollToTopBtn"
                            onClick={scrollToTop}
                            initial={{
                                opacity: 1,
                                y: 0
                            }}
                            exit={{
                                opacity: 0,
                                y: 10
                            }}
                        >
                            <TfiAngleUp />
                        </motion.button>
                    ): null}
                </AnimatePresence>
            </main>
        </div>
    )
}
 
export default AgreementsLayout