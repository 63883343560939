import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
const Blog = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="growth-section p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-80 p-b-xs-60 overflow-hidden">
        <div className="container">
          <div className="row growth-row m-b-140 m-b-md-120 m-b-xs-120">
            <div className="col-md-6">
              <div className="growth-contents-2">
                <div className="info">
                  <h4
                    className="subtitle"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Startup Support
                  </h4>
                  <h3 className="title" data-aos="fade-up" data-aos-delay="100">
                    Building the Right Team for Your Startup
                  </h3>
                  <p data-aos="fade-up" data-aos-delay="150">
                    Tips for hiring and managing a remote or hybrid team as a
                    new business owner.
                  </p>
                </div>
                <div className="author-read-wrap">
                  <div
                    className="author-wrap"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <div className="author-icon">
                      <img src="assets/img/thumb/thumb-88.png" alt="thumb" />
                    </div>
                    <div className="author-info">
                      <h4>Olivia Rhye</h4>
                      <p>20 Jan 2024</p>
                    </div>
                  </div>
                  <div
                    className="learn-more-btn-2"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <Link to="#">
                      Learn More
                      <span className="icon">
                        <i className="fa fa-arrow-right-long"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="growth-content-thumb"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <img src="assets/img/thumb/thumb-89.png" alt="thumb" />
              </div>
            </div>
          </div>
          <div className="editors-text position-relative m-b-60 m-b-xs-40">
            <h4
              className="position-relative z-1 d-inline-block"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Editor’s Picks
            </h4>
          </div>
          <div className="editors-slider swiper">
            <div className="editors-slider-wrapper swiper-wrapper">
              <Swiper
                spaceBetween={34}
                slidesPerView="auto"
                modules={[Autoplay]}
                loop
                speed={5000}
                autoplay
                // allowTouchMove={false}
                // disableOnInteraction
              >
                <SwiperSlide
                  className="editors-content-wrap swiper-slide"
                  // data-aos="fade-up"
                  data-aos-delay="100"
                  style={{ width: "360px" }}
                >
                  <div className="tag">Digital Tools</div>
                  <h3>Tap, Scan, Pay: Revolutionizing Business Payments</h3>
                  <p>
                    How Itump’s Tap to Pay and Scan to Pay are simplifying
                    transactions.
                  </p>
                  <div className="author-wrap">
                    <div className="author-icon">
                      <img src="assets/img/thumb/thumb-88.png" alt="thumb" />
                    </div>
                    <div className="info">
                      <h4>Olivia Rhye</h4>
                      <p>20 Jan 2024</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className="editors-content-wrap swiper-slide"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  style={{ width: "360px" }}
                >
                  <div className="tag">Digital Tools</div>
                  <h3>
                    Why Every Entrepreneur Needs a Comprehensive Digital Wallet
                  </h3>
                  <p>
                    Explore how digital wallets can make managing business
                    finances easier tha...
                  </p>
                  <div className="author-wrap">
                    <div className="author-icon">
                      <img src="assets/img/thumb/thumb-88.png" alt="thumb" />
                    </div>
                    <div className="info">
                      <h4>Olivia Rhye</h4>
                      <p>20 Jan 2024</p>
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide
                  className="editors-content-wrap swiper-slide"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  style={{ width: "360px" }}
                >
                  <div className="tag">Digital Tools</div>
                  <h3>Is DIY Business Registration Right for You?</h3>
                  <p>
                    Weighing the pros and cons of registering your business
                    independently.
                  </p>
                  <div className="author-wrap">
                    <div className="author-icon">
                      <img src="assets/img/thumb/thumb-88.png" alt="thumb" />
                    </div>
                    <div className="info">
                      <h4>Olivia Rhye</h4>
                      <p>20 Jan 2024</p>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="swiper-pagination style-2 m-t-40 d-flex d-md-none align-items-center justify-content-center position-static"></div>
          </div>
        </div>
        <div className="s-shape-1">
          <img src="assets/img/shape/shape-49.png" alt="shape" />
        </div>
        <div className="s-shape-2 d-none d-md-block">
          <img src="assets/img/shape/shape-50.png" alt="shape" />
        </div>
      </section>

      <section className="blog-section bg-light p-t-60 p-b-60">
        <div className="container">
          <div className="row m-b-15">
            <div className="container">
              <div className="col-xl-12">
                <div className="blog-tab">
                  <ul
                    className="nav nav-tabs m-b-40 flex-nowrap overflow-auto"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="all-tab-pane"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="global-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#global-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="global-tab-pane"
                        aria-selected="false"
                      >
                        Global Trends
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="startup-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#startup-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="startup-tab-pane"
                        aria-selected="false"
                      >
                        Startup Support
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="finance-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#finance-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="finance-tab-pane"
                        aria-selected="false"
                      >
                        Finance & Compliance
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="entrepreneurial-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#entrepreneurial-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="entrepreneurial-tab-pane"
                        aria-selected="false"
                      >
                        Entrepreneurial Growth
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="digital-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#digital-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="digital-tab-pane"
                        aria-selected="false"
                      >
                        Digital Tools
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="business-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#business-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="business-tab-pane"
                        aria-selected="false"
                      >
                        Business Tips
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all-tab-pane"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="50"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-90.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="100"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-91.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Navigating EIN Applications: A Complete Guide
                              </Link>
                            </h3>
                            <p>
                              Simplify the process of getting an EIN with our
                              step-by-step guide.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="150"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-92.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="200"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-93.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Why Every Entrepreneur Needs a Comprehensive
                                Digital Wallet
                              </Link>
                            </h3>
                            <p>
                              Explore how digital wallets can make managing
                              business finances easier than ever.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="250"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-94.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                How Annual Filings Affect Your Business
                                Compliance
                              </Link>
                            </h3>
                            <p>
                              Avoid penalties and stay compliant with our annual
                              filing overview.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="300"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-95.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Is DIY Business Registration Right for You?
                              </Link>
                            </h3>
                            <p>
                              Weighing the pros and cons of registering your
                              business independently.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="350"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-96.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                The Top 10 Mistakes Small Business Owners Make
                              </Link>
                            </h3>
                            <p>
                              Learn from others’ missteps to keep your business
                              on the path to success.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="400"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-97.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Tap, Scan, Pay: Revolutionizing Business
                                Payments
                              </Link>
                            </h3>
                            <p>
                              How Itump’s Tap to Pay and Scan to Pay are
                              simplifying transactions.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-lg-4 col-md-6 m-b-45"
                          data-aos="fade-up"
                          data-aos-delay="450"
                        >
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-98.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Understanding the Importance of a Registered
                                Agent
                              </Link>
                            </h3>
                            <p>
                              Discover why having a registered agent is critical
                              for compliance and communication.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="global-tab-pane"
                      role="tabpanel"
                      aria-labelledby="global-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-90.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="/blog">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="/blog">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-91.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Navigating EIN Applications: A Complete Guide
                              </Link>
                            </h3>
                            <p>
                              Simplify the process of getting an EIN with our
                              step-by-step guide.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-92.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-93.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Why Every Entrepreneur Needs a Comprehensive
                                Digital Wallet
                              </Link>
                            </h3>
                            <p>
                              Explore how digital wallets can make managing
                              business finances easier than ever.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-94.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                How Annual Filings Affect Your Business
                                Compliance
                              </Link>
                            </h3>
                            <p>
                              Avoid penalties and stay compliant with our annual
                              filing overview.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-95.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Is DIY Business Registration Right for You?
                              </Link>
                            </h3>
                            <p>
                              Weighing the pros and cons of registering your
                              business independently.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-96.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                The Top 10 Mistakes Small Business Owners Make
                              </Link>
                            </h3>
                            <p>
                              Learn from others’ missteps to keep your business
                              on the path to success.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-97.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Tap, Scan, Pay: Revolutionizing Business
                                Payments
                              </Link>
                            </h3>
                            <p>
                              How Itump’s Tap to Pay and Scan to Pay are
                              simplifying transactions.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-98.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Understanding the Importance of a Registered
                                Agent
                              </Link>
                            </h3>
                            <p>
                              Discover why having a registered agent is critical
                              for compliance and communication.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="startup-tab-pane"
                      role="tabpanel"
                      aria-labelledby="startup-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-90.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-95.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Is DIY Business Registration Right for You?
                              </Link>
                            </h3>
                            <p>
                              Weighing the pros and cons of registering your
                              business independently.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="finance-tab-pane"
                      role="tabpanel"
                      aria-labelledby="finance-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-91.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Navigating EIN Applications: A Complete Guide
                              </Link>
                            </h3>
                            <p>
                              Simplify the process of getting an EIN with our
                              step-by-step guide.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-94.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                How Annual Filings Affect Your Business
                                Compliance
                              </Link>
                            </h3>
                            <p>
                              Avoid penalties and stay compliant with our annual
                              filing overview.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-98.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Understanding the Importance of a Registered
                                Agent
                              </Link>
                            </h3>
                            <p>
                              Discover why having a registered agent is critical
                              for compliance and communication.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="entrepreneurial-tab-pane"
                      role="tabpanel"
                      aria-labelledby="entrepreneurial-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-92.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-96.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                The Top 10 Mistakes Small Business Owners Make
                              </Link>
                            </h3>
                            <p>
                              Learn from others’ missteps to keep your business
                              on the path to success.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="digital-tab-pane"
                      role="tabpanel"
                      aria-labelledby="digital-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-93.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Why Every Entrepreneur Needs a Comprehensive
                                Digital Wallet
                              </Link>
                            </h3>
                            <p>
                              Explore how digital wallets can make managing
                              business finances easier than ever.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-97.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Tap, Scan, Pay: Revolutionizing Business
                                Payments
                              </Link>
                            </h3>
                            <p>
                              How Itump’s Tap to Pay and Scan to Pay are
                              simplifying transactions.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="business-tab-pane"
                      role="tabpanel"
                      aria-labelledby="business-tab"
                      tabindex="0"
                    >
                      <div className="row m-b-15">
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-90.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-91.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Navigating EIN Applications: A Complete Guide
                              </Link>
                            </h3>
                            <p>
                              Simplify the process of getting an EIN with our
                              step-by-step guide.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-92.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                5 Steps to Building Business Credit from Day One
                              </Link>
                            </h3>
                            <p>
                              Build your business’s financial foundation with
                              the essentials for establishing strong credit.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-93.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Why Every Entrepreneur Needs a Comprehensive
                                Digital Wallet
                              </Link>
                            </h3>
                            <p>
                              Explore how digital wallets can make managing
                              business finances easier than ever.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-94.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                How Annual Filings Affect Your Business
                                Compliance
                              </Link>
                            </h3>
                            <p>
                              Avoid penalties and stay compliant with our annual
                              filing overview.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-95.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag">
                              <Link to="#">Startup Support</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Is DIY Business Registration Right for You?
                              </Link>
                            </h3>
                            <p>
                              Weighing the pros and cons of registering your
                              business independently.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-96.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag green">
                              <Link to="#">Entrepreneurial Growth</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                The Top 10 Mistakes Small Business Owners Make
                              </Link>
                            </h3>
                            <p>
                              Learn from others’ missteps to keep your business
                              on the path to success.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-97.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag purple">
                              <Link to="#">Digital Tools</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Tap, Scan, Pay: Revolutionizing Business
                                Payments
                              </Link>
                            </h3>
                            <p>
                              How Itump’s Tap to Pay and Scan to Pay are
                              simplifying transactions.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-4 m-b-45">
                          <div className="blog-content">
                            <div className="thumb">
                              <Link to="#">
                                <img
                                  src="assets/img/thumb/thumb-98.png"
                                  alt="thumb"
                                />
                              </Link>
                            </div>
                            <div className="tag blue">
                              <Link to="#">Finance & Compliance</Link>
                            </div>
                            <h3>
                              <Link to="#">
                                Understanding the Importance of a Registered
                                Agent
                              </Link>
                            </h3>
                            <p>
                              Discover why having a registered agent is critical
                              for compliance and communication.
                            </p>
                            <div className="author-read-wrap">
                              <div className="author-wrap">
                                <div className="author-icon">
                                  <img
                                    src="assets/img/thumb/thumb-88.png"
                                    alt="thumb"
                                  />
                                </div>
                                <div className="info">
                                  <h4>Olivia Rhye</h4>
                                  <p>20 Jan 2024</p>
                                </div>
                              </div>
                              <div className="learn-more-2">
                                <Link to="#">
                                  Read More
                                  <span className="icon">
                                    <i className="fa-light fa-arrow-right-long"></i>
                                  </span>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row text-center">
            <div className="col-xl-12">
              <div
                className="learn-more-btn style-2"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login">Learn More</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="cta-section-2 bg-light p-b-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="cta-content-wrap text-center">
            <div className="cta-content mx-auto">
              <h3 data-aos="fade-up" data-aos-delay="50">
                Get Started
              </h3>
              <p data-aos="fade-up" data-aos-delay="100">
                Start your journey to smarter business management and growth
                with Itump's easy-to-use tools.
              </p>
              <div
                className="cta-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-btn get-started-btn-2">
                  <Link to="https://boi.itump.com/login">Get Started</Link>
                </div>
                <div className="cta-btn learn-more-btn style-3">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default Blog;
