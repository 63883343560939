
const TableOfContent = ({ contents }) => {
    return (
        <ol
            style={{
                paddingLeft: "25px",
                marginBottom: "15px",
            }}
        >
            {contents.map((content, index) => (
                <li
                    key={index}
                    style={{
                        marginBottom: "10px",
                        color: "#7C4DFF"
                    }}
                >
                    <a
                        style={{
                            textDecoration: "none",
                            color: "#7C4DFF"
                        }}
                        href={content.href}
                    >
                        {content.title}
                    </a>
                </li>
            ))}
        </ol>
    )
}
 
export default TableOfContent