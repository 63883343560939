import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";

const ServiceRegister = () => {
  const faqs = [
    {
      id: "one",
      question: "How long does it take to register a new business?",
      answer:
        "The time it takes to register a business varies depending on the state and complexity of your business structure. However, with Itump, the process is streamlined and typically takes just a few weeks.",
    },
    {
      id: "two",
      question: "Do I need a registered agent?",
      answer:
        "Yes, in most states, you'll need a registered agent. This is a person or entity designated to receive legal and official mail on behalf of your business. Itump can help you find a reliable registered agent service if needed.",
    },
    {
      id: "three",
      question: "Can I register a business if I'm not based in the U.S.?",
      answer:
        "Absolutely! Itump makes it possible to register a U.S. business from anywhere in the world. We'll guide you through the process and help you meet all the requirements.",
    },
    {
      id: "four",
      question: "What support is available after my business is registered?",
      answer:
        "Itump provides ongoing support even after your business is registered. We offer resources and guidance on compliance, taxes, and other aspects of running your business.",
    },
    {
      id: "five",
      question: "What business structures can I choose from?",
      answer:
        "You can choose from various business structures, including sole proprietorship, partnership, LLC, and corporation. Itump can help you determine the best structure for your needs.",
    },
    {
      id: "six",
      question: "Do I need an EIN to register my business?",
      answer:
        "An EIN (Employer Identification Number) is required for most businesses, especially if you plan to hire employees or operate as a corporation or partnership. Itump can help you obtain an EIN.",
    },
    {
      id: "seven",
      question: "Can I register my business in any state?",
      answer:
        "Yes, you can choose to register your business in any U.S. state. Itump can help you understand the specific requirements and regulations for each state.",
    },
    {
      id: "eight",
      question: "What documents do I need to provide?",
      answer:
        "The required documents vary depending on your business structure and the state you choose. Itump will guide you through the process and help you gather the necessary documents.",
    },
    {
      id: "nine",
      question: "Can I change my business structure later?",
      answer:
        "Yes, you can change your business structure later if needed. However, this involves legal and administrative procedures. Itump can help you with this process.",
    },
    {
      id: "ten",
      question: "What happens after my business is registered?",
      answer:
        "Once your business is registered, you'll receive a confirmation and any necessary documentation. You can then start operating your business legally. Itump will continue to support you with compliance, taxes, and other business needs.",
    },
  ];
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-2 service-hero service-register-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-230 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-60">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  REGISTER NEW BUSINESS
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Start Your Journey with Ease
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Get your business up and running with our simple, guided
                  registration process
                </p>
              </div>
              <div
                className="hero-btn-wrap hero-2-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="250"
              >
                <img src="assets/img/thumb/thumb-39.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-6.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-7.png" alt="shape" />
        </div>
      </section>

      <section className="service-card-section p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>Requirements</h3>
                </div>
                <div
                  className="service-card-thumb m-b-80"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-32.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-1"
                        aria-expanded="false"
                        aria-controls="service-item-coll-1"
                      >
                        <span>Business Name</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-1"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-2"
                        aria-expanded="false"
                        aria-controls="service-item-coll-2"
                      >
                        <span>Business Structure</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-2"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-3"
                        aria-expanded="false"
                        aria-controls="service-item-coll-3"
                      >
                        <span>Registered Agent</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-3"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-4"
                        aria-expanded="false"
                        aria-controls="service-item-coll-4"
                      >
                        <span>Business Address</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-4"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-5"
                        aria-expanded="false"
                        aria-controls="service-item-coll-5"
                      >
                        <span>Owner Information</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-5"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-6"
                        aria-expanded="false"
                        aria-controls="service-item-coll-6"
                      >
                        <span>EIN (Employer Identification Number)</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-6"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-1">
                  <img src="assets/img/shape/shape-10.png" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>What You Get</h3>
                </div>
                <div
                  className="service-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-33.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content m-t-35"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-7"
                        aria-expanded="false"
                        aria-controls="service-item-coll-7"
                      >
                        <span>Official Business Registration</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-7"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-8"
                        aria-expanded="false"
                        aria-controls="service-item-coll-8"
                      >
                        <span>EIN Acquisition</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-8"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-9"
                        aria-expanded="false"
                        aria-controls="service-item-coll-9"
                      >
                        <span>Compliance Support</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-9"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-10"
                        aria-expanded="false"
                        aria-controls="service-item-coll-10"
                      >
                        <span>Digital Copies of Documents</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-10"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-11"
                        aria-expanded="false"
                        aria-controls="service-item-coll-11"
                      >
                        <span>Registered Agent Service</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-11"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-12"
                        aria-expanded="false"
                        aria-controls="service-item-coll-12"
                      >
                        <span>Business Bank Account Setup Assistance</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-12"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-2">
                  <img src="assets/img/shape/shape-11.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <section className="industry-solution">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="common-title-wrap m-b-70">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Why Register with Itump?
                </h3>
                <p
                  className="common-title-body"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Let Us Handle Your Business Registration
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap file-with-us-wrap register-card-wrapper m-b-65 m-b-xs-40">
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40.1335 27L8.5335 27C5.5335 27 4.3335 28.28 4.3335 31.46L4.3335 39.54C4.3335 42.72 5.5335 44 8.5335 44L40.1335 44C43.1335 44 44.3335 42.72 44.3335 39.54L44.3335 31.46C44.3335 28.28 43.1335 27 40.1335 27Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M26.1335 4L8.5335 4C5.5335 4 4.3335 5.28 4.3335 8.46L4.3335 16.54C4.3335 19.72 5.5335 21 8.5335 21L26.1335 21C29.1335 21 30.3335 19.72 30.3335 16.54L30.3335 8.46C30.3335 5.28 29.1335 4 26.1335 4Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Seamless Process
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Streamlined steps to get your business registered quickly
                      and efficiently
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M32 4H16C9 4 6 8 6 14V34C6 40 9 44 16 44H32C39 44 42 40 42 34V14C42 8 39 4 32 4ZM16 24.5H24C24.82 24.5 25.5 25.18 25.5 26C25.5 26.82 24.82 27.5 24 27.5H16C15.18 27.5 14.5 26.82 14.5 26C14.5 25.18 15.18 24.5 16 24.5ZM32 35.5H16C15.18 35.5 14.5 34.82 14.5 34C14.5 33.18 15.18 32.5 16 32.5H32C32.82 32.5 33.5 33.18 33.5 34C33.5 34.82 32.82 35.5 32 35.5ZM37 18.5H33C29.96 18.5 27.5 16.04 27.5 13V9C27.5 8.18 28.18 7.5 29 7.5C29.82 7.5 30.5 8.18 30.5 9V13C30.5 14.38 31.62 15.5 33 15.5H37C37.82 15.5 38.5 16.18 38.5 17C38.5 17.82 37.82 18.5 37 18.5Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Compliance Assurance
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Ensure your business meets all local, state, and federal
                      regulations
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="49"
                      height="48"
                      viewBox="0 0 49 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24.667 30C32.1228 30 38.167 24.1797 38.167 17C38.167 9.8203 32.1228 4 24.667 4C17.2111 4 11.167 9.8203 11.167 17C11.167 24.1797 17.2111 30 24.667 30Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M32.247 31.2199C32.907 30.8799 33.667 31.3799 33.667 32.1199V41.8199C33.667 43.6199 32.407 44.4999 30.847 43.7599L25.487 41.2199C25.027 41.0199 24.307 41.0199 23.847 41.2199L18.487 43.7599C16.927 44.4799 15.667 43.5999 15.667 41.7999L15.707 32.1199C15.707 31.3799 16.487 30.8999 17.127 31.2199C19.387 32.3599 21.947 32.9999 24.667 32.9999C27.387 32.9999 29.967 32.3599 32.247 31.2199Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Expert Guidance
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Access to our knowledgeable support team for any questions
                      or assistance
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.0801 8.23993L26.0801 4.11993C24.9401 3.69993 23.0801 3.69993 21.9401 4.11993L10.9401 8.23993C8.8201 9.03993 7.1001 11.5199 7.1001 13.7799V29.9799C7.1001 31.5999 8.1601 33.7399 9.4601 34.6999L20.4601 42.9199C22.4001 44.3799 25.5801 44.3799 27.5201 42.9199L38.5201 34.6999C39.8201 33.7199 40.8801 31.5999 40.8801 29.9799V13.7799C40.9001 11.5199 39.1801 9.03993 37.0801 8.23993ZM30.9601 19.4399L22.3601 28.0399C22.0601 28.3399 21.6801 28.4799 21.3001 28.4799C20.9201 28.4799 20.5401 28.3399 20.2401 28.0399L17.0401 24.7999C16.4601 24.2199 16.4601 23.2599 17.0401 22.6799C17.6201 22.0999 18.5801 22.0999 19.1601 22.6799L21.3201 24.8399L28.8601 17.2999C29.4401 16.7199 30.4001 16.7199 30.9801 17.2999C31.5601 17.8799 31.5601 18.8599 30.9601 19.4399Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Secure Document Management
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Safely store and manage all your registration documents in
                      one place
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M36 4H12C8.68 4 6 6.66 6 9.94V31.76C6 35.04 8.68 37.7 12 37.7H13.52C15.12 37.7 16.64 38.32 17.76 39.44L21.18 42.82C22.74 44.36 25.28 44.36 26.84 42.82L30.26 39.44C31.38 38.32 32.92 37.7 34.5 37.7H36C39.32 37.7 42 35.04 42 31.76V9.94C42 6.66 39.32 4 36 4ZM20.76 26.02C21.58 26.02 22.26 26.7 22.26 27.52C22.26 28.34 21.58 29.02 20.76 29.02H15.4C14.52 29.02 13.7 28.6 13.18 27.88C12.68 27.2 12.56 26.36 12.8 25.56C13.5 23.42 15.22 22.26 16.74 21.22C18.34 20.14 19.24 19.46 19.24 18.3C19.24 17.26 18.4 16.42 17.36 16.42C16.32 16.42 15.5 17.28 15.5 18.32C15.5 19.14 14.82 19.82 14 19.82C13.18 19.82 12.5 19.14 12.5 18.32C12.5 15.64 14.68 13.44 17.38 13.44C20.08 13.44 22.26 15.62 22.26 18.32C22.26 21.14 20.14 22.58 18.44 23.74C17.38 24.46 16.38 25.14 15.88 26.04H20.76V26.02ZM34 26.16H33.58V27.54C33.58 28.36 32.9 29.04 32.08 29.04C31.26 29.04 30.58 28.36 30.58 27.54V26.16H26.66C26.66 26.16 26.66 26.16 26.64 26.16C25.66 26.16 24.76 25.64 24.26 24.8C23.76 23.94 23.76 22.88 24.26 22.04C25.62 19.7 27.2 17.04 28.64 14.72C29.28 13.7 30.5 13.24 31.64 13.56C32.78 13.9 33.58 14.94 33.56 16.14V23.18H34C34.82 23.18 35.5 23.86 35.5 24.68C35.5 25.5 34.82 26.16 34 26.16Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M30.5801 23.16V17.28C29.4001 19.2 28.1801 21.26 27.0801 23.14H30.5801V23.16Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Ongoing Support
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Continuous access to resources and tools to help your
                      business grow
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="steps-to-register-section p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="common-title-wrap m-b-125 m-b-lg-80 m-b-md-60 m-b-xs-40">
                <h2
                  className="common-title mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Steps to Register Your Business
                </h2>
              </div>
            </div>
          </div>
          <div
            className="step-container position-relative"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <div className="step-wrapper register-steps-wrap d-flex flex-column position-relative z-1">
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number with-border fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    1
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Choose Your Business Structure
                    </h3>
                    <p className="step-desc mb-0">
                      {" "}
                      Select the entity type that best suits your business needs
                      (LLC, Corporation, Sole Proprietorship, etc.).
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/business-stucture.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    2
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Provide Essential Information
                    </h3>
                    <p className="step-desc mb-0">
                      Enter basic details about your business, such as the name,
                      address, and owner information.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/business-info.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    3
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      File Required Documents
                    </h3>
                    <p className="step-desc mb-0">
                      Upload and file all necessary documents, including
                      Articles of Incorporation, and more.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/documents.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    4
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Obtain Necessary Licenses and Permits
                    </h3>
                    <p className="step-desc mb-0">
                      Identify and apply for any licenses or permits required
                      for your business type and location.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/license.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div className="step-content-wrap d-flex">
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    5
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Receive Confirmation
                    </h3>
                    <p className="step-desc mb-0">
                      Once all documents are processed, receive your official
                      business registration and begin operating.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/confirmation.png"
                  alt="step thumb"
                  className="step-thumb"
                />
              </div>
            </div>
            <div className="step-shape h-100">
              <img
                src="assets/img/shape/step-border-shape.png"
                alt="border"
                className="h-100"
              />
            </div>
          </div>
          <div
            className="text-center p-t-175 p-t-xl-140 p-t-lg-100 p-t-md-80 p-t-xs-60"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <Link to="https://boi.itump.com/login" className="theme-btn">
              Get Started <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="s-step-shape-1">
          <img src="assets/img/shape/shape-2.png" alt="shape" />
        </div>
      </div>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end  */}

      <FAQSection faqs={faqs} />

      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceRegister;
