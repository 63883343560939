import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";

const ServiceAnnual = () => {
  const faqs = [
    {
      id: "one",
      question: "Is the annual filing process the same for all business types?",
      answer:
        "No, the annual filing process can vary depending on your business structure (sole proprietorship, LLC, corporation, etc.), the state where you're registered, and your industry. Itump can help you understand the specific requirements for your business.",
    },
    {
      id: "two",
      question: "What documents do I need for annual filing?",
      answer:
        "The required documents vary, but you'll typically need:\n\n" +
        "- Your business's identification number (e.g., EIN)\n" +
        "- Information about your business's officers and directors\n" +
        "- Financial statements (if applicable)\n" +
        "- Any required licenses or permits\n\n" +
        "Itump can help you determine the specific documents you need and keep them organized.",
    },
    {
      id: "three",
      question: "What happens if I file my annual report late?",
      answer:
        "Late filing can result in penalties, late fees, or even the loss of your business's good standing. It's important to file on time. Itump can help you track deadlines and avoid late filing.",
    },
    {
      id: "four",
      question: "Can I file my annual report early?",
      answer:
        "Yes, you can usually file your annual report early. Itump can help you prepare your documents and submit your filing ahead of the deadline.",
    },
    {
      id: "five",
      question: "Will I be notified when my annual filing is due?",
      answer:
        "Yes, Itump will send you reminders and notifications about upcoming annual filing deadlines.",
    },
    {
      id: "six",
      question:
        "Can I change my registered agent during the annual filing process?",
      answer:
        "Yes, you can typically change your registered agent during the annual filing process. Itump can help you with this process if needed.",
    },
    {
      id: "seven",
      question: "Is there a grace period for annual filing?",
      answer:
        "The grace period, if any, varies depending on the state and the type of filing. It's best to file on time to avoid penalties. Itump can help you understand any applicable grace periods.",
    },
  ];
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-2 service-hero service-annual-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-245 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  ANNUAL FILING
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Stay Compliant with Annual Filings
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Ensure your business remains in good standing with timely and
                  accurate annual filings.
                </p>
              </div>
              <div>
                <Link
                  to="https://boi.itump.com/login"
                  className="theme-btn"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <img src="assets/img/thumb/thumb-49.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-24.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-25.png" alt="shape" />
        </div>
      </section>

      <section className="annual-section p-b-240 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="annual-content">
            <h3 data-aos="fade-up" data-aos-delay="50">
              Understanding Annual Filing{" "}
            </h3>
            <p data-aos="fade-up" data-aos-delay="100">
              Annual filing is a mandatory requirement for most businesses,
              including corporations and LLCs, to submit yearly reports to their
              state. These filings typically include information about the
              company's current structure, ownership, and financial status.
              Staying compliant with annual filing requirements helps maintain
              your business's good standing and avoid penalties or dissolution.
            </p>
          </div>
        </div>
      </section>

      <section className="industry-solution">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Why Annual Filing is Important
                </h3>
                <p
                  className="common-title-body"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Stay compliant, maintain your good standing, and keep your
                  business records up-to-date with timely annual filings.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap file-with-us-wrap copyright-card-wrapper m-b-65 m-b-xs-40">
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img src="assets/img/icons/buildings-2.svg" alt="" />
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Legal Compliance
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Avoid fines, penalties, and potential business dissolution
                      by adhering to state-mandated annual filing requirements
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img
                      src="assets/img/icons/activity-icon.svg"
                      alt="activity-icon"
                    />
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Maintain Good Standing
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Keep your business in good standing with the state to
                      ensure eligibility for contracts, loans, and expansion
                      opportunities
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <img
                      src="assets/img/icons/document-text.svg"
                      alt="document-text"
                    />
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Updated Records
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Ensure that your company’s information on public record is
                      accurate and up-to-date, reflecting any changes in
                      operations
                    </p>
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="service-card-section p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>Requirements</h3>
                </div>
                <div
                  className="service-card-thumb m-b-80"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-32.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-1"
                        aria-expanded="false"
                        aria-controls="service-item-coll-1"
                      >
                        <span>Business Name and Address</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-1"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-2"
                        aria-expanded="false"
                        aria-controls="service-item-coll-2"
                      >
                        <span>
                          Social Security Number (SSN) or Individual Taxpayer
                          Identification Number (ITIN)
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-2"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-3"
                        aria-expanded="false"
                        aria-controls="service-item-coll-3"
                      >
                        <span>Legal Structure of the Business</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-3"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-1">
                  <img src="assets/img/shape/shape-10.png" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>What You Get</h3>
                </div>
                <div
                  className="service-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-33.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content m-t-35"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-4"
                        aria-expanded="false"
                        aria-controls="service-item-coll-4"
                      >
                        <span>Employer Identification Number (EIN)</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-4"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-5"
                        aria-expanded="false"
                        aria-controls="service-item-coll-5"
                      >
                        <span>Official IRS documentation</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-5"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-6"
                        aria-expanded="false"
                        aria-controls="service-item-coll-6"
                      >
                        <span>Compliance with tax regulations</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-6"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-7"
                        aria-expanded="false"
                        aria-controls="service-item-coll-7"
                      >
                        <span>
                          Ability to open business bank accounts and hire
                          employees
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-7"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-2">
                  <img src="assets/img/shape/shape-11.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <section className="steps-to-register-section annual-filling-step-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="common-title-wrap m-b-125 m-b-lg-80 m-b-md-60 m-b-xs-40">
                <h2
                  className="common-title mb-0"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  How It Works
                </h2>
              </div>
            </div>
          </div>
          <div className="step-container annual-filling-step-container position-relative">
            <div className="step-wrapper register-steps-wrap d-flex flex-column position-relative z-1">
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div
                  className="step-content-wrap d-flex"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <span className="step-number with-border fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    1
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">
                      Submit Your Request
                    </h3>
                    <p className="step-desc mb-0">
                      Fill out our online form with the necessary information.
                      Start the process quickly and easily from any device.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/annual-filling-step-1.png"
                  alt="step thumb"
                  className="step-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div
                  className="step-content-wrap d-flex"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    2
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">Review and File</h3>
                    <p className="step-desc mb-0">
                      Our experts review your submission and prepare the
                      documents. We ensure everything is accurate and compliant
                      with state laws.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/annual-filling-step-2.png"
                  alt="step thumb"
                  className="step-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                />
              </div>
              <div className="step d-flex flex-column flex-md-row justify-content-between align-items-start">
                <div
                  className="step-content-wrap d-flex"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <span className="step-number fw-bold d-flex align-items-center justify-content-center rounded-circle flex-shrink-0">
                    3
                  </span>
                  <div className="step-content">
                    <h3 className="step-title fw-bold mb-2">Confirmation</h3>
                    <p className="step-desc mb-0">
                      Get notified once the change is officially processed. Keep
                      a record of your updated agent details for future
                      reference.
                    </p>
                  </div>
                </div>
                <img
                  src="assets/img/thumb/annual-filling-step-3.png"
                  alt="step thumb"
                  className="step-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                />
              </div>
            </div>
            <div className="step-shape h-100">
              <img
                src="assets/img/shape/step-border-shape.png"
                alt="border"
                className="h-100"
              />
            </div>
          </div>
          <div
            className="text-center p-t-175 p-t-xl-140 p-t-lg-100 p-t-md-80 p-t-xs-60"
            data-aos="fade-up"
            data-aos-delay="50"
          >
            <Link to="https://boi.itump.com/login" className="theme-btn">
              Get Started <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="s-step-shape-1">
          <img src="assets/img/shape/shape-26.png" alt="shape" />
        </div>
      </section>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end */}

      <FAQSection faqs={faqs} />

      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceAnnual;
