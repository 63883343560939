export const CONTENTS = [
    {
        title: "OUR SERVICES",
        href: "#our-services"
    },
    {
        title: "INTELLECTUAL PROPERTY RIGHTS",
        href: "#property-rights"
    },
    {
        title: "USER REPRESENTATIONS",
        href: "#user-representations"
    },
    {
        title: "USER REGISTRATIONS",
        href: "#user-registrations"
    },
    {
        title: "PRODUCTS",
        href: "#products"
    },
    {
        title: "PURCHASES AND PAYMENT",
        href: "#purchases-payment"
    },
    {
        title: "SUBSCRIPTIONS",
        href: "#subscriptions"
    },
    {
        title: "RETURN/REFUNDS POLICY",
        href: "#return-policy"
    },
    {
        title: "SOFTWARE",
        href: "#software"
    },
    {
        title: "PROHIBITED ACTIVITIES",
        href: "#prohibited-acts"
    },
    {
        title: "USER GENERATED CONTRIBUTIONS",
        href: "#user-contributions"
    },
    {
        title: "CONTRIBUTION LICENSE",
        href: "#contribution-license"
    },
    {
        title: "GUIDELINES FOR REVIEWS",
        href: "#review-guidelines"
    },
    {
        title: "MOBILE APPLICATION LICENSE",
        href: "#mobile-license"
    },
    {
        title: "THIRD-PARTY WEBSITES AND CONTENT",
        href: "#third-parties"
    },
    {
        title: "ADVERTISERS",
        href: "#advertisers"
    },
    {
        title: "SERVICES MANAGEMENT",
        href: "#services-management"
    },
    {
        title: "PRIVACY POLICY",
        href: "#privacy-policy"
    },
    {
        title: "TERM AND TERMINATION",
        href: "#term-termination"
    },
    {
        title: "MODIFICATIONS AND INTERRUPTIONS",
        href: "#modifications"
    },
    {
        title: "GOVERNING LAW",
        href: "#governing-law"
    },
    {
        title: "DISPUTE RESOLUTION",
        href: "#dispute-resolution"
    },
    {
        title: "CORRECTIONS",
        href: "#corrections"
    },
    {
        title: "DISCLAIMER",
        href: "#disclaimer"
    },

    {
        title: "LIMITATIONS OF LIABILITY",
        href: "#limitations"
    },
    {
        title: "INDEMNIFICATION",
        href: "#indemnification"
    },
    {
        title: "USER DATA",
        href: "#user-data"
    },
    {
        title: "ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES",
        href: "#electronic-functions"
    },
    {
        title: "SMS TEXT MESSAGING",
        href: "#text-messaging"
    },
    {
        title: "CALIFORNIA USERS AND RESIDENTS",
        href: "#california-residents"
    },
    {
        title: "MISCELLANEOUS",
        href: "#miscellaneous"
    },
    {
        title: "ITUMP LO-FI FOR BUSINESSES",
        href: "#itump-lo-fi"
    },
    {
        title: "ORDER PLACEMENT AND REFUND POLICY",
        href: "#order-placement"
    },
    {
        title: "TAP TO PAY TERMS AND CONDITIONS",
        href: "#tap-to-pay"
    },
    {
        title: "ITUMP LO-FI FINANCING TERMS FOR CUSTOMERS",
        href: "#terms-for-customers"
    },
    {
        title: "CONTACT US",
        href: "#contact-us"
    }
]