import React from "react";
import { Link } from "react-router";

const CopyrightSection = () => {
  return (
    <>
      <section className="copyright-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-12">
              <div className="footer-bottom">
                <div className="footer-copyright">
                  <p className="copyright-text d-flex align-items-center">
                    <span>© 2024 Itump, Inc. All rights reserved</span>
                    <span className="divider d-none d-md-inline-block">|</span>
                    <Link
                      to="/eula"
                      className="copyright-link d-none d-md-inline-block"
                    >
                      Legal
                    </Link>
                    <span className="divider d-none d-md-inline-block">|</span>
                    <Link
                      to="/privacy"
                      className="copyright-link d-none d-md-inline-block"
                    >
                      Privacy Policy
                    </Link>
                    <span className="divider d-none d-md-inline-block">|</span>
                    <Link
                      to="/terms-of-service"
                      className="copyright-link d-none d-md-inline-block"
                    >
                      Terms & Conditions
                    </Link>
                  </p>
                </div>
                <div className="footer-social-wrap">
                  <Link to="#" title="tiktok" className="social-icon">
                    <i className="fa-brands fa-tiktok"></i>
                  </Link>
                  <Link to="#" title="youtube" className="social-icon">
                    <i className="fa-brands fa-youtube"></i>
                  </Link>
                  <Link to="#" title="twitter" className="social-icon">
                    <i className="fa-brands fa-x-twitter"></i>
                  </Link>
                  <Link to="#" title="instagram" className="social-icon">
                    <i className="fa-brands fa-instagram"></i>
                  </Link>
                  <Link to="#" title="linkedin" className="social-icon">
                    <i className="fa-brands fa-linkedin"></i>
                  </Link>
                </div>
                <p className="copyright-link-wrapper d-flex align-items-center d-md-none">
                  <Link to="/terms-of-service" className="copyright-link">
                    Terms of Service
                  </Link>
                  <span className="divider">|</span>
                  <Link to="/privacy" className="copyright-link">
                    Privacy Policy
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CopyrightSection;
