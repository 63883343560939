import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SupportIndividualPage = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* job section start */}
      <section className="support-section bg-light overflow-hidden p-t-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-120 p-b-lg-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="support-section-title common-title-wrap common-title-light mx-auto text-md-center">
            <ul
              className="pagination d-flex align-items-center justify-content-md-center p-0 list-unstyled gap-1"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <li className="d-none d-md-inline-block">
                <Link to="/support">Support</Link>
              </li>
              <li className="arrow-right d-none d-md-flex align-items-center justify-content-center flex-shrink-0">
                <i className="fa-regular fa-angle-right"></i>
              </li>
              <li className="dots d-none d-md-flex align-items-center justify-content-center flex-shrink-0">
                <i className="fa-regular fa-ellipsis"></i>
              </li>
              <li className="arrow-right d-none d-md-flex align-items-center justify-content-center flex-shrink-0">
                <i className="fa-regular fa-angle-right"></i>
              </li>
              <li>
                <Link to="/support">Setup</Link>
              </li>
              <li className="arrow-right d-flex align-items-center justify-content-center flex-shrink-0">
                <i className="fa-regular fa-angle-right"></i>
              </li>
              <li>Setting Up Your Itump Account</li>
            </ul>
            <h1
              className="common-title"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Setting Up Your Itump Account
            </h1>
          </div>
          <div className="question-blk-wrapper setting-account-wrapper mx-auto m-t-60">
            <div className="row">
              <div className="col-lg-8">
                <div className="setup-account">
                  <p
                    className="setup-account-title mb-4"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Welcome to Itump! Follow these simple steps to create and
                    configure your account:
                  </p>
                  <ol
                    className="setup-account-step m-0"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <li>
                      <strong>Sign Up:</strong> Visit{" "}
                      <Link to="#" className="text-decoration-underline">
                        itump.com/signup
                      </Link>{" "}
                      and complete the registration form with your business
                      details.
                    </li>
                    <li>
                      <strong>Verify Your Email:</strong> Check your inbox for a
                      confirmation email and click the verification link.
                    </li>
                    <li>
                      <strong>Personalize Your Dashboard:</strong> Choose your
                      preferred settings, connect your payment accounts, and
                      explore initial setup tools.
                    </li>
                    <li>
                      <strong>Start Exploring:</strong> Access tutorials and
                      guides in the Help Center to maximize your experience with
                      Itump.
                    </li>
                  </ol>
                </div>
              </div>
              <div className="col-lg-4">
                <div
                  className="question-blk"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <h5 className="question-blk-title fw-bold mb-0">
                    Others in Setup
                  </h5>
                  <ul className="question-list p-0 list-unstyled">
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Can I upgrade or downgrade my plan?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a free trial available?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Are there any cancellation fees?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a setup fee?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Is there a free trial available?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium"
                      >
                        <span>Are there any cancellation fees?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="#"
                        className="d-flex align-items-center justify-content-between gap-4 fw-medium border-b-none"
                      >
                        <span>Is there a setup fee?</span>
                        <i className="fa-regular fa-angle-right"></i>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* job section end */}

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SupportIndividualPage;
