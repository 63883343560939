export const CONTENTS = [
    { title: "THE APPLICATION", href: "#application" },
    { title: "SCOPE OF LICENSE", href: "#license" },
    { title: "TECHNICAL REQUIREMENTS", href: "#requirements" },
    { title: "MAINTENANCE & SUPPORT", href: "#maintenance" },
    { title: "USE OF DATA", href: "#use-of-data" },
    { title: "USER-GENERATED CONTRIBUTIONS", href: "#user-contributions" },
    { title: "CONTRIBUTION LICENSE", href: "#contribution-license" },
    { title: "LIABILITY", href: "#liability" },
    { title: "WARRANTY", href: "#warranty" },
    { title: "PRODUCT CLAIMS", href: "#product-claims" },
    { title: "LEGAL COMPLIANCE", href: "#legal-compliance" },
    { title: "CONTACT INFORMATION", href: "#contact-information" },
    { title: "TERMINATION", href: "#termination" },
    { title: "THIRD-PARTY TERMS OF AGREEMENTS & BENEFICIARY", href: "#third-party-terms" },
    { title: "INTELLECTUAL PROPERTY RIGHTS", href: "#intellectual-rights" },
    { title: "APPLICABLE LAW", href: "#applicable-law" },
    { title: "MISCELLANEOUS", href: "#miscellaneous" }
]