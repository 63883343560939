import React from "react";
import { Link } from "react-router";
import { Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

const ServiceSliderSection = () => {
  return (
    <>
      <section className="customer-slider p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-80 p-b-160 p-b-md-120 p-b-xs-80">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 p-b-65 p-b-xs-40">
              <div className="customer-title-wrap d-flex align-items-lg-end justify-content-between gap-4">
                <div
                  className="customer-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h2>
                    Not for You? <br /> Check out our other Services{" "}
                  </h2>
                </div>
                <div
                  className="service-button-wrap slider-btn-wrapper d-flex align-items-center"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  <button
                    type="button"
                    title="slide prev"
                    className="slider-btn service-button-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                  >
                    <i className="fa fa-arrow-left-long"></i>
                  </button>
                  <button
                    type="button"
                    title="slide next"
                    className="slider-btn service-button-next d-flex align-items-center justify-content-center rounded-circle border-0"
                  >
                    <i className="fa fa-arrow-right-long"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div
                className="service-active-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="service-slider swiper">
                  <div className="swiper-wrapper">
                    <Swiper
                      spaceBetween={34}
                      slidesPerView="auto"
                      modules={[Autoplay]}
                      loop
                      speed={5000}
                      autoplay
                      // allowTouchMove={false}
                      // disableOnInteraction
                    >
                      <SwiperSlide
                        className="service-slide-card swiper-slide"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-34.png"
                            alt="card thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Register New Business</p>
                            <span>Get your business up and running</span>
                          </div>
                          <Link
                            to="/service-register"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide red"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/closed-store.png"
                            alt="card thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Close Your Business</p>
                            <span>Wind down your business properly</span>
                          </div>
                          <Link
                            to="/service-close"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide green"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-35.png"
                            alt="card thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Get a New EIN</p>
                            <span>Get your business up and running</span>
                          </div>
                          <Link
                            to="/service-ein"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide yellow"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-36.png"
                            alt="card thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Build Business Credit</p>
                            <span>
                              Up your business credit to qualify for amazing
                              loans
                            </span>
                          </div>
                          <Link
                            to="/service-build"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide red"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-37.png"
                            alt="card-thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Change Your Agent</p>
                            <span>
                              Change to or get a professional registered agent
                              with us
                            </span>
                          </div>
                          <Link
                            to="/service-change"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide slate"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-45.png"
                            alt="card-thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Copyright & Trademark</p>
                            <span>Trademark and Copyright your business</span>
                          </div>
                          <Link
                            to="/service-copyright"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                      <SwiperSlide
                        className="service-slide-card swiper-slide slate-2"
                        style={{ width: "343px" }}
                      >
                        <div className="service-thumb">
                          <img
                            src="assets/img/thumb/thumb-46.png"
                            alt="card-thumb"
                          />
                        </div>
                        <div className="service-content-wrap">
                          <div className="service-content">
                            <p>Annual Filing</p>
                            <span>Business Compliance and responsibility</span>
                          </div>
                          <Link
                            to="/service-annual"
                            className="arrow-btn d-flex align-items-center justify-content-center rounded-circle"
                          >
                            <i className="fa fa-arrow-right-long"></i>
                          </Link>
                        </div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSliderSection;
