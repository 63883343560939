import React, { useState } from "react";
import { Link } from "react-router";
import { motion } from "motion/react";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SolutionsWallet = () => {
  const [isHovered1, setIsHovered1] = useState(false);

  const handleMouseOver1 = () => {
    setIsHovered1(true);
  };

  const handleMouseOut1 = () => {
    setIsHovered1(false);
  };

  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section
        className="hero-section-4 p-t-235 p-t-xl-200 p-t-lg-200 p-t-md-160 p-t-xs-140"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-8">
              <div className="hero-content mx-auto">
                <h2
                  className="hero-title px-md-3"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  The Ultimate Hub for Your Digital Finances
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="100">
                  Itump Wallet provides users with the flexibility, security,
                  and convenience they need to control every aspect of their
                  finances
                </p>
              </div>
              <div
                className="hero-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="get-started-btn-2">
                  <Link to="https://boi.itump.com/login">Get Started</Link>
                </div>
                <div className="learn-more-btn">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>
            </div>
            <div className="col-xl-12" style={{ overflow: "hidden" }}>
              <motion.div
                className="hero-thumb m-t-115 hero-wallet"
                data-aos="zoom-in"
                data-aos-delay="200"
                onMouseOver={handleMouseOver1}
                onMouseOut={handleMouseOut1}
                initial="initial"
                animate="initial"
                whileHover="hovered"
              >
                <motion.img
                  src="assets/img/thumb/wallet-light.svg"
                  alt="thumb"
                  style={{
                    position: "absolute",
                    right: "231px",
                    bottom: "-75px",
                  }}
                  transition={{ duration: 0.5 }}
                  initial={{ right: "410px", bottom: "-75px" }}
                  animate={{
                    right: isHovered1 ? "-20px" : "231px",
                    bottom: isHovered1 ? "0px" : "-75px",
                  }}
                />
                <motion.img
                  src="assets/img/thumb/wallet-dark.svg"
                  alt="thumb"
                  style={{ position: "relative", bottom: "-50px" }}
                  transition={{ duration: 0.5 }}
                  initial={{ bottom: "-75px" }}
                  animate={{
                    bottom: isHovered1 ? "0px" : "-75px",
                  }}
                />

                <motion.img
                  src="assets/img/logo/solution_logo.svg"
                  alt="thumb"
                  style={{
                    position: "absolute",
                    left: "251px",
                  }}
                  transition={{ duration: 0.5 }}
                  initial={{ left: "251px", bottom: "-75px" }}
                  animate={{
                    left: isHovered1 ? "205px" : "251px",
                    bottom: isHovered1 ? "42px" : "-52.5px",
                  }}
                />
              </motion.div>
            </div>
          </div>
        </div>
        <motion.div
          className="hero-shape-1"
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered1 ? 1 : 0 }}
          transition={{ duration: 1.5 }}
          style={{
            position: "absolute",
            bottom: "0px",
            left: "120px",
          }}
        >
          <img
            src="assets/img/bg/hero-hover-background.svg"
            alt="Hero-background"
          />
        </motion.div>
      </section>

      <section
        className="finance-section position-relative "
        style={{ zIndex: "10" }}
      >
        <img
          src="assets/img/shape/finance-border-shape.png"
          alt="border-shape"
          className="border-shape d-block mx-auto"
          data-aos="fade-down"
          data-aos-delay="50"
        />
        <div className="container">
          <div className="finance-steps-wrapper">
            <div className="finance-steps-wrap">
              <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                <img src="assets/img/thumb/finance-thumb-1.png" alt="thumb" />
              </div>
              <div className="info" data-aos="fade-up" data-aos-delay="100">
                <h3>Automatic Transaction Tracking</h3>
                <p>
                  Easily track your income and expenses with real-time updates
                  on all transactions
                </p>
              </div>
            </div>
            <div className="finance-steps-wrap">
              <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                <img src="assets/img/thumb/finance-thumb-2.png" alt="thumb" />
              </div>
              <div className="info" data-aos="fade-up" data-aos-delay="100">
                <h3>Multi-Currency Support</h3>
                <p>
                  Manage multiple currencies in one wallet, making global
                  business and personal transactions easier and more efficient
                  than ever
                </p>
              </div>
            </div>
            <div className="finance-steps-wrap">
              <div className="thumb" data-aos="zoom-in" data-aos-delay="50">
                <img src="assets/img/thumb/finance-thumb-3.png" alt="thumb" />
              </div>
              <div className="info" data-aos="fade-up" data-aos-delay="100">
                <h3>Invoice Management</h3>
                <p>
                  Create, send, and manage invoices through the wallet
                  interface, ensuring that all your receivables are consolidated
                  and easily trackable
                </p>
              </div>
            </div>
          </div>
          <div className="row align-items-center mt-xxl-4 p-t-300 p-t-xl-260 p-t-lg-200 p-t-md-140 p-t-xs-120 p-b-220 p-b-xl-180 p-b-lg-140 p-b-md-120 p-b-xs-120">
            <div className="col-lg-6">
              <div className="finance-content-wrap m-b-md-40 m-b-xs-40">
                <h3 className="title" data-aos="fade-up" data-aos-delay="50">
                  A Seamless Integration of Financial Tools
                </h3>
                <p className="text" data-aos="fade-up" data-aos-delay="100">
                  The Itump Wallet simplifies financial management by
                  integrating multi-currency support, allowing users to manage
                  various currencies in one wallet. With advanced encryption and
                  security features, your funds are protected during
                  transactions. It syncs with Itump Pay for quick access to
                  payments, invoices, and tracking tools. The wallet’s
                  user-friendly interface makes navigating personal and business
                  transactions easy, while real-time exchange rate updates and
                  transaction history tracking complete the financial ecosystem
                  for global use.
                </p>
              </div>
            </div>
            <div className="col-lg-6" data-aos="zoom-in" data-aos-delay="150">
              <div className="finance-thumb p-l-75 p-l-lg-40 p-l-md-0 p-l-xs-0">
                <img
                  src="assets/img/thumb/financial-tools.png"
                  alt="finance tools"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="finance-thumb security-thumb p-r-65 p-r-lg-40 p-r-md-0 p-r-xs-0"
                data-aos="zoom-in"
                data-aos-delay="50"
              >
                <img
                  src="assets/img/thumb/security-thumb.png"
                  alt="thumb"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="finance-content-wrap pt-lg-4 p-b-40">
                <h3 className="title" data-aos="fade-up" data-aos-delay="100">
                  Advanced Security for Complete Peace of Mind
                </h3>
                <p className="text" data-aos="fade-up" data-aos-delay="150">
                  The Itump Wallet prioritizes security with advanced encryption
                  technology, ensuring that your funds and personal data are
                  always protected. All transactions are safeguarded with
                  multi-layer authentication, preventing unauthorized access.
                  Regular security updates keep the platform resilient against
                  emerging threats, while real-time monitoring alerts you of any
                  suspicious activities. With Itump Wallet, your financial data
                  remains confidential, and your transactions are secure, giving
                  you peace of mind for both business and personal use
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="f-shape-1 position-absolute start-0">
          <img src="assets/img/shape/shape-36.png" alt="shape" />
        </div>
        <div className="f-shape-2 position-absolute end-0">
          <img src="assets/img/shape/shape-37.png" alt="shape" />
        </div>
      </section>

      <section className="cta-section">
        <div className="container">
          <div className="cta-content common-title-wrap mx-auto text-center">
            <h3 className="common-title" data-aos="fade-up" data-aos-delay="50">
              Ready to take control?
            </h3>
            <p
              className="common-title-body"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Unlock seamless financial management with Itump Wallet. Whether
              for personal or business use, experience secure transactions,
              multi-currency support, and real-time tracking in one intuitive
              platform.
            </p>
            <Link
              to="https://boi.itump.com/login"
              className="theme-btn"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              Get Started <i className="fa fa-arrow-right"></i>
            </Link>
          </div>
        </div>
        <div className="c-cta-shape position-absolute top-0 start-0 end-0 mx-auto">
          <img src="assets/img/shape/shape-38.svg" alt="shape" />
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SolutionsWallet;
