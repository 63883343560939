import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
// import { useState } from "react";
import { Link } from "react-router";
import { useMediaQuery } from "react-responsive";
import "swiper/css";

const DesktopView = () => {
  // const [isHovered0, setIsHovered0] = useState(false);

  // const handleMouseOver0 = () => {
  //   setIsHovered0(true);
  // };

  // const handleMouseOut0 = () => {
  //   setIsHovered0(false);
  // };

  return (
    <>
      <div
        className="container position-relative z-1"
        initial="initial"
        animate="initial"
        whileHover="hovered"
        // onMouseOver={handleMouseOver0}
        // onMouseOut={handleMouseOut0}
      >
        <div className="hero-container">
          <div
            className="feature-item-container feature-left"
            // style={{
            //   transform: "rotate(25deg)",
            //   transformOrigin: "100% 0%",
            // }}
            data-aos="fade-up"
            data-aos-delay="500"
            // initial={{ transform: "rotate(25deg)" }}
            // animate={{
            //   transform: isHovered0 ? "rotate(0deg)" : "rotate(25deg)",
            // }}
            // transition={{ duration: 1 }}
          >
            <div className="feature-item pt-md-0 ">
              <div className="feature-thumb">
                <img src="assets/img/thumb/thumb-1.png" alt="feature-thumb" />
              </div>
              <div className="feature-content">
                <p className="feature-title">Automated Growth Suggestions</p>
                <p className="feature-body">
                  Tailored, data-driven recommendations to optimize efficiency,
                  enhance customer engagement, and boost revenue
                </p>
                <a href="#Growth" className="read-more-btn">
                  Read More <i className="fa-solid fa-circle-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="feature-item-container "
            data-aos="fade-up"
            data-aos-delay="250"
          >
            <div className="feature-item ">
              <div className="feature-thumb">
                <img
                  src="assets/img/thumb/thumb-2.png"
                  alt="feature-thumb"
                  style={{ width: "100%" }}
                />
              </div>
              <div className="feature-content">
                <p className="feature-title">Financial Management</p>
                <p className="feature-body">
                  Make informed financial decisions with real-time reporting and
                  actionable
                  <br />
                  insights
                </p>
                <a href="#financial" className="read-more-btn">
                  Read More <i className="fa-solid fa-circle-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
          <div
            className="feature-item-container feature-right"
            data-aos="fade-up"
            data-aos-delay="500"
            // style={{
            //   transform: "rotate(-25deg)",
            //   transformOrigin: "0% 0%",
            // }}
            // initial={{ transform: "rotate(-25deg)" }}
            // animate={{
            //   transform: isHovered0 ? "rotate(0deg)" : "rotate(-25deg)",
            // }}
            // transition={{ duration: 1 }}
          >
            <div className="feature-item pt-md-0">
              <div className="feature-thumb">
                <img src="assets/img/thumb/thumb-3.png" alt="feature-thumb" />
              </div>
              <div className="feature-content">
                <p className="feature-title">Compliance & Documentation</p>
                <p className="feature-body">
                  Simplify compliance management with automated checks, alerts,
                  and centralized document storage
                </p>
                <a href="#compliance" className="read-more-btn">
                  Read More <i className="fa-solid fa-circle-arrow-down"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const MobileView = () => {
  return (
    <>
      <div className="container position-relative z-1">
        <div className="">
          <Swiper
            slidesPerView="auto"
            spaceBetween={24}
            modules={[Pagination]}
            pagination={{ clickable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide style={{ width: "370px" }}>
              <div
                className="feature-item-container "
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="feature-item ">
                  <div className="feature-thumb">
                    <img
                      src="assets/img/thumb/thumb-2.png"
                      alt="feature-thumb"
                    />
                  </div>
                  <div className="feature-content">
                    <p className="feature-title">Financial Management</p>
                    <p className="feature-body">
                      Make informed financial decisions with real-time reporting
                      and actionable
                      <br />
                      insights
                    </p>
                    <Link to="#" className="read-more-btn">
                      Read More{" "}
                      <i className="fa-solid fa-circle-arrow-down"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide style={{ width: "370px" }}>
              <div
                className="feature-item-container feature-left "
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="feature-item pt-md-0 w-[370px]">
                  <div className="feature-thumb">
                    <img
                      src="assets/img/thumb/thumb-1.png"
                      alt="feature-thumb"
                    />
                  </div>
                  <div className="feature-content">
                    <p className="feature-title">
                      Automated Growth Suggestions
                    </p>
                    <p className="feature-body">
                      Tailored, data-driven recommendations to optimize
                      efficiency, enhance customer engagement, and boost revenue
                    </p>
                    <Link to="#" className="read-more-btn">
                      Read More{" "}
                      <i className="fa-solid fa-circle-arrow-down"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide style={{ width: "370px" }}>
              <div
                className="feature-item-container feature-right "
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <div className="feature-item w-[370px] ">
                  <div className="feature-thumb">
                    <img
                      src="assets/img/thumb/thumb-3.png"
                      alt="feature-thumb"
                    />
                  </div>
                  <div className="feature-content">
                    <p className="feature-title">Compliance & Documentation</p>
                    <p className="feature-body">
                      Simplify compliance management with automated checks,
                      alerts, and centralized document storage
                    </p>
                    <Link to="#" className="read-more-btn">
                      Read More{" "}
                      <i className="fa-solid fa-circle-arrow-down"></i>
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </>
  );
};

const FeatureSection = () => {
  const isLargeScreen = useMediaQuery({
    minWidth: 1440,
  });

  return (
    <section className="feature-section p-t-140 p-t-lg-120 p-t-md-80 p-t-xs-60 position-relative">
      {isLargeScreen ? <DesktopView /> : <MobileView />}
    </section>
  );
};

export default FeatureSection;
