import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const InTheNews = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* news section start */}
      <section className="news-section bg-light p-t-100 p-t-md-80 p-t-xs-60 p-b-100 p-b-md-80 p-b-s-60 overflow-hidden">
        <div className="container">
          <div className="news-wrapper">
            <div className="m-b-60 m-b-xs-40">
              <Link
                to="/news"
                className="back-btn d-inline-flex align-items-center gap-2 fw-medium"
              >
                <i className="fa fa-arrow-left-long"></i> Newsroom
              </Link>
            </div>
            <div className="news-wrapper-title common-title-wrap common-title-light text-center">
              <h2
                className="common-title big mb-0"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                In the News
              </h2>
              <p
                className="common-title-body mb-0 mx-auto"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                Discover how Itump is making headlines—featured articles,
                interviews, and industry recognition.
              </p>
            </div>
            <div className="news-card-wrapper pt-4">
              <div className="row">
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          How Itump Simplifies Small Business Management
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">Forbes</p>
                        <p className="news-date mb-0">November 15, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          Tech Spotlight: Itump Wallet’s Multi-Currency Features
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">TechCrunch</p>
                        <p className="news-date mb-0">November 15, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          Forbes Highlights Itump as a Game-Changer for SMEs
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">Forbes</p>
                        <p className="news-date mb-0">November 12, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="350"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          TechRadar Praises Itump Wallet for Multi-Currency
                          Management
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">TechRadar</p>
                        <p className="news-date mb-0">November 10, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="450"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          Business Daily Lists Itump Among Top Startups to Watch
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">Business Daily</p>
                        <p className="news-date mb-0">October 28, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4 col-md-6"
                  data-aos="fade-up"
                  data-aos-delay="500"
                >
                  <div className="news-card d-flex flex-column justify-content-between">
                    <div className="news-card-content">
                      <h4 className="news-card-title fw-bold">
                        <Link to="#" className="d-block">
                          Wired Features Itump as a Pioneer in Digital Business
                          Management
                        </Link>{" "}
                      </h4>
                      <div className="news-card-info d-flex align-items-center">
                        <p className="news-subject mb-0">WIRED</p>
                        <p className="news-date mb-0">October 27, 2024</p>
                      </div>
                    </div>
                    <div className="news-card-btn">
                      <Link
                        to="#"
                        className="d-inline-flex align-items-center gap-2 fw-bold"
                      >
                        {" "}
                        Read More{" "}
                        <span className="icon">
                          <i className="fa-light fa-arrow-right-long"></i>
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="learn-more-btn style-2 text-center"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <Link to="#">See More</Link>
            </div>
          </div>
        </div>
      </section>
      {/* news section end  */}

      <section className="cta-section-2 bg-light p-b-100 p-b-md-80 p-b-xs-60">
        <div className="container">
          <div className="cta-content-wrap text-center">
            <div className="cta-content mx-auto">
              <h3 data-aos="fade-up" data-aos-delay="50">
                Get Started
              </h3>
              <p data-aos="fade-up" data-aos-delay="100">
                Start your journey to smarter business management and growth
                with Itump's easy-to-use tools.
              </p>
              <div
                className="cta-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="cta-btn get-started-btn-2">
                  <Link to="https://boi.itump.com/login">Get Started</Link>
                </div>
                <div className="cta-btn learn-more-btn style-3">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default InTheNews;
