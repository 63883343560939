import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import ServiceSliderSection from "../../component/ServiceSliderSection";
import ItumpMobile from "../../component/ItumpMobileSection";
import FAQSection from "../../component/FAQSection";

const ServiceEin = () => {
  const faqs = [
    {
      id: "one",
      question: "What is an EIN?",
      answer:
        "An EIN (Employer Identification Number) is like a Social Security number for your business. It's a unique nine-digit number that the IRS uses to identify your business. You'll need it for important things like paying taxes, hiring employees, and opening a bank account.",
    },
    {
      id: "two",
      question: "Who needs an EIN?",
      answer: `
      - Have employees (even if it's just one!)
      - Operate your business as a corporation or partnership
      - Withhold taxes on income, other than wages
      - Have a Keogh plan (a retirement savings plan for self-employed people and small business owners)
      - Are involved with certain types of organizations (trusts, estates, etc.)
      `,
    },
    {
      id: "three",
      question: "How long does it take to get an EIN?",
      answer:
        "With Itump, you can get your EIN quickly, often within minutes! We streamline the application process and help you get your EIN as fast as possible.",
    },

    {
      id: "four",
      question: "Can I use my EIN to open a business bank account?",
      answer:
        "Yes, you'll need your EIN to open a business bank account. It's one of the first things banks will ask for.",
    },

    {
      id: "five",
      question: "What if I already have an EIN?",
      answer:
        " If you already have an EIN, you generally don't need to apply for a new one unless you've had a major change in your business structure or ownership.",
    },

    {
      id: "six",
      question: "Can I apply for an EIN if I’m outside the U.S.?",
      answer:
        "Yes, you can apply for an EIN even if you're not in the U.S. Itump makes it easy to apply from anywhere in the world.",
    },

    {
      id: "seven",
      question: "What if I lose my EIN?",
      answer:
        " If you lose your EIN, you can find it on previous tax returns or by contacting the IRS. Itump can also help you retrieve your EIN if you've used our platform to apply for it.",
    },

    {
      id: "eight",
      question: "Can I apply for an EIN if I haven't started my business yet?",
      answer:
        " Yes, you can apply for an EIN even if you haven't officially started your business. It's often a good idea to get your EIN early in the process.",
    },
  ];

  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-2 service-hero service-ein-hero p-t-200 p-t-xl-160 p-t-lg-120 p-t-md-100 p-t-xs-60 p-b-245 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120">
        <div className="container">
          <div className="row">
            <div className="col-md-7 m-b-xs-60">
              <div className="hero-content service-hero-content position-relative">
                <p
                  className="hero-sub-1"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  GET A NEW EIN
                </p>
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Secure Your Employer Identification Number
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="150">
                  Easily obtain your Employer Identification Number (EIN) to
                  kickstart your business operations with confidence.
                </p>
              </div>
              <div>
                <Link
                  to="https://boi.itump.com/login"
                  className="theme-btn"
                  data-aos="fade-up"
                  data-aos-delay="200"
                >
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="hero-thumb"
                data-aos="zoom-in"
                data-aos-delay="250"
              >
                <img src="assets/img/thumb/thumb-41.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
        <div className="s-hero-shape-1">
          <img src="assets/img/shape/shape-8.png" alt="shape" />
        </div>
        <div className="s-hero-shape-2">
          <img src="assets/img/shape/shape-9.png" alt="shape" />
        </div>
      </section>

      <section className="">
        <div className="container">
          <div className="row">
            <div className="col-xl-7">
              <div className="common-title-wrap m-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Why Do You Need an EIN?
                </h3>
                <p
                  className="common-title-body"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  Essential for Business Operations and Compliance
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="industry-solution-wrap style-2 file-with-us-wrap ein-card-wrapper">
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M39.8 27L8.2 27C5.2 27 4 28.28 4 31.46L4 39.54C4 42.72 5.2 44 8.2 44L39.8 44C42.8 44 44 42.72 44 39.54L44 31.46C44 28.28 42.8 27 39.8 27Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M25.8 4L8.2 4C5.2 4 4 5.28 4 8.46L4 16.54C4 19.72 5.2 21 8.2 21L25.8 21C28.8 21 30 19.72 30 16.54L30 8.46C30 5.28 28.8 4 25.8 4Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Tax Filing
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Required for federal and state tax filings
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 30C31.4558 30 37.5 24.1797 37.5 17C37.5 9.8203 31.4558 4 24 4C16.5442 4 10.5 9.8203 10.5 17C10.5 24.1797 16.5442 30 24 30Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M31.58 31.2199C32.24 30.8799 33 31.3799 33 32.1199V41.8199C33 43.6199 31.74 44.4999 30.18 43.7599L24.82 41.2199C24.36 41.0199 23.64 41.0199 23.18 41.2199L17.82 43.7599C16.26 44.4799 15 43.5999 15 41.7999L15.04 32.1199C15.04 31.3799 15.82 30.8999 16.46 31.2199C18.72 32.3599 21.28 32.9999 24 32.9999C26.72 32.9999 29.3 32.3599 31.58 31.2199Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Hiring Employees
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Essential for payroll and reporting employee taxes
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.0801 8.23993L26.0801 4.11993C24.9401 3.69993 23.0801 3.69993 21.9401 4.11993L10.9401 8.23993C8.8201 9.03993 7.1001 11.5199 7.1001 13.7799V29.9799C7.1001 31.5999 8.1601 33.7399 9.4601 34.6999L20.4601 42.9199C22.4001 44.3799 25.5801 44.3799 27.5201 42.9199L38.5201 34.6999C39.8201 33.7199 40.8801 31.5999 40.8801 29.9799V13.7799C40.9001 11.5199 39.1801 9.03993 37.0801 8.23993ZM30.9601 19.4399L22.3601 28.0399C22.0601 28.3399 21.6801 28.4799 21.3001 28.4799C20.9201 28.4799 20.5401 28.3399 20.2401 28.0399L17.0401 24.7999C16.4601 24.2199 16.4601 23.2599 17.0401 22.6799C17.6201 22.0999 18.5801 22.0999 19.1601 22.6799L21.3201 24.8399L28.8601 17.2999C29.4401 16.7199 30.4001 16.7199 30.9801 17.2999C31.5601 17.8799 31.5601 18.8599 30.9601 19.4399Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Opening Bank Accounts
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Most banks require an EIN to open a business account
                    </p>
                  </div>
                </div>
                <div className="industry-solution-item w-100">
                  <div
                    className="solution-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="48"
                      height="48"
                      viewBox="0 0 48 48"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M36 4H12C8.68 4 6 6.66 6 9.94V31.76C6 35.04 8.68 37.7 12 37.7H13.52C15.12 37.7 16.64 38.32 17.76 39.44L21.18 42.82C22.74 44.36 25.28 44.36 26.84 42.82L30.26 39.44C31.38 38.32 32.92 37.7 34.5 37.7H36C39.32 37.7 42 35.04 42 31.76V9.94C42 6.66 39.32 4 36 4ZM20.76 26.02C21.58 26.02 22.26 26.7 22.26 27.52C22.26 28.34 21.58 29.02 20.76 29.02H15.4C14.52 29.02 13.7 28.6 13.18 27.88C12.68 27.2 12.56 26.36 12.8 25.56C13.5 23.42 15.22 22.26 16.74 21.22C18.34 20.14 19.24 19.46 19.24 18.3C19.24 17.26 18.4 16.42 17.36 16.42C16.32 16.42 15.5 17.28 15.5 18.32C15.5 19.14 14.82 19.82 14 19.82C13.18 19.82 12.5 19.14 12.5 18.32C12.5 15.64 14.68 13.44 17.38 13.44C20.08 13.44 22.26 15.62 22.26 18.32C22.26 21.14 20.14 22.58 18.44 23.74C17.38 24.46 16.38 25.14 15.88 26.04H20.76V26.02ZM34 26.16H33.58V27.54C33.58 28.36 32.9 29.04 32.08 29.04C31.26 29.04 30.58 28.36 30.58 27.54V26.16H26.66C26.66 26.16 26.66 26.16 26.64 26.16C25.66 26.16 24.76 25.64 24.26 24.8C23.76 23.94 23.76 22.88 24.26 22.04C25.62 19.7 27.2 17.04 28.64 14.72C29.28 13.7 30.5 13.24 31.64 13.56C32.78 13.9 33.58 14.94 33.56 16.14V23.18H34C34.82 23.18 35.5 23.86 35.5 24.68C35.5 25.5 34.82 26.16 34 26.16Z"
                        fill="#7256FF"
                      />
                      <path
                        d="M30.5801 23.16V17.28C29.4001 19.2 28.1801 21.26 27.0801 23.14H30.5801V23.16Z"
                        fill="#7256FF"
                      />
                    </svg>
                  </div>
                  <div className="solution-content">
                    <p
                      className="solution-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      Business Credit
                    </p>
                    <p
                      className="solution-body"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      Helps you establish and build your business credit profile
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="text-center m-t-65 m-t-md-40 m-t-xs-40"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              <Link to="https://boi.itump.com/login" className="theme-btn">
                Get Started <i className="fa fa-arrow-right"></i>
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="service-card-section p-t-240 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-300 p-b-xl-260 p-b-lg-200 p-b-md-160 p-b-xs-120">
        <div className="container">
          <div className="row gy-4 gx-3 gx-lg-4">
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>Requirements</h3>
                </div>
                <div
                  className="service-card-thumb m-b-80"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-32.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-1"
                        aria-expanded="false"
                        aria-controls="service-item-coll-1"
                      >
                        <span>Business Name and Address</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-1"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-2"
                        aria-expanded="false"
                        aria-controls="service-item-coll-2"
                      >
                        <span>
                          Social Security Number (SSN) or Individual Taxpayer
                          Identification Number (ITIN)
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-2"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-3"
                        aria-expanded="false"
                        aria-controls="service-item-coll-3"
                      >
                        <span>Legal Structure of the Business</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-3"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-1">
                  <img src="assets/img/shape/shape-10.png" alt="shape" />
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="service-card h-100">
                <div
                  className="service-card-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  <h3>What You Get</h3>
                </div>
                <div
                  className="service-card-thumb"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img src="assets/img/thumb/thumb-33.png" alt="thumb" />
                </div>
                <div
                  className="service-card-content m-t-35"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  <div className="service-accordion">
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-4"
                        aria-expanded="false"
                        aria-controls="service-item-coll-4"
                      >
                        <span>Employer Identification Number (EIN)</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-4"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-5"
                        aria-expanded="false"
                        aria-controls="service-item-coll-5"
                      >
                        <span>Official IRS documentation</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-5"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-6"
                        aria-expanded="false"
                        aria-controls="service-item-coll-6"
                      >
                        <span>Compliance with tax regulations</span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-6"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                    <div className="service-item">
                      <button
                        type="button"
                        className="service-header position-relative w-100 border-0 bg-transparent p-0 text-start"
                        data-bs-toggle="collapse"
                        data-bs-target="#service-item-coll-7"
                        aria-expanded="false"
                        aria-controls="service-item-coll-7"
                      >
                        <span>
                          Ability to open business bank accounts and hire
                          employees
                        </span>
                      </button>
                      <div
                        className="service-content collapse"
                        id="service-item-coll-7"
                      >
                        <p>A unique name for your new business.</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="s-service-shape-2">
                  <img src="assets/img/shape/shape-11.png" alt="shape" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/bg/unified-bg-pattern.png"
          alt="bg pattern"
          className="bg-pattern mx-auto w-100 position-absolute start-0 end-0"
        />
      </section>

      <section className="boi-complete-section">
        <div className="container">
          <div className="row">
            <div className="col-xl-6">
              <div className="common-title-wrap m-b-90 m-xl-b-70 m-b-md-50 m-b-xs-40">
                <h3
                  className="common-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Simple 3-Step Process for Your EIN
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-12">
              <div className="boi-complete-steps p-b-85 p-b-lg-60 p-b-md-50 p-b-xs-40">
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M37.6666 4.6665H18.9999C10.8333 4.6665 7.33325 9.33317 7.33325 16.3332V39.6665C7.33325 46.6665 10.8333 51.3332 18.9999 51.3332H37.6666C45.8333 51.3332 49.3333 46.6665 49.3333 39.6665V16.3332C49.3333 9.33317 45.8333 4.6665 37.6666 4.6665ZM18.9999 28.5832H28.3333C29.2899 28.5832 30.0833 29.3765 30.0833 30.3332C30.0833 31.2898 29.2899 32.0832 28.3333 32.0832H18.9999C18.0433 32.0832 17.2499 31.2898 17.2499 30.3332C17.2499 29.3765 18.0433 28.5832 18.9999 28.5832ZM37.6666 41.4165H18.9999C18.0433 41.4165 17.2499 40.6232 17.2499 39.6665C17.2499 38.7098 18.0433 37.9165 18.9999 37.9165H37.6666C38.6233 37.9165 39.4166 38.7098 39.4166 39.6665C39.4166 40.6232 38.6233 41.4165 37.6666 41.4165ZM43.4999 21.5832H38.8333C35.2866 21.5832 32.4166 18.7132 32.4166 15.1665V10.4998C32.4166 9.54317 33.2099 8.74984 34.1666 8.74984C35.1233 8.74984 35.9166 9.54317 35.9166 10.4998V15.1665C35.9166 16.7765 37.2233 18.0832 38.8333 18.0832H43.4999C44.4566 18.0832 45.2499 18.8765 45.2499 19.8332C45.2499 20.7898 44.4566 21.5832 43.4999 21.5832Z"
                        fill="#7A7A7A"
                      ></path>
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Complete the Application</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        Fill out our simple online form with your business
                        details
                      </p>
                    </div>
                  </div>
                  <div className="step-shape">
                    <svg
                      width="321"
                      height="8"
                      viewBox="0 0 321 8"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M320.354 4.35355C320.549 4.15829 320.549 3.84171 320.354 3.64645L317.172 0.464466C316.976 0.269204 316.66 0.269204 316.464 0.464466C316.269 0.659728 316.269 0.976311 316.464 1.17157L319.293 4L316.464 6.82843C316.269 7.02369 316.269 7.34027 316.464 7.53553C316.66 7.7308 316.976 7.7308 317.172 7.53553L320.354 4.35355ZM0 4.5H2.96296V3.5H0V4.5ZM8.88889 4.5H14.8148V3.5H8.88889V4.5ZM20.7407 4.5H26.6667V3.5H20.7407V4.5ZM32.5926 4.5H38.5185V3.5H32.5926V4.5ZM44.4444 4.5H50.3704V3.5H44.4444V4.5ZM56.2963 4.5H62.2222V3.5H56.2963V4.5ZM68.1481 4.5H74.0741V3.5H68.1481V4.5ZM80 4.5H85.9259V3.5H80V4.5ZM91.8519 4.5H97.7778V3.5H91.8519V4.5ZM103.704 4.5H109.63V3.5H103.704V4.5ZM115.556 4.5H121.481V3.5H115.556V4.5ZM127.407 4.5H133.333V3.5H127.407V4.5ZM139.259 4.5H145.185V3.5H139.259V4.5ZM151.111 4.5H157.037V3.5H151.111V4.5ZM162.963 4.5H168.889V3.5H162.963V4.5ZM174.815 4.5H180.741V3.5H174.815V4.5ZM186.667 4.5H192.593V3.5H186.667V4.5ZM198.518 4.5H204.444V3.5H198.518V4.5ZM210.37 4.5H216.296V3.5H210.37V4.5ZM222.222 4.5H228.148V3.5H222.222V4.5ZM234.074 4.5H240V3.5H234.074V4.5ZM245.926 4.5H251.852V3.5H245.926V4.5ZM257.778 4.5H263.704V3.5H257.778V4.5ZM269.629 4.5H275.555V3.5H269.629V4.5ZM281.481 4.5H287.407V3.5H281.481V4.5ZM293.333 4.5H299.259V3.5H293.333V4.5ZM305.185 4.5H311.111V3.5H305.185V4.5ZM317.037 4.5H320V3.5H317.037V4.5Z"
                        fill="url(#paint0_linear_2189_74711)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2189_74711"
                          x1="0"
                          y1="4.5"
                          x2="320"
                          y2="4.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#323232" />
                          <stop offset="1" stop-color="#478F66" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="56"
                      height="56"
                      viewBox="0 0 56 56"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M50.307 25.0598L47.1337 21.3731C46.527 20.6731 46.037 19.3665 46.037 18.4331V14.4665C46.037 11.9931 44.007 9.96314 41.5337 9.96314H37.567C36.657 9.96314 35.327 9.47314 34.627 8.86648L30.9404 5.69314C29.3304 4.31648 26.6937 4.31648 25.0604 5.69314L21.397 8.88981C20.697 9.47314 19.367 9.96314 18.457 9.96314H14.4204C11.947 9.96314 9.91704 11.9931 9.91704 14.4665V18.4565C9.91704 19.3665 9.42704 20.6731 8.84371 21.3731L5.69371 25.0831C4.34038 26.6931 4.34038 29.3065 5.69371 30.9165L8.84371 34.6265C9.42704 35.3265 9.91704 36.6331 9.91704 37.5431V41.5331C9.91704 44.0065 11.947 46.0365 14.4204 46.0365H18.457C19.367 46.0365 20.697 46.5265 21.397 47.1331L25.0837 50.3065C26.6937 51.6831 29.3304 51.6831 30.9637 50.3065L34.6504 47.1331C35.3504 46.5265 36.657 46.0365 37.5904 46.0365H41.557C44.0304 46.0365 46.0604 44.0065 46.0604 41.5331V37.5665C46.0604 36.6565 46.5504 35.3265 47.157 34.6265L50.3304 30.9398C51.6837 29.3298 51.6837 26.6698 50.307 25.0598ZM37.707 23.5898L26.437 34.8598C26.1104 35.1865 25.667 35.3731 25.2004 35.3731C24.7337 35.3731 24.2904 35.1865 23.9637 34.8598L18.317 29.2131C17.6404 28.5365 17.6404 27.4165 18.317 26.7398C18.9937 26.0631 20.1137 26.0631 20.7904 26.7398L25.2004 31.1498L35.2337 21.1165C35.9104 20.4398 37.0304 20.4398 37.707 21.1165C38.3837 21.7931 38.3837 22.9131 37.707 23.5898Z"
                        fill="#478F66"
                      />
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Verification and Submission</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        We verify your information and submit the EIN
                        application to the IRS on your behalf
                      </p>
                    </div>
                  </div>
                  <div className="step-shape">
                    <svg
                      width="321"
                      height="8"
                      viewBox="0 0 321 8"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M320.354 4.35355C320.549 4.15829 320.549 3.84171 320.354 3.64645L317.172 0.464466C316.976 0.269204 316.66 0.269204 316.464 0.464466C316.269 0.659728 316.269 0.976311 316.464 1.17157L319.293 4L316.464 6.82843C316.269 7.02369 316.269 7.34027 316.464 7.53553C316.66 7.7308 316.976 7.7308 317.172 7.53553L320.354 4.35355ZM0 4.5H2.96296V3.5H0V4.5ZM8.88889 4.5H14.8148V3.5H8.88889V4.5ZM20.7407 4.5H26.6667V3.5H20.7407V4.5ZM32.5926 4.5H38.5185V3.5H32.5926V4.5ZM44.4444 4.5H50.3704V3.5H44.4444V4.5ZM56.2963 4.5H62.2222V3.5H56.2963V4.5ZM68.1481 4.5H74.0741V3.5H68.1481V4.5ZM80 4.5H85.9259V3.5H80V4.5ZM91.8519 4.5H97.7778V3.5H91.8519V4.5ZM103.704 4.5H109.63V3.5H103.704V4.5ZM115.556 4.5H121.481V3.5H115.556V4.5ZM127.407 4.5H133.333V3.5H127.407V4.5ZM139.259 4.5H145.185V3.5H139.259V4.5ZM151.111 4.5H157.037V3.5H151.111V4.5ZM162.963 4.5H168.889V3.5H162.963V4.5ZM174.815 4.5H180.741V3.5H174.815V4.5ZM186.667 4.5H192.593V3.5H186.667V4.5ZM198.518 4.5H204.444V3.5H198.518V4.5ZM210.37 4.5H216.296V3.5H210.37V4.5ZM222.222 4.5H228.148V3.5H222.222V4.5ZM234.074 4.5H240V3.5H234.074V4.5ZM245.926 4.5H251.852V3.5H245.926V4.5ZM257.778 4.5H263.704V3.5H257.778V4.5ZM269.629 4.5H275.555V3.5H269.629V4.5ZM281.481 4.5H287.407V3.5H281.481V4.5ZM293.333 4.5H299.259V3.5H293.333V4.5ZM305.185 4.5H311.111V3.5H305.185V4.5ZM317.037 4.5H320V3.5H317.037V4.5Z"
                        fill="url(#paint0_linear_2189_74712)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2189_74712"
                          x1="0"
                          y1="4.5"
                          x2="320"
                          y2="4.5"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#478F66" />
                          <stop offset="1" stop-color="#27AE60" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                </div>
                <div className="boi-complete-step">
                  <div
                    className="boi-step-icon"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    <svg
                      width="57"
                      height="56"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http:/www.w3.org/2000/svg"
                    >
                      <path
                        d="M38.0003 4.6665H19.3337C11.167 4.6665 7.66699 9.33317 7.66699 16.3332V39.6665C7.66699 46.6665 11.167 51.3332 19.3337 51.3332H38.0003C46.167 51.3332 49.667 46.6665 49.667 39.6665V16.3332C49.667 9.33317 46.167 4.6665 38.0003 4.6665ZM31.1637 36.1432C29.9737 39.9465 25.797 41.9298 24.6303 41.9298C23.487 41.9065 19.357 39.9698 18.1203 36.1432C17.3503 33.7398 18.2603 30.7765 20.757 29.9598C22.087 29.5398 23.7203 29.8898 24.6303 31.1732C25.4937 29.8432 27.197 29.5398 28.5037 29.9598C31.0237 30.7765 31.9103 33.7398 31.1637 36.1432ZM43.8337 21.5832H39.167C35.6203 21.5832 32.7503 18.7132 32.7503 15.1665V10.4998C32.7503 9.54317 33.5437 8.74984 34.5003 8.74984C35.457 8.74984 36.2503 9.54317 36.2503 10.4998V15.1665C36.2503 16.7765 37.557 18.0832 39.167 18.0832H43.8337C44.7903 18.0832 45.5837 18.8765 45.5837 19.8332C45.5837 20.7898 44.7903 21.5832 43.8337 21.5832Z"
                        fill="#27AE60"
                      />
                    </svg>
                  </div>
                  <div className="step-content">
                    <div
                      className="boi-complete-step-title"
                      data-aos="fade-up"
                      data-aos-delay="100"
                    >
                      <p>Receive Your EIN</p>
                    </div>
                    <div
                      className="boi-complete-step-info"
                      data-aos="fade-up"
                      data-aos-delay="150"
                    >
                      <p>
                        Get your EIN delivered to your email within a few
                        business days
                      </p>
                    </div>
                  </div>
                  <div className="s-bio-shape-1">
                    <img src="assets/img/shape/shape-13.png" alt="shape" />
                  </div>
                </div>
              </div>
              <div
                className="text-center"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <Link to="https://boi.itump.com/login" className="theme-btn">
                  Get Started <i className="fa fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* testimonial section start  */}
      <section className="testimonial-section p-t-230 p-t-xl-200 p-t-lg-160 p-t-md-120 p-t-xs-120 p-b-255 p-b-xl-200 p-b-lg-160 p-b-md-120 p-b-xs-120 overflow-hidden position-relative">
        <div className="container">
          <div className="testimonial-section-wrapper d-flex align-items-lg-center flex-column flex-lg-row">
            <div className="common-title-wrap position-relative z-1">
              <h2
                className="common-title m-b-5"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                What our users have to say
              </h2>
              <div
                className="slider-btn-wrapper d-flex align-items-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <button
                  type="button"
                  title="slide prev"
                  className="slider-btn t-slider-btn-prev d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-left-long"></i>
                </button>
                <button
                  type="button"
                  title="slide next"
                  className="slider-btn t-slider-btn-next d-flex align-items-center justify-content-center rounded-circle border-0"
                >
                  <i className="fa fa-arrow-right-long"></i>
                </button>
              </div>
              <img
                src="assets/img/shape/testimonial-area-shape.png"
                alt="shape"
                className="border-shape position-absolute d-none d-lg-block"
                data-aos="zoom-in"
                data-aos-delay="150"
              />
            </div>
            <div
              className="testimonial-slider-container flex-shrink-0 position-relative"
              data-aos="fade-up"
              data-aos-delay="200"
            >
              <div className="testimonial-slider swiper">
                <div className="testimonial-slider-wrapper swiper-wrapper">
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-cotnainer swiper-slide">
                    <div className="testimonial-item">
                      <img
                        src="assets/img/icons/quote-left.svg"
                        alt="quote-left"
                        className="qoute-icon mb-3"
                      />
                      <p className="testimonial-desc">
                        itump FinCen's BOI has been a game changer, With Itump's
                        seamless BOI reporting service, I was able to complete
                        my ownership reporting effortlessly. The platform guided
                        me through each step, ensuring that I provided all
                        necessary details accurately and on time
                      </p>
                      <div className="user-info d-flex flex-column gap-1">
                        <div className="user-img rounded-circle">
                          <img
                            src="assets/img/user-avatar.png"
                            alt="user img"
                            className="w-100 h-100 object-fit-cover rounded-circle"
                          />
                        </div>
                        <h5 className="user-name fw-bold mb-0">
                          Alex Thompson
                        </h5>
                        <span className="user-designation">
                          CEO of Innovative Solutions Inc.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial section end  */}

      <FAQSection faqs={faqs} />

      <ServiceSliderSection />

      <section className="itump-mobile-section p-b-275 p-b-xl-220 p-b-lg-180 p-b-md-120 p-b-xs-80 position-relative overflow-hidden">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <ItumpMobile />
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="50"
              >
                <div className="contact-icon">
                  <img src="assets/img/icons/location-dot.svg" alt="location" />
                </div>
                <p>
                  30 Wall Street, <br /> New York, NY 10005
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div
                className="contact-info"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <div className="contact-icon d-flex align-items-center">
                  <img src="assets/img/icons/phone.svg" alt="phone" />
                  <img src="assets/img/icons/envelope2.svg" alt="envelope" />
                </div>
                <div className="contact-link">
                  <Link to="tel:(888) 87-ITUMP">(888) 87-ITUMP</Link>
                  <Link
                    className="text-decoration-underline"
                    to="start.today@itump.com"
                  >
                    start.today@itump.com
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img
          src="assets/img/thumb/thumb-38.png"
          alt="pattern"
          className="bg-pattern w-100 mx-auto start-0 end-0 bottom-0 position-absolute d-none d-md-block"
        />
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default ServiceEin;
