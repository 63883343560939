import React, { useState } from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ItumpMobile from "../../component/ItumpMobileSection";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    country: "",
    company: "",
    jobTitle: "",
    email: "",
    phone: "",
    support: "",
    subject: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Construct mailto link
    const subject = encodeURIComponent("New Contact Form Submission");
    const body = encodeURIComponent(`
    First Name: ${formData.firstName}
    Last Name: ${formData.lastName}
    Country: ${formData.country}
    Company: ${formData.company}
    Job Title: ${formData.jobTitle}
    Email: ${formData.email}
    Phone: ${formData.phone}
    Support Type: ${formData.support}
    Message: ${formData.subject}
  `);

    // Open email client
    window.location.href = `mailto:olujawo1996@gmail.com?subject=${subject}&body=${body}`;

    setIsSubmitting(true);

    // Simulating a form submission delay (API call)
    setTimeout(() => {
      console.log("Form Submitted:", formData);
      setSuccessMessage("Your message has been sent successfully!");
      setIsSubmitting(false);

      // Clear form after submission
      setFormData({
        firstName: "",
        lastName: "",
        country: "",
        company: "",
        jobTitle: "",
        email: "",
        phone: "",
        support: "",
        subject: "",
      });
      setSuccessMessage("");
    }, 2000);
  };
  return (
    <>
      <HeaderSection />

      <SlideBar />

      {/* hero section start */}
      <section className="contact-section team-hero bg-light overflow-hidden p-t-140 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-60">
        <div className="container">
          <div className="contact-section-wrapper">
            <div className="row">
              <div className="col-lg-6">
                <div className="common-title-wrap common-title-light">
                  <h1
                    className="common-title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Contact our <br /> sales team
                  </h1>
                  <p
                    className="common-title-body"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Whether you have questions, need support, or want to learn
                    more about how Itump can empower your business, our team is
                    ready to assist you.
                  </p>
                </div>
                <div className="contact-brand py-5 mb-5 d-flex flex-column">
                  <div
                    className="contact-brand-slider swiper w-100"
                    id="contact-brand-slider-1"
                    data-aos="zoom-in"
                    data-aos-delay="150"
                  >
                    <div className="swiper-wrapper">
                      <Swiper
                        spaceBetween={34}
                        slidesPerView="auto"
                        modules={[Autoplay]}
                        loop
                        speed={5000}
                        autoplay
                        style={{ margin: "0" }}
                      >
                        <SwiperSlide className="swiper-slide">
                          <Link to="#" className="brand-logo d-inline-block">
                            <img
                              src="assets/img/brand-logo/stripe.svg"
                              alt="stripe"
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                          <Link to="#" className="brand-logo d-inline-block">
                            <img
                              src="assets/img/brand-logo/fix-tops.svg"
                              alt="fix-tops"
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                          <Link to="#" className="brand-logo d-inline-block">
                            <img
                              src="assets/img/brand-logo/hutoh.svg"
                              alt="hutoh"
                            />
                          </Link>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                  <div
                    className="contact-brand-slider swiper w-100"
                    id="contact-brand-slider-2"
                    data-aos="zoom-in"
                    data-aos-delay="200"
                  >
                    <div className="swiper-wrapper">
                      <Swiper
                        spaceBetween={34}
                        slidesPerView="auto"
                        modules={[Autoplay]}
                        loop
                        speed={5000}
                        autoplay
                        style={{ margin: "0" }}
                      >
                        <SwiperSlide className="swiper-slide">
                          <Link to="#" className="brand-logo d-inline-block">
                            <img
                              src="assets/img/brand-logo/fox.svg"
                              alt="bluekanel"
                            />
                          </Link>
                        </SwiperSlide>
                        <SwiperSlide className="swiper-slide">
                          <Link to="#" className="brand-logo d-inline-block">
                            <img
                              src="assets/img/brand-logo/forbes.svg"
                              alt="forbes"
                            />
                          </Link>
                        </SwiperSlide>
                      </Swiper>
                    </div>
                  </div>
                </div>
                <div
                  className="c-testimonial-slider swiper"
                  data-aos="fade-up"
                  data-aos-delay="250"
                >
                  <div className="swiper-wrapper">
                    <div className="testimonial swiper-slide">
                      <img
                        src="assets/img/brand-logo/foodiebox.png"
                        alt="foodiebox"
                        className="testimonial-logo"
                      />
                      <p className="testimonial-desc my-3">
                        “Working at itump has been an incredible journey. The
                        supportive environment and collaborative spirit empower
                        me to grow professionally every day. I love being part
                        of a team that genuinely cares about each other's
                        success!”
                      </p>
                      <div className="client-info">
                        <h6 className="fw-bold mb-1">John Doe</h6>
                        <span>CEO, FoodieBox</span>
                      </div>
                    </div>
                    <div className="testimonial swiper-slide">
                      <img
                        src="assets/img/brand-logo/foodiebox.png"
                        alt="foodiebox"
                        className="testimonial-logo"
                      />
                      <p className="testimonial-desc my-3">
                        “Working at itump has been an incredible journey. The
                        supportive environment and collaborative spirit empower
                        me to grow professionally every day. I love being part
                        of a team that genuinely cares about each other's
                        success!”
                      </p>
                      <div className="client-info">
                        <h6 className="fw-bold mb-1">John Doe</h6>
                        <span>CEO, FoodieBox</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                {/* <form
                  action="#"
                  className="contact-form bg-white d-flex flex-column"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <div className="form-input-wrapper d-grid">
                    <div className="form-input">
                      <label for="f-name" className="d-block">
                        First Name
                      </label>
                      <input
                        type="text"
                        placeholder="e.g. John"
                        className="input-field w-100 border-0"
                        id="f-name"
                      />
                    </div>
                    <div className="form-input">
                      <label for="l-name" className="d-block">
                        Last Name
                      </label>
                      <input
                        type="text"
                        placeholder="e.g. Doe"
                        className="input-field w-100 border-0"
                        id="l-name"
                      />
                    </div>
                  </div>
                  <div className="form-input-wrapper d-grid">
                    <div className="form-input position-relative">
                      <label for="country" className="d-block">
                        Country
                      </label>
                      <select
                        name="country"
                        id="country"
                        className="input-field w-100 border-0"
                      >
                        <option>Select Country</option>
                        <option value="1">Bangladesh</option>
                        <option value="2">Pakistan</option>
                        <option value="3">Maldives</option>
                      </select>
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                        className="arrow-down position-absolute d-flex align-items-center justify-content-center"
                      />
                    </div>
                    <div className="form-input">
                      <label for="company" className="d-block">
                        Company
                      </label>
                      <input
                        type="text"
                        placeholder="e.g. Doe Industries"
                        className="input-field w-100 border-0"
                        id="company"
                      />
                    </div>
                  </div>
                  <div className="form-input-wrapper d-grid">
                    <div className="form-input position-relative">
                      <label for="job-title" className="d-block">
                        Job Title
                      </label>
                      <select
                        name="job-title"
                        id="job-title"
                        className="input-field w-100 border-0"
                      >
                        <option value="1">C-Suite</option>
                        <option value="2">C-Suite</option>
                        <option value="3">C-Suite</option>
                      </select>
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                        className="arrow-down position-absolute d-flex align-items-center justify-content-center"
                      />
                    </div>
                    <div className="form-input">
                      <label for="email" className="d-block">
                        Email Address
                      </label>
                      <input
                        type="text"
                        placeholder="e.g. johndoe@doe.com"
                        className="input-field w-100 border-0"
                        id="email"
                      />
                    </div>
                  </div>
                  <div className="form-input-wrapper d-grid">
                    <div className="form-input">
                      <label for="phone" className="d-block">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        placeholder="(555) 555-5555"
                        className="input-field w-100 border-0"
                        id="phone"
                      />
                    </div>
                    <div className="form-input position-relative">
                      <label for="support" className="d-block">
                        How can we support you?
                      </label>
                      <select
                        name="support"
                        id="support"
                        className="input-field w-100 border-0"
                      >
                        <option value="1">Select</option>
                        <option value="2">Option</option>
                        <option value="3">Option</option>
                      </select>
                      <img
                        src="assets/img/icons/arrow-down2.svg"
                        alt="arrow down"
                        className="arrow-down position-absolute d-flex align-items-center justify-content-center"
                      />
                    </div>
                  </div>
                  <div className="form-input">
                    <label for="subject" className="d-block">
                      Subject
                    </label>
                    <textarea
                      placeholder="(555) 555-5555"
                      className="input-field w-100 border-0"
                      id="subject"
                    ></textarea>
                  </div>
                  <button
                    type="submit"
                    className="form-btn theme-btn d-inline-block border-0"
                  >
                    Get Started
                  </button>
                </form> */}

                <form
                  onSubmit={handleSubmit}
                  className="contact-form bg-white d-flex flex-column"
                  data-aos="zoom-in"
                  data-aos-delay="50"
                >
                  <div className="form-input-wrapper d-grid">
                    <div className="form-input">
                      <label htmlFor="f-name" className="d-block">
                        First Name
                      </label>
                      <input
                        type="text"
                        name="firstName"
                        placeholder="e.g. John"
                        className="input-field w-100 border-0"
                        id="f-name"
                        value={formData.firstName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-input">
                      <label htmlFor="l-name" className="d-block">
                        Last Name
                      </label>
                      <input
                        type="text"
                        name="lastName"
                        placeholder="e.g. Doe"
                        className="input-field w-100 border-0"
                        id="l-name"
                        value={formData.lastName}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-input-wrapper d-grid">
                    <div className="form-input position-relative">
                      <label htmlFor="country" className="d-block">
                        Country
                      </label>
                      <select
                        name="country"
                        id="country"
                        className="input-field w-100 border-0"
                        value={formData.country}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Country</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Maldives">Maldives</option>
                      </select>
                    </div>
                    <div className="form-input">
                      <label htmlFor="company" className="d-block">
                        Company
                      </label>
                      <input
                        type="text"
                        name="company"
                        placeholder="e.g. Doe Industries"
                        className="input-field w-100 border-0"
                        id="company"
                        value={formData.company}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="form-input-wrapper d-grid">
                    <div className="form-input position-relative">
                      <label htmlFor="job-title" className="d-block">
                        Job Title
                      </label>
                      <select
                        name="jobTitle"
                        id="job-title"
                        className="input-field w-100 border-0"
                        value={formData.jobTitle}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Job Title</option>
                        <option value="C-Suite">C-Suite</option>
                        <option value="Manager">Manager</option>
                        <option value="Developer">Developer</option>
                      </select>
                    </div>
                    <div className="form-input">
                      <label htmlFor="email" className="d-block">
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="email"
                        placeholder="e.g. johndoe@doe.com"
                        className="input-field w-100 border-0"
                        id="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>

                  <div className="form-input-wrapper d-grid">
                    <div className="form-input">
                      <label htmlFor="phone" className="d-block">
                        Phone Number
                      </label>
                      <input
                        type="tel"
                        name="phone"
                        placeholder="(555) 555-5555"
                        className="input-field w-100 border-0"
                        id="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-input position-relative">
                      <label htmlFor="support" className="d-block">
                        How can we support you?
                      </label>
                      <select
                        name="support"
                        id="support"
                        className="input-field w-100 border-0"
                        value={formData.support}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select</option>
                        <option value="Consultation">Consultation</option>
                        <option value="Technical Support">
                          Technical Support
                        </option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-input">
                    <label htmlFor="subject" className="d-block">
                      Subject
                    </label>
                    <textarea
                      name="subject"
                      placeholder="Enter your message here..."
                      className="input-field w-100 border-0"
                      id="subject"
                      value={formData.subject}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="form-btn theme-btn d-inline-block border-0"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Submitting..." : "Get Started"}
                  </button>

                  {successMessage && (
                    <p className="success-message">{successMessage}</p>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* hero section end  */}

      <section className="itump-mobile-section bg-light p-t-145 p-t-xl-120 p-t-lg-100 p-t-md-80 p-t-xs-60 p-b-145 p-b-xl-120 p-b-lg-100 p-b-md-80 p-b-xs-40">
        <div className="container">
          <ItumpMobile className="style-light" />
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default Contact;
