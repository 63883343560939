import React from "react";
import { Link } from "react-router";

const ItumpMobile = ({ className }) => {
  return (
    <>
      <div className="col-xl-12">
        <div className={`itump-card-layout-2 ${className}`}>
          <div className="itump-card-content m-b-70 m-b-md-50 m-b-xs-40">
            <h2
              className="itump-card-title"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              Itump Mobile <br className="d-none d-md-block" />
              Is Here
            </h2>
            <p
              className="itump-card-body"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              Get Ready for Business on the Go with our native mobile
              applications coming soon
            </p>
            <div
              className="download-btn-wrapper d-flex align-items-center"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <Link
                to="https://apps.apple.com/us/app/itump/id6443640069"
                className="download-btn appstore-btn d-inline-block"
              >
                <img
                  src="assets/img/thumb/app.svg"
                  alt="app store"
                  className="w-100"
                />
              </Link>
              <Link
                to="#"
                className="download-btn playstore-btn d-inline-block"
              >
                <img
                  src="assets/img/thumb/play.svg"
                  alt="play store"
                  className="w-100"
                />
              </Link>
            </div>
          </div>
          <div
            className="itump-card-thumb-3"
            data-aos="zoom-in"
            data-aos-delay="200"
          >
            <img src="assets/img/thumb/thumb-27.png" alt="thumb" />
          </div>
        </div>
        <div className="itump-border"></div>
      </div>
    </>
  );
};

export default ItumpMobile;
