import React from "react";

const ReturnPolicy = () => {
    return (
        <div
            style={{
                color: "#333",
                maxWidth: "800px",
                lineHeight: "1.6",
            }}
        >
            <h1
                style={{
                    fontSize: "48px",
                    fontWeight: "bold",
                    marginBottom: "30px",
                    color: "#222",
                }}
            >
                RETURN POLICY
            </h1>
            <p style={{ fontWeight: "bold" }}>
                Last updated November 13, 2024
            </p>
            <div
                className="d-flex flex-column gap-5 "
                style={{
                    marginBottom: "30px",
                }}
            >
                <div className="d-flex flex-column gap-2">
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        REFUNDS
                    </p>
                    <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        All sales are final and no refund will be issued. 
                    </p>
                    <div>
                        Itump Order Placement and Refund Policy By placing an order or making a payment through the Itump platform, you acknowledge and agree
                        {" "}to the following terms and conditions. These terms are designed to protect the integrity of Itump&apos;s operations and its agreements
                        {" "}with third-party service providers. Due to the involvement of external partners in fulfilling tasks and services, all orders and payments
                        {" "}made on the platform are final and non-refundable. Order Placement Policy 1. Binding Commitment: Placing an order or making a payment
                        {" "}through the Itump platform constitutes a binding commitment. Once an order is submitted and confirmed, it cannot be canceled or modified.
                        {" "}2. No Refunds: Payments made for orders are final and non-refundable. This includes cases where services are delayed, interrupted, or affected
                        {" "}by external factors beyond Itump&apos;s control. 3. Third-Party Engagement: Many tasks and services are fulfilled through partnerships
                        {" "}with third-party providers. Payments made are used to engage these providers, and refunds cannot be processed once the order has been
                        {" "}initiated. User Responsibilities 1. Accuracy of Information: Users are responsible for ensuring that all information provided during
                        {" "}order placement is accurate and complete. Errors or omissions may result in delays or incomplete services, for which Itump is not
                        {" "}liable. 2. Order Review: Users are encouraged to review their orders carefully before submitting them, as changes cannot be made once
                        {" "}the order is confirmed. Limitations of Liability 1. Third-Party Dependencies: Itump is not responsible for the performance or delays
                        {" "}caused by third-party providers engaged to fulfill tasks or services.  2. Service Quality: While Itump works with reliable third-party
                        {" "}partners, it does not guarantee the quality or timelines of services delivered by these providers. 3. Force Majeure: Itump is not liable
                        {" "}for delays or failures in service due to events beyond its control, including but not limited to natural disasters, technical failures,
                        {" "}or third-party disruptions. Dispute Resolution 1. Service Issues: If an issue arises regarding the quality or delivery of services
                        {" "}Itump will work to mediate and resolve the matter with the relevant third-party provider. However, this does not entitle the user to a
                        {" "}refund. 2. Final Determination: Itump’s determination regarding disputes related to orders or payments is final and binding. Policy Amendments
                        {" "}1. Changes to Terms: Itump reserves the right to amend this policy at any time. Users will be notified of significant updates, and continued
                        {" "}use of the platform constitutes acceptance of the revised terms. Acknowledgment and Acceptance By placing an order or making a payment on the
                        {" "}Itump platform, you confirm the following: • You have read, understood, and agree to this Order Placement and Refund Policy.
                        {" "}• You acknowledge that all payments are final and non-refundable. • You accept full responsibility for ensuring the accuracy of
                        {" "}your order details and understand that errors cannot be corrected after confirmation. • You agree to hold Itump harmless from claims or
                        {" "}disputes related to third-party service delivery. For any questions or concerns regarding your order, contact Itump support prior to
                        {" "}submission. By proceeding, you accept these terms in full.
                    </div>
                </div>
                <div className="d-flex flex-column gap-2">
                    <p style={{ fontWeight: "bold", marginBottom: "10px", fontSize: "18px" }}>
                        QUESTIONS
                    </p>
                    <p style={{ fontWeight: "bold", marginBottom: "10px" }}>
                        If you have any questions concerning our return policy, please contact us at:
                    </p>
                    <div className="d-flex flex-column gap-2">
                        <span><a href="call:88887ITUMP">88887ITUMP</a></span>
                        <span><a href="mailto:help@itump.com">help@itump.com</a></span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ReturnPolicy
