import React from "react";
import { Link } from "react-router";
import HeaderSection from "../../component/HeaderSection";
import FooterSection from "../../component/FooterSection";
import CopyrightSection from "../../component/CopyrightSection";
import SlideBar from "../../component/SlideBar";

const SolutionsHealth = () => {
  return (
    <>
      <HeaderSection />

      <SlideBar />

      <section className="hero-section-5">
        <div className="container">
          <div className="row justify-content-center text-center">
            <div className="col-xl-9">
              <div className="hero-content mx-auto">
                <h2
                  className="hero-title"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  Keeping Your Business Compliant and On Track
                </h2>
                <p className="hero-sub" data-aos="fade-up" data-aos-delay="100">
                  Stay ahead of deadlines, manage documents, and ensure that
                  your business operations are seamlessly compliant across all
                  levels
                </p>
              </div>
              <div
                className="hero-btn-wrap"
                data-aos="fade-up"
                data-aos-delay="150"
              >
                <div className="get-started-btn-2">
                  <Link to="https://boi.itump.com/login">Get Started</Link>
                </div>
                <div className="learn-more-btn">
                  <Link to="https://boi.itump.com/login">Learn More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="steps-section p-b-275 p-b-xl-200 p-b-lg-140 p-b-md-100 p-b-xs-60">
        <img
          src="assets/img/shape/progress-shape.png"
          alt="shape"
          className="step-shape w-100 d-none d-md-block"
          data-aos="fade-up"
          data-aos-delay="50"
        />
        <div className="container">
          <div className="service-steps-wrapper style-2">
            <div
              className="service-steps-wrap border-0"
              data-aos="zoom-in"
              data-aos-delay="50"
            >
              <div className="icon">
                <img src="assets/img/icons/guidance-icon.svg" alt="icon" />
              </div>
              <h3>Personalized Guidance</h3>
              <p>
                Receive tailored advice and recommendations based on your
                specific business type and location.
              </p>
            </div>
            <div
              className="service-steps-wrap border-0"
              data-aos="zoom-in"
              data-aos-delay="100"
            >
              <div className="icon">
                <img src="assets/img/icons/step-icon.svg" alt="icon" />
              </div>
              <h3>Step-by-Step Instructions</h3>
              <p>
                Get clear, easy-to-follow instructions for completing essential
                compliance tasks.
              </p>
            </div>
            <div
              className="service-steps-wrap border-0"
              data-aos="zoom-in"
              data-aos-delay="150"
            >
              <div className="icon">
                <img src="assets/img/icons/track-icon.svg" alt="icon" />
              </div>
              <h3>Compliance Tracking</h3>
              <p>
                Monitor your compliance status in real-time and identify any
                potential issues before they become problems.
              </p>
            </div>
          </div>
          <div className="row align-items-center m-t-225 m-t-xl-180 m-t-lg-140 m-t-md-120 m-t-xs-120 m-b-230 m-b-200 m-b-xl-180 m-b-lg-140 m-b-md-120 m-b-xs-120">
            <div className="col-lg-6">
              <div className="finance-content-wrap m-b-md-60 m-b-xs-40">
                <h3 className="title" data-aos="fade-up" data-aos-delay="50">
                  Tailored guidance for your business
                </h3>
                <p className="text" data-aos="fade-up" data-aos-delay="100">
                  Itump Health is like having a business expert by your side,
                  giving you personalized advice on how to follow the rules. We
                  learn about your business and give you custom-made tips and
                  simple steps to keep your business safe. You're in control, so
                  you can follow our suggestions, mark things you've already
                  done, or skip things that don't apply to you. It's like a GPS
                  for your business, guiding you to success while making sure
                  you follow all the rules! With Itump Health, you'll save time
                  and feel confident knowing your business is on the right
                  track.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="seo-card text-center ms-lg-auto mx-auto"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img
                  src="assets/img/thumb/seo-card-img.png"
                  alt="seo img"
                  className="seo-card-img mx-auto w-100"
                />
                <h6 className="seo-card-title text-white fw-bold">
                  Improve Website SEO
                </h6>
                <p className="seo-card-desc">
                  <span className="fw-medium text-white">
                    Fix issues with this service:
                  </span>{" "}
                  help us understand if this service has been performed
                  elsewhere. We have in-house professionals that maybe available
                  to help with this, Kindly apply to fix this problem.
                </p>
                <Link
                  to="#"
                  className="seo-card-btn theme-btn w-100 d-block text-center"
                >
                  Fix this problem
                </Link>
                <div className="seo-card-btn-wrapper d-flex">
                  <Link
                    to="#"
                    className="seo-card-btn theme-btn with-border w-100 d-block text-center"
                  >
                    Done Already
                  </Link>
                  <Link
                    to="#"
                    className="seo-card-btn theme-btn with-border w-100 d-block text-center"
                  >
                    Not Interested
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="progress-thumb finance-thumb mx-auto"
                data-aos="zoom-in"
                data-aos-delay="50"
              >
                <img
                  src="assets/img/thumb/progress-img.png"
                  alt="thumb"
                  className="w-100"
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="progress-content finance-content-wrap p-t-40">
                <h3 className="title" data-aos="fade-up" data-aos-delay="100">
                  Real-time progress tracking
                </h3>
                <p className="text" data-aos="fade-up" data-aos-delay="150">
                  From compliance milestones to key operational metrics, this
                  module tracks your achievements in real time. Visual progress
                  indicators and detailed reports highlight areas of success
                  while identifying opportunities for improvement. Automated
                  tracking ensures you stay on top of deadlines, with accurate
                  percentage metrics providing actionable insights at a glance.
                  With Itump Health, you can confidently monitor, measure, and
                  optimize your business performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="suggestion-section transaction-section style-2 p-t-155 p-t-lg-120 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="row">
            <div className="col-md-7">
              <div className="suggestion-content transaction-content ms-0 p-t-30 p-t-xs-0 ps-0 p-b-40">
                <h3 data-aos="fade-up" data-aos-delay="50">
                  Smart Suggestions for Your Business
                </h3>
                <p data-aos="fade-up" data-aos-delay="100">
                  Itump Health doesn't just react to your needs; it anticipates
                  them. We analyze your recent progress and business activity to
                  provide proactive service suggestions that keep you ahead of
                  the curve. Whether it's reminding you about upcoming
                  deadlines, suggesting relevant licenses, or recommending
                  resources to optimize your operations, Itump Health acts as
                  your personal compliance assistant, ensuring you're always
                  prepared for the next step in your business journey.
                </p>
              </div>
            </div>
            <div className="col-md-5">
              <div
                className="suggestion-thumb transaction-thumb"
                data-aos="zoom-in"
                data-aos-delay="150"
              >
                <img src="assets/img/thumb/business-img.png" alt="thumb" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ready-section use-section position-relative z-1 p-t-210 p-t-xl-160 p-t-lg-120 p-t-md-80 p-t-xs-60">
        <div className="container">
          <div className="use-section-wrapper p-b-210 p-b-xl-160 p-b-lg-120 p-b-md-80 p-b-xs-60">
            <div className="row justify-content-center text-center">
              <div className="col-xl-9 col-lg-10">
                <div className="use-content common-title-wrap">
                  <h2
                    className="common-title"
                    data-aos="fade-up"
                    data-aos-delay="50"
                  >
                    Ready to ensure your business compliance?
                  </h2>
                  <p
                    className="common-title-body"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    Let Itump Health be your compliance partner. We'll guide you
                    every step of the way, so you can build a successful and
                    sustainable business.
                  </p>
                  <Link
                    to="https://boi.itump.com/login"
                    className="theme-btn"
                    data-aos="fade-up"
                    data-aos-delay="150"
                  >
                    Get Started <i className="fa fa-arrow-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterSection />

      <CopyrightSection />
    </>
  );
};

export default SolutionsHealth;
